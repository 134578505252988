import { Action, createReducer, on } from '@ngrx/store';
import { GeolocationActions } from './actions';
import { GeolocationState } from './state';

const initialState = new GeolocationState();

const reducer = createReducer(
  initialState,
  on(GeolocationActions.geolocate, (state) => ({
    ...state,
    isGeolocating: true
  })),
  on(GeolocationActions.geolocateComplete, (state) => ({
    ...state,
    isGeolocating: false
  }))
);

export const geolocationReducer = (
  state: GeolocationState | undefined,
  action: Action
): GeolocationState => reducer(state, action);
