import { Expose, Type } from 'class-transformer';
import { Route } from './route';

export class DirectionsResponse {
  @Expose()
  @Type(() => Route)
  public routes: Array<Route>;

  constructor(model: DirectionsResponse) {
    Object.assign(this, model);
  }
}
