<div [class.collapsible-field-opened]="!isCollapsed" class="collapsible-field">
  <button (click)="triggerButtonClciked()" class="collapsible-field-trigger">
    <div class="collapsible-field-chevron">
      <icon type="angle-right" [color]="isCollapsed ? 'black' : 'grays-silver'"></icon>
    </div>
    <div class="collapsible-field-info">
      <div class="collapsible-field-title">
        {{ title }}
      </div>
      {{ info }}
    </div>
    <div class="collapsible-field-side">
      <ng-content select="[side]"></ng-content>
    </div>
  </button>
  <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
    (scrolled)="contentScrolled()" [style.maxHeight]="isCollapsed ? '0' : maxHeight"
    class="collapsible-field-content hidden-scroll">
    <ng-content></ng-content>
  </div>
</div>
