import { PaginationRequest } from '@shared/pagination';
import { PromocodeIncludeField } from '../types';

export class PromocodePaginationRequest extends PaginationRequest<
  string,
  string,
  string,
  PromocodeIncludeField
> {
  constructor(model: Partial<PromocodePaginationRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
