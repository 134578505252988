import { PaginationRequest } from '@shared/pagination';
import { Expose } from 'class-transformer';
import { ClientPreferenceOptionRelation } from '../types';

export class SearchClientPreferenceOptionsRequest extends PaginationRequest<ClientPreferenceOptionRelation> {
  @Expose({ name: 'laundry_id' })
  public laundryID?: number;

  @Expose({ name: 'service_id' })
  public serviceID?: number;

  constructor(model: Partial<SearchClientPreferenceOptionsRequest>) {
    super(model);

    Object.assign(this, model);
  }
}
