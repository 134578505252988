import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpEvent, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { Injectable, makeStateKey, TransferState } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrowserStateInterceptor implements HttpInterceptor {
  constructor(
    private transferState: TransferState
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== 'GET') {
      return next.handle(req);
    }

    const stateKey = makeStateKey<Array<HttpResponseBase>>(req.urlWithParams);
    const savedResponses = this.transferState.get(stateKey, []);

    if (savedResponses.length) {
      const storedResponse = savedResponses.pop();

      this.transferState.set(stateKey, savedResponses);

      return (storedResponse.ok)
        ? of(new HttpResponse(storedResponse))
        : throwError(() => new HttpErrorResponse(storedResponse));
    }

    return next.handle(req);
  }
}
