<ng-container *ngIf="userZip$ | async as userZip">
  <ng-container *ngIf="geolocationZip$ | async as geolocationZip">
    <ng-container *ngIf="geolocationZip!=userZip.code">
      <div *ngVar="'SHARED.LOCATION_MISMATCH.' as translations" class="mismatch">
        <div class="mismatch-main">

          <icon type="different-location" color="purple"></icon>
          {{ translations + 'TEXT_LOCATION_FAR_AWAY' | translate }}
          <icon [tooltip]="translations + 'TEXT_UPDATE_LOCATION' | translate" type="information-outline" color="purple"
                class="mismatch-tooltip">
          </icon>
        </div>
        <p class="mismatch-info">
          {{ translations + 'TEXT_UPDATE_LOCATION' | translate }}
        </p>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
