import { TransformDate, TransformDateFormat } from '@shared/transform-date';
import { Expose } from 'class-transformer';
import { DateTime } from 'luxon';

export class PaginationRequest<
  Relation = string,
  WithCount = string,
  OrderBy = string,
  IncludeField = string
> {
  @Expose()
  public page?: number;

  @Expose({ name: 'per_page' })
  public perPage?: number;

  @Expose()
  public all?: boolean;

  @Expose()
  public query?: string;

  @Expose({ name: 'order_by' })
  public orderBy?: OrderBy;

  @Expose()
  public desc?: boolean;

  @Expose()
  @TransformDate(TransformDateFormat.SQL)
  public from?: DateTime;

  @Expose()
  @TransformDate(TransformDateFormat.SQL)
  public to?: DateTime;

  @Expose({ name: 'with', toPlainOnly: true })
  public relations?: Array<Relation>;

  @Expose({ name: 'with_count', toPlainOnly: true })
  public withCount?: Array<WithCount>;

  @Expose({ name: 'include_fields', toPlainOnly: true })
  public includeFields?: Array<IncludeField>;

  constructor(model: Partial<PaginationRequest<Relation, WithCount, OrderBy>> = {}) {
    Object.assign(this, model);
  }
}
