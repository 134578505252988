export class SocialAuthData {
  public token: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;

  constructor(model: Partial<SocialAuthData>) {
    Object.assign(this, model);
  }
}
