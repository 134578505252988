import {NgModule} from '@angular/core';
import {NoInternetComponent} from '@app/public/no-internet/no-internet.component';
import {ContentComponent} from '@shared/content';
import {InviteFriendsComponent} from '@shared/invite-friends';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {PageModule} from '@shared/page';
import {PublicNotFoundPageModule} from '@app/public/not-found/not-found.module';
import {FooterModule} from '@shared/footer';
import {NavbarModule} from '@shared/navbar';
import {WebviewHiddenDirective} from '@shared/webview-hidden';

@NgModule({
  exports: [
    NoInternetComponent
  ],
  imports: [
    ContentComponent,
    InviteFriendsComponent,
    RouterLink,
    TranslateModule,
    PageModule,
    PublicNotFoundPageModule,
    FooterModule,
    NavbarModule,
    WebviewHiddenDirective
  ],
  declarations: [NoInternetComponent]
})

export class NoInternetModule {}
