import { NgModule } from '@angular/core';
import { provideDialogConfig } from '@ngneat/dialog';
import { AppDialogService } from './dialog.service';
import { APP_DIALOG_CONFIG } from './config';

@NgModule({
  providers: [
    AppDialogService,
    provideDialogConfig(APP_DIALOG_CONFIG)
  ]
})
export class DialogModule { }
