<div class="section-layout">
  <navbar></navbar>
  <div class="section-layout-body">
    <page *ngVar="'PUBLIC.NO_INTERNET.' as translations" class="promo no-internet">
      <section class="no-internet-top">
        <content class="no-internet-content">
          <div class="no-internet-image">
            <not-found-stars></not-found-stars>
            <not-found-ufo></not-found-ufo>
          </div>
          <h2>
            {{ translations + 'TEXT_UH_OH' | translate }}
          </h2>
          <p>
            {{ translations + 'TEXT_NO_INTERNET' | translate }}
          </p>
        </content>
      </section>
      <invite-friends></invite-friends>
    </page>
  </div>
  <footer *webviewHidden></footer>
</div>

