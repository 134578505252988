<div *ngVar="'SHARED.INVITE_FRIENDS.' as translations" class="invite">
  <content [size]="isNewDesign ? 'default-new' : 'large'" class="invite-content">
    <img ngSrc="/assets/images/dancers318x253.svg" class="invite-image" width="318" height="253" alt="Hamperapp invent friend" />
    <div class="invite-body">
      <p animateOnScroll animationClass="fliponscroll-active" [offset]="0" class="fliponscroll invite-title hidden-mobile">
        {{ translations + ( isNewDesign ? 'TEXT_GIFT_CARD_NEW' : 'TEXT_GIFT_CARD') | translate }}
      </p>

      <p *ngIf="isNewDesign" animateOnScroll animationClass="fliponscroll-active" [offset]="0" class="fliponscroll invite-title-big visible-mobile">
        {{ translations + 'TEXT_GIFT_GIVE' | translate }}
      </p>
      <p animateOnScroll animationClass="fliponscroll-active" [offset]="0" class="fliponscroll invite-title visible-mobile">
        {{ translations + ( isNewDesign ? 'TEXT_GIFT_GIVE_AFTER' : 'TEXT_GIFT_CARD') | translate }}
      </p>
      <a animateOnScroll animationClass="fliponscroll-active" [offset]="0" routerLink="/refer-a-friend"
        class="invite-button fliponscroll">
        <app-button variant="purple" customClass="flare">
          {{ translations + 'BUTTON_INVITE' | translate }}
          <icon type="arrow-right" color="default"></icon>
        </app-button>
      </a>
    </div>
  </content>
</div>
