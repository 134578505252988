import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScriptModule } from '@shared/script';
import { GeolocationService } from './geolocation.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { geolocationReducer, GeolocationEffects } from './store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ScriptModule,
    StoreModule.forFeature('geolocation', geolocationReducer),
    EffectsModule.forFeature([GeolocationEffects])
  ],
  providers: [GeolocationService]
})
export class GeolocationModule { }

