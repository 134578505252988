import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseDialogDirective } from '@shared/base-dialog';

@Component({
  selector: 'summary-service-fee-info',
  templateUrl: 'service-fee-info.html',
  styleUrls: ['service-fee-info.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderSummaryServiceFeeInfoComponent extends BaseDialogDirective<Record<string, never>> {
  public static width = '380px';
}
