import { Expose, Type } from 'class-transformer';
import { NamedEntity } from '@shared/base-entity';
import { LocationType } from '../enums';

export class Location extends NamedEntity {
  @Expose()
  public code: string;

  @Expose()
  public title: string;

  @Expose()
  public zip: string;

  @Expose({ toClassOnly: true })
  public description: string;

  @Expose()
  public type: LocationType;

  @Expose()
  @Type(() => Location)
  public parent: Location;

  @Expose()
  @Type(() => Location)
  public children: Array<Location>;

  @Expose()
  @Type(() => Location)
  public states_nearby: Array<Location>;

  @Expose()
  @Type(() => Location)
  public cities: Array<Location>;

  @Expose()
  @Type(() => Location)
  public cities_nearby: Array<Location>;

  @Expose()
  public iso: string;

  public get viewName(): string {
    return this.title;
  }

  constructor(model: Partial<Location>) {
    super(model);
    Object.assign(this, model);
  }

  public getParentOfType(type: LocationType): Location | null {
    if (this.type === type) {
      return this;
    }

    return this.parent?.getParentOfType(type) || null;
  }
}
