import { TransformDate, TransformDateFormat } from '@shared/transform-date';
import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { AvailabilityInterval } from './availability-interval';

export class Availability {
  @Expose()
  @TransformDate(TransformDateFormat.SQL_DATE)
  public date: DateTime;

  @Expose()
  @Type(() => AvailabilityInterval)
  public intervals: Array<AvailabilityInterval>;

  constructor(model: Partial<Availability>) {
    Object.assign(this, model);
  }

  public insertInterval(newInterval: AvailabilityInterval): void {
    let newIntervalIndex = this.intervals.findIndex((interval) => +interval.from >= +newInterval.from);
    newIntervalIndex = (newIntervalIndex === -1) ? this.intervals.length : newIntervalIndex;

    if (+this.intervals[newIntervalIndex]?.from === +newInterval.from) {
      return;
    }

    const newIntervals = [...this.intervals];
    newIntervals.splice(newIntervalIndex, 0, newInterval);

    this.intervals = newIntervals;
  }
}
