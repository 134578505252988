import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import Hotjar from '@hotjar/browser';
import {configuration} from '@configurations';
import { isPlatformBrowser } from '@angular/common';

@NgModule()
export class HotjarModule {
  constructor(
    @Inject(PLATFORM_ID) private platformID: object
  ) {
    if (isPlatformBrowser(this.platformID)) {
      Hotjar.init(Number(configuration.hotJarId), 6)
    }
  }
}
