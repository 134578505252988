import { Injectable } from '@angular/core';
import { ReadableEntityService } from '@shared/base-entity';
import { ServicePreference, ServicePreferencePaginationRequest } from './models';

@Injectable({
  providedIn: 'root'
})
export class ServicePreferenceService extends ReadableEntityService<
  ServicePreference,
  ServicePreferencePaginationRequest
> {
  constructor() {
    super();

    this.endpoint = '/service-preferences';
    this.entityConstructor = ServicePreference;
    this.entityPaginationRequestConstructor = ServicePreferencePaginationRequest;
  }
}
