import { createAction, props } from '@ngrx/store';

export class GeolocationViewActions {
  public static resetState = createAction(
    '[GeolocationView] Reset State'
  );

  public static tryPredictZip = createAction(
    '[GeolocationView] Try Predict Zip'
  );

  public static predictZipStarted = createAction(
    '[GeolocationView] Predict Zip Started'
  );

  public static predictZipSuccess = createAction(
    '[GeolocationView] Predict Zip Success',
    props<{ zipCode: string }>()
  );

  public static predictZipFailure = createAction(
    '[GeolocationView] Predict Zip Failure'
  );

  public static clearPredictedZip = createAction(
    '[GeolocationView] Clear Predicted Zip'
  );

  public static tryUpdateZip = createAction(
    '[GeolocationView] Try Update Zip',
    props<{ dialogID: string; zipCode: string ;isRedirectToCity?: boolean }>()
  );

  public static closeInfoPopup = createAction(
    '[GeolocationView] Close Info Popup'
  );
}
