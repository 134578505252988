<div *ngIf="label" class="form-group-label">
  {{ label }}
</div>
<div *ngIf="title" class="form-group-title">{{ title }}</div>

<div [class.loading]="isLoading"
  class="form-group-control loading-right loading-primary loading-thin loading-clickable">
  <ng-content></ng-content>
  <div *ngIf="hasMessageLimit" class="form-limit" [class.form-limit-error]="messageControlState?.length === limit">
    <span>{{ 'SHARED.FORM_GROUP.LIMIT' | translate }} {{messageControlState?.length}} / {{limit}}</span>
  </div>
</div>
<ng-content select=".form-group-additional"></ng-content>
<div *ngIf="hasError" [attr.data-test]="testID + '-error'" class="form-error">
  <span *ngIf="hasRequiredError">
    {{ 'SHARED.FORM_GROUP.TEXT_REQUIRED_ERROR' | translate }}
  </span>
  <span *ngIf="!hasRequiredError">
    {{ invalidValueError || errorMessage }}
  </span>
</div>
