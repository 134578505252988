import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Address } from '@shared/address';

@Pipe({
  standalone: true,
  name: 'addressInfo'
})
export class AddressInfoPipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  public transform(addressOrString: string | Address, options?: { apt?: string; gatecode?: string; buildingName?: string }): string {
    if (!addressOrString) {
      return null;
    }
    let address: string; let apt: string; let gatecode: string; let buildingName: string;
    if (addressOrString instanceof Address) {
      address = addressOrString.viewAddress;
      apt = addressOrString.apt;
      gatecode = addressOrString.gatecode;
      buildingName = addressOrString.buildingName;
    } else {
      address = addressOrString;
      apt = options?.apt;
      gatecode = options?.gatecode;
      buildingName = options?.buildingName;
    }
    if (apt) {
      address += `, ${this.translateService.instant('SHARED.ADDRESS_INFO.TEXT_APT', { apt })}`;
    }
    if (gatecode) {
      address += `, ${this.translateService.instant('SHARED.ADDRESS_INFO.TEXT_GATECODE', { gatecode })}`;
    }
    if (buildingName) {
      address += `, ${buildingName}`;
    }

    return address;
  }
}
