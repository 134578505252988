import { Injectable, Injector } from '@angular/core';
import { UserService as CommonUserService } from '@ronas-it/angular-common';
import { instanceToPlain, plainToClassFromExist, plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ClientPreferenceOption, SearchClientPreferenceOptionsRequest, UpdateClientPreferenceOptionsRequest, User } from './models';
import type { AppState } from '@shared/store';
import { Store } from '@ngrx/store';
import { UserEntityRequest } from './models/user-entity-request';
import { IntercomActions } from '@shared/intercom/store';
import { concat } from 'lodash';
import { PaginationResponse } from '@shared/pagination';

@Injectable()
export class UserService extends CommonUserService<User> {
  constructor(
    private store: Store<AppState>,
    injector: Injector
  ) {
    super(injector);
  }

  public override setProfile(user: User): void {
    return super.setProfile((user)
      ? new User({
        ...user,
        activePaymentMethod: user?.paymentMethods?.find((method) => method.isActive) || null
      })
      : null
    );
  }

  public override loadProfile(request?: Partial<UserEntityRequest>): Observable<User> {
    return this.fetchProfile(request).pipe(
      switchMap(() => this.profile$)
    );
  }

  public fetchProfile(request?: Partial<UserEntityRequest>): Observable<User> {
    const requestParams = new UserEntityRequest({
      ...request,
      relations: concat(
        request?.relations || [],
        'active_address.zip', 'photo', 'payment_methods', 'addresses.zip'
      )
    });

    return this.apiService.get('/profile', instanceToPlain(requestParams))
      .pipe(
        map((response) => plainToInstance(User, response)),
        tap((profile) => this.setProfile(profile))
      );
  }

  public update(request: Partial<User>): Observable<void> {
    const requestBody = new User(request);
    return this.apiService.put('/profile', instanceToPlain(requestBody, { excludeExtraneousValues: true }))
      .pipe(
        tap(() => {
          this.patchProfile(request);
          this.store.dispatch(IntercomActions.update({}));
        })
      );
  }

  public searchPreferenceOptions(
    request: SearchClientPreferenceOptionsRequest
  ): Observable<PaginationResponse<ClientPreferenceOption>> {
    const requestParams = new SearchClientPreferenceOptionsRequest(request);

    return this.apiService.get('/client-preference-options', instanceToPlain(requestParams))
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse(ClientPreferenceOption),
          response
        ))
      );
  }

  public updatePreferenceOptions(request: UpdateClientPreferenceOptionsRequest): Observable<void> {
    const requestParams = new UpdateClientPreferenceOptionsRequest(request);

    return this.apiService.post('/client-preference-options', instanceToPlain(requestParams));
  }
}
