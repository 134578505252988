import type { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { OrderItemsState } from './state';
import { FormGroupState } from 'ngrx-forms';
import { OrderItemsFiltersForm } from '../forms';
import { ItemCategory } from '@shared/item-category';
import { Service } from '@shared/service';

const selectFeature = (state: AppState): OrderItemsState => state.orderItems;

export class OrderItemsSelectors {
  public static service: MemoizedSelector<AppState, Service> = createSelector(
    selectFeature,
    (state: OrderItemsState) => state.service
  );

  public static filtersForm: MemoizedSelector<AppState, FormGroupState<OrderItemsFiltersForm>> = createSelector(
    selectFeature,
    (state: OrderItemsState) => state.filtersForm
  );

  public static categories: MemoizedSelector<AppState, Array<ItemCategory>> = createSelector(
    selectFeature,
    (state: OrderItemsState) => state.categories
  );

  public static isLoading: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: OrderItemsState) => state.isLoading
  );

  public static openedCategoriesIDs: MemoizedSelector<AppState, Array<number>> = createSelector(
    selectFeature,
    (state: OrderItemsState) => state.openedCategoriesIDs
  );
}
