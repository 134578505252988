import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IntercomActions } from '@shared/intercom/store';
import { ItemForm, ServiceItem } from '@shared/item';
import { ItemCategory } from '@shared/item-category';
import { Service } from '@shared/service';
import type { AppState } from '@shared/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { OrderItemsFiltersForm } from './forms';
import { OrderItemsActions, OrderItemsSelectors } from './store';

@Injectable()
export class OrderItemsFacade {
  public get filtersForm$(): Observable<FormGroupState<OrderItemsFiltersForm>> {
    return this.store.select(OrderItemsSelectors.filtersForm);
  }

  public get categories$(): Observable<Array<ItemCategory>> {
    return this.store.select(OrderItemsSelectors.categories);
  }

  public get isLoading$(): Observable<boolean> {
    return this.store.select(OrderItemsSelectors.isLoading);
  }

  public get openedCategoriesIDs$(): Observable<Array<number>> {
    return this.store.select(OrderItemsSelectors.openedCategoriesIDs);
  }

  constructor(
    private store: Store<AppState>
  ) { }

  public init(service: Service): void {
    this.store.dispatch(OrderItemsActions.init({ service }));
  }

  public openIntercom(): void {
    this.store.dispatch(IntercomActions.show({}));
  }

  public addItemInArray(item: ServiceItem, items: Array<ItemForm>): Array<ItemForm> {
    if (!items?.find((itemForm) => itemForm.id === item.id)) {
      return [...(items || []), ItemForm.fromEntity(item)];
    }

    return items.map((itemForm) => itemForm.id === item.id
      ? ItemForm.fromEntity(item, itemForm.count + 1)
      : itemForm
    );
  }

  public removeItemFromArray(item: ServiceItem, items: Array<ItemForm>): Array<ItemForm> {
    return items
      ?.map((itemForm) => itemForm.id === item.id
        ? ItemForm.fromEntity(item, itemForm.count - 1)
        : itemForm
      )
      .filter((itemForm) => itemForm.count > 0) || [];
  }

  public openCategory(categoryID: number): void {
    this.store.dispatch(OrderItemsActions.openCategory({ categoryID }));
  }

  public closeCategory(categoryID: number): void {
    this.store.dispatch(OrderItemsActions.closeCategory({ categoryID }));
  }
}
