import { inject } from '@angular/core';
import { BaseServicePageSelectors } from './selectors';
import { BaseServicePageActions } from './actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import type { AppState } from '@shared/store';
import { catchError, debounceTime, distinctUntilChanged, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { CalculateOrderRequest, OrderService } from '@shared/order';
import { isEqual } from 'lodash';
import { instanceToPlain } from 'class-transformer';
import { ErrorHandlingService } from '@shared/error-handling';
import { BaseServicePageState } from './state';
import {UserZipSelectors} from "@shared/user";

export class BaseServicePageEffects<State extends BaseServicePageState> {
  public actions$: Actions = inject(Actions);
  public store: Store<AppState> = inject(Store);

  public tryCalculateOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.tryCalculateOrder),
      withLatestFrom(
        this.store.select(this.selectors.canCalculateOrder),
        this.store.select(UserZipSelectors.zip)
      ),
      filter(([_, canCalculateOrder]) => canCalculateOrder),
      map(([{ order},_,zip]) => {

        const request = CalculateOrderRequest.fromOrder(order,zip?.id);

        const isPromoCodeUsed = localStorage.getItem('isPromoCodeUsed')
        request.is_email = isPromoCodeUsed === 'false' || null
        return {
          request,
          plain: instanceToPlain(request, { excludeExtraneousValues: true })
        };
      }),
      withLatestFrom(this.store.select(this.selectors.summary)),
      distinctUntilChanged(([prev], [curr, summary]) => !!prev && !!summary && isEqual(prev.plain, curr?.plain)),
      tap(() => this.store.dispatch(this.actions.calculateOrder())),
      debounceTime(200),
      switchMap(([{ request }]) => this.orderService.calculate(request)
        .pipe(
          map((summary) => this.actions.calculateOrderSuccess({ summary })),
          catchError((response) => of(this.actions.calculateOrderFailure({ response })))
        )
      )
    )
  );

  protected orderService: OrderService;
  protected errorHandlingService: ErrorHandlingService;

  constructor(
    protected actions: BaseServicePageActions,
    protected selectors: BaseServicePageSelectors<State>
  ) {
    this.orderService = inject(OrderService);
    this.errorHandlingService = inject(ErrorHandlingService);
  }
}
