import { Expose } from 'class-transformer';

export class OrderSummaryFees {
  @Expose()
  public delivery: number;

  @Expose()
  public service: number;

  @Expose()
  public cancelation: number;

  @Expose({ name: 'price_remainings' })
  public priceRemainings: number;

  constructor(model: Partial<OrderSummaryFees>) {
    Object.assign(this, model);
  }
}
