import { Expose, Type } from 'class-transformer';
import { ServicePreferenceOption } from '@shared/service-preference';
import { BaseOrder } from './base-order';

export class OrderDraft extends BaseOrder {
  @Expose({ name: 'options' })
  @Type(() => ServicePreferenceOption)
  public override options: Array<ServicePreferenceOption>;

  constructor(model: Partial<OrderDraft>) {
    super(model);

    Object.assign(this, model);
  }
}
