import { IconType } from '@shared/icon';

export class SwitchOption<T = string | number> {
  public value: T;
  public title: string;
  public additional?: string;
  public iconType?: IconType;

  constructor(model: SwitchOption) {
    Object.assign(this, model);
  }
}
