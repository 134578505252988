import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MediaService } from './media.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [MediaService]
})
export class MediaModule { }

