import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@shared/icon';

@Component({
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    IconModule
  ],
  selector: 'show-button',
  templateUrl: 'show-button.html',
  styleUrls: ['show-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowButtonComponent {
  @Input() isOpened: boolean;
  @Input() showText: string;

  @Output() clicked: EventEmitter<void>;

  constructor() {
    this.clicked = new EventEmitter();
  }

  public buttonClicked(): void {
    this.clicked.emit();
  }
}
