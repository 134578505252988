import { isNil } from 'lodash';

export class LocalStorageObjectItem<T = object | Array<any>> {
  constructor(private key: string) { }

  public set(value: T): void {
    if (isNil(value)) {
      this.remove();
    } else {
      localStorage.setItem(this.key, JSON.stringify(value));
    }
  }

  public get(): T | null {
    try {
      return JSON.parse(localStorage.getItem(this.key));
    } catch {
      return null;
    }
  }

  public remove(): void {
    localStorage.removeItem(this.key);
  }
}
