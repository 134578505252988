import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { LogoFacade } from './logo.facade';
import { LogoVariant } from './types';

@Component({
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    RouterLink
  ],
  providers: [
    LogoFacade
  ],
  selector: 'logo',
  templateUrl: 'logo.html',
  styleUrls: ['logo.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {
  @Input() variant: LogoVariant;

  public isAuthenticated$: Observable<boolean>;

  constructor(private facade: LogoFacade) {
    this.variant = 'primary';

    this.isAuthenticated$ = this.facade.isAuthenticated$;
  }
}
