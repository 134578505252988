import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceViewComponent } from './service-view.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/button';
import { IconModule } from '@shared/icon';
import { ServiceViewNextComponent } from './components/next/next.component';
import { ServiceViewRemoveItemsComponent } from './components/remove-items/remove-items.component';
import { ServiceViewSkipSelectionComponent } from './components/skip-selection/skip-selection.component';
import {OrderSummaryModule} from "@shared/order-summary";

@NgModule({
  declarations: [
    ServiceViewComponent,
    ServiceViewNextComponent,
    ServiceViewRemoveItemsComponent,
    ServiceViewSkipSelectionComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        ButtonComponent,
        IconModule,
        OrderSummaryModule
    ],
  providers: [],
  exports: [
    ServiceViewComponent,
    ServiceViewNextComponent,
    ServiceViewRemoveItemsComponent,
    ServiceViewSkipSelectionComponent
  ]
})
export class ServiceViewModule { }
