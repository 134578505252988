import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import type { AppState } from '@shared/store';
import { Actions } from 'ngrx-forms';

@Injectable()
export class FormControlAddressFacade {
  constructor(private store: Store<AppState>) { }

  public dispatchAction(action: Actions<any>): void {
    this.store.dispatch(action);
  }
}
