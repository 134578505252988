import { PaymentSystem } from '../enums';

export class PaymentSystemData {
  public type: PaymentSystem;
  public name: string;

  constructor(model: Partial<PaymentSystemData>) {
    Object.assign(this, model);
  }
}
