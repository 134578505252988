import { ItemForm } from '@shared/item';
import { OrderSchedullingForm } from '@shared/order/forms';
import { box, Boxed } from 'ngrx-forms';

export class BaseServicePageForm {
  public schedulling: OrderSchedullingForm;
  public pounds: number;
  public items: Boxed<Array<ItemForm>>;

  constructor() {
    this.schedulling = new OrderSchedullingForm();
    this.pounds = 0;
    this.items = box([]);
  }
}
