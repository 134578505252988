import { createAction, props } from '@ngrx/store';
import { User } from '@shared/user';

export class IntercomActions {
  public static init = createAction(
    '[Intercom] Init',
    props<{ onSuccess?: () => void }>()
  );

  public static tryLogin = createAction(
    '[Intercom] Try Login',
    props<{ user: User; onSuccess?: () => void }>()
  );

  public static loggedIn = createAction(
    '[Intercom] Logged In',
    props<{ user: User }>()
  );

  public static logout = createAction(
    '[Intercom] Logout'
  );

  public static update = createAction(
    '[Intercom] Update',
    props<{ orderId?: string }>()
  );

  public static show = createAction(
    '[Intercom] Show',
    props<{ orderId?: string,isShowMassage?: boolean }>()
  );

  public static hide = createAction(
    '[Intercom] Hide'
  );
}
