<div *ngVar="'PRIVATE.SHARED.POUNDS_SELECTOR.' as translations" class="row pounds-selector">
  <div [ngClass]="'col-' + ((poundPrice) ? '80' : 'content')" class="col col-mobile-stretch">
    <form-control-counter [(ngModel)]="value" [valueTemplate]="valueTemplate" [min]="minPounds" variant="purple">
      <ng-template #valueTemplate let-value="value">
        {{ translations + 'TEXT_LBS' | translate: { count: value || 0 } }}
      </ng-template>
    </form-control-counter>
  </div>
  <div *ngIf="poundPrice" class="col col-20 pounds-selector-price">
    {{ (poundPrice * value) | currency }}
  </div>
  <div class="col col-stretch col-mobile-100 pounds-selector-notification">
    {{ translations + 'TEXT_NOTIFICATION' | translate }}
  </div>
</div>
