import { createAction, props } from '@ngrx/store';
import { SocialAuthData } from '@shared/firebase-auth/models';
import { ConnectionType } from '../enums';

export class AuthActions {
  public static authorized = createAction(
    '[Auth] Authorized'
  );

  public static unauthorized = createAction(
    '[Auth] Unathorized'
  );

  public static signInSocial = createAction(
    '[Auth] Sign In Social',
    props<{
      connection: ConnectionType;
      onSuccess?: (data: SocialAuthData) => void;
      onFailure?: (error: any) => void;
      retryCount?: number;
      isLogin?: boolean;
    }>()
  );


  public static logout = createAction(
    '[Auth] Logout'
  );
}
