import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Order, OrderSummary } from '@shared/order';
import { Service } from '@shared/service';
import type { AppState } from '@shared/store';
import { BaseServicePageStateName } from '../types';
import { BaseServicePageState } from './state';

export class BaseServicePageSelectors<State extends BaseServicePageState> {
  public get selectFeature(): (state: AppState) => State {
    return (state: AppState) => state[this.stateName] as unknown as State;
  }

  public isCalculating: MemoizedSelector<AppState, boolean> = createSelector(
    this.selectFeature,
    (state) => state?.isCalculating
  );

  public services: MemoizedSelector<AppState, Array<Service>> = createSelector(
    this.selectFeature,
    (state) => state?.services
  );

  public summary: MemoizedSelector<AppState, OrderSummary> = createSelector(
    this.selectFeature,
    (state) => state?.summary
  );

  public formState: MemoizedSelector<AppState, State['formState']> = createSelector(
    this.selectFeature,
    (state) => state?.formState
  );

  public formValue: MemoizedSelector<AppState, State['formState']['value']> = createSelector(
    this.formState,
    (formState) => formState?.value
  );

  public order: MemoizedSelector<AppState, Partial<Order>> = createSelector(
    this.selectFeature,
    (state) => state?.order
  );

  public stage: MemoizedSelector<AppState, State['stage']> = createSelector(
    this.selectFeature,
    (state) => state.stage
  );

  public canCalculateOrder: MemoizedSelector<AppState, boolean>;

  constructor(
    private stateName: BaseServicePageStateName
  ) { }
}
