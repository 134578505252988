import { PaginationRequest } from '@shared/pagination';
import { Expose } from 'class-transformer';

export class ServiceItemsByCategoryRequest extends PaginationRequest {
  @Expose({ name: 'service_id' })
  public serviceID: number;

  @Expose({ name: 'laundry_id' })
  public laundryID: number;

  constructor(model: Partial<ServiceItemsByCategoryRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
