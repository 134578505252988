import { isNil } from 'lodash';
import { DateTime } from 'luxon';

export class LocalStorageDateItem {
  constructor(private key: string) { }

  public set(value: DateTime): void {
    if (isNil(value)) {
      this.remove();
    } else if (value.isValid) {
      localStorage.setItem(this.key, value.toISO());
    }
  }

  public get(): DateTime | null {
    const date = DateTime.fromISO(localStorage.getItem(this.key) || '');

    return (date.isValid) ? date : null;
  }

  public remove(): void {
    localStorage.removeItem(this.key);
  }
}
