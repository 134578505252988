<ng-container *ngVar="isLoading$ | async as isLoading">
  <ng-container *ngVar="'SHARED.GEOLOCATION_VIEW.' as translations">
    <ng-container *ngVar="hasAddresses$ | async as hasAddresses">
      <ng-container *ngVar="zipAddress$ | async as zipAddress">
        <ng-container *ngVar="predictedZip$ | async as predictedZip">

          <div *ngIf="isVisible$ | async" class="geolocation">
            <button [class.loading]="isLoading" (click)="geolocationInputClicked(hasAddresses)"
              class="geolocation-address loading-primary">
              <div class="geolocation-address-icon">
                <icon type="arrow-right" color="white"></icon>
              </div>
              <div *ngIf="!zipAddress?.length && !predictedZip?.length" class="geolocation-address-placeholder">
                <p>{{translations + 'TEXT_WHERE' | translate}}</p>
                <p>{{translations + 'TEXT_ADD_ZIP' | translate}}</p>
              </div>
              <input [value]="zipAddress || predictedZip || ''"
                [placeholder]="''" [tooltip]="zipAddress"
                [display]="!!zipAddress && zipAddress.length > 30" [showDelay]="700" [hideDelay]="0" placement="bottom"
                readonly />
            </button>
            <geolocation-view-no-zip [isOpened]="shouldShowInfo$ | async" (closeClicked)="closeInfoClicked()">
            </geolocation-view-no-zip>
            <geolocation-view-predicted-zip [predictedZip]="!zipAddress && predictedZip"
              (closeClicked)="confirmationCloseClicked()" (noClicked)="confirmationNoClicked(hasAddresses)"
              (yesClicked)="confirmationYesClicked(predictedZip)">
            </geolocation-view-predicted-zip>
          </div>

        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #zipPicker let-ref>
    <geolocation-view-zip-picker [isRedirectToCity]="isRedirectToCity" [dialogRef]="ref" [isLoading]="isLoading">
    </geolocation-view-zip-picker>
  </ng-template>
  <ng-template #addressPicker let-ref>
    <address-picker [dialogRef]="ref" [user]="user$ | async">
    </address-picker>
  </ng-template>
</ng-container>
