import { BaseEntity } from '@shared/base-entity';
import { Media } from '@shared/media';
import { Expose, Type } from 'class-transformer';

export class DeliveryProof extends BaseEntity {
  @Expose({ name: 'order_id' })
  public orderID: number;

  @Expose({ name: 'photo_id' })
  public photoID: number;

  @Expose()
  @Type(() => Media)
  public photo: Media;

  @Expose()
  public latitude: number;

  @Expose()
  public longitude: number;

  constructor(model: Partial<DeliveryProof>) {
    super(model);
    Object.assign(this, model);
  }
}
