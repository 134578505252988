import { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PrivateServicesServicePageState } from './state';
import { Order } from '@shared/order';
import { Service } from '@shared/service';
import { BaseServicePageSelectors } from '@shared/base-service-page/store/selectors';
import { CreateOrderForm } from '../forms';
import { some } from 'lodash';

class Selectors extends BaseServicePageSelectors<PrivateServicesServicePageState> {
  public code: MemoizedSelector<AppState, string> = createSelector(
    this.selectFeature,
    (state) => state.code
  );

  public isLoading: MemoizedSelector<AppState, boolean> = createSelector(
    this.selectFeature,
    (state) => state.isLoading
  );

  public laundryService: MemoizedSelector<AppState, Service> = createSelector(
    this.selectFeature,
    (order) => order.laundryService
  );

  public currentOrder: MemoizedSelector<AppState, Order> = createSelector(
    this.formValue,
    this.order,
    (value, order) => new Order({
      ...order,
      ...CreateOrderForm.toEntity(value),
      laundryServiceID: order?.laundryService?.id,
      service: order?.laundryService?.service,
      serviceID: order?.laundryService?.parentID,
      laundry: order?.laundryService?.laundry,
      laundryID: order?.laundryService?.laundryID
    })
  );

  public isConfirming: MemoizedSelector<AppState, boolean> = createSelector(
    this.selectFeature,
    (state) => state.isConfirming
  );

  public isPrefillingOrder: MemoizedSelector<AppState, boolean> = createSelector(
    this.selectFeature,
    (state) => state.isPrefillingOrder
  );

  public isPrefilling: MemoizedSelector<AppState, boolean> = createSelector(
    this.isLoading,
    this.isPrefillingOrder,
    (...loadings) => some(loadings)
  );

  public override canCalculateOrder: MemoizedSelector<AppState, boolean> = createSelector(
    this.currentOrder,
    this.isConfirming,
    this.isPrefilling,
    (order, isConfirming, isPrefilling) => !!order && !order.id &&
      order.pickupTime?.isValid && order.deliveryTime?.isValid &&
      !isConfirming && !isPrefilling
  );

  public isInformationShowed: MemoizedSelector<AppState, boolean> = createSelector(
    this.selectFeature,
    (state) => state.isInformationShowed
  );

  constructor() {
    super('privateServicesServicePage');
  }
}

export const PrivateServicesServicePageSelectors = new Selectors();
