import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseUIDirective } from '@shared/base-ui';

@Component({
  selector: 'dialog-body',
  templateUrl: 'body.html',
  styleUrls: ['body.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogViewBodyComponent extends BaseUIDirective implements OnInit {
  @Input() isEmphasized: boolean;

  public ngOnInit(): void {
    this.updateHostClasses({
      'dialog-body-emphasized': this.isEmphasized
    });
  }
}

