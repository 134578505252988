import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  standalone: true,
  selector: 'img'
})
export class ImageDirective implements OnInit {
  @Input() placeholderVariant: 'default' | 'default-small' | 'primary';

  public element: HTMLImageElement;

  constructor(
    private elementRef: ElementRef
  ) {
    this.element = this.elementRef.nativeElement;
  }

  public ngOnInit(): void {
    if (!this.placeholderVariant) {
      return;
    }

    this.element.classList.add('image-placeholder');
    this.element.addEventListener('error', () => {
      this.element.classList.add(`image-placeholder-${this.placeholderVariant}`);
      this.element.classList.add('processed');
      this.element.parentElement?.classList.remove('skeleton');
    });
    this.element.addEventListener('load', () => {
      this.element.classList.add('processed');
      this.element.parentElement?.classList.remove('skeleton');
    });
  }
}
