import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api';
import { ItemCategory } from '@shared/item-category';
import { PaginationResponse } from '@shared/pagination';
import { instanceToPlain, plainToClassFromExist, plainToInstance } from 'class-transformer';
import { isUndefined, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceItem, ServiceItemsByCategoryRequest, ServiceItemsPaginationRequest } from './models';

@Injectable()
export class ServiceItemService {
  constructor(private apiService: ApiService) { }

  public searchTop(request: Partial<ServiceItemsPaginationRequest>): Observable<PaginationResponse<ServiceItem>> {
    const requestParams = new ServiceItemsPaginationRequest(request);

    return this.apiService.get('/service-items/top', pickBy(instanceToPlain(requestParams), (value) => !isUndefined(value)))
      .pipe(
        map((response) => plainToClassFromExist(new PaginationResponse(ServiceItem), response))
      );
  }

  public searchByCategory(request: Partial<ServiceItemsByCategoryRequest>): Observable<Array<ItemCategory>> {
    const requestParams = new ServiceItemsByCategoryRequest(request);

    return this.apiService.get<Array<ItemCategory>>('/service-items/by-category', pickBy(instanceToPlain(requestParams), (value) => !isUndefined(value)))
      .pipe(
        map((response) => plainToInstance(ItemCategory, response))
      );
  }
}
