import { Connection, ConnectionType } from '@shared/auth';
import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import { RegisterForm } from '../forms';

export class PublicRegisterFormState {
  public isSubmitting: boolean;
  public formState: FormGroupState<RegisterForm>;
  public currentConnection: Connection;
  public isEmailValidating: boolean;
  public isPhoneValidating: boolean;
  public checkingConnectionType: ConnectionType | null;
  public stage: number ;

  constructor() {
    this.isSubmitting = false;
    this.formState = createFormGroupState('RegisterForm', new RegisterForm());
    this.currentConnection = null;
    this.isEmailValidating = false;
    this.isPhoneValidating = false;
    this.checkingConnectionType = null;
    this.stage = 1;
  }
}
