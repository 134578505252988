import { BaseServicePageForm } from '@shared/base-service-page';
import { ItemForm, ServiceItem } from '@shared/item';
import { DropOffType, Order } from '@shared/order';
import { OrderSchedullingForm } from '@shared/order/forms';
import { PaymentSystem } from '@shared/payment';
import { PriceType } from '@shared/price';
import { ServicePreferenceOptionForm } from '@shared/service-preference';
import { truthyValidator } from '@shared/truthy-validator';
import { DateTime } from 'luxon';
import { box, Boxed, FormGroupState, setValue, unbox, updateGroup, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

export class CreateOrderForm extends BaseServicePageForm {
  public paymentMethodID: number;
  public paymentSystem: PaymentSystem;
  public tip: number;
  public tipType: PriceType;
  public note: string;
  public dropOffType: DropOffType;
  public pickupAddressID: number;
  public deliveryAddressID: number;
  public promocodeID: number;
  public bagsQuantity: number;
  public deliveryInstructions: string;
  public options: Boxed<Array<ServicePreferenceOptionForm>>;
  public isSaveOptions: boolean;

  constructor() {
    super();

    this.paymentMethodID = null;
    this.paymentSystem = null;
    this.tip = undefined;
    this.tipType = undefined;
    this.note = '';
    this.dropOffType = DropOffType.AT_THE_DOOR;
    this.pickupAddressID = undefined;
    this.deliveryAddressID = undefined;
    this.promocodeID = undefined;
    this.bagsQuantity = 1;
    this.deliveryInstructions = '';
    this.options = box([]);
    this.isSaveOptions = false;
  }

  public static prefillForm = (
    state: FormGroupState<CreateOrderForm>,
    order: Order
  ): FormGroupState<CreateOrderForm> => updateGroup<CreateOrderForm>(state, {
      schedulling: updateGroup({
        pickupTime: setValue(order.pickupTime.toISO()),
        pickupTo: setValue(order.pickupTo.toISO()),
        deliveryTime: setValue(order.deliveryTime.toISO()),
        deliveryTo: setValue(order.deliveryTo.toISO())
      }),
      dropOffType: setValue<string>(order.dropOffType),
      paymentMethodID: setValue(order.paymentMethodID),
      paymentSystem: setValue<string>(order.paymentSystem),
      pounds: setValue(order.pounds),
      tip: setValue(order.tip),
      tipType: setValue<string>(order.tipType),
      note: setValue(order.note),
      pickupAddressID: setValue(order.pickupAddress?.id),
      deliveryAddressID: setValue(order.deliveryAddress?.id),
      bagsQuantity: setValue(order.bagsQuantity || 1),
      items: setValue(box(order.items?.map((orderItem) => ItemForm.fromEntity(orderItem)) || [])),
      options: setValue(box(order.options?.map((orderOption) => ServicePreferenceOptionForm.fromEntity(orderOption)) || [])),
      deliveryInstructions: setValue(order.deliveryInstructions || ''),
      isSaveOptions: setValue(order.isSaveOptions)
    });

  public static validate = (
    state: FormGroupState<CreateOrderForm>,
    additional: { order?: Partial<Order> }
  ): FormGroupState<CreateOrderForm> => updateGroup<CreateOrderForm>(state, {
    schedulling: updateGroup<OrderSchedullingForm>({
      pickupTime: validate(required),
      deliveryTime: validate(required)
    }),
    paymentMethodID: validate<number>((state.value.paymentSystem) ? () => ({}) : required),
    pickupAddressID: validate<number>(required, truthyValidator(!!additional.order?.pickupAddress?.address?.length))
  });

  public static toEntity = (value: CreateOrderForm): Partial<Order> => ({
    ...value,
    pickupTime: DateTime.fromISO(value.schedulling.pickupTime),
    pickupTo: DateTime.fromISO(value.schedulling.pickupTo),
    deliveryTime: DateTime.fromISO(value.schedulling.deliveryTime),
    deliveryTo: DateTime.fromISO(value.schedulling.deliveryTo),
    items: unbox(value.items).map((formItem) => new ServiceItem(formItem)),
    options: unbox(value.options).map((option) => ServicePreferenceOptionForm.toEntity(option))
  });
}
