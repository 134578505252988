<dialog-view *ngVar="'SHARED.ORDER_SUMMARY.SERVICE_FEE_INFO.' as translations" variant="small" class="service-fee">
  <dialog-header variant="underline" class="service-fee-header">
    <dialog-content size="smaller">
      {{ translations + 'TEXT_TITLE' | translate }}
      <dialog-close (clicked)="close()"></dialog-close>
    </dialog-content>
  </dialog-header>
  <dialog-body>
    <dialog-content size="smaller">
      <div class="service-fee-image">
        <img ngSrc="/assets/images/service-fee-info-image222x135.svg" width="222" height="135" alt="icon"/>
      </div>
      <div class="service-fee-info">
        {{ translations + 'TEXT_INFO' | translate }}
      </div>
      <app-button variant="primary" textSize="big" [isBlock]="true" (clicked)="close()">
        {{ translations + 'BUTTON_OK' | translate }}
      </app-button>
    </dialog-content>
  </dialog-body>
</dialog-view>
