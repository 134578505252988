import { Injectable } from '@angular/core';
import { configuration } from '@configurations';
import { AppError, AppErrorCode } from '@shared/error-handling';
import { CanMakePaymentResult, PaymentMethod, PaymentRequest } from '@stripe/stripe-js';
import { plainToInstance } from 'class-transformer';
import { StripeService } from 'ngx-stripe';
import { catchError, exhaustMap, from, map, Observable, of, Subject, switchMap, take, throwError } from 'rxjs';
import { PaymentSystem } from './enums';
import { PaymentSystemsAvailability } from './models';

@Injectable()
export class PaymentService {
  paymentRequest:PaymentRequest=null;
  private get paymentRequest$(): Observable<PaymentRequest> {

    return this.stripeService.getStripeReference()
      .pipe(
        map(() => {
          if(!this.paymentRequest){
            this.paymentRequest = this.stripeService.paymentRequest({
              country: 'US',
              currency: 'usd',
              total: {
                amount: configuration.cardAuthorizationAmountLocal * 100,
                label: 'Hamperapp authorization'
              },
              disableWallets: ['browserCard','link']
            })
          }
          return this.paymentRequest;
        })
      );
  }

  constructor(
    private stripeService: StripeService
  ) { }

  public getPaymentSystemsAvailability(): Observable<PaymentSystemsAvailability> {
    return this.paymentRequest$
      .pipe(
        exhaustMap((paymentRequest) => from(paymentRequest.canMakePayment())
          .pipe(
            map(this.convertCanMakePaymentResult),
            catchError(() => {
              return of(new PaymentSystemsAvailability())
            })
          )
        ),
      );
  }

  public requestPaymentMethod(paymentSystem: PaymentSystem): Observable<PaymentMethod> {
    return this.paymentRequest$
      .pipe(
        take(1),
        exhaustMap((paymentRequest) => from(paymentRequest.canMakePayment())
          .pipe(
            map(this.convertCanMakePaymentResult),
            map((paymentSystemsAvailability) => {
              if (!paymentSystemsAvailability.isPaymentSystemAvailable(paymentSystem)) {
                throw new AppError(AppErrorCode.PAYMENT_SYSTEM_NOT_AVAILABLE);
              }

              return paymentRequest;
            }),
            catchError(() => throwError(() => new AppError(AppErrorCode.PAYMENT_SYSTEM_NOT_AVAILABLE)))
          )
        ),
        switchMap((paymentRequest) => {
          const paymentMethodSubject$ = new Subject<PaymentMethod>();
          paymentRequest.on('paymentmethod', (event) => {
            paymentMethodSubject$.next(event.paymentMethod);
            paymentMethodSubject$.complete();
            event.complete('success');
          });
          paymentRequest.on('cancel', () => {
            paymentMethodSubject$.error(new AppError(AppErrorCode.STRIPE_PAYMENT_METHOD_NOT_PROVIDED));
            paymentMethodSubject$.complete();
          });
          paymentRequest.show();

          return paymentMethodSubject$;
        })
      );
  }

  private convertCanMakePaymentResult(result: CanMakePaymentResult): PaymentSystemsAvailability {
    return (result)
      ? plainToInstance(PaymentSystemsAvailability, result)
      : new PaymentSystemsAvailability();
  }
}
