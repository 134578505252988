import { Directive, Input } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Directive()
export class BaseTemplateDialogDirective {
  @Input() dialogRef: DialogRef;

  public close(): void {
    this.dialogRef.close();
  }
}
