import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import {CanMatch} from '@angular/router';

@Injectable()
export class UnauthenticatedMatcher implements CanMatch {
  constructor(
    private authService: AuthService
  ) { }

  public canMatch(): Observable<boolean> {
    return this.authService.isAuthenticated$
      .pipe(
        map((isAuthenticated) => !isAuthenticated)
      );
  }
}
