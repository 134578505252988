import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'order-items-item-controls',
  templateUrl: 'item-controls.html',
  styleUrls: ['item-controls.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderItemsItemControlsComponent {
  @Input() count: number;

  @Output() decreased: EventEmitter<void>;
  @Output() increased: EventEmitter<void>;

  constructor() {
    this.decreased = new EventEmitter();
    this.increased = new EventEmitter();
  }

  public decreaseButtonClicked(): void {
    if (this.count > 0) {
      this.decreased.emit();
    }
  }

  public increaseButtonClicked(): void {
    this.increased.emit();
  }
}
