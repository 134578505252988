import 'reflect-metadata';
import { AppModule } from './app/app.module';
import { configuration } from '@configurations';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (configuration.production) {
  enableProdMode();
  window.console.log=()=>{}
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
});
