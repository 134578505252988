<div *ngVar="'SHARED.GEOLOCATION_VIEW.' as translations" [class.opened]="isOpened" class="geolocation-popup no-zip">
  <app-button variant="grays-silver" [isFlat]="true" (clicked)="closeButtonClicked()" class="geolocation-popup-close">
    <icon type="multiply" color="default"></icon>
  </app-button>
  <div class="geolocation-popup-content">
    <div class="no-zip-title">
      {{ translations + 'TEXT_ENTER_YOUR_ZIP' | translate }}
    </div>
    <p class="no-zip-text">
      {{ translations + 'TEXT_WE_CANT_SHOW' | translate }}
    </p>
  </div>
</div>
