import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ServiceItem } from '@shared/item';

@Component({
  selector: 'order-items-item',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderItemsItemComponent implements OnInit {
  @Input() item: ServiceItem;
  @Input() count: number;
  @Input() isReadonly: boolean;

  @Output() countIncreased: EventEmitter<void>;
  @Output() countDecreased: EventEmitter<void>;

  public isDescriptionExpanded: boolean;
  public shortDescription: string;

  constructor() {
    this.countIncreased = new EventEmitter<void>();
    this.countDecreased = new EventEmitter<void>();
    this.isDescriptionExpanded = true;
  }

  public ngOnInit(): void {
    if (this.item?.description?.length > 40) {
      this.isDescriptionExpanded = false;
      this.shortDescription = this.item.description.slice(0, 41);
    }
  }

  public increaseCountButtonClicked(): void {
    this.countIncreased.emit();
  }

  public decreaseCountButtonClicked(): void {
    this.countDecreased.emit();
  }
}
