import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseUIDirective } from '@shared/base-ui';

@Component({
  standalone: true,
  selector: 'content',
  template: '<ng-content></ng-content>',
  styleUrls: ['content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentComponent extends BaseUIDirective implements OnInit {
  @Input() size: 'default' | 'small' | 'smallest' | 'large' | 'large-new' | 'default-new';

  constructor() {
    super();

    this.size = 'default';
  }

  public ngOnInit(): void {
    this.updateHostClasses({
      content: true,
      [`content-${this.size}`]: true
    });
  }
}
