import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/base-form-control';

@Component({
  standalone: true,
  imports: [
    FormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlTextareaComponent),
      multi: true
    }
  ],
  selector: 'form-control-textarea',
  templateUrl: 'form-control-textarea.html',
  styleUrls: ['form-control-textarea.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlTextareaComponent extends BaseFormControlDirective<string> implements AfterViewInit {
  @Input() maxlength: string | number | null = 400;
  @Input() hasBorderLimit:boolean=false;
  @ViewChild('textarea') textarea: ElementRef;
  ngAfterViewInit() {
    this.setInitialHeight();
  }

  setInitialHeight() {
    const textarea = this.textarea.nativeElement;
    textarea.value = this.value;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  autoResize() {
    const textarea = this.textarea.nativeElement;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  public changeHandler(value: string): void {
    const regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    if (regexExp.test(value)) {
      const textarea = this.textarea.nativeElement;
      textarea.value = value.split(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi)[0];
    }
  }
}
