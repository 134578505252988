import { BaseServicePageForm } from '@shared/base-service-page/forms';
import { BaseServicePageState } from '@shared/base-service-page/store/state';
import { SeoPage } from '@shared/seo-page';
import { createFormGroupState } from 'ngrx-forms';
import { OrderCreationStage, PublicServicesServiceLocationsState } from '../models';

export class PublicServicesServicePageState extends BaseServicePageState<
  BaseServicePageForm,
  OrderCreationStage
> {
  public isLoadingInitialDates: boolean;
  public areServicesLoading: boolean;
  public isHandlingSuccessfulAuth: boolean;
  public settings: SeoPage;
  public serviceCode: string;
  public locationCode: string;
  public locationsState: PublicServicesServiceLocationsState;

  constructor() {
    super(
      createFormGroupState('PublicServicePageForm', new BaseServicePageForm())
    );

    this.isLoadingInitialDates = false;
    this.areServicesLoading = false;
    this.isHandlingSuccessfulAuth = false;
    this.stage = new OrderCreationStage({ name: 'items' });
    this.locationsState = new PublicServicesServiceLocationsState();
  }
}
