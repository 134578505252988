import { BaseEntity } from '@shared/base-entity';
import { ServiceItem } from '@shared/item/models';
import { Expose, Type } from 'class-transformer';

export class ItemCategory extends BaseEntity {
  @Expose()
  public name: string;

  @Expose()
  public emoji: string | null;

  @Expose({ name: 'emoji_background_color' })
  public emojiBackgroundColor: string | null;

  @Expose()
  @Type(() => ServiceItem)
  public items: Array<ServiceItem>;

  constructor(model: Partial<ItemCategory>) {
    super(model);
    Object.assign(this, model);
  }
}
