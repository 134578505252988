import { ShareButtonsConfig } from 'ngx-sharebuttons';

export const shareButtonsConfig: ShareButtonsConfig = {
  prop: {
    facebook: {
      params: {
        description: 'description'
      }
    }
  }
};
