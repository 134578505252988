import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SeoService } from './seo.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { seoReducer, SeoEffects } from './store';
import { SeoPageModule } from '@shared/seo-page';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('seo', seoReducer),
    EffectsModule.forFeature([SeoEffects]),
    SeoPageModule
  ],
  providers: [SeoService]
})
export class SeoModule { }

