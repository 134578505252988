import { Injectable } from '@angular/core';
import { MenuFacade } from '@shared/menu/menu.facade';
import { Observable } from 'rxjs';

@Injectable()
export class AppFacade {
  public get isMenuOpened$(): Observable<boolean> {
    return this.menuFacade.isOpened$;
  }
  constructor(
    private menuFacade: MenuFacade
  ) { }
}
