<a *ngIf="!href" [routerLink]="routerLink" [ngClass]="classes" [routerLinkActive]="routerLink ? activeClasses : ''"  (click)="navigate(routerLink)"
  [target]="target" [routerLinkActiveOptions]="{ exact: isExact }">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<a *ngIf="href" [href]="href" [ngClass]="classes" [target]="target">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
