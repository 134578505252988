import { Action, createReducer, on } from '@ngrx/store';
import { IntercomActions } from './actions';
import { IntercomState } from './state';

const initialState = new IntercomState();

const reducer = createReducer(
  initialState,
  on(IntercomActions.loggedIn, (state, { user }) => ({
    ...state,
    user
  })),
  on(IntercomActions.logout, (state) => ({
    ...state,
    user: null
  }))
);

export const intercomReducer = (
  state: IntercomState | undefined,
  action: Action
): IntercomState => reducer(state, action);
