<ng-container *ngVar="'SHARED.ORDER_ITEMS.CATEGORY.' as translations">
  <collapsible-field *ngVar="selectedCategoryItems | itemsCount as itemsCount" [isCollapsed]="isCollapsed"
    [title]="category.name"
    [info]="(itemsCount) ? (translations + 'TEXT_SELECTED' | translate: { count: itemsCount }) : ''" maxHeight="400px"
    (opened)="opened.emit()" (closed)="closed.emit()">
    <div *ngIf="category.emoji" side [ngStyle]="{ backgroundColor: category.emojiBackgroundColor || '' }"
      class="category-icon">
      {{ category.emoji }}
    </div>
    <div class="category-cards">
      <order-items-item *ngFor="let item of category.items; trackByProperty: 'id'" [item]="item"
        [count]="item | map : getItemCount : selectedCategoryItems" [isReadonly]="isReadonly"
        (countDecreased)="decreaseItemCountButtonClicked(item)" (countIncreased)="increaseItemCountButtonClicked(item)">
      </order-items-item>
    </div>
  </collapsible-field>
</ng-container>
