import {Injectable} from '@angular/core';
import {ConfirmationModalData, ConfirmationModalComponent} from '@shared/confirmation-modal';
import {Store} from '@ngrx/store';
import {AppDialogService} from '@shared/dialog';
import {Address, AddressForm} from '@shared/address';
import type {AppState} from '@shared/store';
import {UserAddressActions, UserAddressSelectors, UserRootActions, UserRootSelectors} from '@shared/user';
import {Observable} from 'rxjs';
import {PrivateServicesServicePageActions} from "@app/private/services/service/shared/store";
import {Order} from "@shared/order";
import {Router} from "@angular/router";

@Injectable()
export class AddressPickerFacade {
  public onOrderAddressSelect: (address: Address) => void;
  public onOrderAddressCreate: (address: Address) => void;
  public onOrderAddressUpdate: (address: Address) => void;

  public get isUpserting$(): Observable<boolean> {
    return this.store.select(UserAddressSelectors.isUpserting);
  }

  public get isDeleting$(): Observable<boolean> {
    return this.store.select(UserAddressSelectors.isDeleting);
  }

  public get isSelecting$(): Observable<boolean> {
    return this.store.select(UserAddressSelectors.isSelecting);
  }

  constructor(
    private store: Store<AppState>,
    private dialogService: AppDialogService,
    private router: Router,
  ) {
  }

  public init({onOrderAddressSelect, onOrderAddressCreate, onOrderAddressUpdate}: {
    onOrderAddressSelect: (address: Address) => void;
    onOrderAddressCreate: (address: Address) => void;
    onOrderAddressUpdate: (address: Address) => void;
  }): void {
    this.onOrderAddressSelect = onOrderAddressSelect;
    this.onOrderAddressCreate = onOrderAddressCreate;
    this.onOrderAddressUpdate = onOrderAddressUpdate;
  }
  public get activeOrder$(): Observable<Order> {
    return this.store.select(UserRootSelectors.activeOrder);
  }
  public select({address, dialogID}: {
    address: Address;
    dialogID: string;
    isSelectingDefault: boolean;
    withConfirmation: boolean;
  }): void {
     let order$ = this.activeOrder$.subscribe(order=>{
       if(order && !order?.laundryService?.hasZipCode(address.zipCode)){
         this.openChangeConfirmationDialog({
           onConfirm: () => {
             this.store.dispatch( UserRootActions.clearCurrentOrders())
             this.store.dispatch(UserAddressActions.select({
               address,
               onSuccess: () => {
                 this.store.dispatch(PrivateServicesServicePageActions.setAddress({address}));
                 this.dialogService.closeByID(dialogID)
               }
             }));
           }
         });
       }else{
         this.store.dispatch(UserAddressActions.select({
           address,
           onSuccess: () => {
             this.dialogService.closeByID(dialogID)
             this.onOrderAddressSelect(new Address({...address, isActive: true}));
             this.store.dispatch(PrivateServicesServicePageActions.setAddress({address}));
           }
         }));
       }
     })

      order$.unsubscribe();
  }

  public upsert({formValue, address, withConfirmation, dialogID}: {
    formValue: AddressForm;
    address?: Address;
    withConfirmation: boolean;
    dialogID?: string;
  }): void {
    if (withConfirmation) {
      this.openChangeConfirmationDialog({
        onConfirm: () => this.store.dispatch(UserAddressActions.upsert({
          request: {...formValue, id: address?.id},
          onSuccess: this.onUpsertAddressSuccess.bind(this),
          dialogID
        }))
      });
    } else {
      this.store.dispatch(UserAddressActions.tryUpsert({
        request: {...formValue, id: address?.id},
        onSuccess: this.onUpsertAddressSuccess.bind(this),
        dialogID
      }));
    }
  }

  public delete(address: Address, selectData: any,dialogId): void {
    this.dialogService.openComponent<ConfirmationModalData>(
      ConfirmationModalComponent, {
        data: {
          title: 'SHARED.ADDRESS_PICKER.TEXT_DELETE_ADDRESS',
          text: `SHARED.ADDRESS_PICKER.TEXT_DELETE_ADDRESS_CONFIRMATION`,
          cancelButtonText: 'SHARED.ADDRESS_PICKER.BUTTON_CANCEL',
          confirmButtonText: 'SHARED.ADDRESS_PICKER.BUTTON_DELETE_ADDRESS',
          isLoading$: this.isDeleting$,
          action: (dialogID: string) => {
            this.store.dispatch(UserAddressActions.delete({
              address,
              onSuccess: () => {
                this.dialogService.closeByID(dialogID)
                if (address.isActive) {
                  if(selectData.address){
                    this.select(selectData)
                  }else {
                    this.router.navigate([ '/services']);
                    this.dialogService.closeByID(dialogId)

                  }

                }

              }
            }));
          }
        }
      });
  }

  private openChangeConfirmationDialog({onConfirm}: {
    onConfirm: () => void;
  }): void {
    this.dialogService.openComponent<ConfirmationModalData>(ConfirmationModalComponent, {
      data: {
        title: 'SHARED.ADDRESS_PICKER.TEXT_CHANGE_LOCATION',
        text: 'SHARED.ADDRESS_PICKER.TEXT_CHANGE_LOCATION_WARN',
        cancelButtonText: 'SHARED.ADDRESS_PICKER.BUTTON_NO',
        confirmButtonText: 'SHARED.ADDRESS_PICKER.BUTTON_YES',
        action: (dialogID: string) => {
          this.dialogService.closeByID(dialogID);
          onConfirm();
        }
      }
    });
  }

  private onUpsertAddressSuccess(address: Address): void {
    if (address?.id) {
      this.onOrderAddressUpdate(address);
    } else {
      this.onOrderAddressCreate(address);
    }
    if (address.isActive) {
      this.store.dispatch(PrivateServicesServicePageActions.setAddress({address}));

    }
  }
}
