import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {DropOffType} from "@shared/order";

@Pipe({
  standalone: true,
  name: 'dropoffPreferenceName'
})
export class DropoffPreferenceNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  public transform(value: string): string {
    return this.translateService.instant(`SHARED.ORDER.DROP_OFF_TYPE.TEXT_${value?.toUpperCase() || DropOffType.AT_THE_DOOR}`);
  }
}
