import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ItemCategory } from '@shared/item-category';
import { Service } from '@shared/service';

export class OrderItemsActions {
  public static resetState = createAction(
    '[OrderItems] Reset State'
  );

  public static init = createAction(
    '[OrderItems] Init',
    props<{ service: Service }>()
  );

  public static tryLoadItems = createAction(
    '[OrderItems] Try Load Items'
  );

  public static loadItems = createAction(
    '[OrderItems] Load Items'
  );

  public static loadItemsSuccess = createAction(
    '[OrderItems] Load Items Success',
    props<{ categories: Array<ItemCategory> }>()
  );

  public static loadItemsFailure = createAction(
    '[OrderItems] Load Items Failure',
    props<{ response: HttpErrorResponse }>()
  );

  public static openCategory = createAction(
    '[OrderItems] Open Category',
    props<{ categoryID: number }>()
  );

  public static closeCategory = createAction(
    '[OrderItems] Close Category',
    props<{ categoryID: number }>()
  );
}
