import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseUIDirective } from '@shared/base-ui';
import { DialogViewVariant } from './types';

@Component({
  selector: 'dialog-view',
  templateUrl: 'dialog-view.html',
  styleUrls: ['dialog-view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogViewComponent extends BaseUIDirective implements OnInit {
  @Input() variant: DialogViewVariant;

  public ngOnInit(): void {
    this.updateHostClasses({
      [`dialog-${this.variant || 'expandable'}`]: true
    });
  }
}
