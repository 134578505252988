import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {configuration} from '@configurations';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ScrollService} from '@shared/scroll';
import {DateTime} from 'luxon';
import {Observable} from 'rxjs';
import {FooterFacade} from './footer.facade';
import {FooterLink} from './models';
import {Zip} from '@shared/zip';
import {Location} from '@shared/location/models/location';
import {Router, Scroll} from '@angular/router';
import {QuestionsSectionService} from "@shared/questions-section/questions-section.service";
import {debounceTime} from 'rxjs/operators';
import {LocationType} from "@shared/location";

@UntilDestroy()
@Component({
  selector: 'footer',
  templateUrl: 'footer.html',
  styleUrls: ['footer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  @ViewChild('footer', {static: true})
  footerElement: ElementRef<HTMLDivElement>;
  @ViewChild('footerSmartphone', {static: true})
  smartphoneElement: ElementRef<HTMLImageElement>;
  public userZip$: Observable<Zip>;
  public citiesInLoundryServiceAll: Array<FooterLink>;
  public citiesInDryCleaningAll: Array<FooterLink>;
  public isAuthenticated$: Observable<boolean>;
  public services: Array<FooterLink>;
  public additionalContentTemplate$: Observable<TemplateRef<any>>;
  public currentYear: number;
  public topLocations$: Observable<Array<Location>>;

  public get links(): typeof configuration.customerLinks {
    return configuration.customerLinks;
  }

  constructor(
    private facade: FooterFacade,
    private scrollService: ScrollService,
    private router: Router,
    private questionSectionService: QuestionsSectionService
  ) {
    this.citiesInLoundryServiceAll = this.facade.citiesInLoundryService;
    this.citiesInDryCleaningAll = this.facade.citiesInDryCleaning;
    this.services = this.facade.services;
    this.additionalContentTemplate$ = this.facade.additionalContentTemplate$;
    this.currentYear = DateTime.local().year;
    this.userZip$ = this.facade.userZip$;
    this.topLocations$ = this.facade.topLocations$;
    this.isAuthenticated$ = this.facade.isAuthenticated$;
  }

  public ngOnInit(): void {
    this.facade.loadTopLocations();
    this.scrollService.scrollEvents$
      .pipe(untilDestroyed(this))
      .subscribe({next: this.handleWindowScroll.bind(this)});

    this.router.events
      .pipe(untilDestroyed(this), debounceTime(600))
      .subscribe(event => {
        if (event instanceof Scroll) {
          if (event.routerEvent.url.includes('sectionId=questionsSectionId')) {
            const section: HTMLElement = this.questionSectionService.getElementToScroll().nativeElement
            section.scrollIntoView({behavior: 'smooth'})
          }
        }
      })
  }

  public scrollToSection() {

    if (!this.questionSectionService.getElementToScroll()) {
      return this.router.navigateByUrl(`/?sectionId=questionsSectionId`)
    }

    const section: HTMLElement = this.questionSectionService.getElementToScroll().nativeElement
    section.scrollIntoView({behavior: 'smooth'})
  }

  private handleWindowScroll(): void {
    const scrolledFooterHeight = this.scrollService.getScrolledHeight(
      this.footerElement.nativeElement
    );
    if (scrolledFooterHeight >= 0) {
      const footerHeight =
        this.footerElement.nativeElement.getBoundingClientRect().height;
      const rotation = 45 - (scrolledFooterHeight / footerHeight) * 45;
      this.smartphoneElement.nativeElement.style.transform = `rotate(${rotation}deg)`;
    }
  }

  protected readonly LocationType = LocationType;
}
