import { Expose } from 'class-transformer';

export class ItemPivot {
  @Expose()
  public count: number;

  @Expose()
  public price: number;

  @Expose()
  public title: string;

  @Expose()
  public link: string;

  @Expose()
  public pivot_item_id: number;

  constructor(model: Partial<ItemPivot>) {
    Object.assign(this, model);
  }
}
