import { PaymentStatus } from '@shared/payment-method';
import { PriceType } from '@shared/price';
import { TransformDate } from '@shared/transform-date';
import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { OrderStatus } from '../enums';
import { Employee } from '@shared/employee';
import { DeliveryProof } from './delivery-proof';
import { BaseOrder } from './base-order';

export class Order extends BaseOrder {
  @Expose({ name: 'employee_id' })
  public employeeID: number;

  @Expose()
  @Type(() => Employee)
  public employee: Employee;

  @Expose({ name: 'status_id' })
  public statusID: OrderStatus;

  @Expose({ name: 'delivery_fee' })
  public deliveryFee: number;

  @Expose({ name: 'delivery_fee_type' })
  public deliveryFeeType: PriceType;

  @Expose({ name: 'delivery_proof' })
  @Type(() => DeliveryProof)
  public deliveryProof: DeliveryProof;

  @Expose({ name: 'service_fee' })
  public serviceFee: number;

  @Expose({ name: 'service_fee_type' })
  public serviceFeeType: PriceType;

  @Expose({ name: 'payment_status' })
  public paymentStatus: PaymentStatus;

  @Expose({ name: 'payment_method_stripe_id' })
  public paymentMethodStripeID: string;

  @Expose({ name: 'done_at' })
  @TransformDate()
  public doneAt: DateTime;

  @Expose({ name: 'ready_at' })
  @TransformDate()
  public readyAt: DateTime;

  @Expose({ name: 'cancellation_fee' })
  public cancellationFee: number;

  @Expose({ name: 'refunded_amount' })
  public refundedAmount: number;

  @Expose({ name: 'available_amount_to_refund' })
  public availableAmountToRefund: number;

  @Expose({ name: 'options', toPlainOnly: true })
  public get optionIDs(): Array<number> {
    return this.options?.map((option) => option.id) || [];
  }

  public get isPaid(): boolean {
    return (this.isCanceled)
      ? !!this.cancellationFee
      : this.paymentStatus === PaymentStatus.PAID || this.paymentStatus === PaymentStatus.REFUNDED;
  }

  public get paid(): number {
    if (this.isPaid) {
      return this.cancellationFee || this.availableAmountToRefund;
    }

    return 0;
  }

  public get isReadonly(): boolean {
    return this.statusID === OrderStatus.CANCELED || this.statusID === OrderStatus.DONE;
  }

  public get canEditPickupInfo(): boolean {
    return this.statusID === OrderStatus.NEW && !this.isReadonly;
  }
  public get canEditDeliveryInfo(): boolean {
    return ((this.statusID >= OrderStatus.NEW && this.statusID <= OrderStatus.READY_TO_GO) || this.statusID ===OrderStatus.PROCESSING)
      && !this.isReadonly;
  }

  public get canBeCanceled(): boolean {
    return !this.isReadonly
      &&( this.statusID === OrderStatus.NEW || this.statusID === OrderStatus.PENDING || this.statusID === OrderStatus.PICKING_UP || this.statusID === OrderStatus.EN_ROUTE_PICKUP)
      && !(this.subscriptionID > 0);
  }
  public get canReorder(): boolean {
    return this.statusID === OrderStatus.DONE || this.statusID === OrderStatus.CANCELED;

  }

  public get isNewPaymentStatus(): boolean {
    return this.paymentStatus === PaymentStatus.NEW || !this.paymentStatus;
  }

  public get isNew(): boolean {
    return this.statusID === OrderStatus.NEW;
  }
  public get isPending(): boolean {
    return this.statusID === OrderStatus.PENDING;
  }

  public get isCanceled(): boolean {
    return this.statusID === OrderStatus.CANCELED;
  }

  public get isEnRoutePickup(): boolean {
    return this.statusID === OrderStatus.EN_ROUTE_PICKUP;
  }

  constructor(model: Partial<Order>) {
    super(model);

    Object.assign(this, model);
  }
}

