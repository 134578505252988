import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Order, OrderSummary } from '@shared/order';
import { BaseServicePageStateName } from '../types';
import { BaseServicePageState } from './state';
import { ItemForm} from '@shared/item';

export class BaseServicePageActions<State extends BaseServicePageState = BaseServicePageState> {
  public resetState = createAction(
    `[${this.stateName}] Reset State`
  );

  public tryCalculateOrder = createAction(
    `[${this.stateName}] Try Calculate Order`,
    props<{ order: Order }>()
  );

  public calculateOrder = createAction(
    `[${this.stateName}] Calculate Order`
  );

  public calculateOrderSuccess = createAction(
    `[${this.stateName}] Calculate Order Success`,
    props<{ summary: OrderSummary }>()
  );

  public calculateOrderFailure = createAction(
    `[${this.stateName}] Calculate Order Failure`,
    props<{ response: HttpErrorResponse }>()
  );

  public removeItems = createAction(
    `[${this.stateName}] Remove Items`
  );
  public changeItems = createAction(
    `[${this.stateName}] Change Items`,
    props<{ items: Array<ItemForm> }>()
  );

  public setStage = createAction(
    `[${this.stateName}] Set Stage`,
    props<{ stageName: State['stage']['name'] }>()
  );

  constructor(protected stateName: BaseServicePageStateName) { }
}
