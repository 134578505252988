<div *ngVar="'SHARED.GEOLOCATION_VIEW.' as translations" [class.opened]="!!predictedZip"
  class="geolocation-popup predicted-zip">
  <app-button variant="grays-silver" [isFlat]="true" (clicked)="closeButtonClicked()" class="geolocation-popup-close">
    <icon type="multiply" color="default"></icon>
  </app-button>
  <div class="geolocation-popup-content">
    <span>
      {{ translations + 'TEXT_YOUR_ZIP' | translate }}<br />
      <span class="predicted-zip-location">{{ predictedZip }}?</span>
    </span>
    <div class="predicted-zip-controls">
      <app-button [isFlat]="true" variant="purple" textSize="inherit" (clicked)="noButtonClicked()">
        {{ translations + 'BUTTON_NO' | translate }}
      </app-button>
      <app-button [isFlat]="true" variant="primary" textSize="inherit" (clicked)="yesButtonClicked()">
        {{ translations + 'BUTTON_YES' | translate }}
      </app-button>
    </div>
  </div>
</div>
