import { Action, ActionReducerMap, combineReducers } from '@ngrx/store';
import { userAddressReducer } from './address';
import { userPaymentMethodReducer } from './payment-method';
import { userRootReducer } from './root';
import { UserState } from './state';
import { userZipReducer } from './zip';

const initialState = new UserState();

const userReducerMap: ActionReducerMap<UserState> = {
  root: userRootReducer,
  addressState: userAddressReducer,
  paymentMethodState: userPaymentMethodReducer,
  zipState: userZipReducer
};

const reducer = combineReducers(userReducerMap, initialState);

export const userReducer = (
  state: UserState | undefined,
  action: Action
): UserState => reducer(state, action);
