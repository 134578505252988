import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressFormComponent } from './address-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroupComponent } from '@shared/form-group';
import { FormControlAddressComponent } from '@shared/form-control-address';
import { GeolocationButtonComponent } from '@shared/geolocation-button';
import { FormControlComponent } from '@shared/form-control';
import { CheckboxFormControlComponent } from '@shared/checkbox-form-control';
import { ButtonComponent } from '@shared/button';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgVariableDirective } from '@shared/ng-variable';

@NgModule({
  declarations: [
    AddressFormComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormGroupComponent,
    FormControlAddressComponent,
    GeolocationButtonComponent,
    FormControlComponent,
    CheckboxFormControlComponent,
    ButtonComponent,
    NgrxFormsModule,
    NgVariableDirective
  ],
  providers: [],
  exports: [
    AddressFormComponent
  ]
})
export class AddressFormModule { }
