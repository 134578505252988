import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, PLATFORM_ID } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/button';
import { IconModule } from '@shared/icon';
import { NgVariableDirective } from '@shared/ng-variable';
import { Observable } from 'rxjs';
import { CookiePopupFacade } from './cookie.facade';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonComponent,
    NgVariableDirective,
    IconModule
  ],
  providers: [
    CookiePopupFacade
  ],
  selector: 'cookie',
  templateUrl: 'cookie.html',
  styleUrls: ['cookie.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePopupComponent {
  public isVisible$: Observable<boolean>;
  public isBrowser: boolean;

  constructor(
    private facade: CookiePopupFacade
  ) {
    this.isVisible$ = this.facade.isVisible$;
    this.isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  }

  public closeButtonClicked(cookieBanner: HTMLDivElement): void {
    cookieBanner.classList.add('closing');
    setTimeout(() => this.facade.closeCookiePopup(), 1000);
  }

  public acceptButtonClicked(cookieBanner: HTMLDivElement): void {
    cookieBanner.classList.add('closing');
    setTimeout(() => this.facade.acceptCookies(), 1000);
  }
}
