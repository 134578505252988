import { Action, createReducer, on } from '@ngrx/store';
import { UserRootState } from './state';
import { UserRootActions } from './actions';

const reducer = createReducer(
  new UserRootState(),
  on(UserRootActions.resetState, () => new UserRootState()),
  on(UserRootActions.updateCurrentOrders, (state, { order, setActive }) => ({
    ...state,
    activeServiceID: (setActive) ? order?.serviceID : state.activeServiceID,
    currentOrders: (order?.serviceID)
      ? {
        ...((setActive) ? {} : state.currentOrders),
        [order?.serviceID]: order
      }
      : state.currentOrders
  })),
  on(UserRootActions.clearCurrentOrders, (state) => ({
    ...state,
    activeServiceID: null,
    currentOrders: {}
  })),
  on(UserRootActions.updatePromocodesAvailability, (state, { areAvailable }) => ({
    ...state,
    arePublicPromocodesAvailable: areAvailable
  }))
);

export const userRootReducer = (
  state: UserRootState | undefined,
  action: Action
): UserRootState => reducer(state, action);
