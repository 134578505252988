import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LaundryService } from './laundry.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [LaundryService]
})
export class LaundryModule { }

