import { Expose } from 'class-transformer';

export class Route {
  @Expose()
  public geometry: string;

  constructor(model: Route) {
    Object.assign(this, model);
  }
}
