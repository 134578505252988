export class UserAddressState {
  public isUpserting: boolean;
  public isSelecting: boolean;
  public isDeleting: boolean;

  constructor() {
    this.isUpserting = false;
    this.isSelecting = false;
    this.isDeleting = false;
  }
}
