import { DeviceInfo, DeviceInfoType } from '@shared/device-info';
import { Expose, Type } from 'class-transformer';
import isMobile from 'is-mobile';
import { Connection } from './connection';

export class AuthCredentials {
  @Expose()
  public username: string;

  @Expose()
  public password: string;

  @Expose()
  public forgot: boolean;

  @Expose()
  public remember: number;

  @Expose()
  public connection: Connection;

  @Expose({ name: 'device_info' })
  @Type(() => DeviceInfo)
  public deviceInfo: DeviceInfo;

  constructor(credentials: Partial<AuthCredentials>) {
    Object.assign(this, credentials);

    this.deviceInfo = credentials.deviceInfo || new DeviceInfo({
      type: (isMobile()) ? DeviceInfoType.MOBILE_WEB : DeviceInfoType.WEB
    });
  }
}
