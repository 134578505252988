import {AsyncPipe, NgIf} from '@angular/common';
import {Component, ChangeDetectionStrategy, Output, EventEmitter, Input} from '@angular/core';
import { Place } from '@shared/google-places';
import { IconModule } from '@shared/icon';
import { Observable } from 'rxjs';
import { GeolocationButtonFacade } from './geolocation-button.facade';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    IconModule,
    NgIf
  ],
  providers: [
    GeolocationButtonFacade
  ],
  selector: 'geolocation-button',
  templateUrl: 'geolocation-button.html',
  styleUrls: ['geolocation-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeolocationButtonComponent {
  @Output() geolocated: EventEmitter<Place>;
  @Input() isIconMode: boolean;

  public isGeolocating$: Observable<boolean>;

  constructor(
    private facade: GeolocationButtonFacade
  ) {
    this.geolocated = new EventEmitter();
    this.isGeolocating$ = this.facade.isGeolocating$;
  }

  public buttonClicked(): void {
    this.facade.geolocate((place) => this.geolocated.emit(place));
  }
}
