import { Expose } from 'class-transformer';

export class OrderSummaryDetails {
  @Expose()
  public pounds: number;

  @Expose({ name: 'pounds_count' })
  public poundsCount: number;

  @Expose()
  public options: number;

  @Expose()
  public items: number;

  constructor(model: Partial<OrderSummaryDetails>) {
    Object.assign(this, model);
  }
}
