import {Injectable} from '@angular/core';
import {configuration} from '@configurations';

@Injectable({
  providedIn: "root"
})
export class BrazeService {
  public logCustomEvent(eventName: string, eventData: {[key: string]: any}): void {
    if (configuration.environment === 'production') {
      import( "@braze/web-sdk" ).then(({ logCustomEvent }) => {logCustomEvent(eventName, eventData)});
    }
  }

  public changeUser(userID: string): void {
    if (configuration.environment === 'production') {
      import( "@braze/web-sdk" ).then(({ openSession, changeUser, automaticallyShowInAppMessages, requestPushPermission }) => {
        openSession()
        changeUser(userID)
        automaticallyShowInAppMessages();
        requestPushPermission();
      });
    }
  }
}
