import { PaginationRequest } from '@shared/pagination';
import { Expose } from 'class-transformer';

export class PaymentMethodPaginationRequest extends PaginationRequest {
  @Expose({ name: 'without_apple_pay' })
  public withoutApplePay: boolean;

  @Expose({ name: 'without_google_pay' })
  public withoutGooglePay: boolean;

  constructor(model: Partial<PaymentMethodPaginationRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
