import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ItemForm, ServiceItem } from '@shared/item';
import { SpecialCategoryID, ItemCategory } from '@shared/item-category';
import { intersectionBy } from 'lodash';

@Component({
  selector: 'order-items-category',
  templateUrl: 'category.html',
  styleUrls: ['category.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderItemsCategoryComponent implements OnChanges {
  @Input() isReadonly: boolean;
  @Input() category: ItemCategory;
  @Input() selectedItems: Array<ItemForm>;

  @Input()
  public set openedCategoriesIDs(ids: Array<number>) {
    this.isCollapsed = !ids.includes(this.category.id);
  }

  @Output() itemAdded: EventEmitter<ServiceItem>;
  @Output() itemSubtracted: EventEmitter<ServiceItem>;
  @Output() opened: EventEmitter<void>;
  @Output() closed: EventEmitter<void>;

  public selectedCategoryItems: Array<ItemForm>;
  public isCollapsed: boolean;

  constructor() {
    this.itemAdded = new EventEmitter();
    this.itemSubtracted = new EventEmitter();
    this.opened = new EventEmitter();
    this.closed = new EventEmitter();
  }

  public ngOnChanges(): void {
    this.filterSelectedCategoryItems();
  }

  public getItemCount(_item: ServiceItem, selectedCategoryItems: Array<ItemForm>): number {
    return selectedCategoryItems?.find((item) => item.id === _item.id)?.count || 0;
  }

  public increaseItemCountButtonClicked(item: ServiceItem): void {
    this.itemAdded.emit(item);
  }

  public decreaseItemCountButtonClicked(item: ServiceItem): void {
    this.itemSubtracted.emit(item);
  }

  private filterSelectedCategoryItems(): void {
    if (!this.selectedItems) {
      this.selectedCategoryItems = [];
    } else if (this.category.id === SpecialCategoryID.POPULAR) {
      this.selectedCategoryItems = intersectionBy(this.selectedItems, this.category.items, 'id');
    } else {
      this.selectedCategoryItems = this.selectedItems.filter((item) => item.categoryID === (this.category.id || null));
    }
  }
}
