import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageComponent } from './page.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContentComponent } from '@shared/content';
import { RouterModule } from '@angular/router';
import { NgVariableDirective } from '@shared/ng-variable';
import { PageTitleComponent } from './components/title';

@NgModule({
  declarations: [
    PageComponent
  ],
  imports: [
    NgVariableDirective,
    ContentComponent,
    PageTitleComponent
  ],
  providers: [],
  exports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ContentComponent,
    NgVariableDirective,
    PageComponent,
    PageTitleComponent
  ]
})
export class PageModule { }
