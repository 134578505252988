import { Expose } from 'class-transformer';

export class PrepareOrderRequest {
  @Expose({ name: 'service_id' })
  public serviceID: number;

  @Expose({ name: 'laundry_id' })
  public laundryID: number;

  constructor(model: PrepareOrderRequest) {
    Object.assign(this, model);
  }
}
