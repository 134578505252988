import type { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PhoneVerificationState } from './state';
import { DateTime } from 'luxon';
import { configuration } from '@configurations';
import { FormGroupState } from 'ngrx-forms';
import { PhoneVerificationForm } from '../forms';
import { PhoneVerificationData } from '../models';
import { some } from 'lodash';

const selectFeature = (state: AppState): PhoneVerificationState => state.phoneVerification;

export class PhoneVerificationSelectors {
  public static isStarting: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: PhoneVerificationState) => state.isStarting
  );

  public static isChecking: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: PhoneVerificationState) => state.isChecking
  );

  public static isLoading: MemoizedSelector<AppState, boolean> = createSelector(
    PhoneVerificationSelectors.isStarting,
    PhoneVerificationSelectors.isChecking,
    (...loadings) => some(loadings)
  );

  public static lastCodeRequestTime: MemoizedSelector<AppState, DateTime> = createSelector(
    selectFeature,
    (state: PhoneVerificationState) => state.lastCodeRequestTime
  );

  public static isCountdown: MemoizedSelector<AppState, boolean> = createSelector(
    PhoneVerificationSelectors.lastCodeRequestTime,
    (lastCodeRequestTime: DateTime) => lastCodeRequestTime?.diffNow('seconds').seconds > -configuration.verificationCodeRequestTimeout
  );

  public static data: MemoizedSelector<AppState, PhoneVerificationData> = createSelector(
    selectFeature,
    (state: PhoneVerificationState) => state.data
  );

  public static phone: MemoizedSelector<AppState, string> = createSelector(
    PhoneVerificationSelectors.data,
    (data: PhoneVerificationData) => data?.phone || ''
  );

  public static checkAttempts: MemoizedSelector<AppState, number> = createSelector(
    selectFeature,
    (state: PhoneVerificationState) => state.checkAttempts
  );

  public static formState: MemoizedSelector<AppState, FormGroupState<PhoneVerificationForm>> = createSelector(
    selectFeature,
    (state: PhoneVerificationState) => state.formState
  );
}
