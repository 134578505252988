import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogViewComponent } from './dialog-view.component';
import { DialogViewCloseComponent } from './components/close/close.component';
import { ButtonComponent } from '@shared/button';
import { IconModule } from '@shared/icon';
import { DialogViewButtonsComponent } from './components/buttons/buttons.component';
import { DialogViewContentComponent } from './components/content/content.component';
import { DialogViewBodyComponent } from './components/body/body.component';
import { DialogViewHeaderComponent } from './components/header/header.component';

@NgModule({
  declarations: [
    DialogViewComponent,
    DialogViewCloseComponent,
    DialogViewButtonsComponent,
    DialogViewContentComponent,
    DialogViewBodyComponent,
    DialogViewHeaderComponent
  ],
  imports: [
    NgClass,
    ButtonComponent,
    IconModule
  ],
  providers: [],
  exports: [
    DialogViewComponent,
    DialogViewCloseComponent,
    DialogViewButtonsComponent,
    DialogViewContentComponent,
    DialogViewBodyComponent,
    DialogViewHeaderComponent
  ]
})
export class DialogViewModule { }
