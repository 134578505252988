import { Expose, Type } from 'class-transformer';
import type { DriverEventMessage } from '../types';

export class DriverEventData {
  @Expose()
  public driverID: number;

  @Expose()
  public orderID: number;

  @Expose()
  public eta: number;

  @Expose()
  @Type(() => Number)
  public lat: number;

  @Expose()
  @Type(() => Number)
  public lon: number;

  @Expose()
  public message?: DriverEventMessage;

  public get isArrived(): boolean {
    return this.message === 'arrived';
  }

  constructor(model: Partial<DriverEventData>) {
    Object.assign(this, model);
  }
}
