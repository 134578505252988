import { User } from '@shared/user';
import { Expose, Type } from 'class-transformer';

export class RegisterResponse {
  @Expose()
  public token: string;

  @Expose()
  @Type(() => User)
  public user: User;

  constructor(model: Partial<RegisterResponse>) {
    Object.assign(this, model);
  }
}
