import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'map'
})
export class MapPipe implements PipeTransform {
  public transform<T, R>(
    value: T,
    project: (value: T, ...additionalArgs: Array<any>) => R,
    ...args: Array<any>
  ): R {
    return project(value, ...args);
  }
}
