import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'summary-price',
  templateUrl: 'price.html',
  styleUrls: ['price.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderSummaryPriceComponent {
  @Input() price: number;
}
