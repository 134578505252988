import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScriptService } from './script.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ScriptService]
})
export class ScriptModule { }

