import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrderSummaryComponent } from './summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgVariableDirective } from '@shared/ng-variable';
import { ButtonComponent } from '@shared/button';
import { NgForTrackByPropertyDirective } from '@shared/ng-for-track-by-property';
import { ItemsCostPipe, ItemsCountPipe } from '@shared/item/pipes';
import { InfoFieldComponent } from '@shared/info-field';
import { IconModule } from '@shared/icon';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ServiceNamePipe } from '@shared/service';
import { OrderSummaryFacade } from './summary.facade';
import { PaymentMethodNamePipe } from '@shared/payment-method';
import { OrderSummaryDeliveryFeeInfoComponent, OrderSummaryServiceFeeInfoComponent } from './components';
import { ItemImageComponent } from '@shared/item-image';
import { DialogViewModule } from '@shared/dialog-view';
import { LocationMismatchComponent } from '@shared/location-mismatch';
import { OrderSummaryPriceComponent } from './components/price/price.component';
import { PreferencesOptionNamesPipe } from '@shared/service-preference/pipes';
import {ShowButtonComponent} from '@shared/show-button';
import {ShowButtonSimpleComponent} from '@shared/show-button-simple';

@NgModule({
  declarations: [
    OrderSummaryComponent,
    OrderSummaryServiceFeeInfoComponent,
    OrderSummaryDeliveryFeeInfoComponent,
    OrderSummaryPriceComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgVariableDirective,
    ButtonComponent,
    ItemImageComponent,
    NgForTrackByPropertyDirective,
    ItemsCountPipe,
    ItemsCostPipe,
    InfoFieldComponent,
    ServiceNamePipe,
    PreferencesOptionNamesPipe,
    IconModule,
    TooltipModule,
    PaymentMethodNamePipe,
    DialogViewModule,
    LocationMismatchComponent,
    NgOptimizedImage,
    ShowButtonComponent,
    ShowButtonSimpleComponent
  ],
  providers: [OrderSummaryFacade],
    exports: [
        OrderSummaryComponent,
        OrderSummaryPriceComponent
    ]
})
export class OrderSummaryModule { }
