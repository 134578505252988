<span *ngVar="'SHARED.MENU.' as translations" [class.menu-opened]="isOpened$ | async" (click)="backgroundClicked()"
  class="menu-container hidden-scroll">
  <div *ngVar="user$ | async as user" (click)="$event.stopPropagation()" class="menu">
    <div [class.menu-header-authorized]="!!user" class="menu-header">
      <div class="menu-content">
        <ng-container *ngIf="user">
          <avatar [src]="user.avatarLink" size="medium"></avatar>
          <div class="menu-user">
            <div class="menu-user-name">
              {{ user.nameWithInitials }}
            </div>
            <div class="menu-user-actions">
              <a routerLink="/profile">
                <app-button variant="primary" [isFlat]="true" textSize="inherit">
                  {{ translations + 'LINK_EDIT_ACCOUNT' | translate }}
                </app-button>
              </a>
              <app-button variant="grays-silver" [isFlat]="true" textSize="inherit" testID="menu-logout"
                (clicked)="logoutButtonClicked()">
                {{ translations + 'BUTTON_LOGOUT' | translate }}
              </app-button>
            </div>
          </div>
        </ng-container>
        <button class="button menu-close" (click)="closeClicked()">
          <icon type="multiply" color="grays-dark"></icon>
        </button>
      </div>
    </div>
    <div class="menu-body">
      <div class="menu-content">
        <a *ngIf="!user" routerLink="/register" class="menu-link">
          <app-button variant="primary" size="smaller" [isBlock]="true">
            {{ translations + 'BUTTON_SIGNUP' | translate }}
          </app-button>
        </a>
        <div *ngFor="let link of menuLinks$ | async" class="menu-link">
        <ng-container  *ngVar="city$ | async as city">
          <app-link [routerLink]="(city?.code && link.isCity) ? (link.href+link.addUrl+city?.code):link.href" color="black" [useInheritedFont]="true" (click)="link.action?.()">
            <icon *ngIf="link.iconType !== 'wash-machine'" [type]="link.iconType" [color]="link.iconColor"></icon>
            <span class="menu-content-wash" *ngIf="link.iconType === 'wash-machine'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="wash-machine">
                <rect id="Rectangle 5689" x="3" y="2" width="18" height="20" rx="3" fill="#69BE3F"/>
                <path id="Vector" d="M6.29289 5.29289C6.48043 5.10536 6.73478 5 7 5H9C9.26522 5 9.51957 5.10536 9.70711 5.29289C9.89464 5.48043 10 5.73478 10 6C10 6.26522 9.89464 6.51957 9.70711 6.70711C9.51957 6.89464 9.26522 7 9 7H7C6.73478 7 6.48043 6.89464 6.29289 6.70711C6.10536 6.51957 6 6.26522 6 6C6 5.73478 6.10536 5.48043 6.29289 5.29289Z" fill="white"/>
                <path id="Vector_2" d="M14.7071 6.70711C14.5196 6.89464 14.2652 7 14 7C13.7348 7 13.4804 6.89464 13.2929 6.70711C13.1054 6.51957 13 6.26522 13 6C13 5.73478 13.1054 5.48043 13.2929 5.29289C13.4804 5.10536 13.7348 5 14 5C14.2652 5 14.5196 5.10536 14.7071 5.29289C14.8946 5.48043 15 5.73478 15 6C15 6.26522 14.8946 6.51957 14.7071 6.70711Z" fill="white"/>
                <path id="Vector_3" d="M17.7071 6.70711C17.5196 6.89464 17.2652 7 17 7C16.7348 7 16.4804 6.89464 16.2929 6.70711C16.1054 6.51957 16 6.26522 16 6C16 5.73478 16.1054 5.48043 16.2929 5.29289C16.4804 5.10536 16.7348 5 17 5C17.2652 5 17.5196 5.10536 17.7071 5.29289C17.8946 5.48043 18 5.73478 18 6C18 6.26522 17.8946 6.51957 17.7071 6.70711Z" fill="white"/>
                <path id="Exclude" fill-rule="evenodd" clip-rule="evenodd" d="M12 20C14.2091 20 16 18.2091 16 16C16 13.7909 14.2091 12 12 12C9.79086 12 8 13.7909 8 16C8 18.2091 9.79086 20 12 20ZM12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" fill="white"/>
                <line id="Line 29" x1="3" y1="9" x2="21" y2="9" stroke="white" stroke-width="2"/>
                </g>
              </svg>
            </span>
            {{ link.text | translate }}
          </app-link>
        </ng-container>
        </div>
      </div>
    </div>
    <div *webviewHidden class="menu-footer">
      <div class="menu-content">
        <div class="row">
          <div class="col col-content">
            <div class="menu-app-image">
              <img src="/assets/images/logo-symbol.svg" alt="icon">
            </div>
          </div>
          <div class="col col-stretch menu-get-app">
            {{ translations + 'TEXT_USE_APP' | translate }}
          </div>
        </div>
        <div class="menu-footer-buttons">
          <store-buttons></store-buttons>
        </div>
      </div>
    </div>
  </div>
</span>
