import { Expose, Type } from 'class-transformer';
import { Promocode } from './promocode';

export class ReferralCode {
  @Expose()
  @Type(() => Promocode)
  public promocode: Promocode;

  @Expose({ name: 'available_credits_count' })
  public availableCreditsCount: number;

  constructor(model: Partial<ReferralCode>) {
    Object.assign(this, model);
  }
}
