export abstract class BaseOrderCreationStage<TName extends string = string> {
  public name: TName;

  public abstract get isItemSelection(): boolean;

  constructor(model: Partial<BaseOrderCreationStage<TName>>) {
    Object.assign(this, model);
  }

  public abstract fromStageName(name: TName): BaseOrderCreationStage<TName>;
}
