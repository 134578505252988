import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RealtimeService } from '@shared/realtime/realtime.service';
import { exhaustMap, Observable, take } from 'rxjs';

@Injectable()
export class RealtimeConnectionKeyInterceptor implements HttpInterceptor {
  constructor(
    private realtimeService: RealtimeService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.realtimeService.connectionKey$.pipe(
      take(1),
      exhaustMap((connectionKey) => {
        if (!connectionKey) {
          return next.handle(req);
        }

        return next.handle(req.clone({
          setHeaders: {
            'X-Socket-ID': connectionKey
          }
        }));
      })
    );
  }
}
