import { Laundry } from '@shared/laundry';
import { Service } from '@shared/service';
import { Expose, Type } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity';
import { Location, LocationType } from '@shared/location';

export class Zip extends BaseEntity {
  @Expose()
  public code: string;

  @Expose({ name: 'is_supported' })
  public isSupported: boolean;

  @Expose({ name: 'state_id' })
  public stateId: number;

  @Expose({ toClassOnly: true })
  @Type(() => Service)
  public services: Array<Service>;

  @Expose({ toClassOnly: true })
  @Type(() => Laundry)
  public laundries: Array<Laundry>;

  @Expose()
  @Type(() => Location)
  public locations: Array<Location>;

  constructor(model: Partial<Zip>) {
    super(model);
    Object.assign(this, model);
  }

  public hasLaundry(laundry: Laundry): boolean {
    return !!this.services?.find((zipService) => laundry?.id === zipService.laundry?.id);
  }

  public hasService(service: Service): boolean {
    return !!this.services?.find((zipService) => service?.id === zipService?.parentID);
  }

  public getLocationOfType(type: LocationType): Location | null {
    for (const location of (this.locations || [])) {
      const locationOfType = location.getParentOfType(type);

      if (locationOfType) {
        return locationOfType;
      }
    }

    return null;
  }

  public isInLocation(location: Location): boolean {
    return location && this.code==location.zip;
  }
}
