import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from '@shared/auth';
import { WebviewGuard } from '@shared/webview';
import {PromocodeGuard} from '@shared/promocode/guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./public/public.module').then((module) => module.PublicModule),
    canActivate: [AuthenticatedGuard, WebviewGuard, PromocodeGuard],
    data: { isPublicSection: true }
  },
  {
    path: '',
    loadChildren: () => import('./private/private.module').then((module) => module.PrivateModule),
    canActivate: [AuthenticatedGuard, WebviewGuard, PromocodeGuard]
  },
  {
    path: '**',
    redirectTo: '/not-found'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
