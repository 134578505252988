<dialog-view *ngVar="'SHARED.PHONE_VERIFICATION.' as translations" variant="small" class="phone-verification">
  <ng-container *ngVar="isLoading$ | async as isLoading">
    <form *ngIf="formState$ | async as formState" [ngrxFormState]="formState">
      <dialog-header>
        <dialog-content size="small">
          {{ translations + 'TEXT_TITLE' | translate }}
          <dialog-close [isLoading]="isLoading" (clicked)="close()"></dialog-close>
        </dialog-content>
      </dialog-header>
      <dialog-body>
        <dialog-content size="small">
          <p *ngIf="phone$ | async as phone" class="phone-verification-hint">
            {{ translations + 'TEXT_ENTER_VERIFICATION_CODE' | translate: { number: phone } }}
          </p>
          <form-group [errorMessage]="translations + 'TEXT_CODE_VALIDATION_ERROR' | translate"
            [formControlState]="formState.controls.code" testID="verification-code">
            <form-control [placeholder]="translations + 'TEXT_CODE_PLACEHOLDER' | translate" type="text"
              [ngrxFormControlState]="formState.controls.code" mask="0000" autocomplete="off"
              testID="verification-code">
            </form-control>
          </form-group>
          <div class="phone-verification-countdown">
            <countdown #cd [config]="{ leftTime: verificationCodeRequestTimeout, format: 'mm:ss', notify: 0 }"
              (event)="($event)">
            </countdown>
          </div>
          <dialog-buttons class="row">
            <div class="col col-stretch">
              <app-button variant="primary" [isBlock]="true" [isLoading]="isLoading" type="button"
                [isDisabled]="cd.left > 0" (clicked)="cd.restart(); resendButtonClicked()">
                {{ (translations + 'BUTTON_RESEND' | translate) }}
                <div *ngIf="!!cd.left">
                  {{ translations + 'BUTTON_SECONDS' | translate: { count: cd.i.text } }}
                </div>
              </app-button>
            </div>
          </dialog-buttons>
        </dialog-content>
      </dialog-body>
    </form>
  </ng-container>
</dialog-view>
