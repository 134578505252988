import { NgModule } from '@angular/core';
import { UserService } from './user.service';
import { UserModule as CommonUserModule } from '@ronas-it/angular-common';
import { User } from './models';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserAddressEffects, UserPaymentMethodEffects, userReducer, UserRootEffects, UserZipEffects } from './store';
import { PaymentMethodModule } from '@shared/payment-method';
import { AddressModule } from '@shared/address';
import { PromocodeModule } from '@shared/promocode';
import {MapApiModule} from "@shared/map-api";

@NgModule({
  declarations: [],
  imports: [
    CommonUserModule.forRoot({
      userModel: User,
      userService: UserService
    }),
    StoreModule.forFeature('user', userReducer),
    EffectsModule.forFeature([UserRootEffects, UserAddressEffects, UserPaymentMethodEffects, UserZipEffects]),
    PaymentMethodModule,
    AddressModule,
    MapApiModule
  ],
  providers: [UserService]
})
export class UserModule { }

