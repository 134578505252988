import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api';
import { Realtime, Types } from 'ably';
import { BehaviorSubject, filter, firstValueFrom, map, Observable, Subject } from 'rxjs';

@Injectable()
export class AblyService {
  public get server$(): Observable<Realtime> {
    return this._server$.pipe(
      filter((server) => !!server)
    );
  };

  public get connectionKey$(): Observable<string | undefined> {
    return this._server$.pipe(
      map((server) => server?.connection?.key)
    );
  }

  private _server: Realtime;
  private _server$: Subject<Realtime | null>;

  private get server(): Realtime {
    return this._server;
  }

  private set server(server: Realtime) {
    this._server = server;
    this._server$.next(this.server);
  }

  constructor(
    private apiService: ApiService
  ) {
    this._server$ = new BehaviorSubject(null);
    this.server = null;
  }

  public init(): void {
    this.closeConnection();

    this.server = new Realtime({
      echoMessages: false,
      authCallback: async (_, callback) => {
        try {
          const response = await firstValueFrom(this.apiService.get('/ably/access-token'));

          callback(null, response);
        } catch (e) {
          callback(e as Types.ErrorInfo, null);
        }
      }
    });
  }

  public closeConnection(): void {
    if (this.server) {
      this.server.close();
      this.server = null;
    }
  }
}
