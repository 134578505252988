import {createAction, props} from '@ngrx/store';
import {Location} from "@shared/location";
import {HttpErrorResponse} from "@angular/common/http";

export class TopLocationsActions {

  public static loadLocations = createAction(
    `[TopLocations] Load Locations`,
  );

  public  static loadLocationsSuccess = createAction(
    `[TopLocations] Load Locations Success`,
    props<{ response: Array<Location> }>()
  );

  public static loadLocationsFailure = createAction(
    `[TopLocations] Load Locations Failure`,
    props<{ response: HttpErrorResponse }>()
  );

  public static lessLocations = createAction(
    `[TopLocations] Less Locations`,
    props<{ id:number }>()
  );

  public  static lessLocationsSuccess = createAction(
    `[TopLocations] Less Locations Success`,
    props<{id:number}>()
  );
}
