import {Injectable} from '@angular/core';
import {ReadableEntityService} from '@shared/base-entity';
import {Location, LocationEntityRequest, LocationPaginationRequest} from './models';
import {catchError, map, Observable, of} from "rxjs";
import {HttpErrorResponse, HttpUrlEncodingCodec} from '@angular/common/http';
import {ErrorHandlingService} from "@shared/error-handling";

@Injectable()
export class LocationService extends ReadableEntityService<
  Location,
  LocationPaginationRequest,
  LocationEntityRequest
> {
  constructor(private errorHandlingService: ErrorHandlingService,) {
    super();

    this.endpoint = '/locations';
    this.entityConstructor = Location;
    this.entityPaginationRequestConstructor = LocationPaginationRequest;
    this.entityRequestConstructor = LocationEntityRequest;
  }

  public getStates(): Observable<Array<Location>> {
    return this.apiService
      .get('/states')
      .pipe(
        map((response) => {
          return response
        }));
  }

  public getStateCity(slug: string): Observable<Location> {
    let codec = new HttpUrlEncodingCodec;

    return this.apiService
      .get(`/get-state-city?code=${codec.encodeValue(slug)}`)
      .pipe(
        map((response) => {
          return response
        }),
        catchError((response: HttpErrorResponse) => {
          this.errorHandlingService.handleHttpError(response, {
            translateKey: 'not found'
          });

          return of();
        }));
  }

  public getNearbyCites(code: string): Observable<Array<Location>> {
    return this.apiService
      .get(`/get-city-nearby?zipcode=${code}`)
      .pipe(
        map((response) => {
          return response
        }));
  }

}
