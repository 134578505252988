import { BaseEntity } from '@shared/base-entity';
import { Location } from '@shared/location';
import { Media } from '@shared/media';
import { Service } from '@shared/service';
import { TransformPageTags } from '@shared/transform-page-tags';
import { Expose, Type } from 'class-transformer';
import { SeoPageType } from '../enums';
import { SeoPageHtmlTags } from './html-tags';
import { SeoPageTag } from './tag';

export class SeoPage extends BaseEntity {
  @Expose()
  public url: string;

  @Expose()
  public title: string;

  @Expose()
  public type: SeoPageType;

  @Expose({ name: 'is_default' })
  public isDefault: boolean;

  @Expose({ name: 'code_head' })
  public codeHead: string;

  @Expose({ name: 'code_body_start' })
  public codeBodyStart: string;

  @Expose({ name: 'code_body_end' })
  public codeBodyEnd: string;

  @Expose()
  @Type(() => SeoPageTag)
  @TransformPageTags()
  public tags: SeoPageHtmlTags;

  @Expose({ name: 'seo_tags' })
  @Type(() => SeoPageTag)
  public seoTags: Array<SeoPageTag>;

  @Expose({ name: 'social_tags' })
  @Type(() => SeoPageTag)
  public socialTags: Array<SeoPageTag>;

  @Expose()
  @Type(() => SeoPageTag)
  public content: Array<SeoPageTag>;

  @Expose({ name: 'social_media_id' })
  public socialMediaID: number;

  @Expose({ name: 'social_media' })
  @Type(() => Media)
  public socialMedia: Media;

  @Expose()
  @Type(() => Location)
  public location: Location;

  @Expose()
  @Type(() => Service)
  public service: Service;

  constructor(model: Partial<SeoPage>) {
    super(model);
    Object.assign(this, model);
  }

  public get isSanDiego(): boolean {
    return this.url==='/services/wash-and-fold-laundry-services/city/san-diego-ca'
  }

  public get isMiamiFlorida(): boolean {
    return this.url==='/services/wash-and-fold-laundry-services/city/miami-fl'
  }
}
