import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WebviewForbiddenGuard, WebviewGuard } from './guards';
import { WebviewService } from './webview.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    WebviewService,
    WebviewGuard,
    WebviewForbiddenGuard
  ]
})
export class WebviewModule { }

