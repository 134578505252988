import { Expose } from 'class-transformer';

export class Contact {
  @Expose()
  public name: string;

  @Expose()
  public email: string;

  @Expose()
  public phone: string;

  constructor(model: Partial<Contact>) {
    Object.assign(this, model);
  }
}
