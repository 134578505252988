import { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PublicServicesServicePageState } from './state';
import { Order } from '@shared/order';
import { Service } from '@shared/service';
import { DateTime } from 'luxon';
import { BaseServicePageSelectors } from '@shared/base-service-page/store/selectors';
import { some } from 'lodash';
import { SeoPage } from '@shared/seo-page';
import { Location } from '@shared/location';
import { ServiceItem } from '@shared/item';
import { unbox } from 'ngrx-forms';
import { PublicServicesServiceLocationsState } from '../models';

class Selectors extends BaseServicePageSelectors<PublicServicesServicePageState> {
  public areServicesLoading: MemoizedSelector<AppState, boolean> = createSelector(
    this.selectFeature,
    (state) => state.areServicesLoading
  );

  public isHandlingSuccessfulAuth: MemoizedSelector<AppState, boolean> = createSelector(
    this.selectFeature,
    (state) => state.isHandlingSuccessfulAuth
  );

  public isLoading: MemoizedSelector<AppState, boolean> = createSelector(
    this.areServicesLoading,
    this.isHandlingSuccessfulAuth,
    (...loadings) => some(loadings)
  );

  public serviceCode: MemoizedSelector<AppState, string> = createSelector(
    this.selectFeature,
    (state) => state?.serviceCode
  );

  public settings: MemoizedSelector<AppState, SeoPage> = createSelector(
    this.selectFeature,
    (state) => state.settings
  );

  public service: MemoizedSelector<AppState, Service> = createSelector(
    this.settings,
    (settings) => settings?.service
  );

  public currentOrder: MemoizedSelector<AppState, Order> = createSelector(
    this.formValue,
    this.order,
    this.service,
    (value, order, service) => new Order({
      ...(order || {}),
      ...value,
      pickupTime: DateTime.fromISO(value.schedulling.pickupTime),
      pickupTo: DateTime.fromISO(value.schedulling.pickupTo),
      deliveryTime: DateTime.fromISO(value.schedulling.deliveryTime),
      deliveryTo: DateTime.fromISO(value.schedulling.deliveryTo),
      items: unbox(value.items).map((formItem) => new ServiceItem(formItem)),
      serviceID: service?.parentID || service?.id,
      laundryID: service?.laundryID,
      service: (service?.isLaundryService) ? service.service : service,
      laundryService: (service?.isLaundryService) ? service : undefined
    })
  );

  public override canCalculateOrder: MemoizedSelector<AppState, boolean> = createSelector(
    this.currentOrder,
    (order) => order?.pickupTime?.isValid && order?.deliveryTime?.isValid && !!order?.laundryService
  );

  public locationsState: MemoizedSelector<AppState, PublicServicesServiceLocationsState | undefined> = createSelector(
    this.selectFeature,
    (state) => state?.locationsState
  );

  public parentLocation: MemoizedSelector<AppState, Location | null> = createSelector(
    this.locationsState,
    (locationsState) => locationsState?.parent
  );

  public areLocationsLoading: MemoizedSelector<AppState, boolean> = createSelector(
    this.locationsState,
    (locationsState) => locationsState?.isLoading
  );

  public canLoadMoreLocations: MemoizedSelector<AppState, boolean> = createSelector(
    this.locationsState,
    ({ pagination, isLoading }) => !isLoading && pagination.currentPage < pagination.lastPage
  );

  public override isCalculating: MemoizedSelector<AppState, boolean> = createSelector(
    this.selectFeature,
    (state) => state.isCalculating || state.isLoadingInitialDates
  );

  constructor() {
    super('publicServicesServicePage');
  }
}

export const PublicServicesServicePageSelectors = new Selectors();
