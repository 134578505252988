import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseDialogDirective } from '@shared/base-dialog';
import {PageModule} from '@shared/page';
import {DialogViewModule} from '@shared/dialog-view';
import {ButtonComponent} from '@shared/button';
import {NgOptimizedImage} from '@angular/common';
import {PromocodeDiscountPipe} from '@shared/promocode/pipes';
import {Promocode} from '@shared/promocode';

@Component({
  selector: 'promocode-field-applied',
  templateUrl: 'applied-modal.html',
  styleUrls: ['applied-modal.scss'],
  standalone: true,
  imports: [
    PageModule,
    DialogViewModule,
    ButtonComponent,
    NgOptimizedImage,
    PromocodeDiscountPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromocodeFieldAppliedModalComponent extends BaseDialogDirective<Promocode> {}
