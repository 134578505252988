<ng-container *ngVar="'SHARED.ORDER_ITEMS.' as translations">
  <div *ngIf="filtersForm$ | async as filtersForm" class="order-items">
    <div class="order-items-search">
      <form-control [placeholder]="translations + 'TEXT_SEARCH_ITEM' | translate"
        [ngrxFormControlState]="filtersForm.controls.query" [focusTrackingEnabled]="true" autocomplete="off">
      </form-control>
      <span class="order-items-search-icon">
        <icon type="search" [color]="filtersForm.controls.query.isFocused ? 'black' : 'grays-silver'"></icon>
      </span>
    </div>
    <div *ngVar="openedCategoriesIDs$ | async as openedCategoriesIDs" class="order-items-categories">
      <spinner *ngIf="isLoading$ | async; else categoriesTemplate"></spinner>
      <ng-template #categoriesTemplate>
        <order-items-category *ngFor="let category of (categories$ | async); trackByProperty: 'id'"
          [category]="category" [selectedItems]="value" [isReadonly]="!service?.isLaundryService"
          [openedCategoriesIDs]="openedCategoriesIDs" (itemAdded)="increaseItemCountButtonClicked($event)"
          (itemSubtracted)="decreaseItemCountButtonClicked($event)" (opened)="categoryOpened(category.id)"
          (closed)="categoryClosed(category.id)">
        </order-items-category>
      </ng-template>
    </div>
    <div class="order-items-footer">
      <div>
        {{ translations + 'TEXT_DIDNT_FIND' | translate }}
      </div>
      <div>
        <app-link underline="always" [useInheritedFont]="true" color="grays-silver" (click)="contactLinkClicked()">
          {{ translations + 'LINK_CHAT' | translate }}
        </app-link>
        {{ translations + 'TEXT_WE_WILL_HELP' | translate }}
      </div>
    </div>
  </div>
</ng-container>
