import { Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core';

@Directive()
export class BaseUIDirective {
  @Input() testID: string;

  protected renderer: Renderer2;
  protected elementRef: ElementRef;

  constructor() {
    this.renderer = inject(Renderer2);
    this.elementRef = inject(ElementRef);
  }

  protected updateHostClasses(classes: Record<string, boolean>): void {
    for (const [className, isEnabled] of Object.entries(classes)) {
      if (isEnabled) {
        this.renderer.addClass(this.elementRef.nativeElement, className);
      } else {
        this.renderer.removeClass(this.elementRef.nativeElement, className);
      }
    }
  }
}
