import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrderItemsComponent } from './order-items.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgVariableDirective } from '@shared/ng-variable';
import { FormControlComponent } from '@shared/form-control';
import { IconModule } from '@shared/icon';
import { NgForTrackByPropertyDirective } from '@shared/ng-for-track-by-property';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { orderItemsReducer, OrderItemsEffects } from './store';
import { NgrxFormsModule } from 'ngrx-forms';
import { ItemsCountPipe } from '@shared/item/pipes';
import { LinkComponent } from '@shared/link';
import { OrderItemsCategoryComponent, OrderItemsItemControlsComponent, OrderItemsItemComponent } from './components';
import { ServiceItemModule } from '@shared/item';
import { CollapsibleFieldComponent } from '@shared/collapsible-field';
import { ButtonComponent } from '@shared/button';
import { ImageDirective } from '@shared/image-directive';
import { SpinnerComponent } from '@shared/spinner';
import { MapPipe } from '@shared/map';

@NgModule({
  declarations: [
    OrderItemsComponent,
    OrderItemsCategoryComponent,
    OrderItemsItemControlsComponent,
    OrderItemsItemComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgVariableDirective,
    FormControlComponent,
    IconModule,
    CollapsibleFieldComponent,
    NgForTrackByPropertyDirective,
    StoreModule.forFeature('orderItems', orderItemsReducer),
    EffectsModule.forFeature([OrderItemsEffects]),
    NgrxFormsModule,
    ItemsCountPipe,
    LinkComponent,
    ServiceItemModule,
    ButtonComponent,
    ImageDirective,
    SpinnerComponent,
    MapPipe
  ],
  providers: [],
  exports: [
    OrderItemsComponent,
    OrderItemsItemControlsComponent
  ]
})
export class OrderItemsModule { }
