import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateMediaRequest, Media } from './models';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor(private apiService: ApiService) { }

  public create(request: Partial<CreateMediaRequest>): Observable<Media> {
    const requestBody = new CreateMediaRequest(request);

    return this.apiService.post('/media', requestBody)
      .pipe(
        map((response) => plainToInstance(Media, response))
      );
  }

  public download(file: File, ext: string): void {
    const url = window.URL.createObjectURL(file);
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', `${file.name}.${ext}`);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
