import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Place } from '@shared/google-places';
import { Address, AddressForm } from '@shared/address';
import { Actions, createFormGroupState, FormGroupState, formStateReducer, setValue } from 'ngrx-forms';

@Component({
  selector: 'address-form',
  templateUrl: 'address-form.html',
  styleUrls: ['address-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressFormComponent {
  @Input() isLoading: boolean;
  @Input() showSetDefaultOption?: boolean;
  @Input() isPartialMode?: boolean;
  @Input() buttonsTemplate?: TemplateRef<any>;

  @Input()
  public set address(address: Address | undefined) {
    this.initForm(address);
    this.isEditMode = !!address;
  }

  @Output() submitted: EventEmitter<AddressForm>;
  @Output() cancelled: EventEmitter<void>;

  public formState: FormGroupState<AddressForm>;
  public isGeocoding: boolean;
  public isEditMode: boolean;

  public get isAddressInvalid(): boolean {
    return (this.formState.isSubmitted || this.formState.controls.address.isTouched)
      && (this.formState.controls.address.isInvalid || this.formState.controls.zipCode.isInvalid);
  }

  constructor() {
    this.formState = AddressForm.validate(
      createFormGroupState<AddressForm>('AddressPickerForm', new AddressForm())
    );
    this.submitted = new EventEmitter();
    this.cancelled = new EventEmitter();
  }

  public initForm(address: Address | undefined): void {
    this.formState = AddressForm.validate(
      AddressForm.prefillForm(address)(this.formState)
    );
  }

  public ngrxFormsActionEmitted(action: Actions<any>): void {
    this.formState = AddressForm.validate(
      formStateReducer(this.formState, action)
    );
  }

  public handleGeolocated(place: Place): void {
    this.formState = AddressForm.validate(
      setValue<AddressForm>({
        ...place,
        address: place.formattedAddress,
        apt: '',
        gatecode: '',
        buildingName: '',
        isActive: this.formState.value.isActive
      })(this.formState)
    );
  }

  public cancelClicked(): void {
    this.cancelled.emit();
  }

  public submitClicked(): void {
    if (this.formState.isValid || this.isPartialMode) {
      this.submitted.emit(this.formState.value);
    }
  }
}
