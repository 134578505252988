import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { GetSeoPageByURLRequest, SeoPage } from '@shared/seo-page';

export class SeoActions {
  public static resetState = createAction(
    '[Seo] Reset State'
  );

  public static tryLoadSettings = createAction(
    '[Seo] Try Load Settings',
    props<{
      onSuccess?: (settings: SeoPage) => void;
      onFailure?: (response: HttpErrorResponse) => void;
      request: GetSeoPageByURLRequest;
      defaultSettings?: SeoPage;
    }>()
  );

  public static loadSettings = createAction(
    '[Seo] Load Settings'
  );

  public static loadSettingsSuccess = createAction(
    '[Seo] Load Settings Success',
    props<{ settings: SeoPage }>()
  );

  public static loadSettingsFailure = createAction(
    '[Seo] Load Settings Failure',
    props<{
      response: HttpErrorResponse;
      settings?: SeoPage;
    }>()
  );
}
