import { NamedEntity } from '@shared/base-entity';
import { Laundry } from '@shared/laundry';
import { Media } from '@shared/media';
import { Expose, Type } from 'class-transformer';
import { LaundryZip } from './laundry-zip';
import { DeliveryPrice } from './delivery-price';
import { ServiceSettings } from './service-settings';

export class Service extends NamedEntity {
  @Expose()
  public name: string;

  @Expose()
  public code: string;

  @Expose()
  public description: string;

  @Expose({ name: 'laundry_id' })
  public laundryID: number;

  @Expose({ name: 'parent_id' })
  public parentID: number;

  @Expose({ name: 'media_id' })
  public mediaID: number;

  @Expose({ name: 'has_intervals' })
  public hasIntervals: boolean;

  @Expose({ name: 'has_items' })
  public hasItems: boolean;

  @Expose({ name: 'has_subscriptions' })
  public hasSubscriptions: boolean;

  @Expose({ name: 'has_price_per_pound' })
  public hasPricePerPound: boolean;

  @Expose({ name: 'has_service_fee' })
  public hasServiceFee: boolean;

  @Expose({ name: 'has_delivery_fee' })
  public hasDeliveryFee: boolean;

  @Expose({ name: 'minimum_order_price' })
  public minimumOrderPrice: number;

  @Expose({ name: 'minimum_order_weight' })
  public minimumOrderWeight: number;

  @Expose()
  @Type(() => ServiceSettings)
  public settings: ServiceSettings;

  @Expose({ name: 'delivery_prices', })
  @Type(() => DeliveryPrice)
  public deliveryPrices: Array<DeliveryPrice>;

  @Expose({ name: 'is_available', })
  public isAvailable: boolean;

  @Type(() => Laundry)
  @Expose()
  public laundries: Array<Laundry>;

  @Expose()
  @Type(() => Laundry)
  public laundry: Laundry;

  @Type(() => Media)
  @Expose()
  public media: Media;

  @Expose()
  public backgroundImageName: string;

  @Expose()
  @Type(() => Service)
  public service: Service;

  @Type(() => LaundryZip)
  @Expose({ name: 'laundry_zips', toClassOnly: true })
  public laundryZips: Array<LaundryZip>;

  public get serviceName(): string {
    return this.service?.name || this.name || '';
  }

  public get serviceDescription(): string {
    return this.service?.description || this.description || '';
  }

  public get serviceCode(): string {
    return this.service?.code || this.code || '';
  }

  public get serviceMedia(): Media {
    return this.media || this.service?.media;
  }

  public get isLaundryService(): boolean {
    return !!this.parentID;
  }

  public get viewName(): string {
    return this.serviceName;
  }

  public get intervalDuration(): number {
    return this.settings.intervalDuration;
  }

  constructor(model: Partial<Service>) {
    super(model);
    Object.assign(this, model);
  }

  public hasZipCode(zipCode: string): boolean {
    return !!this.laundryZips?.find((laundryZip) => laundryZip.zipCode === zipCode);
  }
}
