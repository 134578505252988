import { Zip } from '@shared/zip';
import { Expose, Type } from 'class-transformer';

export class LaundryZip {
  @Expose()
  public id: number;

  @Expose({ name: 'zip_id' })
  public zipID: number;

  @Expose()
  @Type(() => Zip)
  public zip: Zip;

  @Expose({ name: 'laundry_id' })
  public laundryID: number;

  @Expose({ name: 'service_id' })
  public serviceID: number;

  public get zipCode(): string {
    return this.zip?.code || '';
  }

  constructor(model: Partial<LaundryZip>) {
    Object.assign(this, model);
  }
}
