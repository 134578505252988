import { Expose } from 'class-transformer';
import { OrderRelation } from '../types';

export class OrderEntityRequest {
  @Expose({ name: 'with', toPlainOnly: true })
  public relation?: Array<OrderRelation>;

  constructor(request: Partial<OrderEntityRequest>) {
    Object.assign(this, request);
  }
}
