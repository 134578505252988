import { Injectable } from '@angular/core';
import { EntityRequest, ReadableEntityService } from '@shared/base-entity';
import { PaginationRequest } from '@shared/pagination';
import { Laundry } from './models';

@Injectable()
export class LaundryService extends ReadableEntityService<
  Laundry,
  PaginationRequest,
  EntityRequest
> {
  constructor() {
    super();

    this.endpoint = '/laundries';
    this.entityConstructor = Laundry;
    this.entityPaginationRequestConstructor = PaginationRequest;
    this.entityConstructor = this.entityConstructor;
  }
}
