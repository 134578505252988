import { AvailabilityInterval } from '@shared/availability';
import { ServicePreferenceOption } from '@shared/service-preference';
import { Expose, Type } from 'class-transformer';

export class PrepareOrderData {
  @Expose({ name: 'pickup_interval' })
  @Type(() => AvailabilityInterval)
  public pickupInterval: AvailabilityInterval;

  @Expose({ name: 'delivery_interval' })
  @Type(() => AvailabilityInterval)
  public deliveryInterval: AvailabilityInterval;

  @Expose()
  @Type(() => ServicePreferenceOption)
  public options: Array<ServicePreferenceOption>;

  @Expose({ name: 'were_all_client_options_specified' })
  public wereAllClientOptionsSpecified: boolean;

  constructor(model: Partial<PrepareOrderData>) {
    Object.assign(this, model);
  }
}
