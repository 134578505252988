import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'geolocation-view-no-zip',
  templateUrl: 'no-zip.html',
  styleUrls: ['../../geolocation-view.scss', 'no-zip.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeolocationViewNoZipComponent {
  @Input() isOpened: boolean;

  @Output() closeClicked: EventEmitter<void>;

  constructor() {
    this.closeClicked = new EventEmitter();
  }

  public closeButtonClicked(): void {
    this.closeClicked.emit();
  }
}
