import { ClassConstructor, instanceToPlain, plainToInstance } from 'class-transformer';
import { isEmpty, isNil } from 'lodash';

export class LocalStorageClassItem<T = any> {
  constructor(
    private key: string,
    private type: ClassConstructor<T>
  ) { }

  public set(value: T): void {
    if (isNil(value)) {
      this.remove();
    } else {
      localStorage.setItem(this.key, JSON.stringify(instanceToPlain(value, {
        excludeExtraneousValues: true,
        exposeUnsetFields: false
      })));
    }
  }

  public get(): T | null {
    try {
      const parsedObject = JSON.parse(localStorage.getItem(this.key));
      if (!isEmpty(parsedObject)) {
        return plainToInstance(this.type, parsedObject);
      }

      return null;
    } catch {
      return null;
    }
  }

  public remove(): void {
    localStorage.removeItem(this.key);
  }
}
