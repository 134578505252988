import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'geolocation-view-predicted-zip',
  templateUrl: 'predicted-zip.html',
  styleUrls: ['../../geolocation-view.scss', 'predicted-zip.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeolocationViewPredictedZipComponent {
  @Input() predictedZip: string;

  @Output() closeClicked: EventEmitter<void>;
  @Output() noClicked: EventEmitter<void>;
  @Output() yesClicked: EventEmitter<void>;

  constructor() {
    this.closeClicked = new EventEmitter();
    this.noClicked = new EventEmitter();
    this.yesClicked = new EventEmitter();
  }

  public closeButtonClicked(): void {
    this.closeClicked.emit();
  }

  public noButtonClicked(): void {
    this.noClicked.emit();
  }

  public yesButtonClicked(): void {
    this.yesClicked.emit();
  }
}
