import { Action, createReducer, on } from '@ngrx/store';
import { UserZipActions } from '@shared/user';
import { GeolocationViewActions } from './actions';
import { GeolocationViewState } from './state';

const initialState = new GeolocationViewState();

const reducer = createReducer(
  initialState,
  on(GeolocationViewActions.resetState, () => initialState),
  on(GeolocationViewActions.tryPredictZip, (state) => ({
    ...state,
    predictedZip: null
  })),
  on(GeolocationViewActions.predictZipStarted, (state) => ({
    ...state,
    isPredictingZip: true
  })),
  on(GeolocationViewActions.predictZipSuccess, (state, { zipCode }) => ({
    ...state,
    predictedZip: zipCode,
    isPredictingZip: false
  })),
  on(GeolocationViewActions.predictZipFailure, (state) => ({
    ...state,
    isPredictingZip: false
  })),
  on(GeolocationViewActions.clearPredictedZip, (state) => ({
    ...state,
    predictedZip: null
  })),
  on(UserZipActions.setZip, (state) => ({
    ...state,
    predictedZip: null,
    isPredictingZip: false
  })),
  on(GeolocationViewActions.closeInfoPopup, (state) => ({
    ...state,
    isInfoClosed: true
  }))
);

export const geolocationViewReducer = (
  state: GeolocationViewState | undefined,
  action: Action
): GeolocationViewState => reducer(state, action);
