import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconModule } from '@shared/icon';
import { ImageDirective } from '@shared/image-directive';
import { AvatarSize } from './types';

@Component({
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    IconModule,
    ImageDirective
  ],
  selector: 'avatar',
  templateUrl: 'avatar.html',
  styleUrls: ['avatar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit {
  @Input() src: string;
  @Input() size: AvatarSize;

  public classes: Array<string>;

  public ngOnInit(): void {
    this.classes = [
      'avatar',
      `avatar-${this.size || 'default'}`
    ];
  }
}
