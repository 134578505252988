import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/base-form-control';
import { GooglePlacesDirective, Place } from '@shared/google-places';
import { AddressForm } from '@shared/address';
import { FormGroupState, SetValueAction } from 'ngrx-forms';
import { FormControlAddressFacade } from './form-control-address.facade';

@Component({
  standalone: true,
  imports: [
    GooglePlacesDirective,
    FormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlAddressComponent),
      multi: true
    },
    FormControlAddressFacade
  ],
  selector: 'form-control-address',
  templateUrl: 'form-control-address.html',
  styleUrls: ['form-control-address.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlAddressComponent extends BaseFormControlDirective<string> {
  @Input() addressState?: FormGroupState<AddressForm>;

  @Output() isGeocodingChanged: EventEmitter<boolean>;

  constructor(
    protected facade: FormControlAddressFacade
    ) {
    super();
    this.isGeocodingChanged = new EventEmitter();
  }

  public placeChanged(place: Place): void {
    if (!place.zipCode?.length) {
      this.value = ""
      return
    }
    if (this.addressState) {
      const action = this.createSetAddressAction(place, this.addressState);
      if (this.ngrxFormsAction.observed) {
        this.ngrxFormsAction.emit(action);
      } else {
        this.facade.dispatchAction(action);
      }
    } else {
      this.value = place.formattedAddress;
    }
  }

  public isGeocodingChangeEmitted(isGeocoding: boolean): void {
    this.isGeocodingChanged.emit(isGeocoding);
  }

  private createSetAddressAction(place: Place, addressState: FormGroupState<AddressForm>): SetValueAction<AddressForm> {
    return new SetValueAction<AddressForm>(addressState.id, {
      ...place,
      address: place.formattedAddress,
      apt: (this.value === place.formattedAddress && this.value?.length) ? addressState.value.apt : '',
      gatecode: (this.value === place.formattedAddress && this.value?.length) ? addressState.value.gatecode : '',
      buildingName: (this.value === place.formattedAddress && this.value?.length) ? addressState.value.buildingName : '',
      isActive: addressState.value.isActive
    });
  }
}
