import { BaseOrderCreationStage } from '@shared/base-service-page';
import { OrderCreationStageName } from '../types';

export class OrderCreationStage extends BaseOrderCreationStage<OrderCreationStageName> {
  public get isItemSelection(): boolean {
    return this.name === 'items';
  }

  public get isSignIn(): boolean {
    return this.name === 'signin';
  }

  public get isSignUp(): boolean {
    return this.name === 'signup';
  }

  public fromStageName(name: OrderCreationStageName): OrderCreationStage {
    return new OrderCreationStage({ name });
  }
}
