import { Component, ChangeDetectionStrategy, TemplateRef, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { User } from '@shared/user';
import { Observable } from 'rxjs';
import { GeolocationViewFacade } from './geolocation-view.facade';

@Component({
  selector: 'geolocation-view',
  templateUrl: 'geolocation-view.html',
  styleUrls: ['geolocation-view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeolocationViewComponent implements OnInit, OnDestroy {
  @ViewChild('zipPicker', { static: true }) zipPicker: TemplateRef<any>;
  @ViewChild('addressPicker', { static: true }) addressPicker: TemplateRef<any>;
  @Input() isRedirectToCity:boolean;

  public zipAddress$: Observable<string>;
  public predictedZip$: Observable<string>;
  public shouldShowInfo$: Observable<boolean>;
  public hasAddresses$: Observable<boolean>;
  public user$: Observable<User>;
  public isLoading$: Observable<boolean>;
  public isVisible$: Observable<boolean>;

  constructor(private facade: GeolocationViewFacade) {
    this.zipAddress$ = this.facade.zipAddress$;
    this.predictedZip$ = this.facade.predictedZip$;
    this.shouldShowInfo$ = this.facade.shouldShowInfo$;
    this.hasAddresses$ = this.facade.hasAddresses$;
    this.user$ = this.facade.user$;
    this.isLoading$ = this.facade.isLoading$;
    this.isVisible$ = this.facade.isVisible$;
  }

  public ngOnInit(): void {
    this.facade.tryPredictZip();
  }

  public ngOnDestroy(): void {
    this.facade.destroy();
  }

  public geolocationInputClicked(hasAddresses: boolean): void {
    if (hasAddresses) {
      this.facade.openAddressPicker(this.addressPicker);
    } else {
      this.facade.openZipPicker(this.zipPicker);
    }
  }

  public confirmationNoClicked(hasAddresses: boolean): void {
    this.geolocationInputClicked(hasAddresses);
  }

  public confirmationYesClicked(predictedZip: string): void {
    this.facade.changeZip(predictedZip);
  }

  public confirmationCloseClicked(): void {
    this.facade.clearPredictedZip();
  }

  public closeInfoClicked(): void {
    this.facade.closeInfoPopup();
  }
}
