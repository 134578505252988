import type { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { UserAddressState } from './state';

const selectFeature = (state: AppState): UserAddressState => state.user.addressState;

export class UserAddressSelectors {
  public static isUpserting: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => state.isUpserting
  );

  public static isDeleting: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => state.isDeleting
  );

  public static isSelecting: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => state.isSelecting
  );
}
