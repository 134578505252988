import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseUIDirective } from '@shared/base-ui';
import { DialogContentSize } from '@shared/dialog-view/types';

@Component({
  selector: 'dialog-content',
  templateUrl: 'content.html',
  styleUrls: ['content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogViewContentComponent extends BaseUIDirective implements OnInit {
  @Input() size: DialogContentSize;

  public ngOnInit(): void {
    this.updateHostClasses({
      [`dialog-content-${this.size || 'default'}`]: true
    });
  }
}
