import {isEmpty} from "lodash";

export function createNoSpaceValidator(name: string): (value: any) => object {
  let validationResult: object = {};
  if(!isEmpty(name)){
  if ( (name.indexOf(' ') !== -1)) {
    validationResult = { space:true};
  }}
  return () => validationResult;
}
