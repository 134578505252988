import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconComponent } from './icon.component';
import { IconBackgroundComponent } from './components/background/background.component';

@NgModule({
  declarations: [
    IconComponent,
    IconBackgroundComponent
  ],
  imports: [
    NgClass
  ],
  exports: [
    IconComponent,
    IconBackgroundComponent
  ]
})
export class IconModule { }
