import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseUIDirective } from '@shared/base-ui';
import { ValidationErrorName } from '@shared/error-handling';
import { FormControlState } from 'ngrx-forms';

@Component({
  standalone: true,
  imports: [
    NgIf,
    TranslateModule
  ],
  selector: 'form-group',
  templateUrl: 'form-group.html',
  styleUrls: ['form-group.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormGroupComponent extends BaseUIDirective {
  @Input() label: string;
  @Input() title: string;
  @Input() errorMessage: string;
  @Input() formControlState: FormControlState<any>;
  @Input() isLoading: boolean;
  @Input() hasMessageLimit: boolean;
  @Input() hasLimitError: boolean;
  @Input() limit: number=800;

  public get hasError(): boolean {
    return this.formControlState?.isInvalid
      && (!!this.formControlState?.isTouched
        || !!this.formControlState?.isDirty
        || !!this.formControlState?.isSubmitted
      );
  }

  public get invalidValueError(): string {
    return this.formControlState?.errors[`$${ValidationErrorName.INVALID}`]?.toString();
  }

  public get hasRequiredError(): boolean {
    if (!this.hasLimitError) {
      return !!this.formControlState?.errors[ValidationErrorName.REQUIRED];
    }
    return false
  }

  public get messageControlState(): string {
    return this.formControlState.value
  }
}
