import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions } from '@shared/auth/store';
import { compact, without } from 'lodash';
import { onNgrxForms } from 'ngrx-forms';
import { OrderItemsActions } from './actions';
import { OrderItemsState } from './state';

const initialState = new OrderItemsState();

const reducer = createReducer(
  initialState,
  on(
    OrderItemsActions.resetState,
    AuthActions.unauthorized,
    () => initialState
  ),
  on(OrderItemsActions.init, (state, { service }) => ({
    ...((state.service?.id === service?.id) ? state : initialState),
    service
  })),
  on(OrderItemsActions.loadItems, (state) => ({
    ...state,
    isLoading: true,
    categories: null
  })),
  on(OrderItemsActions.loadItemsSuccess, (state, { categories }) => ({
    ...state,
    isLoading: false,
    categories,
    openedCategoriesIDs: (state.openedCategoriesIDs.length)
      ? state.openedCategoriesIDs
      : compact([categories[0]?.id,categories[1]?.id])
  })),
  on(OrderItemsActions.loadItemsFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(OrderItemsActions.openCategory, (state, { categoryID }) => ({
    ...state,
    openedCategoriesIDs: [categoryID, ...state.openedCategoriesIDs]
  })),
  on(OrderItemsActions.closeCategory, (state, { categoryID }) => ({
    ...state,
    openedCategoriesIDs: without(state.openedCategoriesIDs, categoryID)
  })),
  onNgrxForms()
);

export const orderItemsReducer = (
  state: OrderItemsState | undefined,
  action: Action
): OrderItemsState => reducer(state, action);
