import {Directive, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {ActionsSubject} from '@ngrx/store';
import {BaseUIDirective} from '@shared/base-ui';
import {
  Actions,
  FocusAction,
  NgrxValueConverter,
  NgrxValueConverters,
  UnfocusAction,
  FormControlState,
  FormControlValueTypes
} from 'ngrx-forms';

@Directive()
export class BaseFormControlDirective<ValueType = any, StateType extends FormControlValueTypes = FormControlValueTypes>
  extends BaseUIDirective
  implements ControlValueAccessor, OnInit {
  @Input() required: boolean;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() ngrxFormControlState: FormControlState<StateType>;
  @Input() ngrxValueConverter: NgrxValueConverter<ValueType, StateType>;
  @Input() disabled: boolean;
  @Input() focusTrackingEnabled: boolean;
  @Input() tabOrder: string;
  @Input() readonly: boolean;

  @Input()
  public get value(): ValueType {
    if (this.isInitialized || this._value !== undefined) {
      return this._value;
    }

    return this.ngrxValueConverter.convertStateToViewValue(this.ngrxFormControlState?.value);
  }

  public set value(value: ValueType) {
    if (!Object.is(this._value, value)) {
      if (typeof value == 'string') {
        const regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
        let changedValue = value as string
        changedValue = `${value}`.split(regexExp)[0]
        this.handleChanges(changedValue);
        this._value = changedValue as ValueType;
      } else {
        this.handleChanges(value);
        this._value = value;
      }
    }
  }

  @Output() ngrxFormsAction: EventEmitter<Actions<any>>;
  @Output() valueChange: EventEmitter<ValueType>;

  public get hasNgrxFormsAction(): boolean {
    return this.ngrxFormsAction?.observed;
  }

  public get hasError(): boolean {
    return this.ngrxFormControlState?.isInvalid && this.ngrxFormControlState?.isTouched;
  }

  public get isDisabled(): boolean {
    return this.ngrxFormControlState?.isDisabled || this.disabled;
  }

  protected isInitialized: boolean;
  protected _value: ValueType;
  protected element: Element;

  private actionsSubject: ActionsSubject;

  constructor() {
    super();
    this.actionsSubject = inject(ActionsSubject);

    this.ngrxValueConverter = NgrxValueConverters.default();
    this.ngrxFormsAction = new EventEmitter();
    this.valueChange = new EventEmitter();
  }

  public ngOnInit(): void {
    this.isInitialized = true;
  }

  public onChange: any = () => { };

  public onTouched: any = () => { };

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {
    this._value = value;
  }

  public focusChanged(isFocused: boolean): void {
    if (!this.focusTrackingEnabled) {
      return;
    }
    const action = (isFocused)
      ? new FocusAction(this.ngrxFormControlState.id)
      : new UnfocusAction(this.ngrxFormControlState.id);
    if (this.hasNgrxFormsAction) {
      this.ngrxFormsAction.emit(action);
    } else {
      this.actionsSubject.next(action);
    }
  }

  protected handleChanges(value: any): void {
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(value);
  }
}
