import {Location} from "@shared/location/models/location";

export class TopLocationsState {
  public cites: Array<Location> ;
  public page: number

  constructor() {
    this.page = 1;
    this.cites = [];
  }
}
