import { Expose } from 'class-transformer';
import { AddressRelation } from '../types';

export class AddressEntityRequest {
  @Expose({ name: 'with', toPlainOnly: true })
  public relations?: Array<AddressRelation>;

  constructor(model: Partial<AddressEntityRequest>) {
    Object.assign(this, model);
  }
}
