import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DiscountType } from '@shared/promocode';
import { Promocode } from '@shared/promocode/models';

@Pipe({
  standalone: true,
  name: 'promocodeDiscount'
})
export class PromocodeDiscountPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) { }

  public transform(promocode: Promocode): string {
    if (promocode?.discountType === DiscountType.PERCENT) {
      return `${+promocode.discount}%`;
    }
    if (promocode?.discountType === DiscountType.FIXED) {
      return this.currencyPipe.transform(promocode.discount);
    }

    return '';
  }
}
