import {Component, ChangeDetectionStrategy, OnInit, Inject, PLATFORM_ID} from '@angular/core';
import {configuration} from '@configurations';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppFacade} from './app.facade';
import {IntercomActions} from '@shared/intercom/store';
import {Store} from '@ngrx/store';
import {AppState} from '@shared/store';
import {NavigationEnd, Router} from '@angular/router';
import {isInOrderPage} from '@shared/helpers/validation-helper';
import {WebviewService} from '@shared/webview';
import {SeoService} from '@shared/seo';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.html',
  styleUrls: ['./app.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public isMenuOpened$: Observable<boolean>;
  public isWebView: boolean = false;

  public network: BehaviorSubject<boolean> = new BehaviorSubject(true)
  constructor(
    private translateService: TranslateService,
    private facade: AppFacade,
    private store: Store<AppState>,
    private router: Router,
    private webviewService: WebviewService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformID: object,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isMenuOpened$ = this.facade.isMenuOpened$;
    this.isWebView = this.webviewService.isWebview;
  }

  public ngOnInit(): void {
    this.checkNetworkStatus()
    // const app = initializeApp(configuration.firebaseConfig);
    // if (isPlatformBrowser(this.platformID)) {
    //   getPerformance(app);
    // }

    this.translateService.addLangs(configuration.language.available);
    this.translateService.setDefaultLang(configuration.language.default);

    this.setLanguage(configuration.language.default);
    this.setNoIndexMeta();

    this.router.events.pipe().subscribe((value) => {
      if(value instanceof NavigationEnd) {
        this.store.dispatch(IntercomActions.hide())
        this.store.dispatch(IntercomActions.update({}))
      }
    })
  }
  private setLanguage(language: string): void {
    const isLanguageAvailable =
      configuration.language.available.includes(language);
    if (isLanguageAvailable) {
      this.translateService.use(language);
    } else {
      this.translateService.use(configuration.language.default);
    }
  }

  private setNoIndexMeta(): void {
    const hasNiIndexMeta = this.document.querySelector('[name^="robots"]')
    if (configuration.environment !== 'production' && !hasNiIndexMeta) {
      let noIndexTag = this.document.createElement('meta')
      noIndexTag.name = "robots"
      noIndexTag.content = "noindex"
      this.document.getElementsByTagName('head')[0].appendChild(noIndexTag);
    }
  }

  public intercomChantClicked(): void {
    const isOrderPageOpened = isInOrderPage(this.router.url)
    const orderPageUrl = this.router.url.split('/')
    this.store.dispatch(IntercomActions.show({orderId: isOrderPageOpened ? orderPageUrl[orderPageUrl.length - 1] : undefined}));
  }

  public checkNetworkStatus(): void {
    if (isPlatformBrowser(this.platformID)) {
      window.addEventListener("load", () => {
        this.network.next(navigator.onLine)

        window.addEventListener("online", () => {
          this.network.next(true)
        });

        window.addEventListener("offline", () => {
          this.network.next(false)
        });
      });
    }
  }
  public get networkStatus$(): Observable<boolean> {
    return this.network.asObservable()
  }
}
