import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServicePreferenceService } from './service-preference.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ServicePreferenceService]
})
export class ServicePreferenceModule { }
