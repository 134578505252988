import { Pipe, PipeTransform } from '@angular/core';
import { IconType } from '@shared/icon';
import { PaymentMethod } from '@shared/payment-method';
import { PaymentSystem } from '@shared/payment/enums';

@Pipe({
  standalone: true,
  name: 'paymentMethodIcon'
})
export class PaymentMethodIconPipe implements PipeTransform {
  public iconMap: Record<string, IconType>;

  constructor() {
    const paymentSystemIcons: Record<PaymentSystem, IconType> = {
      [PaymentSystem.GOOGLE_PAY]: 'gpay',
      [PaymentSystem.APPLE_PAY]: 'apay'
    };
    this.iconMap = {
      amex: 'amex',
      visa: 'visa',
      mastercard: 'mastercard',
      jcb: 'jcb',
      discover: 'discover',
      diners_club: 'diners-club',
      ...paymentSystemIcons
    };
  }

  public transform(paymentMethodOrSystem: PaymentMethod | PaymentSystem): IconType {
    const iconMapKey = (paymentMethodOrSystem instanceof PaymentMethod)
      ? paymentMethodOrSystem.paymentSystem || paymentMethodOrSystem.brand
      : paymentMethodOrSystem;

    return this.iconMap[iconMapKey] || 'unknown-brand';
  }
}
