import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrderMainStageComponent } from './order-main-stage.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgVariableDirective } from '@shared/ng-variable';
import { InfoFieldComponent } from '@shared/info-field';
import { PoundsSelectorComponent } from '@shared/pounds-selector';
import { OrderItemsModule } from '@shared/order-items';
import { NgrxFormsModule } from 'ngrx-forms';
import { AppDatePipe } from '@shared/app-date';
import { IconModule } from '@shared/icon';

@NgModule({
  declarations: [
    OrderMainStageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgVariableDirective,
    InfoFieldComponent,
    PoundsSelectorComponent,
    OrderItemsModule,
    NgrxFormsModule,
    AppDatePipe,
    IconModule
  ],
  providers: [],
  exports: [
    OrderMainStageComponent
  ]
})
export class OrderMainStageModule { }
