import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { configuration } from '@configurations';
import { BaseDialogDirective } from '@shared/base-dialog';
import { PhoneVerificationForm } from '@shared/phone-verification';
import { FormGroupState } from 'ngrx-forms';
import { CountdownTimer } from 'ngx-countdown';
import { Observable } from 'rxjs';
import { PhoneVerificationModalFacade } from './modal.facade';

@Component({
  selector: 'phone-verification-modal',
  templateUrl: 'modal.html',
  styleUrls: ['modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PhoneVerificationModalFacade,
    CountdownTimer
  ]
})
export class PhoneVerificationModalComponent extends BaseDialogDirective<Record<string, never>> implements OnDestroy {
  public formState$: Observable<FormGroupState<PhoneVerificationForm>>;
  public phone$: Observable<string>;

  public get verificationCodeRequestTimeout(): number {
    return configuration.verificationCodeRequestTimeout;
  }

  constructor(
    protected facade: PhoneVerificationModalFacade
  ) {
    super();

    this.formState$ = this.facade.formState$;
    this.isLoading$ = this.facade.isLoading$;
  }

  public ngOnDestroy(): void {
    this.facade.resetState();
  }

  public resendButtonClicked(): void {
    this.facade.sendCode();
  }
}
