import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDialogService } from '@shared/dialog';
import { ErrorHandlingService } from '@shared/error-handling';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private dialogService: AppDialogService,
    private errorHandlingService: ErrorHandlingService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response?.status === HttpStatusCode.Forbidden) {
            this.authService.unauthorize();
            this.dialogService.closeAll();
            this.errorHandlingService.handleHttpError(response);
          }

          return throwError(() => response);
        })
      );
  }
}
