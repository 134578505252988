import {UserType} from '@shared/user-type-modal';

export class UserTypeModalData {

  public onConfirm: (type:UserType) => void;

  constructor(model: Partial<UserTypeModalData>) {
    Object.assign(this, model);
  }
}
