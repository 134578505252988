import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api';
import { PaginationResponse } from '@shared/pagination';
import { instanceToPlain, plainToInstance, plainToClassFromExist } from 'class-transformer';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isNil, omitBy, pickBy } from 'lodash';
import { Order, OrderPaginationRequest, OrderEntityRequest, OrderSummary, CalculateOrderRequest, PrepareOrderRequest, PrepareOrderData } from './models';
import { OrderRelation } from './types';
import { RealtimeService } from '@shared/realtime/realtime.service';

@Injectable()
export class OrderService {
  constructor(
    private apiService: ApiService,
    private realtimeService: RealtimeService
  ) { }

  public search(request: Partial<OrderPaginationRequest>): Observable<PaginationResponse<Order>> {
    const requestParams = new OrderPaginationRequest(request);

    return this.apiService.get('/orders', pickBy(instanceToPlain(requestParams)))
      .pipe(
        map((response) => plainToClassFromExist(new PaginationResponse<Order>(Order), response))
      );
  }

  public get(id: string | number, params: OrderEntityRequest = {}): Observable<Order> {
    const request = new OrderEntityRequest(params);

    return this.apiService
      .get<Order>(`/orders/${id}`, pickBy(instanceToPlain<OrderEntityRequest>(request)))
      .pipe(
        map((response) => plainToInstance(Order, response))
      );
  }

  public update(request: Partial<Order>): Observable<void> {
    const requestBody = new Order(request);

    return this.apiService.put(`/orders/${request.id}`, instanceToPlain<Order>(requestBody));
  }

  public getCancellationChargeAmount(id: number): Observable<number> {
    return this.get(id, { relation: ['laundry_service'] })
      .pipe(
        map((order) => ((order.employeeID || (!order.isNew && !order.isPending) || !!order.subscriptionID) && !order.isEnRoutePickup)
          ? order.laundryService.settings.cancellationFee :
          0
        )
      );
  }

  public cancel(id: number, withCharge: boolean, reason?: string): Observable<void> {
    return this.apiService.post(`/v2/orders/${id}/cancel`, {without_charge: withCharge, reason});
  }

  public create(request: Partial<Order>): Observable<Order> {
    const requestBody = new Order(request);

    return this.apiService
      .post<Order>('/orders', omitBy(instanceToPlain(requestBody, { excludeExtraneousValues: true }), isNil))
      .pipe(
        map((response) => plainToInstance(Order, response))
      );
  }

  public calculate(requestBody: CalculateOrderRequest): Observable<OrderSummary> {
    return this.apiService.post('/orders/get-cost', omitBy(instanceToPlain(requestBody, { excludeExtraneousValues: true }), isNil))
      .pipe(
        map((response) => plainToInstance(OrderSummary, response))
      );
  }

  public prepare(request: PrepareOrderRequest): Observable<PrepareOrderData> {
    const requestBody = new PrepareOrderRequest(request);

    return this.apiService.post('/orders/prepare', instanceToPlain(requestBody))
      .pipe(
        map((response) => plainToInstance(PrepareOrderData, response))
      );
  }

  public exportReceipt(id: number): Observable<File> {
    return this.apiService.getPDF(`/orders/${id}/final-receipt`)
      .pipe(
        map((blob) => new File([blob], `order#${id}_final-receipt`))
      );
  }

  public upsertEvents$({ orderID, clientID, relation }: {
    clientID: number;
    orderID?: number;
    relation: Array<OrderRelation>;
  }): Observable<{ order: Order; isNew: boolean }> {
    return this.realtimeService.getChannelEventsSource({
      channel: `private:clients/${clientID}/orders`,
      onMessage: (message) => ({
        order: plainToInstance(Order, message.data),
        isNew: message.name === 'order.created'
      })
    }).pipe(
      filter(({ order }) => !orderID || order.id === orderID),
      switchMap(({ order, isNew }) => this.get(order.id, { relation })
        .pipe(
          map((newOrder) => ({ order: newOrder, isNew }))
        )
      )
    );
  }
}
