import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { AppState } from '@shared/store';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { IntercomService } from '../intercom.service';
import { Observable, of } from 'rxjs';
import { IntercomActions } from './actions';
import { catchError, exhaustMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { AuthActions } from '@shared/auth/store/actions';
import { NotificationService } from '@shared/notification';
import { IntercomStatus } from '../enums';
import {User, UserService, UserZipActions} from '@shared/user';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class IntercomEffects {
  public init$: Observable<[void, User]> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntercomActions.init),
      withLatestFrom(
        this.intercomService.status$
      ),
      filter(([_, status]) => isPlatformBrowser(this.platformID) && status === IntercomStatus.DOWN),
      exhaustMap(([{ onSuccess }]) => this.intercomService.loadApi()
        .pipe(
          withLatestFrom(this.userService.profile$),
          tap(([_, user]) => {
            this.intercomService.boot();
            this.store.dispatch(IntercomActions.tryLogin({ user, onSuccess }));
          }),
          catchError(() => {
            this.notificationService.error('SHARED.INTERCOM.TEXT_LOAD_FAILED');

            return of();
          })
        )
      )
    ),
    { dispatch: false }
  );

  public tryLogin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntercomActions.tryLogin),
      withLatestFrom(
        this.intercomService.status$
      ),
      filter(([_, status]) => status === IntercomStatus.LOADED),
      tap(([{ user, onSuccess }]) => {
        this.intercomService.update(this.getUserInfoForIntercom(user));
        onSuccess?.();
      }),
      map(([{ user }]) => IntercomActions.loggedIn({ user }))
    )
  );

  public update$: Observable<[Action, IntercomStatus, User]> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntercomActions.update),
      withLatestFrom(
        this.intercomService.status$,
        this.userService.profile$
      ),
      filter(([_, status]) => status === IntercomStatus.LOADED),
      tap(([{orderId}, __, user]) => this.intercomService.update(this.getUserInfoForIntercom(user, orderId)))
    ),
    { dispatch: false }
  );

  public show$: Observable<[Action, IntercomStatus]> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntercomActions.show),
      withLatestFrom(this.intercomService.status$),
      tap(([{orderId,isShowMassage}, status]) => {
        if (status === IntercomStatus.DOWN) {
          this.store.dispatch(IntercomActions.init({
            onSuccess: () => this.store.dispatch(IntercomActions.show({orderId,isShowMassage}))
          }));
        }
        if (status === IntercomStatus.LOADING) {
          this.notificationService.warning('SHARED.INTERCOM.TEXT_LOADING');
        }
        if (status === IntercomStatus.FAILED) {
          this.notificationService.error('SHARED.INTERCOM.TEXT_LOAD_FAILED');
        }
      }),
      filter(([_, status]) => status === IntercomStatus.LOADED),
      tap(([{orderId,isShowMassage}]) => {
        if(isShowMassage){
          this.intercomService.showMessages()
        }else{
          this.intercomService.show()
        }

        this.store.dispatch(IntercomActions.update({orderId}))
      })
    ),
    { dispatch: false }
  );

  public hide$: Observable<[Action, IntercomStatus]> = createEffect(() =>
      this.actions$.pipe(
        ofType(IntercomActions.hide),
        withLatestFrom(this.intercomService.status$),
        filter(([_, status]) => status === IntercomStatus.LOADED),
        tap(() => this.intercomService?.hide())
      ),
    { dispatch: false }
  );

  public unauthorized$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.unauthorized),
      withLatestFrom(this.intercomService.status$),
      filter(([_, status]) => isPlatformBrowser(this.platformID) && status === IntercomStatus.LOADED),
      tap(() => this.intercomService.shutdown()),
      map(() => IntercomActions.logout()),
      map(() => UserZipActions.clearZip())
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private intercomService: IntercomService,
    private notificationService: NotificationService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformID: object
  ) { }

  private getUserInfoForIntercom(
    user?: User | null,
    orderId?: string
  ): Pick<Intercom_.IntercomSettings, 'email' | 'phone' | 'created_at' | 'name' | 'user_id' | 'order_id'|'user_hash'> {
    let hash=undefined
    if(user?.id) {
       hash = this.calculateHMAC(String(user.id), "avk5XyBRXxfRp7lraZEwWxzwWTHsHmqotjNV15tl");
    }
    return {
      email: user?.email,
      phone: user?.fullPhone,
      created_at: user?.createdAt.toMillis(),
      name: user?.name?.length ? user.name : user?.firstName?.length ? user.firstName : '',
      user_id: user?.id.toString(),
      order_id: orderId ? orderId : 0,
      user_hash:hash
    };
  }

  calculateHMAC(message: string, key: string): string {
    const hmac = CryptoJS.HmacSHA256(message, key);
    return hmac.toString(CryptoJS.enc.Hex);
  }
}
