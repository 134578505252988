<div *ngVar="'SHARED.ORDER_ITEMS.ITEM.' as translations" [class.selected]="count > 0" [class.readonly]="isReadonly"
  class="item">
  <div class="item-image-container skeleton">
    <div class="item-count">
      <icon type="check" color="white"></icon>
      <span>{{ count }}</span>
    </div>
    <img *ngIf="item?.imageLink" [src]="item?.imageLink" placeholderVariant="default" class="item-image" loading="lazy" alt="Hamperapp order item">
    <div class="item-controls">
      <ng-container [ngTemplateOutlet]="itemControlsTemplate"></ng-container>
    </div>
  </div>
  <div class="item-info">
    <div class="item-info-title">
      {{ item.title }}
    </div>
    <div *ngIf="item.description" class="item-info-description">
      <ng-container *ngIf="isDescriptionExpanded; else shortDescriptionTemplate">
        <div>{{ item.description }}</div>
        <app-button *ngIf="shortDescription" variant="purple" [isFlat]="true" textSize="inherit"
          (clicked)="isDescriptionExpanded = false">
          {{ translations + 'BUTTON_HIDE' | translate }}
        </app-button>
      </ng-container>
      <ng-template #shortDescriptionTemplate>
        <div>{{ shortDescription }}</div>
        <app-button variant="purple" [isFlat]="true" textSize="inherit" (clicked)="isDescriptionExpanded = true">
          {{ translations + 'BUTTON_SHOW_MORE' | translate }}
        </app-button>
      </ng-template>
    </div>
    <div *ngIf="!isReadonly" class="item-info-price">
      {{ item.price | currency }}
    </div>
  </div>
  <div class="item-mobile-controls">
    <ng-container [ngTemplateOutlet]="itemControlsTemplate"></ng-container>
  </div>
</div>
<ng-template #itemControlsTemplate>
  <order-items-item-controls *ngIf="!isReadonly" [count]="count" (decreased)="decreaseCountButtonClicked()"
    (increased)="increaseCountButtonClicked()">
  </order-items-item-controls>
</ng-template>
