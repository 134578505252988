import { Expose } from 'class-transformer';

export class ServiceSettings {
  @Expose({ name: 'interval_no_order_time' })
  public intervalNoOrderTime: number;

  @Expose({ name: 'interval_max_orders' })
  public intervalMaxOrders: number;

  @Expose({ name: 'interval_duration' })
  public intervalDuration: number;

  @Expose({ name: 'min_handling_hours' })
  public minHandlingHours: number;

  @Expose({ name: 'service_fee_percent' })
  public serviceFeePercent: number;

  @Expose({ name: 'working_hours_start' })
  public workingHoursStart: number;

  @Expose({ name: 'working_hours_end' })
  public workingHoursEnd: number;

  @Expose({ name: 'price_per_pound' })
  public pricePerPound: number;

  @Expose({ name: 'cancellation_fee' })
  public cancellationFee: number;

  constructor(model: Partial<ServiceSettings>) {
    Object.assign(this, model);
  }
}
