import { Injectable } from '@angular/core';
import { Auth, AuthProvider, FacebookAuthProvider, GoogleAuthProvider, inMemoryPersistence, OAuthProvider, signInWithPopup, UserCredential  } from '@angular/fire/auth';
import { from, map, Observable } from 'rxjs';
import { SocialAuthData } from './models';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable()
export class FirebaseAuthService {
  private facebookProvider: AuthProvider;
  private appleProvider: AuthProvider;
  private googleProvider: AuthProvider;

  constructor(
    private fireauthService: Auth,
    public afAuth: AngularFireAuth
  ) {
    this.facebookProvider = this.createFacebookProvider();
    this.appleProvider = this.createAppleProvider();
    this.googleProvider = this.createGoogleProvider();
    this.fireauthService.setPersistence(inMemoryPersistence);
  }
  countSignIn=false;
  SignUpForFire(email, password) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        this.SignInForFire(email,password)
      })
      .catch(() => {
      });
  }
  SignInForFire(email, password) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
      })
      .catch(() => {
        if(!this.countSignIn){
          this.countSignIn=true;
          this.SignUpForFire(email, password)
        }

      });
  }
  public loginWithFacebook(): Observable<SocialAuthData> {
    return from(signInWithPopup(this.fireauthService, this.facebookProvider))
      .pipe(
        map((result) => this.convertAuthResult(result, FacebookAuthProvider.credentialFromResult(result)?.accessToken))
      );
  }

  public loginWithApple(): Observable<SocialAuthData> {
    return from(signInWithPopup(this.fireauthService, this.appleProvider))
      .pipe(
        map((result) => this.convertAuthResult(result, OAuthProvider.credentialFromResult(result)?.idToken))
      );
  }

  public loginWithGoogle(): Observable<SocialAuthData> {
    return from(signInWithPopup(this.fireauthService, this.googleProvider))
      .pipe(
        map((result) => this.convertAuthResult(result, GoogleAuthProvider.credentialFromResult(result)?.accessToken))
      );
  }

  public signOut(): Promise<void> {
    return this.fireauthService.signOut();
  }

  private createFacebookProvider(): AuthProvider {
    const provider = new FacebookAuthProvider();
    provider.addScope('public_profile');
    provider.addScope('email');
    provider.setCustomParameters({
      auth_type: 'rerequest'
    });

    return provider;
  }

  private createAppleProvider(): AuthProvider {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    return provider;
  }

  private createGoogleProvider(): AuthProvider {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');

    return provider;
  }

  private convertAuthResult(result: UserCredential, token: string): SocialAuthData {
    const userInfo = result.user.providerData?.[0];
    const name = result.user.displayName || userInfo?.displayName;
    const [firstName, lastName] = name?.split(' ') || [];

    return new SocialAuthData({
      token,
      firstName,
      lastName,
      email: this.getEmailFromResult(result),
      phone: userInfo?.phoneNumber
    });
  }

  private getEmailFromResult(result: UserCredential): string {
    return result.user.providerData[0].email || result['_tokenResponse']?.['email'] || '';
  }
}
