import { Injectable } from '@angular/core';
import { NotificationService } from '@shared/notification';

@Injectable()
export class ClipboardService {
  constructor(
    private notificationService: NotificationService
  ) { }

  public async copy(text: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
    } catch (e) {
      this.notificationService.error('SHARED.CLIPBOARD.TEXT_COPY_FAILURE');
      throw e;
    }
  }
}
