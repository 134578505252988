import { Transform } from 'class-transformer';
import { isNumber } from 'lodash';

export const TransformOrderDiscount: PropertyDecorator = (target, propertyKey) => {
  Transform(({ value }) => {
    if (isNumber(value)) {
      return { promocode: value };
    }

    return value;
  },
    { toClassOnly: true }
  )(target, propertyKey);
};
