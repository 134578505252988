import { Expose } from 'class-transformer';

export class EntityRequest<
  TRelation extends string = string,
  TField extends string = string
> {
  @Expose({ name: 'with', toPlainOnly: true })
  public relations?: Array<TRelation>;

  @Expose({ name: 'include_fields', toPlainOnly: true })
  public includeFields?: Array<TField>;

  constructor(request: Partial<EntityRequest<TRelation>>) {
    Object.assign(this, request);
  }
}
