import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { LogoComponent } from '@shared/logo';
import { NgVariableDirective } from '@shared/ng-variable';
import { LinkComponent } from '@shared/link';
import { IconModule } from '@shared/icon';
import { ButtonComponent } from '@shared/button';
import { FooterFacade } from './footer.facade';
import { ScrollModule } from '@shared/scroll';
import { RouterModule } from '@angular/router';
import { FooterTitleComponent } from './components';
import { ContentComponent } from '@shared/content';
import { StoreButtonsComponent } from '@shared/store-buttons';
import {EffectsModule} from "@ngrx/effects";
import {TopLocationsEffects, topLocationsReducer} from "@shared/footer/store";
import {LocationService} from "@shared/location";
import {StoreModule} from "@ngrx/store";
import {ImageDirective} from '@shared/image-directive';
import {StarsComponent} from '@shared/stars';

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    LogoComponent,
    NgVariableDirective,
    LinkComponent,
    IconModule,
    StoreModule.forFeature('topLocations', topLocationsReducer),
    EffectsModule.forFeature([TopLocationsEffects]),
    ButtonComponent,
    ScrollModule,
    RouterModule,
    FooterTitleComponent,
    NgOptimizedImage,
    ContentComponent,
    StoreButtonsComponent,
    ImageDirective,
    StarsComponent,

  ],
  providers: [FooterFacade,LocationService],
  exports: [
    FooterComponent
  ]
})
export class FooterModule { }
