import { AppErrorCode } from '../enums';

export class AppError extends Error {
  public code: AppErrorCode;

  constructor(code: AppErrorCode, message?: string) {
    super(message);
    this.code = code;
    this.name = AppError.name;
  }
}
