import { ItemCategory } from '@shared/item-category';
import { Service } from '@shared/service';
import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import { OrderItemsFiltersForm } from '../forms/filters';

export class OrderItemsState {
  public filtersForm: FormGroupState<OrderItemsFiltersForm>;
  public service: Service;
  public categories: Array<ItemCategory> | null;
  public isLoading: boolean;
  public openedCategoriesIDs: Array<number>;

  constructor() {
    this.filtersForm = createFormGroupState('OrderItemsFiltersForm', new OrderItemsFiltersForm());
    this.service = null;
    this.categories = null;
    this.isLoading = false;
    this.openedCategoriesIDs = [];
  }
}
