import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api';
import { PaginationResponse } from '@shared/pagination';
import { instanceToPlain, plainToClassFromExist, plainToInstance } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CheckCodeRequest, Promocode, PromocodePaginationRequest, ReferralCode } from './models';

@Injectable()
export class PromocodeService {
  constructor(private apiService: ApiService) { }

  public check(request: CheckCodeRequest): Observable<Promocode> {
    const requestParams = new CheckCodeRequest(request);
    if (!request?.promocode) {
      return of(null);
    }
    return this.apiService.get<Promocode>('/promocodes/check', instanceToPlain(requestParams))
      .pipe(
        map((response) => plainToInstance(Promocode, response))
      );
  }
  public activate(request: CheckCodeRequest): Observable<Promocode> {
    if (!request?.promocode) {
      return of(null);
    }
    return this.apiService.get<Promocode>(`/promocode/${request?.promocode}/activate`)
      .pipe(
        map((response) => plainToInstance(Promocode, response))
      );
  }

  public loadLast(): Observable<Promocode> {
    return this.apiService.get<Promocode>('/promocodes/last-used').pipe(
      map((response) => plainToInstance(Promocode, response))
    );
  }

  public search(request: Partial<PromocodePaginationRequest>): Observable<PaginationResponse<Promocode>> {
    const requestParams = new PromocodePaginationRequest(request);

    return this.apiService.get('/promocodes', instanceToPlain(requestParams))
      .pipe(
        map((response) => plainToClassFromExist(new PaginationResponse(Promocode), response))
      );
  }

  public getRefferal(): Observable<ReferralCode> {
    return this.apiService.get('/promocodes/referral').pipe(
      map((response) => plainToInstance(ReferralCode, response))
    );
  }
}
