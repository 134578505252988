import { Action, createReducer, on } from '@ngrx/store';
import { SeoActions } from './actions';
import { SeoState } from './state';

const initialState = new SeoState();

const reducer = createReducer(
  initialState,
  on(SeoActions.resetState, () => initialState),
  on(SeoActions.tryLoadSettings, (state) => ({
    ...state,
    settings: null
  })),
  on(SeoActions.loadSettings, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SeoActions.loadSettingsSuccess, (state, { settings }) => ({
    ...state,
    isLoading: false,
    settings
  })),
  on(SeoActions.loadSettingsFailure, (state, { settings }) => ({
    ...state,
    isLoading: false,
    settings
  }))
);

export const seoReducer = (
  state: SeoState | undefined,
  action: Action
): SeoState => reducer(state, action);
