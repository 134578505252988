import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseTemplateDialogDirective } from '@shared/base-dialog';
import { GeolocationViewFacade } from '@shared/geolocation-view/geolocation-view.facade';
import { Place } from '@shared/google-places';
import { Actions, createFormGroupState, formGroupReducer, FormGroupState, markAsSubmitted, setValue, updateGroup } from 'ngrx-forms';
import { ZipCodeForm } from '../../forms';

@Component({
  selector: 'geolocation-view-zip-picker',
  templateUrl: 'zip-picker.html',
  styleUrls: ['zip-picker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeolocationViewZipPickerComponent extends BaseTemplateDialogDirective {
  public static width = '380px';

  @Input() isLoading: boolean;
  @Input() isRedirectToCity:boolean;

  public formState: FormGroupState<ZipCodeForm>;

  constructor(
    private facade: GeolocationViewFacade
  ) {
    super();
    this.formState = ZipCodeForm.validate(
      createFormGroupState('ZipCodeForm', new ZipCodeForm())
    );
  }

  public handleFormAction(action: Actions<any>): void {
    this.formState = ZipCodeForm.validate(formGroupReducer(this.formState, action));
  }

  public saveButtonClicked(): void {
    if (this.formState.isValid) {
      this.facade.changeZip(this.formState.value.zipCode, this.dialogRef.id,this.isRedirectToCity);
    } else {
      this.formState = markAsSubmitted(this.formState);
    }
  }

  public geolocated(place: Place): void {
    this.formState = ZipCodeForm.validate(
      updateGroup<ZipCodeForm>(this.formState, {
        zipCode: setValue(place.zipCode)
      })
    );
  }
}
