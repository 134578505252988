import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressService } from './address.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [AddressService]
})
export class AddressModule { }

