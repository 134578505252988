import { Expose } from 'class-transformer';

export class NotificationSettings {
  @Expose({ name: 'is_transactional_sms_enabled' })
  public isTransactionalSMSEnabled: boolean;

  @Expose({ name: 'is_marketing_sms_enabled' })
  public isMarketingSMSEnabled: boolean;

  @Expose({ name: 'is_push_enabled' })
  public isPushEnabled: boolean;

  @Expose({ name: 'is_email_enabled' })
  public isEmailEnabled: boolean;

  constructor(model: Partial<NotificationSettings>) {
    Object.assign(this, model);
  }
}
