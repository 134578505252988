import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceItemService } from './item.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ServiceItemService]
})
export class ServiceItemModule { }

