import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuComponent } from './menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { MenuFacade } from './menu.facade';
import { NgVariableDirective } from '@shared/ng-variable';
import { AvatarComponent } from '@shared/avatar';
import { LinkComponent } from '@shared/link';
import { ButtonComponent } from '@shared/button';
import { IconModule } from '@shared/icon';
import { RouterModule } from '@angular/router';
import { WebviewHiddenDirective } from '@shared/webview-hidden';
import { StoreButtonsComponent } from '@shared/store-buttons';

@NgModule({
  declarations: [
    MenuComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgVariableDirective,
    AvatarComponent,
    LinkComponent,
    ButtonComponent,
    IconModule,
    RouterModule,
    WebviewHiddenDirective,
    StoreButtonsComponent
  ],
  providers: [MenuFacade],
  exports: [
    MenuComponent
  ]
})
export class MenuModule { }
