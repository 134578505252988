import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY, fromEvent, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  public scrollEvents$: Observable<Event>;

  public get viewportHeight(): number {
    return isPlatformBrowser(this.platformID) && (window.innerHeight || document.documentElement.clientHeight) || 0;
  }

  public get scrollY(): number {
    return (isPlatformBrowser(this.platformID)) ? window.scrollY : 0;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformID: object
  ) {
    this.scrollEvents$ = (isPlatformBrowser(platformID)) ? fromEvent(document, 'scroll') : EMPTY;
  }

  public isElementInView(element: Element, offset: number = 0): boolean {
    if (!isPlatformBrowser(this.platformID)) {
      return false;
    }
    const elementTop = element.getBoundingClientRect().top;

    return elementTop <= (this.viewportHeight - offset);
  };

  public getScrolledHeight(element: Element): number {
    if (!isPlatformBrowser(this.platformID)) {
      return 0;
    }
    const elementBoundingClientRect = element.getBoundingClientRect();
    if (elementBoundingClientRect.top > this.viewportHeight) {
      return 0;
    }
    if (this.viewportHeight - elementBoundingClientRect.top < elementBoundingClientRect.height) {
      return this.viewportHeight - elementBoundingClientRect.top;
    }

    return elementBoundingClientRect.height;
  };
}
