import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { BaseUIDirective } from '@shared/base-ui';
import { compact } from 'lodash';
import { ButtonSize, ButtonTextSize, ButtonVariant, ButtonWeight } from './types';

@Component({
  standalone: true,
  imports: [
    NgClass
  ],
  selector: 'app-button',
  templateUrl: 'button.html',
  styleUrls: ['button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent extends BaseUIDirective implements OnChanges {
  @Input() type: 'submit' | 'button';
  @Input() variant: ButtonVariant;
  @Input() textSize: ButtonTextSize;
  @Input() size: ButtonSize;
  @Input() weight: ButtonWeight;
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean;
  @Input() isBlock: boolean | 'mobile';
  @Input() isOutline: boolean;
  @Input() isFlat: boolean;
  @Input() tabOrder: string;
  @Input() customClass: string;

  @Output() clicked: EventEmitter<Event>;

  public classes: Array<string>;

  constructor() {
    super();

    this.clicked = new EventEmitter<Event>();
    this.variant = 'default';
    this.weight = 'medium';
    this.type = 'button';
  }

  public ngOnChanges(): void {
    this.classes = compact([
      `button-${this.variant}`,
      this.textSize && `button-text-${this.textSize}`,
      this.size && `button-${this.size}`,
      `button-weight-${this.weight}`,
      this.isBlock && `button-block${(this.isBlock === 'mobile') ? '-mobile' : ''}`,
      this.isFlat && 'button-flat',
      this.isOutline && 'button-outline',
      this.customClass
    ]);
  }

  public buttonClicked(event: Event): void {
    this.clicked.emit(event);
  }
}
