import { Action, createReducer, on } from '@ngrx/store';
import { UserActions } from '../actions';
import { UserAddressActions } from './actions';
import { UserAddressState } from './state';

const initialState = new UserAddressState();

const reducer = createReducer(
  initialState,
  on(UserActions.resetState, () => initialState),
  on(UserAddressActions.upsert, (state) => ({
    ...state,
    isUpserting: true
  })),
  on(
    UserAddressActions.upsertSuccess,
    UserAddressActions.upsertFailure,
    (state) => ({
      ...state,
      isUpserting: false
    })
  ),
  on(UserAddressActions.delete, (state) => ({
    ...state,
    isDeleting: true
  })),
  on(
    UserAddressActions.deleteSuccess,
    UserAddressActions.deleteFailure,
    (state) => ({
      ...state,
      isDeleting: false
    })
  ),
  on(UserAddressActions.select, (state) => ({
    ...state,
    isSelecting: true
  })),
  on(
    UserAddressActions.selectSuccess,
    UserAddressActions.selectFailure,
    (state) => ({
      ...state,
      isSelecting: false
    })
  )
);

export const userAddressReducer = (
  state: UserAddressState | undefined,
  action: Action
): UserAddressState => reducer(state, action);
