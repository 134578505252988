import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { AppState } from '@shared/store';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { SetValueAction } from 'ngrx-forms';
import { forkJoin, Observable, of } from 'rxjs';
import { withLatestFrom, map, catchError, tap, filter, switchMap, debounceTime } from 'rxjs/operators';
import { OrderItemsActions } from './actions';
import { OrderItemsSelectors } from './selectors';
import { ServiceItemsByCategoryRequest, ServiceItemService, ServiceItemsPaginationRequest } from '@shared/item';
import { SpecialCategoryID, ItemCategory } from '@shared/item-category';
import { DateTime } from 'luxon';
import { HttpErrorResponse } from '@angular/common/http';
import { configuration } from '@configurations';
import { ErrorHandlingService } from '@shared/error-handling';
import { Service } from '@shared/service';
import { OrderItemsFiltersForm } from '../forms';
import { TranslateService } from '@ngx-translate/core';
import { compact } from 'lodash';

@Injectable()
export class OrderItemsEffects {
  public init$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderItemsActions.init),
      withLatestFrom(
        this.store.select(OrderItemsSelectors.categories)
      ),
      filter(([_, categories]) => !categories),
      map(() => OrderItemsActions.tryLoadItems())
    )
  );

  public tryLoadItems$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderItemsActions.tryLoadItems),
      withLatestFrom(
        this.store.select(OrderItemsSelectors.service),
        this.store.select(OrderItemsSelectors.filtersForm)
      ),
      filter(([_, service]) => !!service),
      tap(() => this.store.dispatch(OrderItemsActions.loadItems())),
      switchMap(([_, service, filtersForm]) => forkJoin([
        this.loadTopItemsCategory({ service, filters: filtersForm.value }),
        this.loadItemsByCategory({ service, filters: filtersForm.value })
      ])
        .pipe(
          map(([topItemsCategory, categories]) => OrderItemsActions.loadItemsSuccess({
            categories: compact([
              topItemsCategory,
              ...categories
            ])
          })),
          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'SHARED.ORDER_ITEMS.TEXT_LOAD_ITEMS_FAILURE'
            });

            return of(OrderItemsActions.loadItemsFailure({ response }));
          })
        )
      )
    )
  );

  public filtersFormChange$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetValueAction.TYPE),
      filter((action: SetValueAction<string>) => action.controlId.startsWith('OrderItemsFiltersForm')),
      debounceTime(350),
      map(() => OrderItemsActions.tryLoadItems())
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private itemService: ServiceItemService,
    private translateService: TranslateService,
    private errorHandlingService: ErrorHandlingService
  ) { }

  private loadTopItemsCategory({ service, filters }: {
    service: Service;
    filters: OrderItemsFiltersForm;
  }): Observable<ItemCategory> {
    return this.itemService.searchTop({
      ...this.getCommonSearchParams({ service, filters }),
      from: DateTime.local().minus({ days: 6 }).startOf('day'),
      to: DateTime.local().endOf('day'),
      perPage: 5,
      page: 1
    }).pipe(
      map((response) => (response.data.length)
        ? new ItemCategory({
          id: SpecialCategoryID.POPULAR,
          name: this.translateService.instant('SHARED.ORDER_ITEMS.TEXT_MOST_POPULAR'),
          emoji: '🔥',
          emojiBackgroundColor: '#ff832226',
          items: response.data
        })
        : null
      ),
      catchError(() => of(null))
    );
  }

  private loadItemsByCategory({ service, filters }: {
    service: Service;
    filters: OrderItemsFiltersForm;
  }): Observable<Array<ItemCategory>> {
    return this.itemService.searchByCategory({
      ...this.getCommonSearchParams({ service, filters })
    }).pipe(
      map((categories) => categories.map((category) => (category.id === SpecialCategoryID.NO_CATEGORY)
        ? new ItemCategory({
          ...category,
          name: this.translateService.instant(`SHARED.ORDER_ITEMS.TEXT_${(categories?.length > 1) ? 'OTHER' : 'ALL'}`)
        })
        : category
      ))
    );
  }

  private getCommonSearchParams({ service, filters }: {
    service: Service;
    filters: OrderItemsFiltersForm;
  }): Partial<ServiceItemsPaginationRequest & ServiceItemsByCategoryRequest> {

    let isValidValueRegex = /[^ -\u2122]+ +| *[^ -\u2122]+|^\s+/g;
    const _value = typeof filters.query === 'string' ? filters.query.replace(isValidValueRegex, '') : filters.query

    return {
      serviceID: service.parentID || service.id,
      laundryID: service.laundryID || configuration.defaultLaundryID,
      relations: ['item.media'],
      orderBy:'item.title',
      desc:true,
      query: _value || undefined,
    };
  }
}
