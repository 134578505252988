import { createAction } from '@ngrx/store';

export class UserActions {
  public static resetState = createAction(
    '[User] Reset State'
  );

  public static refreshProfile = createAction(
    '[User] Refresh Profile'
  );
}
