import { Expose } from 'class-transformer';

export class ForgotPasswordRequest {
  @Expose()
  public email: string;

  constructor(model: Partial<ForgotPasswordRequest>) {
    Object.assign(this, model);
  }
}
