import { Expose } from 'class-transformer';

export class CheckVerificationCodeRequest {
  @Expose()
  public phone: string;

  @Expose()
  public code: string;

  @Expose()
  public debug: boolean;

  constructor(model: Partial<CheckVerificationCodeRequest>) {
    Object.assign(this, model);
  }
}
