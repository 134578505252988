import { Expose } from 'class-transformer';
import { OrderDiscountType } from '../enums';

export class OrderSummaryDiscount {
  @Expose()
  public client: number;

  @Expose()
  public promocode: number;

  public get amount(): number {
    return Math.max(this.client, this.promocode);
  }

  public get type(): OrderDiscountType {
    if (this.client) {
      return OrderDiscountType.CLIENT;
    }

    return OrderDiscountType.PROMOCODE;
  }

  constructor(model: Partial<OrderSummaryDiscount>) {
    Object.assign(this, model);
  }
}
