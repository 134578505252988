import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Inject, Input, NgZone, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GeolocationService, GoogleStatus } from '@shared/geolocation';
import { Place } from './models';
import {MapApiService} from "@shared/map-api";

@UntilDestroy()
@Directive({
  standalone: true,
  selector: '[googlePlaces]'
})
export class GooglePlacesDirective implements OnInit {
  @Input() debounceTime?: number;

  @Output() placeChanged: EventEmitter<Place>;
  @Output() isGeocodingChanged: EventEmitter<boolean>;

  private element: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private geolocationService: GeolocationService,
    @Inject(PLATFORM_ID) private platformID: object,
    private mapApiService: MapApiService,
    private ngZone: NgZone
  ) {
    this.element = this.elementRef.nativeElement;
    this.isGeocodingChanged = new EventEmitter();
    this.placeChanged = new EventEmitter();
  }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.geolocationService.loadGoogleApi()
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (status) => {
            if (status === GoogleStatus.SUCCESS) {

              const options = {
                componentRestrictions: { country: ["US",'AM','IN']}
              };

              const autocomplete = new google.maps.places.Autocomplete(this.element,options);
              autocomplete.setFields(['formatted_address', 'place_id', 'address_component', 'geometry', 'type']);
              autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                  const place = autocomplete.getPlace();
                  if (!place.geometry || !place.formatted_address) {
                    return;
                  }
                  this.placeChanged.emit(this.geolocationService.parseGeocoderResult(place));
                })
              });
            }
          }
        });
    }
  }


  private geocode(address: string): void {
    this.isGeocodingChanged.emit(true);
    this.geolocationService.geocode({ address }, (results) => {
      this.placeChanged.emit(this.geolocationService.parseGeocoderResult(results?.[0]));
      this.isGeocodingChanged.emit(false);
    });
  }
}
