import { ServiceItem } from '@shared/item';
import { PriceType } from '@shared/price';
import { TransformDate } from '@shared/transform-date';
import { Expose, Type } from 'class-transformer';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { Order } from './order';

export class CalculateOrderRequest {
  @Expose({ name: 'pickup_time' })
  @TransformDate()
  public pickupTime: DateTime;

  @Expose({ name: 'delivery_time' })
  @TransformDate()
  public deliveryTime: DateTime;

  @Expose()
  @Type(() => ServiceItem)
  public items: Array<ServiceItem>;

  @Expose()
  public options: Array<number>;

  @Expose()
  public pounds: number;

  @Expose({ name: 'service_id' })
  public serviceID: number;

  @Expose({ name: 'promocode_id' })
  public promocodeID: number;

  @Expose({ name: 'delivery_fee' })
  public deliveryFee: number;

  @Expose({ name: 'delivery_fee_type' })
  public deliveryFeeType: PriceType;

  @Expose({ name: 'service_fee' })
  public serviceFee: number;

  @Expose({ name: 'service_fee_type' })
  public serviceFeeType: PriceType;

  @Expose()
  public tip: number;

  @Expose({ name: 'tip_type' })
  public tipType: PriceType;

  @Expose({ name: 'is_quick' })
  public isQuick: boolean;

  @Expose({ name: 'zip_id' })
  public zipID: number;

  @Expose({ name: 'not_validate_promocode' })
  public notValidatePromocode: boolean;

  @Expose()
  public is_email: boolean | null;

  constructor(model: Partial<CalculateOrderRequest>) {
    Object.assign(this, model);
  }

  public static fromOrder = (order: Order,zipId:number=undefined): CalculateOrderRequest => new CalculateOrderRequest({
    ...order,
    serviceFeeType: isNil(order.serviceFee) ? undefined : order.serviceFeeType,
    serviceFee: isNil(order.serviceFeeType) ? undefined : order.serviceFee,
    deliveryFeeType: undefined,
    deliveryFee: undefined,
    promocodeID: order.promocodeID,
    is_email: null,
    notValidatePromocode: !!order.id,
    options: order.optionIDs,
    zipID:!isNil(order.pickupAddress?.zipID) ?order.pickupAddress?.zipID : zipId
  });
}
