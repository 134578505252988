import { createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  from,
  map,
  Observable,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { PublicServicesServicePageActions } from './actions';
import {
  User,
  UserAddressActions,
  UserService,
  UserZipActions,
  UserZipSelectors,
} from '@shared/user';
import { HttpErrorResponse } from '@angular/common/http';
import { ServiceService } from '@shared/service';
import { PublicServicesServicePageSelectors } from './selectors';
import { DateTime } from 'luxon';
import { BaseServicePageEffects } from '@shared/base-service-page/store';
import { LocalStorageService } from '@shared/local-storage';
import { Order } from '@shared/order';
import { Router } from '@angular/router';
import { Zip } from '@shared/zip';
import { Address } from '@shared/address';
import { getSelectors } from '@ngrx/router-store';
import { SeoActions } from '@shared/seo/store';
import { SeoPageType } from '@shared/seo-page';
import { AuthService } from '@shared/auth';
import { compact } from 'lodash';
import { LocationService, LocationType, Location } from '@shared/location';
import { PublicServicesServicePageState } from './state';
import { CookieService } from '@shared/cookie-service';

@Injectable()
export class PublicServicesServicePageEffects extends BaseServicePageEffects<PublicServicesServicePageState> {
  public init$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PublicServicesServicePageActions.init),
      withLatestFrom(
        this.store.select(UserZipSelectors.zip),
        this.store.select(getSelectors().selectRouteParams)
      ),
      switchMap(([_, userZip, params]) => {
        if (userZip?.code && !params?.locationCode?.length) {
          return this.openServiceInZip({
            serviceCode: params.code,
            zip: userZip,
          });
        }

        return of(true);
      }),
      map(() => PublicServicesServicePageActions.loadServices())
    )
  );

  public loadServices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PublicServicesServicePageActions.loadServices),
      switchMap(() =>
        this.serviceService
          .search({ all: true, parentID: null, relations: ['media'] })
          .pipe(
            map((response) =>
              PublicServicesServicePageActions.loadServicesSuccess({
                services: response.data,
              })
            ),
            catchError((response: HttpErrorResponse) => {
              this.errorHandlingService.handleHttpError(response, {
                translateKey:
                  'PUBLIC.SERVICES.SERVICE.TEXT_LOAD_SERVICES_FAILURE',
              });

              return of(
                PublicServicesServicePageActions.loadServicesFailure({
                  response,
                })
              );
            })
          )
      )
    )
  );

  public zipChanged$: Observable<boolean> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserZipActions.setZip),
        withLatestFrom(
          this.store.select(PublicServicesServicePageSelectors.serviceCode),
          this.authService.isAuthenticated$
        ),
        filter(
          ([_, serviceCode, isAuthenticated]) =>
            !!serviceCode && !isAuthenticated
        ),
        switchMap(([{ zip }, serviceCode]) =>
          this.openServiceInZip({ serviceCode, zip })
        )
      ),
    { dispatch: false }
  );

  public setPageParams$: Observable<[Action, Zip, string]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PublicServicesServicePageActions.setPageParams),
        withLatestFrom(
          this.store.select(UserZipSelectors.zip),
          this.store.select(getSelectors().selectUrl)
        ),
        tap(([{ locationCode }, userZip, url]) =>
          this.store.dispatch(
            SeoActions.tryLoadSettings({
              request: {
                type: !!locationCode
                  ? SeoPageType.LOCATION_SERVICE
                  : SeoPageType.SERVICE,
                url,
                zipCode: userZip?.code,
                relation: !!locationCode ? ['parent'] : ['media'],
              },
              onSuccess: (settings) => {
                this.store.dispatch(
                  PublicServicesServicePageActions.setSettings({ settings })
                );
              },
              onFailure: (response) => {
                this.errorHandlingService.handleHttpError(response);
              },
            })
          )
        )
      ),
    { dispatch: false }
  );

  public calculateOrderFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PublicServicesServicePageActions.calculateOrderFailure),
        tap(({ response }) =>
          this.errorHandlingService.handleHttpError(response, {
            translateKey:
              'PUBLIC.SERVICES.SERVICE.TEXT_CALCULATE_ORDER_FAILURE',
          })
        )
      ),
    { dispatch: false }
  );

  public serviceChanged$: Observable<Action> = createEffect(() =>
    this.store.select(PublicServicesServicePageSelectors.service).pipe(
      distinctUntilChanged(
        (prevService, currService) => prevService?.id === currService?.id
      ),
      filter((service) => service?.isLaundryService),
      map((service) =>
        PublicServicesServicePageActions.tryLoadInitialDates({
          serviceID: service.parentID,
          laundryID: service.laundryID,
        })
      )
    )
  );

  public handleSuccessfulAuth$: Observable<[User, Order, Zip]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PublicServicesServicePageActions.handleSuccessfulAuth),
        exhaustMap(() => this.userService.fetchProfile()),
        withLatestFrom(
          this.store.select(PublicServicesServicePageSelectors.currentOrder),
          this.store.select(UserZipSelectors.zip)
        ),
        tap(([user, order, zip]) => {
          const userAddressRelatedToOrder = user.getAddressByZip(zip.code);
          if (!userAddressRelatedToOrder) {
            this.store.dispatch(
              UserAddressActions.upsert({
                request: { zipCode: zip.code, isActive: true },
                onSuccess: (address) => {
                  this.openPrivateOrderPageWithAddress(order, address);
                  this.store.dispatch(
                    PublicServicesServicePageActions.handleSuccessfulAuthSuccess()
                  );
                },
                onFailure: (response) =>
                  this.store.dispatch(
                    PublicServicesServicePageActions.handleSuccessfulAuthFailure(
                      { response }
                    )
                  ),
              })
            );
          } else {
            this.openPrivateOrderPageWithAddress(
              order,
              userAddressRelatedToOrder
            );
            this.store.dispatch(
              PublicServicesServicePageActions.handleSuccessfulAuthSuccess()
            );
          }
        }),
        catchError((response: HttpErrorResponse) => {
          this.store.dispatch(
            PublicServicesServicePageActions.handleSuccessfulAuthFailure({
              response,
            })
          );

          return of();
        })
      ),
    { dispatch: false }
  );

  public loadLocations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PublicServicesServicePageActions.loadLocations),
      withLatestFrom(
        this.store.select(PublicServicesServicePageSelectors.settings),
        this.store.select(PublicServicesServicePageSelectors.locationsState)
      ),
      switchMap(([{ page }, settings, { parent, pagination }]) =>
        this.locationService
          .search({
            page: page || pagination.currentPage + 1,
            perPage: pagination.perPage,
            type: LocationType.COUNTY,
            parentID: parseInt(sessionStorage.getItem('stateId')),
          })
          .pipe(
            map((response) =>
              PublicServicesServicePageActions.loadLocationsSuccess({
                response,
              })
            ),
            catchError((response) => {
              this.errorHandlingService.handleHttpError(response);

              return of(
                PublicServicesServicePageActions.loadLocationsFailure({
                  response,
                })
              );
            })
          )
      )
    )
  );

  public tryLoadInitialDates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PublicServicesServicePageActions.tryLoadInitialDates),
      filter(({ laundryID, serviceID }) => !!laundryID && !!serviceID),
      tap(() =>
        this.store.dispatch(PublicServicesServicePageActions.loadInitialDates())
      ),
      exhaustMap(({ laundryID, serviceID }) =>
        this.orderService.prepare({ laundryID, serviceID }).pipe(
          map((data) =>
            PublicServicesServicePageActions.loadInitialDatesSuccess(data)
          ),
          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response);

            return of(
              PublicServicesServicePageActions.loadInitialDatesFailure({
                response,
              })
            );
          })
        )
      )
    )
  );

  constructor(
    protected serviceService: ServiceService,
    protected userService: UserService,
    protected authService: AuthService,
    protected router: Router,
    protected locationService: LocationService,
    private cookieService: CookieService
  ) {
    super(PublicServicesServicePageActions, PublicServicesServicePageSelectors);
  }

  private openPrivateOrderPageWithAddress(
    order: Order,
    address: Address
  ): void {
    const orderWithAddress = new Order({
      ...order,
      pickupAddress: address,
      deliveryAddress: address,
    });
    this.store.dispatch(UserAddressActions.select({
      address,
      onSuccess: () =>{}
    }));
    LocalStorageService.orderDraftExpiration.set(
      DateTime.now().plus({ minutes: 1 })
    );
    LocalStorageService.orderDraft.set(orderWithAddress);
    let promo=localStorage.getItem('promoCodeForActivateLoginMode');
    if(promo){
      localStorage.removeItem('promoCodeForActivateLoginMode')
      this.router.navigate(['/services', orderWithAddress.serviceCode], {
        queryParams: {promocode:promo},
      });
    }else{
      this.router.navigate(['services', orderWithAddress.serviceCode]);
    }

  }

  private openServiceInZip({serviceCode, zip,}: {
    serviceCode: string;
    zip: Zip;
  }): Observable<boolean> {
    let city: Location;
    if (zip?.getLocationOfType(LocationType.CITY)) {
      city = zip?.getLocationOfType(LocationType.CITY);
    } else if (zip?.getLocationOfType(LocationType.CITY) === null) {
      city = zip?.getLocationOfType(LocationType.NEIGHBORHOOD);
    } else if (
      zip?.getLocationOfType(LocationType.NEIGHBORHOOD) === null &&
      zip?.getLocationOfType(LocationType.CITY) === null
    ) {
      city = zip?.getLocationOfType(LocationType.COUNTY);
    }

    const newURL = compact([
      '/services',
      serviceCode,
      city?.type,
      city?.code,
    ]).join('/');

    if (this.router.url === newURL) {
      this.store.dispatch(
        PublicServicesServicePageActions.setPageParams({
          serviceCode,
          locationCode: city?.code,
          locationType: city?.type,
        })
      );

      return of(true);
    } else {
      return from(
        this.router.navigate(
          compact(['/services', serviceCode, city?.type, city?.code])
        )
      );
    }
  }
}
