import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { PhoneVerificationData } from '../models';

export class PhoneVerificationActions {
  public static resetState = createAction(
    '[PhoneVerification] Reset State'
  );

  public static tryStartVerification = createAction(
    '[PhoneVerification] Try Start Verification',
    props<{ data?: PhoneVerificationData }>()
  );

  public static startVerification = createAction(
    '[PhoneVerification] Start Verification',
    props<{ data?: PhoneVerificationData }>()
  );

  public static startVerificationSuccess = createAction(
    '[PhoneVerification] Start Verification Success'
  );

  public static startVerificationFailure = createAction(
    '[PhoneVerification] Start Verification Failure',
    props<{ response: HttpErrorResponse }>()
  );

  public static checkVerificationCode = createAction(
    '[PhoneVerification] Check Verification Code'
  );

  public static checkVerificationCodeSuccess = createAction(
    '[PhoneVerification] Check Verification Code Success'
  );

  public static checkVerificationCodeFailure = createAction(
    '[PhoneVerification] Check Verification Code Failure',
    props<{ response: HttpErrorResponse }>()
  );
}
