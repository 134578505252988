import {ElementRef, Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})


export class QuestionsSectionService {
  public elementToScroll: ElementRef | null;

  public setElementToScroll(elem: ElementRef<HTMLElement> | null) {
    return this.elementToScroll = elem;
  }

  public getElementToScroll () {
    return this.elementToScroll;
}
}
