import type { AppState } from '@shared/store';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { UserZipActions } from './actions';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UserZipSelectors } from './selectors';
import { Zip, ZipRelation, ZipService } from '@shared/zip';
import { GeolocationService } from '@shared/geolocation';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from '@shared/error-handling';
import { CookieService } from '@shared/cookie-service';
import { AuthService } from '@shared/auth';
import { compact } from 'lodash';
import {MapApiService} from "@shared/map-api";
import {TopLocationsActions} from '@shared/footer/store';

@Injectable()
export class UserZipEffects implements OnInitEffects {
  public trySetZip$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserZipActions.trySetZip),
      withLatestFrom(
        this.store.select(UserZipSelectors.zip)
      ),
      filter(([action, zip]) => zip?.code !== action.zip?.code),
      map(([{ zip }]) => UserZipActions.setZip({ zip }))
    )
  );

  public setZip$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserZipActions.setZip),
      tap(({ zip, zipAddress }) => {
        this.cookieService.putClassItem('zip', zip, { maxAge: 2e5 });
        this.cookieService.put('zipAddress', zipAddress || zip?.code, { maxAge: 2e5 });
      }),
      withLatestFrom(
        this.geolocationService.geocoder$
      ),
      filter(([{ zip, zipAddress }]) => !!zip && !zipAddress),
      tap(() => this.store.dispatch(UserZipActions.geocodeZip())),
      // switchMap(([{ zip }, geocoder]) => this.geocodeZip(geocoder, zip))
      switchMap(([{ zip },geocoder]) =>{
        this.store.dispatch(TopLocationsActions.loadLocations());

        return  this.getAddress( zip)
      })
    )
  );

  public checkZip$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserZipActions.checkZip),
      withLatestFrom(this.authService.isAuthenticated$),
      switchMap(([{ zipCode, onSuccess, relations }, isAuthenticated]) => this.zipService
        .check({
          code: zipCode,
          relations: compact<ZipRelation>([(!isAuthenticated) && 'locations.parent', ...(relations || [])])
        })
        .pipe(
          tap((zip) => onSuccess?.(zip)),
          map(() => UserZipActions.checkZipSuccess()),
          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'SHARED.USER.TEXT_CHECK_ZIP_FAILURE'
            });

            return of(UserZipActions.checkZipFailure({ response }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private zipService: ZipService,
    private geolocationService: GeolocationService,
    private errorHandlingService: ErrorHandlingService,
    private cookieService: CookieService,
    private authService: AuthService,
    private mapApiService: MapApiService,
  ) { }

  public ngrxOnInitEffects(): Action {
    const zip = this.cookieService.getClassItem('zip', Zip);

    return UserZipActions.setZip({
      zip,
      zipAddress: (zip) ? this.cookieService.get('zipAddress') : undefined
    });
  }

  // private geocodeZip(geocoder: google.maps.Geocoder, zip: Zip): Observable<Action> {
  //   const subject = new Subject<Action>();
  //
  //   //   geocoder.geocode({ address: `zipcode ${zip.code}` }, (results) => {
  //   geocoder.geocode({ componentRestrictions:  { country: "us",postalCode:zip.code } }, (results) => {
  //     const result = results?.[0];
  //     const parsedGeocodeResult = this.geolocationService.parseGeocoderResult(result);
  //
  //     if (parsedGeocodeResult.formattedAddress) {
  //       subject.next(UserZipActions.geocodeZipSuccess({ address: parsedGeocodeResult.formattedAddress }));
  //       this.cookieService.put('zipAddress', parsedGeocodeResult.formattedAddress, { maxAge: 2e5 });
  //     } else {
  //       subject.next(UserZipActions.geocodeZipFailure());
  //     }
  //     subject.complete();
  //   });
  //
  //   return subject;
  // }

  private getAddress(zip: Zip): Observable<Action> {
    const subject = new Subject<Action>();
    this.mapApiService.getAddress(zip.code).pipe().subscribe(data=>{

      const parsedGeocodeResult = this.geolocationService.parseGeocoderResultByMapBox( data.features);

      if (parsedGeocodeResult.formattedAddress) {
        subject.next(UserZipActions.geocodeZipSuccess({ address: parsedGeocodeResult.formattedAddress }));
        this.cookieService.put('zipAddress', parsedGeocodeResult.formattedAddress, { maxAge: 2e5 });
      } else {
        subject.next(UserZipActions.geocodeZipFailure());
      }
      subject.complete();
    })


    return subject;
  }

}
