import { PaymentSystem } from '../enums';
import { PaymentSystemData } from '../models';

export const paymentSystemsData: Record<PaymentSystem, PaymentSystemData> = {
  [PaymentSystem.APPLE_PAY]: new PaymentSystemData({
    type: PaymentSystem.APPLE_PAY,
    name: 'Apple Pay'
  }),
  [PaymentSystem.GOOGLE_PAY]: new PaymentSystemData({
    type: PaymentSystem.GOOGLE_PAY,
    name: 'Google Pay'
  })
};
