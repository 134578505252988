
import { BaseEntity } from '@shared/base-entity';
import { Zip } from '@shared/zip';
import { Expose, Type } from 'class-transformer';

export class Address extends BaseEntity {
  @Expose()
  public address: string;

  @Expose()
  public apt: string;

  @Expose()
  public city: string;

  @Expose()
  public gatecode: string;

  @Expose({ name: 'building_name' })
  public buildingName: string;

  @Expose()
  public latitude: number;

  @Expose()
  public longitude: number;

  @Expose()
  public state: string;

  @Expose({ name: 'is_active' })
  public isActive: boolean;

  @Expose({ name: 'zip', toClassOnly: true })
  @Type(() => Zip)
  public zip: Zip;

  @Expose({ name: 'zip_id' })
  public zipID: number;

  @Expose({ name: 'zip', toPlainOnly: true })
  public get zipCode(): string {
    return this._zipCode || this.zip?.code;
  }

  public set zipCode(code: string) {
    this._zipCode = code;
  }

  public get viewAddress(): string {
    return this.address || this.zipCode;
  }

  private _zipCode: string;

  constructor(model: Partial<Address>) {
    super(model);
    Object.assign(this, model);
  }
}
