import { FormGroupState, setValue, updateGroup } from 'ngrx-forms';
import { NotificationSettings } from '../models';

export class NotificationsForm {
  public isTransactionalSMSEnabled: boolean;
  public isMarketingSMSEnabled: boolean;
  public isPushEnabled: boolean;
  public isEmailEnabled: boolean;

  constructor() {
    this.isTransactionalSMSEnabled = false;
    this.isMarketingSMSEnabled = false;
    this.isPushEnabled = false;
    this.isEmailEnabled = false;
  }

  public static prefillForm = (
    notifications?: NotificationSettings
  ): (state: FormGroupState<NotificationsForm>) => FormGroupState<NotificationsForm> => updateGroup<NotificationsForm>({
    isTransactionalSMSEnabled: setValue(notifications?.isTransactionalSMSEnabled || false),
    isMarketingSMSEnabled: setValue(notifications?.isMarketingSMSEnabled || false),
    isPushEnabled: setValue(notifications?.isPushEnabled || false),
    isEmailEnabled: setValue(notifications?.isEmailEnabled || false)
  });
}
