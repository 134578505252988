import { Injectable, TemplateRef, Type } from '@angular/core';
import { DialogConfig, DialogRef, DialogService } from '@ngneat/dialog';

@Injectable()
export class AppDialogService {
  constructor(private dialogService: DialogService) { }

  // TODO: Improve typing
  public openComponent<D = any, R = any, T extends Type<any> = Type<any>>(
    component: T,
    config?: Partial<DialogConfig<D>>
  ): DialogRef<D, R, InstanceType<T>> {
    return this.dialogService.open<T>(component, config as any) as any;
  }

  public openTemplate<D = any>(template: TemplateRef<any>, config?: Partial<DialogConfig<D>>): DialogRef {
    return this.dialogService.open(template, config);
  }

  public closeByID(dialogID: string, result?: any): void {
    const dialogRef = this.dialogService.dialogs.find((dialog) => dialog.id === dialogID);

    if (dialogRef) {
      dialogRef.close(result);
    }
  }

  public closeAll(): void {
    this.dialogService.closeAll();
  }

  public isOpened(dialogID: string): boolean {
    return !!this.dialogService.dialogs.find((dialog) => dialog.id === dialogID);
  }
}
