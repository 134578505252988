import { Injectable } from '@angular/core';
import { ReadableEntityService } from '@shared/base-entity';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';
import {
  Zip,
  ZipCheckRequest,
  ZipEntityRequest,
  ZipPaginationRequest,
} from './models';
import { configuration } from '@configurations';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ZipService extends ReadableEntityService<
  Zip,
  ZipPaginationRequest,
  ZipEntityRequest
> {
  constructor(
    private http: HttpClient
  ) {
    super();

    this.endpoint = '/zips';
    this.entityConstructor = Zip;
    this.entityPaginationRequestConstructor = ZipPaginationRequest;
    this.entityRequestConstructor = ZipEntityRequest;
  }

  private _geocoder: google.maps.Geocoder;

  getCoordinates(zipCode: string): Observable<any> {
    const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    const params = {
      address: zipCode,
      key: configuration.googleApiKey
    };
    const apiURL = `https://maps.googleapis.com/maps/api/geocode/json`;

    return this.http.get(apiURL, { headers, params });
  }

  public check(request: Partial<ZipCheckRequest>): Observable<Zip> {
    const requestParams = new ZipCheckRequest(request);

    return this.apiService
      .get('/zips/check', instanceToPlain(requestParams))
      .pipe(
        map((response) => {
          const zip = plainToInstance(Zip, response);
          zip.code = request.code;

          return zip;
        })
      );
  }
}
