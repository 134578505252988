import { Injectable } from '@angular/core';
import { configuration } from '@configurations';
import { ScriptService } from '@shared/script';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IntercomStatus } from './enums';

@Injectable()
export class IntercomService {
  public status$: Subject<IntercomStatus>;

  private progressRef: NgProgressRef;

  constructor(
    private scriptService: ScriptService,
    private progress: NgProgress
  ) {
    this.status$ = new BehaviorSubject(IntercomStatus.DOWN);
    this.progressRef = this.progress.ref('router-progressbar');
  }

  public loadApi(): Observable<void> {
    this.status$.next(IntercomStatus.LOADING);
    this.progressRef.start();

    return this.scriptService
      .loadScript(`https://widget.intercom.io/widget/${configuration.intercomAppID}`)
      .pipe(
        tap(() => {
          this.status$.next(IntercomStatus.LOADED);
          this.progressRef.complete();
        }),
        catchError((e) => {
          this.status$.next(IntercomStatus.FAILED);
          this.progressRef.complete();

          throw (e);
        })
      );
  }

  public boot(): void {
    Intercom('boot', {
      app_id: configuration.intercomAppID,
      hide_default_launcher: true
    });
  }

  public shutdown(): void {
    Intercom('shutdown');
    this.status$.next(IntercomStatus.DOWN);
  }

  public show(): void {
    Intercom('show');
  }
  public showMessages(): void {
    Intercom('showMessages');
  }

  public hide(): void {
    Intercom('hide');
  }

  public update(settings: Intercom_.IntercomSettings): void {
    Intercom('update', settings);
  }
}
