import { createAction, props } from '@ngrx/store';
import { Place } from '@shared/google-places';

export class GeolocationActions {
  public static geolocate = createAction(
    '[Geolocation] Geolocate',
    props<{ onSuccess: (place: Place) => void; onFailure?: () => void }>()
  );

  public static geolocateComplete = createAction(
    '[Geolocation] Geolocate Complete'
  );
}
