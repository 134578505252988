import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/base-form-control';
import { IconModule } from '@shared/icon';
import { CounterVariant } from './types';

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgTemplateOutlet,
    IconModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlCounterComponent),
      multi: true
    }
  ],
  selector: 'form-control-counter',
  templateUrl: 'form-control-counter.html',
  styleUrls: ['form-control-counter.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlCounterComponent extends BaseFormControlDirective<number> {
  @Input() valueTemplate: TemplateRef<any>;
  @Input() variant: CounterVariant;
  @Input() min: number;

  constructor() {
    super();
    this.variant = 'purple';
  }

  public minusButtonClicked(): void {
    if (!this.min || this.value > this.min) {
      this.value -= 1;
    } else if (this.value < this.min) {
      this.value = this.min;
    }
  }

  public plusButtonClicked(): void {
    if (this.value < this.min) {
      this.value = this.min;
    } else {
      this.value += 1;
    }
  }
}
