import { SeoPageHeadingTags } from './heading-tags';

export class SeoPageHtmlTags {
  public heading1: SeoPageHeadingTags;
  public heading2: SeoPageHeadingTags;
  public heading3: SeoPageHeadingTags;
  public heading4: SeoPageHeadingTags;
  public heading5: SeoPageHeadingTags;
  public heading6: SeoPageHeadingTags;
  public heading7: SeoPageHeadingTags;
  public heading8: SeoPageHeadingTags;
  public heading9: SeoPageHeadingTags;
  public heading10: SeoPageHeadingTags;
  public heading11: SeoPageHeadingTags;
  public heading12: SeoPageHeadingTags;
  public heading13: SeoPageHeadingTags;
  public heading14: SeoPageHeadingTags;

  constructor(model: Partial<SeoPageHtmlTags>) {
    Object.assign(this, model);
  }

  public hasAnyHeading(ids: Array<number>): boolean {
    for (const id of ids) {
      if ((this[`heading${id}`] as SeoPageHeadingTags)?.hasText) {
        return true;
      }
    }

    return false;
  }
}
