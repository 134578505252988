import { EntityRequest } from '@shared/base-entity';
import { Expose } from 'class-transformer';
import { ZipRelation } from '../types';

export class ZipCheckRequest extends EntityRequest<ZipRelation> {
  @Expose()
  public code: string;

  constructor(model: Partial<ZipCheckRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
