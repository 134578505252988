export class FooterLink {
  public text: string;
  public href: string;
  public isCityAdd: boolean;
  public addUrl?: string;

  constructor(model: Partial<FooterLink>) {
    Object.assign(this, model);
  }
}
