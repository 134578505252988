import { NgIf } from '@angular/common';
import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import { ImageDirective } from '@shared/image-directive';
import { ServiceItem } from '@shared/item/models';
import {OrderItemsModule} from "@shared/order-items";

@Component({
  standalone: true,
  imports: [
    NgIf,
    ImageDirective,
    OrderItemsModule
  ],
  selector: 'item-image',
  templateUrl: 'item-image.html',
  styleUrls: ['item-image.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemImageComponent {
  @Input() item: ServiceItem;
  @Input() isAddToCard: boolean;


  @Output() countIncreased: EventEmitter<void>;
  @Output() countDecreased: EventEmitter<void>;

  constructor() {
    this.countIncreased = new EventEmitter<void>();
    this.countDecreased = new EventEmitter<void>();
  }
  public increaseCountButtonClicked(): void {
    this.countIncreased.emit();
  }

  public decreaseCountButtonClicked(): void {
    this.countDecreased.emit();
  }
}
