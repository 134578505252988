import { Injectable } from '@angular/core';
import { AuthService } from '@shared/auth';
import { CookieService } from '@shared/cookie-service';
import { WebviewService } from '@shared/webview';
import { BehaviorSubject, combineLatest, map, Observable, Subject } from 'rxjs';

@Injectable()
export class CookiePopupFacade {
  public get isVisible$(): Observable<boolean> {
    return combineLatest([
      this.isCookieClosed$,
      this.authService.isAuthenticated$
    ]).pipe(
      map(([isCookieClosed, isAuthenticated]) => {
        if (isAuthenticated && !isCookieClosed) {
          this.acceptCookies();
        }

        return !isCookieClosed && !isAuthenticated && !this.webviewService.isWebview;
      })
    );
  }

  private isCookieClosed$: Subject<boolean>;

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private webviewService: WebviewService
  ) {
    this.isCookieClosed$ = new BehaviorSubject(!!this.cookieService.get('common_isCookieClosed') || false);
  }

  public closeCookiePopup(): void {
    this.isCookieClosed$.next(true);
  }

  public acceptCookies(): void {
    this.isCookieClosed$.next(true);
    this.cookieService.put('common_isCookieClosed', 'true', { maxAge: 4e10 });
  }
}
