import type {AppState} from '@shared/store';
import {TopLocationsState} from "./state";
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {Location} from "@shared/location";

const selectFeature = (state: AppState): TopLocationsState => state.topLocations;

export class TopLocationSelectors {
  public static getCities: MemoizedSelector<AppState, Array<Location>> = createSelector(
    selectFeature,
    (state: TopLocationsState) => state.cites
  );
}
