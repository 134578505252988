import { Directive, inject } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { AppDialogService } from '@shared/dialog';
import { Observable, of } from 'rxjs';

@Directive()
export class BaseDialogDirective<T extends { isLoading$?: Observable<boolean> } | object> {
  public dialogRef: DialogRef<T>;
  public isLoading$: Observable<boolean>;
  public data?: T;

  protected dialogService: AppDialogService;

  constructor() {
    this.dialogRef = inject(DialogRef);
    this.dialogService = inject(AppDialogService);
    this.data = this.dialogRef.data;
    this.isLoading$ = this.isLoading$ = (this.data && 'isLoading$' in this.data) ? this.data.isLoading$ : of(false);
  }

  public close(): void {
    this.dialogService.closeByID(this.dialogRef.id);
  }
}
