import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {configuration} from '@configurations';
import {Place} from '@shared/google-places';
import {NotificationService} from '@shared/notification';
import {ScriptService} from '@shared/script';
import {Observable, of, Subject} from 'rxjs';
import {catchError, exhaustMap, filter, map, switchMap, tap,} from 'rxjs/operators';
import {GoogleStatus} from './enums';
import {HttpClient} from '@angular/common/http';
import {CookieService} from "@shared/cookie-service";

@Injectable()
export class GeolocationService {
  public get geocoder$(): Observable<google.maps.Geocoder> {
    return of(this._geocoder).pipe(
      filter(() => isPlatformBrowser(this.platformID)),
      exhaustMap((geocoder) =>
        geocoder
          ? of(geocoder)
          : this.loadGoogleApi().pipe(map(() => this._geocoder))
      ),
      filter((geocoder) => !!geocoder?.geocode)
    );
  }

  private _geocoder: google.maps.Geocoder;

  constructor(
    private scriptService: ScriptService,
    private notificationService: NotificationService,
    private http: HttpClient,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformID: object
  ) {
  }

  public loadGoogleApi(): Observable<GoogleStatus> {
    return this.scriptService
      .loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${configuration.googleApiKey}&libraries=places&language=en`
      )
      .pipe(
        tap(() => {
          try {
            this._geocoder = new google.maps.Geocoder();
          } catch {
          }
        }),
        map(() => GoogleStatus.SUCCESS),
        catchError(() => of(GoogleStatus.FAILURE))
      );
  }


  public geolocate(): Observable<Place | null> {
    return this.geocoder$.pipe(
      switchMap((geocoder) => {
        const subject = new Subject<Place | null>();
        navigator.geolocation.getCurrentPosition(
          (position) =>
            geocoder.geocode(
              {
                location: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                },
              },
              (results) => {
                subject.next(
                  results?.[0] ? this.parseGeocoderResult(results[0]) : null
                );
                subject.complete();
              }
            ),
          () => {
            subject.next(null);
            subject.complete();
          }
        );

        return subject.asObservable();
      })
    );
  }

  public geocode(
    request: google.maps.GeocoderRequest,
    callback: (
      results: Array<google.maps.GeocoderResult>,
      status?: google.maps.GeocoderStatus
    ) => void
  ): void {
    if (!request.address) {
      return callback([]);
    }

    if (!this._geocoder?.geocode) {
      this.notificationService.error('SHARED.GEOLOCATION.TEXT_GEOCODE_FAILURE');

      return callback([]);
    }

    this._geocoder.geocode(request, (results, status) => {
      if (
        ![
          google.maps.GeocoderStatus.OK,
          google.maps.GeocoderStatus.ZERO_RESULTS,
        ].includes(status)
      ) {
        this.notificationService.error(
          'SHARED.GEOLOCATION.TEXT_GEOCODE_FAILURE'
        );
      }
      callback(results, status);
    });
  }

  public parseGeocoderResult(
    result: google.maps.places.PlaceResult | google.maps.GeocoderResult
  ): Place {
    const place: Partial<Place> = {
      formattedAddress: '',
      zipCode: '',
      state: '',
      city: '',
    };
    if (result) {
      place.latitude = result?.geometry?.location.lat() || undefined;
      place.longitude = result?.geometry?.location.lng() || undefined;
      place.googlePlaceID = result?.place_id || undefined;


      place.formattedAddress = result.formatted_address;
      for (const component of result?.address_components || []) {
        if (component.types.includes('postal_code')) {
          place.zipCode = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          place.state = component.long_name;
        } else if (component.types.includes('locality')) {
          place.city = component.long_name;
        } else if (component.types.includes('county')) {
          place.county = component.long_name;
        }
      }
    }
    return new Place(place);
  }

  public parseGeocoderResultByMapBox(
    features: google.maps.places.PlaceResult | any
  ): Place {
    let location: any | undefined;
    const place: Partial<Place> = {
      formattedAddress: '',
      zipCode: '',
      state: '',
      city: '',
    };
    let filteredData: any = features.filter(item => item.place_name.includes('United States'));
    if (!filteredData.length) {
      filteredData = features
    }
    if (filteredData && filteredData.length > 0) {
      const firstFeature = filteredData[0];
      location = {
        state: this.extractContextValue(firstFeature, 'state'),
        city: this.extractContextValue(firstFeature, 'place'),
        county: this.extractContextValue(firstFeature, 'postcode')
      };
      place.formattedAddress = firstFeature.place_name
      place.formattedAddress = place.formattedAddress.replace("United States","US")
      place.zipCode = location.text
      place.state = location.state
      place.city = location.city
    }
    return new Place(place);
  }

  private extractContextValue(feature: any, identifier: string): string {
    const context = feature.context.find((ctx: any) =>
      ctx.id.toLowerCase().includes(identifier.toLowerCase())
    );
    return context ? context.text : '';
  }
}

