import { DateTime } from 'luxon';

export function birthdateValidator(value: string): object {
  let validationResult: object | null = null;
  if (value) {
    const parsedDate = DateTime.fromISO(value);
    if (
      parsedDate.invalidReason
      || value.length < 10
      || parsedDate.diffNow('days').days > 0
    ) {
      validationResult = { birthdate: false };
    }
  }

  return validationResult;
}
