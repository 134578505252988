export class Pagination {
  public currentPage: number;
  public perPage: number;
  public total: number | null;
  public lastPage: number | null;

  constructor(model?: Partial<Pagination>) {
    const defaultValue: Pagination = {
      currentPage: 1,
      perPage: 9,
      total: null,
      lastPage: null
    };

    Object.assign(this, defaultValue, model);
  }
}
