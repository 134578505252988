import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { AppState } from '@shared/store';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { PhoneVerificationActions } from './actions';
import { catchError, debounceTime, exhaustMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { PhoneVerificationSelectors } from './selectors';
import { PhoneVerificationService } from '../phone-verification.service';
import { ClearAsyncErrorAction, FormGroupState, SetAsyncErrorAction, SetValueAction } from 'ngrx-forms';
import { AppDialogService } from '@shared/dialog';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { PhoneVerificationForm } from '../forms';
import { TranslateService } from '@ngx-translate/core';
import { PhoneVerificationModalComponent } from '../components';
import { PhoneVerificationData } from '../models';
import { ErrorHandlingService, ValidationErrorName } from '@shared/error-handling';

@Injectable()
export class PhoneVerificationEffects {
  public static dialogID: string = 'PhoneVerificationDialog';

  public tryStartVerification$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneVerificationActions.tryStartVerification),
      withLatestFrom(
        this.store.select(PhoneVerificationSelectors.isCountdown),
        this.store.select(PhoneVerificationSelectors.phone)
      ),
      tap(() => {
        if (!this.dialogService.isOpened(PhoneVerificationEffects.dialogID)) {
          this.dialogService.openComponent(PhoneVerificationModalComponent, {
            id: PhoneVerificationEffects.dialogID
          });
        }
      }),
      // filter(([{ data }, isCountdown, phone]) => !isCountdown || data?.phone !== phone),
      tap(([{ data }]) => this.store.dispatch(PhoneVerificationActions.startVerification({ data }))),
      exhaustMap(([{data}, __, phone]) =>
        this.phoneVerificationService.start({ phone, token: data.token})
          .pipe(
            map(() => PhoneVerificationActions.startVerificationSuccess()),
            catchError((response: HttpErrorResponse) => {
              this.errorHandlingService.handleHttpError(response, {
                translateKey: 'SHARED.PHONE_VERIFICATION.TEXT_VERIFICATION_ERROR'
              });

              return of(PhoneVerificationActions.startVerificationFailure({ response }));
            })
          )
      )
    )
  );

  public verificationCodeEntered$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetValueAction.TYPE),
      filter((action: SetValueAction<string>) => action.controlId.startsWith('PhoneVerificationForm')),
      withLatestFrom(
        this.store.select(PhoneVerificationSelectors.formState),
        this.store.select(PhoneVerificationSelectors.checkAttempts)
      ),
      filter(([_, formState, checkAttempts]) => formState.isValid && checkAttempts > 0),
      debounceTime(400),
      map(() => PhoneVerificationActions.checkVerificationCode())
    )
  );

  public checkVerificationCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneVerificationActions.checkVerificationCode),
      withLatestFrom(
        this.store.select(PhoneVerificationSelectors.phone),
        this.store.select(PhoneVerificationSelectors.formState),
      ),
      exhaustMap(([_, phone, { value }]) =>
        this.phoneVerificationService.check({
          phone,
          code: value.code
        }).pipe(
          map(() => PhoneVerificationActions.checkVerificationCodeSuccess()),
          catchError((response) => of(PhoneVerificationActions.checkVerificationCodeFailure({ response })))
        )
      )
    )
  );

  public checkVerificationCodeSuccess$: Observable<[Action, PhoneVerificationData]> = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneVerificationActions.checkVerificationCodeSuccess),
      withLatestFrom(this.store.select(PhoneVerificationSelectors.data)),
      tap(([_, verificationData]) => {
        verificationData.onSuccess?.();
        this.dialogService.closeByID(PhoneVerificationEffects.dialogID);
      })
    ),
    { dispatch: false }
  );

  public checkVerificationCodeFailure$: Observable<[Action, FormGroupState<PhoneVerificationForm>, number]> = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneVerificationActions.checkVerificationCodeFailure),
      withLatestFrom(
        this.store.select(PhoneVerificationSelectors.formState),
        this.store.select(PhoneVerificationSelectors.checkAttempts)
      ),
      tap(([{ response }, formState, checkAttempts]) => {
        if (response.status === HttpStatusCode.UnprocessableEntity) {
          this.store.dispatch(new SetAsyncErrorAction(
            formState.controls.code.id,
            ValidationErrorName.INVALID,
            (checkAttempts > 0)
              ? this.translateService.instant('SHARED.PHONE_VERIFICATION.TEXT_CODE_ERROR', { count: checkAttempts })
              : this.translateService.instant('SHARED.PHONE_VERIFICATION.TEXT_NO_ATTEMPTS')
          ));
        } else if (response.status === HttpStatusCode.BadRequest) {
          this.store.dispatch(new SetAsyncErrorAction(
            formState.controls.code.id,
            ValidationErrorName.INVALID,
            this.translateService.instant('SHARED.PHONE_VERIFICATION.TEXT_NO_ATTEMPTS')
          ));
        } else {
          this.errorHandlingService.handleHttpError(response, {
            translateKey: 'SHARED.PHONE_VERIFICATION.TEXT_CHECK_ERROR'
          });
        }
      })
    ),
    { dispatch: false }
  );

  public clearAsyncError$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetValueAction.TYPE),
      filter((action: SetValueAction<string>) => action.controlId.startsWith('PhoneVerificationForm')),
      withLatestFrom(
        this.store.select(PhoneVerificationSelectors.formState),
        this.store.select(PhoneVerificationSelectors.checkAttempts)
      ),
      filter(([_, formState, checkAttempts]) => formState.isInvalid && checkAttempts > 0),
      map(([_, formState]) => new ClearAsyncErrorAction(formState.controls.code.id, ValidationErrorName.INVALID))
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private phoneVerificationService: PhoneVerificationService,
    private errorHandlingService: ErrorHandlingService,
    private dialogService: AppDialogService,
    private translateService: TranslateService
  ) { }
}
