import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceService } from './service.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ServiceService]
})
export class ServiceModule { }

