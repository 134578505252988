import { inject, Injectable } from '@angular/core';
import { ApiService } from '@shared/api';
import { PaginationRequest, PaginationResponse } from '@shared/pagination';
import { Observable } from 'rxjs';
import { BaseEntity, EntityRequest } from './models';
import { instanceToPlain, plainToInstance, plainToClassFromExist, ClassConstructor } from 'class-transformer';
import { map } from 'rxjs/operators';

@Injectable()
export abstract class ReadableEntityService<
  TEntity extends BaseEntity = BaseEntity,
  TPaginationRequest extends PaginationRequest<string, string, string> = any,
  TEntityRequest extends EntityRequest<string> = any
> {
  protected endpoint: string;
  protected entityConstructor: ClassConstructor<TEntity>;
  protected entityPaginationRequestConstructor: ClassConstructor<TPaginationRequest>;
  protected entityRequestConstructor: ClassConstructor<TEntityRequest>;
  protected apiService: ApiService;

  constructor() {
    this.apiService = inject(ApiService);
  }

  public search(request: Partial<TPaginationRequest>): Observable<PaginationResponse<TEntity>> {
    const requestParams = new this.entityPaginationRequestConstructor(request);
    return this.apiService
      .get<PaginationResponse<TEntity>>(this.endpoint, instanceToPlain<TPaginationRequest>(requestParams))
      .pipe(
        map((response) => plainToClassFromExist(new PaginationResponse<TEntity>(this.entityConstructor), response))
      );
  }

  public get(id: number, request?: TEntityRequest): Observable<TEntity> {
    const requestParams = new this.entityRequestConstructor(request);

    return this.apiService
      .get<TEntity>(`${this.endpoint}/${id}`, instanceToPlain(requestParams))
      .pipe(
        map((response) => plainToInstance(this.entityConstructor, response))
      );
  }
}
