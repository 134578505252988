import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { TransformDateFormat } from './enums';

export function TransformDate(format: TransformDateFormat = TransformDateFormat.SQL): (target: any, key: string) => void {
  const toPlain = Transform(({ value }) => {
    if (value instanceof DateTime) {
      switch (format) {
        case TransformDateFormat.ISO:
          return value.toUTC().toISO();
        case TransformDateFormat.SQL_DATE:
          return value.toUTC().toSQLDate();
        case TransformDateFormat.SQL:
          return value.toUTC().toSQL({ includeOffset: false });
        case TransformDateFormat.EPOCH:
          return value.toSeconds();
      }
    } else {
      return value;
    }
  },
    { toPlainOnly: true }
  );

  const toClass = Transform(({ value }) => {
    if (typeof value === 'string') {
      switch (format) {
        case TransformDateFormat.ISO:
          return DateTime.fromISO(value, { zone: 'utc' });
        case TransformDateFormat.SQL_DATE:
          return DateTime.fromSQL(value, { zone: 'utc' });
        case TransformDateFormat.SQL:
          return DateTime.fromSQL(value, { zone: 'utc' });
      }
    } else if (typeof value === 'number') {
      return DateTime.fromSeconds(value, { zone: 'utc' });
    } else {
      return value;
    }
  },
    { toClassOnly: true }
  );

  return (target, key): void => {
    toPlain(target, key);
    toClass(target, key);
  };
}
