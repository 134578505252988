import { Action, createReducer, on } from '@ngrx/store';
import { baseServicePageReducerEvents } from '@shared/base-service-page/store';
import { Order } from '@shared/order';
import { pickBy } from 'lodash';
import { setValue, updateGroup, wrapReducerWithFormStateUpdate } from 'ngrx-forms';
import { CreateOrderForm } from '../forms';
import { OrderCreationStage } from '../models';
import { PrivateServicesServicePageActions } from './actions';
import { PrivateServicesServicePageState } from './state';

const initialState = new PrivateServicesServicePageState();

const reducer = wrapReducerWithFormStateUpdate(
  createReducer(
    initialState,
    on(PrivateServicesServicePageActions.resetState, () => initialState),
    on(PrivateServicesServicePageActions.init, (state, { code }) => ({
      ...initialState,
      code: code ?? state.code,
      services: state.services
    })),
    on(PrivateServicesServicePageActions.loadZip, (state) => ({
      ...initialState,
      code: state.code,
      isLoading: true
    })),
    on(PrivateServicesServicePageActions.loadZipSuccess, (state, { zip }) => ({
      ...state,
      services: zip.services,
      isLoading: false
    })),
    on(PrivateServicesServicePageActions.loadZipFailure, (state) => ({
      ...state,
      isLoading: false
    })),
    on(PrivateServicesServicePageActions.setService, (state, { serviceCode }) => ({
      ...initialState,
      code: serviceCode,
      stage: state.stage,
      summary: state.summary,
      services: state.services,
      laundryService: state.services?.find((service) => service.serviceCode === serviceCode)
    })),
    on(PrivateServicesServicePageActions.prefillOrder, (state, { laundryService }) => ({
      ...state,
      isPrefillingOrder: true,
      laundryService
    })),
    on(PrivateServicesServicePageActions.prefillOrderSuccess, (state, { order }) => ({
      ...state,
      isPrefillingOrder: false,
      order,
      formState: CreateOrderForm.prefillForm(state.formState, order)
    })),
    on(PrivateServicesServicePageActions.prefillOrderFailure, (state) => ({
      ...state,
      isPrefillingOrder: false
    })),
    on(PrivateServicesServicePageActions.setPromocode, (state, { promocode }) => ({
      ...state,
      order: new Order({
        ...state.order,
        promocode
      }),
      formState: updateGroup<CreateOrderForm>(state.formState, {
        promocodeID: setValue<number>(promocode?.id || undefined)
      })
    })),
    on(PrivateServicesServicePageActions.setAddress, (state, { address }) => ({
      ...state,
      order: new Order({
        ...state.order,
        pickupAddress: address,
        deliveryAddress: address
      }),
      formState: updateGroup<CreateOrderForm>(state.formState, {
        pickupAddressID: setValue<number>(address?.id || undefined),
        deliveryAddressID: setValue<number>(address?.id || undefined)
      })
    })),
    on(PrivateServicesServicePageActions.startOrderConfirmation, (state) => ({
      ...state,
      isConfirming: true
    })),
    on(PrivateServicesServicePageActions.confirmOrderSuccess, (state, { order }) => ({
      ...state,
      stage: new OrderCreationStage({ name: 'placed' }),
      order: new Order({ ...state.order, ...pickBy(order) }),
      isConfirming: false
    })),
    on(PrivateServicesServicePageActions.confirmOrderFailure, (state) => ({
      ...state,
      isConfirming: false
    })),
    on(PrivateServicesServicePageActions.openNextStage, (state) => ({
      ...state,
      stage: state.stage.nextStage({
        isSaveOptions: state.formState.value.isSaveOptions
      })
    })),
    on(PrivateServicesServicePageActions.openPreviousStage, (state) => ({
      ...state,
      stage: state.stage.previousStage({
        isSaveOptions: state.formState.value.isSaveOptions
      })
    })),
    on(PrivateServicesServicePageActions.changeInformationShowedMode, (state, {value}) => ({
      ...state,
      isInformationShowed: value
    })),
    ...baseServicePageReducerEvents<PrivateServicesServicePageState>('privateServicesServicePage')
  ),
  (state: PrivateServicesServicePageState) => state.formState,
  (formState, state) => CreateOrderForm.validate(formState, { order: state.order })
);

export const privateServicesServicePageReducer = (
  state: PrivateServicesServicePageState | undefined,
  action: Action
): PrivateServicesServicePageState => reducer(state, action);
