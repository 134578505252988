import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconBackgroundVariant } from '@shared/icon';

@Component({
  selector: 'icon-background',
  templateUrl: 'background.html',
  styleUrls: ['background.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconBackgroundComponent {
  @Input() variant: IconBackgroundVariant;

  constructor() {
    this.variant = 'default';
  }
}
