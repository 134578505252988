import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GeolocationActions, GeolocationSelectors } from '@shared/geolocation/store';
import { Place } from '@shared/google-places';
import type { AppState } from '@shared/store';
import { Observable } from 'rxjs';

@Injectable()
export class GeolocationButtonFacade {
  public get isGeolocating$(): Observable<boolean> {
    return this.store.select(GeolocationSelectors.isGeolocating);
  }

  constructor(
    private store: Store<AppState>
  ) { }

  public geolocate(onSuccess: (place: Place) => void): void {
    this.store.dispatch(GeolocationActions.geolocate({ onSuccess }));
  }
}
