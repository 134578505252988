import { Exclude, Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { TransformDate } from '@shared/transform-date/transform-date';
import { Address } from '@shared/address';
import { TransformDateFormat } from '@shared/transform-date';
import { Media } from '@shared/media';
import { BaseEntity } from '@shared/base-entity';
import { PaymentMethod } from '@shared/payment-method';
import { Zip } from '@shared/zip';
import { NotificationSettings } from './notification-settings';
import { Gender } from '../enums';
import { DropOffType } from '@shared/order';
import {UserType} from '@shared/user-type-modal';

export class User extends BaseEntity {
  @Exclude()
  public firstName: string;

  @Exclude()
  public lastName: string;

  @Expose()
  public email: string;

  @Expose()
  public phone: string;

  @Expose({ name: 'phone_code' })
  public phoneCode: string;

  @Expose()
  public password: string;

  @Expose()
  public account_type: UserType;

  @Expose({ name: 'old_password' })
  public oldPassword: string;

  @Expose({ name: 'active_address' })
  @Type(() => Address)
  public activeAddress: Address;

  @Expose()
  @Type(() => Address)
  public addresses: Array<Address>;

  @Expose({ name: 'notification_settings' })
  @Type(() => NotificationSettings)
  public notificationSettings: NotificationSettings;

  @Expose()
  @TransformDate(TransformDateFormat.SQL_DATE)
  public birthdate: DateTime;

  @Expose()
  public gender: Gender | null;

  @Expose({ name: 'gender_other' })
  public genderOther: string | null;

  @Expose({ name: 'photo_id' })
  public photoID: number;

  @Expose()
  @Type(() => Media)
  public photo: Media;

  // TODO: Probably will be moved to Address entity
  @Expose({ name: 'drop_off_type' })
  public dropOffType: DropOffType;

  @Expose({ name: 'payment_methods' })
  @Type(() => PaymentMethod)
  public paymentMethods: Array<PaymentMethod>;

  @Exclude()
  public activePaymentMethod: PaymentMethod | null;

  @Expose()
  public get name(): string {
    if (!this.firstName || !this.lastName) {
      return undefined;
    }

    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }

  public set name(name: string) {
    const nameParts = name?.split(' ') || [];
    this.firstName = nameParts[0] || '';
    this.lastName = nameParts[1] || '';
  }

  public get nameWithInitials(): string {
    return `${this.firstName} ${this.lastName.charAt(0)}.`;
  }

  public get zip(): Zip {
    return this.activeAddress?.zip;
  }

  public get avatarLink(): string {
    return this.photo?.link || null;
  }

  public get fullPhone(): string | null {
    if (!this.phoneCode || !this.phone) {
      return null;
    }

    return `+${this.phoneCode}${this.phone}`;
  }

  constructor(model: Partial<User>) {
    super(model);
    Object.assign(this, model);
  }

  public getPaymentMethod(id: number): PaymentMethod | null {
    return this.paymentMethods?.find((method) => method.id === id) || null;
  }

  public getAddress(id: number): Address | null {
    return this.addresses?.find((address) => address.id === id) || null;
  }

  public getAddressByZip(code: string): Address | null {
    return this.addresses?.find((address) => address.zipCode === code) || null;
  }
}
