<div #cookieBanner *ngIf="isVisible$ | async" [class.cookie-flip-in]="isBrowser" class="cookie">
  <ng-container *ngVar="'SHARED.COOKIE.' as translations">
    <div class="cookie-close">
      <button (click)="closeButtonClicked(cookieBanner)" class="button-flat">
        <icon type="multiply" color="grays-silver"></icon>
      </button>
    </div>
    <p>
      {{ translations + 'TEXT_WE_USE' | translate }}
      <strong>{{ translations + 'TEXT_COOKIES' | translate }}</strong>
      {{ translations + 'TEXT_FOR_IMPROVING' | translate }}
    </p>
    <app-button variant="purple" size="smaller" [isBlock]="true" (clicked)="acceptButtonClicked(cookieBanner)">
      {{ translations + 'BUTTON_ACCEPT' | translate }}
    </app-button>
  </ng-container>
</div>
