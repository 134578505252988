import { NgModule } from '@angular/core';
import { AvailabilityModule } from '@shared/availability';
import { MobileControlsComponent } from '@shared/mobile-controls';
import { NgVariableDirective } from '@shared/ng-variable';
import { OrderModule } from '@shared/order';
import { OrderMainStageModule } from '@shared/order-main-stage';
import { OrderSummaryModule } from '@shared/order-summary';
import { PageModule } from '@shared/page';
import { PageBackComponent } from '@shared/page-back';
import { PageSideComponent } from '@shared/page-side';
import { ServiceTabsComponent } from '@shared/service-tabs';
import { ServiceViewModule } from '@shared/service-view';
import { NgrxFormsModule } from 'ngrx-forms';

@NgModule({
  imports: [
    NgVariableDirective,
    ServiceTabsComponent,
    MobileControlsComponent,
    PageBackComponent,
    PageSideComponent
  ],
  exports: [
    PageModule,
    NgrxFormsModule,
    OrderMainStageModule,
    ServiceViewModule,
    AvailabilityModule,
    OrderModule,
    OrderSummaryModule,
    MobileControlsComponent,
    ServiceTabsComponent,
    PageBackComponent,
    PageSideComponent
  ]
})
export class BaseServicePageModule { }
