import { Expose } from 'class-transformer';
import { ConnectionType } from '../enums';

export class CheckSocialTokenRequest {
  @Expose()
  public token: string;

  @Expose()
  public type: ConnectionType;

  constructor(model: CheckSocialTokenRequest) {
    Object.assign(this, model);
  }
}
