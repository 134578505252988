import { Action, createReducer, on } from '@ngrx/store';
import { BaseServicePageForm } from '@shared/base-service-page';
import { baseServicePageReducerEvents } from '@shared/base-service-page/store';
import { Location } from '@shared/location';
import { Pagination } from '@shared/pagination';
import { setValue, updateGroup, wrapReducerWithFormStateUpdate } from 'ngrx-forms';
import { PublicServicesServiceLocationsState } from '../models';
import { PublicServicesServicePageActions } from './actions';
import { PublicServicesServicePageState } from './state';

const initialState = new PublicServicesServicePageState();

const orderInitialState: Partial<PublicServicesServicePageState> = {
  order: initialState.order,
  formState: initialState.formState,
  summary: initialState.summary,
  isCalculating: initialState.isCalculating
};

const reducer = wrapReducerWithFormStateUpdate(
  createReducer(
    initialState,
    on(PublicServicesServicePageActions.resetState, () => initialState),
    on(PublicServicesServicePageActions.setPageParams, (state, { serviceCode, locationCode }) => ({
      ...state,
      ...orderInitialState,
      serviceCode,
      locationCode,
      settings: null
    })),
    on(PublicServicesServicePageActions.loadServices, (state) => ({
      ...state,
      areServicesLoading: true
    })),
    on(PublicServicesServicePageActions.loadServicesSuccess, (state, { services }) => ({
      ...state,
      areServicesLoading: false,
      services
    })),
    on(PublicServicesServicePageActions.loadServicesFailure, (state) => ({
      ...state,
      areServicesLoading: false
    })),
    on(PublicServicesServicePageActions.setSettings, (state, { settings }) => ({
      ...state,
      settings,
      locationsState: (!settings?.location)
        ? initialState.locationsState
        : (state.locationsState.isParentChanged(settings.location))
          ? new PublicServicesServiceLocationsState({
            parent: settings.location || PublicServicesServiceLocationsState.countryLocation
          })
          : state.locationsState
    })),
    on(PublicServicesServicePageActions.handleSuccessfulAuth, (state) => ({
      ...state,
      isHandlingSuccessLogin: true
    })),
    on(PublicServicesServicePageActions.handleSuccessfulAuthSuccess,
      PublicServicesServicePageActions.handleSuccessfulAuthFailure,
      (state) => ({
        ...state,
        isHandlingSuccessfulAuth: false
      })
    ),
    on(PublicServicesServicePageActions.loadLocations, (state) => ({
      ...state,
      locationsState: new PublicServicesServiceLocationsState({
        ...state.locationsState,
        isLoading: true
      })
    })),
    on(PublicServicesServicePageActions.loadLocationsSuccess, (state, { response: { data, ...pagination } }) => ({
      ...state,
      locationsState: new PublicServicesServiceLocationsState({
        ...state.locationsState,
        isLoading: false,
        pagination: new Pagination(pagination),
        parent: new Location({
          ...state.locationsState.parent,
          children: (pagination.currentPage > 1) ? [...state.locationsState.parent.children, ...data] : data
        })
      })
    })),
    on(PublicServicesServicePageActions.loadLocationsFailure, (state) => ({
      ...state,
      locationsState: new PublicServicesServiceLocationsState({
        ...state.locationsState,
        isLoading: false
      })
    })),

    on(PublicServicesServicePageActions.loadInitialDates, (state) => ({
      ...state,
      isLoadingInitialDates: true
    })),
    on(PublicServicesServicePageActions.loadInitialDatesSuccess, (state, { pickupInterval, deliveryInterval }) => ({
      ...state,
      isLoadingInitialDates: false,
      formState: updateGroup<BaseServicePageForm>(state.formState, {
        schedulling: updateGroup({
          pickupTime: setValue<string>(pickupInterval?.from.toISO() || ''),
          pickupTo: setValue<string>(pickupInterval?.to.toISO() || ''),
          deliveryTime: setValue<string>(deliveryInterval?.from.toISO() || ''),
          deliveryTo: setValue<string>(deliveryInterval?.to.toISO() || '')
        })
      })
    })),
    on(PublicServicesServicePageActions.loadInitialDatesFailure, (state) => ({
      ...state,
      isLoadingInitialDates: false
    })),
    ...baseServicePageReducerEvents<PublicServicesServicePageState>('publicServicesServicePage')
  ),
  (state) => state.formState,
  (formState, state) => {
    const laundryService = state.settings?.service?.isLaundryService && state.settings.service;

    return updateGroup<BaseServicePageForm>(formState, {
      pounds: setValue((formState.value.pounds < laundryService?.minimumOrderWeight) ? laundryService.minimumOrderWeight : formState.value.pounds)
    });
  }
);

export const publicServicesServicePageReducer = (
  state: PublicServicesServicePageState | undefined,
  action: Action
): PublicServicesServicePageState => reducer(state, action);
