import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import type { AppState } from '@shared/store';
import { UserZipSelectors } from '@shared/user';
import { Zip } from '@shared/zip';
import { Observable } from 'rxjs';
import {Place} from "@shared/google-places";
import {GeolocationActions} from "@shared/geolocation/store";

@Injectable()
export class LocationMismatchFacade {
  public get userZip$(): Observable<Zip> {
    return this.store.select(UserZipSelectors.zip);
  }

  constructor(
    private store: Store<AppState>
  ) { }

  public geolocate(onSuccess: (place: Place) => void): void {
    this.store.dispatch(GeolocationActions.geolocate({onSuccess}));
  }
}
