import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@shared/store';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { PublicRegisterFormActions } from './actions';
import { catchError, debounceTime, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PublicRegisterFormSelectors } from './selectors';
import { NotificationService } from '@shared/notification';
import {AuthCredentials, AuthService} from '@shared/auth';
import { ClearAsyncErrorAction, SetAsyncErrorAction, SetValueAction, StartAsyncValidationAction } from 'ngrx-forms';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { DateTime } from 'luxon';
import { ErrorHandlingService, ValidationErrorName } from '@shared/error-handling';
import { WebviewService } from '@shared/webview';
import { UserService } from '@shared/user';

@Injectable()
export class PublicRegisterFormEffects {

  public tryRegister$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PublicRegisterFormActions.tryRegister),
      withLatestFrom(
        this.store.select(PublicRegisterFormSelectors.formState),
        this.store.select(PublicRegisterFormSelectors.currentConnection)
      ),
      filter(([_, formState]) => formState.isValid && !formState.isValidationPending),
      exhaustMap(([{ onRegisterSuccess }, { value }, connection]) => this.authService
        .register({
          ...value,
          password: connection ? undefined : value.password,
          birthdate: DateTime.fromISO(value.birthdate),
          connection: connection || undefined,
          deviceInfo: this.webviewService.deviceInfo
        })
        .pipe(
          tap((response) => onRegisterSuccess(response)),
          map(() => PublicRegisterFormActions.registerSuccess()),
          catchError((response) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'PUBLIC.SHARED.REGISTER_FORM.TEXT_REGISTRATION_FAILED_ERROR'
            });

            return of(PublicRegisterFormActions.registerFailure({ response }));
          })
        )
      )
    )
  );

  public startAsyncEmailValidation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PublicRegisterFormActions.startAsyncEmailValidation,
        PublicRegisterFormActions.signInSocialSuccess
      ),
      withLatestFrom(this.store.select(PublicRegisterFormSelectors.formState)),
      filter(([_, formState]) => formState.controls.email.isValid),
      tap(([_, formState]) => this.store.dispatch(new StartAsyncValidationAction(formState.controls.email.id, ValidationErrorName.INVALID))),
      debounceTime(350),
      tap(() => this.store.dispatch(PublicRegisterFormActions.asyncEmailValidationStarted())),
      switchMap(([_, formState]) => this.authService
        .validate({ email: formState.value.email })
        .pipe(
          map(() => new ClearAsyncErrorAction(formState.controls.email.id, ValidationErrorName.INVALID)),
          catchError((response: HttpErrorResponse) => of(new SetAsyncErrorAction(formState.controls.email.id, ValidationErrorName.INVALID, response.error?.errors?.email[0])))
        )
      )
    )
  );

  public clearAsyncEmailError$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetValueAction.TYPE),
      filter((action: SetValueAction<string>) => action.controlId === 'RegisterForm.email'),
      withLatestFrom(this.store.select(PublicRegisterFormSelectors.formState)),
      tap(([_, formState]) => this.store.dispatch(new ClearAsyncErrorAction(formState.controls.email.id, ValidationErrorName.INVALID))),
      map(() => PublicRegisterFormActions.startAsyncEmailValidation())
    )
  );

  public startAsyncPhoneValidation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PublicRegisterFormActions.startAsyncPhoneValidation,
        PublicRegisterFormActions.signInSocialSuccess
      ),
      withLatestFrom(this.store.select(PublicRegisterFormSelectors.formState)),
      filter(([_, formState]) => formState.controls.phone.isValid),
      tap(([_, formState]) => this.store.dispatch(new StartAsyncValidationAction(formState.controls.phone.id, ValidationErrorName.INVALID))),
      debounceTime(350),
      tap(() => this.store.dispatch(PublicRegisterFormActions.asyncPhoneValidationStarted())),
      switchMap(([_, formState]) => this.authService
        .validate({ phone: `+${formState.value.phoneCode}${formState.value.phone}` })
        .pipe(
          map(() => new ClearAsyncErrorAction(formState.controls.phone.id, ValidationErrorName.INVALID)),
          catchError((response: HttpErrorResponse) => of(new SetAsyncErrorAction(formState.controls.phone.id, ValidationErrorName.INVALID, response.error?.errors?.phone[0])))
        )
      )
    )
  );

  public clearAsyncPhoneError$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetValueAction.TYPE),
      filter((action: SetValueAction<string>) => action.controlId.startsWith('RegisterForm.phone')),
      withLatestFrom(this.store.select(PublicRegisterFormSelectors.formState)),
      tap(([_, formState]) => this.store.dispatch(new ClearAsyncErrorAction(formState.controls.phone.id, ValidationErrorName.INVALID))),
      map(() => PublicRegisterFormActions.startAsyncPhoneValidation())
    )
  );

  public checkSocialToken$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PublicRegisterFormActions.checkSocialToken),
      exhaustMap(({ token, connectionType, onSuccess, onFailure }) => this.authService
        .checkSocialToken({ token, type: connectionType })
        .pipe(
          tap(() => {onFailure()}),
          map(() => PublicRegisterFormActions.checkSocialTokenComplete()),
          catchError((response: HttpErrorResponse) => {
            if (response.status === HttpStatusCode.NotFound) {
              onSuccess();
            } else {
              this.errorHandlingService.handleHttpError(response, {
                translateKey: 'PUBLIC.SHARED.REGISTER_FORM.TEXT_CHECK_SOCIAL_TOKEN_FAILURE'
              });
            }

            return of(PublicRegisterFormActions.checkSocialTokenComplete());
          })
        )
      )
    )
  );

  public tryLogin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PublicRegisterFormActions.tryLogin),
      exhaustMap(({connection, onLoginSuccess}) => {

        return this.authService
          .authorize<AuthCredentials>(
            new AuthCredentials({
              connection,
              deviceInfo: this.webviewService.deviceInfo,
              forgot: false,
            }),
            false
          )
          .pipe(
            tap((response) => this.userService.setProfile(null)),
            tap((response) => onLoginSuccess(response)),
            map(() => PublicRegisterFormActions.loginSuccess()),
            catchError((response) => (of(PublicRegisterFormActions.loginFailure({ response }))))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private authService: AuthService,
    private errorHandlingService: ErrorHandlingService,
    private webviewService: WebviewService,
    private userService: UserService
  ) {}
}
