import { NgModule } from '@angular/core';
import { FirebaseAuthService } from './firebase-auth.service';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { PERSISTENCE } from '@angular/fire/compat/auth';
import { configuration } from '@configurations';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';


@NgModule({
  declarations: [],
  imports: [
    provideFirebaseApp(() => initializeApp(configuration.firebaseConfig)),
    provideAuth(() => getAuth()),
  ],
  providers: [
    FirebaseAuthService,
    {
      provide: PERSISTENCE,
      useValue: false
      // useValue: inMemoryPersistence.type
    },
    { provide: FIREBASE_OPTIONS, useValue: configuration.firebaseConfig }

  ]
})
export class FirebaseAuthModule { }

