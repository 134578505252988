import { BaseEntity } from '@shared/base-entity';
import { Expose } from 'class-transformer';
import { SeoPageTagType } from '../enums';

export class SeoPageTag extends BaseEntity {
  @Expose()
  public name: string;

  @Expose()
  public content: string;

  @Expose({ name: 'seo_page_id' })
  public seoPageID: string;

  @Expose()
  public type: SeoPageTagType;

  constructor(model: Partial<SeoPageTag>) {
    super(model);
    Object.assign(this, model);
  }
}
