<div [class.menu-opened]="isMenuOpened$ | async" class="app">
  <div *ngIf="networkStatus$ | async">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="!(networkStatus$ | async)">
    <no-internet-page></no-internet-page>
  </div>
  <app-menu></app-menu>
  <ng-progress id="router-progressbar" color="#69BE3F" spinnerPosition="left"></ng-progress>
  <cookie></cookie>
  <span *ngIf="!isWebView" class="app-chat" (click)="intercomChantClicked()">
    <icon type="chat" color="purple"></icon>
  </span>
</div>
