import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Service } from '@shared/service';

@Pipe({
  standalone: true,
  name: 'serviceName'
})
export class ServiceNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  public transform(value: Service, options?: { isQuick?: boolean }): string {
    return `${options?.isQuick ? `${this.translateService.instant('SHARED.SERVICE_NAME.TEXT_QUICK')} ` : ''}${value.serviceName}`;
  }
}
