import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {BaseDialogDirective} from '@shared/base-dialog';
import {ButtonComponent} from '@shared/button';
import {DialogViewModule} from '@shared/dialog-view';
import {IconModule} from '@shared/icon';
import {UserTypeModalData} from './models';
import {NgForOf, NgIf} from '@angular/common';
import {SwitchOption} from '@shared/switch';
import {UserType} from '@shared/user-type-modal/enum';
import {NgVariableDirective} from '@shared/ng-variable';

@Component({
  standalone: true,
    imports: [
        TranslateModule,
        IconModule,
        ButtonComponent,
        DialogViewModule,
        NgForOf,
        NgIf,
        NgVariableDirective
    ],
  selector: 'user-type-modal',
  templateUrl: 'user-type-modal.html',
  styleUrls: ['user-type-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTypeModalComponent extends BaseDialogDirective<UserTypeModalData> {
  public userTypeOptions: Array<SwitchOption<UserType>>;
  public value: UserType | null;

  constructor() {
    super();
    this.value = null;
    this.userTypeOptions=this.userTypeOptionsGenerate()
  }
  public  userTypeOptionsGenerate(): Array<SwitchOption<UserType>> {
    return [
      new SwitchOption({ value: UserType.RESIDENTIAL, title: 'Residential' ,iconType:'residential'}),
      new SwitchOption({ value: UserType.COMMERCIAL, title: 'Commercial' ,iconType:'commercial'}),
      new SwitchOption({ value: UserType.TOURIST, title: 'Tourist', iconType:'tourist'}),
    ];
  }
  public confirmButtonClicked(): void {
    this.data.onConfirm?.(this.value);
    this.close();
  }

  public optionItemClicked(value: UserType): void {
    this.value = value;
  }
}
