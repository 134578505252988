import { Expose } from 'class-transformer';
import { PaymentSystem } from '../enums';

export class PaymentSystemsAvailability {
  @Expose()
  public applePay: boolean;

  @Expose()
  public googlePay: boolean;

  constructor(model?: Partial<PaymentSystemsAvailability>) {
    Object.assign(this, model);
  }

  public isPaymentSystemAvailable(system: PaymentSystem): boolean {
    if (system === PaymentSystem.APPLE_PAY) {
      return this.applePay;
    }
    if (system === PaymentSystem.GOOGLE_PAY) {
      return this.googlePay;
    }
  }

  public asArray(): Array<PaymentSystem> {
    const result: Array<PaymentSystem> = [];
    if (this.applePay) {
      result.push(PaymentSystem.APPLE_PAY);
    }
    if (this.googlePay) {
      result.push(PaymentSystem.GOOGLE_PAY);
    }

    return result;
  }
}
