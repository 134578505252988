import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from '@shared/user';
import { Observable, tap } from 'rxjs';
import { MenuFacade } from './menu.facade';
import { MenuLink } from './models';
import { Location } from '@shared/location';


@UntilDestroy()
@Component({
  selector: 'app-menu',
  templateUrl: 'menu.html',
  styleUrls: ['menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit {
  public isOpened$: Observable<boolean>;
  public user$: Observable<User>;
  public menuLinks$: Observable<Array<MenuLink>>;
  public city$: Observable<Location>;

  constructor(
    private facade: MenuFacade,
    private router: Router
  ) {
    this.isOpened$ = this.facade.isOpened$;
    this.user$ = this.facade.user$;
    this.menuLinks$ = this.facade.menuLinks$;
    this.city$ = this.facade.city$;
  }

  public ngOnInit(): void {
    this.router.events.pipe(
      untilDestroyed(this),
      tap((event) => (event instanceof NavigationStart) && this.facade.close())
    ).subscribe();
  }

  public backgroundClicked(): void {
    this.facade.close();
  }

  public closeClicked(): void {
    this.facade.close();
  }

  public logoutButtonClicked(): void {
    this.facade.logout();
  }
}
