import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import {Zip, ZipRelation} from '@shared/zip';

export class UserZipActions {
  public static trySetZip = createAction(
    '[UserZip] Try Set Zip',
    props<{ zip: Zip }>()
  );

  public static setZip = createAction(
    '[UserZip] Set Zip',
    props<{ zip: Zip; zipAddress?: string }>()
  );

  public static geocodeZip = createAction(
    '[UserZip] Geocode Zip'
  );

  public static geocodeZipSuccess = createAction(
    '[UserZip] Geocode Zip Success',
    props<{ address: string }>()
  );

  public static geocodeZipFailure = createAction(
    '[UserZip] Geocode Zip Failure'
  );

  public static checkZip = createAction(
    '[UserZip] Check Zip',
    props<{
      zipCode: string;
      relations?: Array<ZipRelation>;
      onSuccess: (zip: Zip) => void;
    }>()
  );

  public static checkZipSuccess = createAction(
    '[UserZip] Check Zip Success'
  );
  public static clearZip = createAction(
    '[UserZip] Clear Zip '
  );

  public static checkZipFailure = createAction(
    '[UserZip] Check Zip Failure',
    props<{ response: HttpErrorResponse }>()
  );
}
