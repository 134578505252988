import {AsyncPipe, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseDialogDirective } from '@shared/base-dialog';
import { ButtonComponent } from '@shared/button';
import { DialogViewModule } from '@shared/dialog-view';
import { IconModule } from '@shared/icon';
import { NgVariableDirective } from '@shared/ng-variable';
import { ConfirmationModalData } from './models';
import {CheckboxComponent} from '@shared/checkbox';
import {FormGroupComponent} from '@shared/form-group';
import {CheckboxFormControlComponent} from '@shared/checkbox-form-control';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormControlTextareaComponent} from '@shared/form-control-textarea';

@Component({
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    NgTemplateOutlet,
    TranslateModule,
    ButtonComponent,
    NgVariableDirective,
    IconModule,
    DialogViewModule,
    CheckboxComponent,
    NgForOf,
    FormGroupComponent,
    CheckboxFormControlComponent,
    FormsModule,
    ReactiveFormsModule,
    FormControlTextareaComponent
  ],
  selector: 'confirmation-modal',
  templateUrl: 'confirmation-modal.html',
  styleUrls: ['confirmation-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationModalComponent extends BaseDialogDirective<ConfirmationModalData> {
  public selectedReason: string = "I rather do it myself";
  public reasons: string[] = ['I rather do it myself', 'Found time is too long', 'Turnover time is too long', 'Duplicated order', 'Other'];
  public otherReasonText: string = "";

  constructor() {
    super();
  }
  public override close(): void {
    super.close();
    this.data.onCancel?.();
  }

  public confirmButtonClicked(): void {
    this.data.action(this.dialogRef.id, this.selectedReason === 'Other' ? this.otherReasonText : this.selectedReason);
  }


  public changeReasonHandler(reason: string): void {
    this.selectedReason = reason;
  }
}
