export class SeoPageHeadingTags {
  public title: string;
  public description: string;

  public get hasText(): boolean {
    return this.hasTitle || this.hasDescription;
  }

  public get hasTitle(): boolean {
    return !!this.title?.length;
  }

  public get hasDescription(): boolean {
    return !!this.description?.length;
  }

  constructor(model: Partial<SeoPageHeadingTags>) {
    Object.assign(this, model);
  }
}
