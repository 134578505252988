import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MapApiService } from './map-api.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [MapApiService]
})
export class MapApiModule { }

