import { IconColor, IconType } from '@shared/icon';

export class MenuLink {
  public href?: string;
  public action?: () => void;
  public text: string;
  public iconType: IconType;
  public iconColor?: IconColor;
  public addUrl?: string;
  public isCity?: boolean;

  constructor(model: MenuLink) {
    Object.assign(this, model);
  }
}
