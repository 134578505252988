import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { configuration } from '@configurations';
import { NgxStripeModule } from 'ngx-stripe';
import { PaymentService } from './payment.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxStripeModule.forRoot(configuration.stripeKey)
  ],
  providers: [PaymentService]
})
export class PaymentModule { }

