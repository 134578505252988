import { createAction } from '@ngrx/store';

export class RealtimeActions {
  public static init = createAction(
    '[Realtime] Init'
  );

  public static close = createAction(
    '[Realtime] Close'
  );
}
