import { NgModule } from '@angular/core';
import { CookieService as AppCookieService } from './cookie.service';
import { CookieModule as CommonCookieModule, CookieService } from '@ronas-it/angular-common';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';

@NgModule({
  imports: [
    CommonCookieModule.forRoot({
      defaultOptions: { path: '/', sameSite: 'lax', secure: true },
      requestToken: REQUEST,
      responseToken: RESPONSE
    })
  ],
  providers: [
    AppCookieService,
    { provide: CookieService, useExisting: AppCookieService }
  ]
})
export class CookieModule { }
