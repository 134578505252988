import { ServicePreferenceOption } from '@shared/service-preference';
import { Expose, Type } from 'class-transformer';

export class ClientPreferenceOption {
  @Expose()
  public id: number;

  @Expose({ name: 'client_id' })
  public clientID: number;

  @Expose({ name: 'service_preference_option_id' })
  public servicePreferenceOptionID: number;

  @Expose({ name: 'service_preference_option' })
  @Type(() => ServicePreferenceOption)
  public servicePreferenceOption: ServicePreferenceOption;

  constructor(model: Partial<ClientPreferenceOption>) {
    Object.assign(this, model);
  }
}
