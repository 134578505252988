import { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PublicRegisterFormState } from './state';
import { RegisterForm } from '../forms';
import { FormGroupState } from 'ngrx-forms';
import { Connection, ConnectionType } from '@shared/auth';
import { PhoneVerificationSelectors } from '@shared/phone-verification';

const selectFeature = (state: AppState): PublicRegisterFormState => state.publicRegisterForm;

export class PublicRegisterFormSelectors {
  public static isSubmitting: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    PhoneVerificationSelectors.isStarting,
    (state: PublicRegisterFormState, isVerificationStarting) => state.isSubmitting || isVerificationStarting
  );

  public static formState: MemoizedSelector<AppState, FormGroupState<RegisterForm>> = createSelector(
    selectFeature,
    (state: PublicRegisterFormState) => state.formState
  );

  public static currentConnection: MemoizedSelector<AppState, Connection> = createSelector(
    selectFeature,
    (state: PublicRegisterFormState) => state.currentConnection
  );

  public static isEmailValidating: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: PublicRegisterFormState) => state.isEmailValidating
  );

  public static isPhoneValidating: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: PublicRegisterFormState) => state.isPhoneValidating
  );

  public static checkingConnectionType: MemoizedSelector<AppState, ConnectionType> = createSelector(
    selectFeature,
    (state: PublicRegisterFormState) => state.checkingConnectionType
  );

  public static stage: MemoizedSelector<AppState, number> = createSelector(
    selectFeature,
    (state: PublicRegisterFormState) => state.stage
  );
}
