import { NgModule } from '@angular/core';
import { AvailabilityService } from './availability.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [AvailabilityService]
})
export class AvailabilityModule { }

