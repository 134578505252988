import type { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { SeoState } from './state';
import { SeoPage } from '@shared/seo-page';

const selectFeature = (state: AppState): SeoState => state.seo as SeoState;

export class SeoSelectors {
  public static isLoading: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: SeoState) => state.isLoading
  );

  public static settings: MemoizedSelector<AppState, SeoPage | null> = createSelector(
    selectFeature,
    (state: SeoState) => state.settings
  );
}
