import { createAction, props } from '@ngrx/store';
import { Order } from '@shared/order';
import {UserType} from '@shared/user-type-modal';

export class UserRootActions {
  public static resetState = createAction(
    '[UserRoot] Reset State'
  );

  public static refreshProfile = createAction(
    '[UserRoot] Refresh Profile'
  );

  public static saveActiveOrder = createAction(
    '[UserRoot] Save Active Order'
  );

  public static updateCurrentOrders = createAction(
    '[UserRoot] Update Current Order',
    props<{ order: Order; setActive?: boolean }>()
  );

  public static clearCurrentOrders = createAction(
    '[UserRoot] Clear Current Orders'
  );

  public static checkPromocodesAvailability = createAction(
    '[UserRoot] Check Promocodes Availability'
  );

  public static updatePromocodesAvailability = createAction(
    '[UserRoot] Update Promocodes Availability',
    props<{ areAvailable: boolean }>()
  );

  public static initClientType = createAction(
    '[UserRoot] Init Client Type'
  );
  public static changeClientType = createAction(
    '[PrivateProfilePage] Change Client Type',
    props<{ account_type: UserType }>()
  );
  public static changeClientTypeSuccess = createAction(
    '[PrivateProfilePage]  Change Client Type Success',
  );
}
