import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api';
import { PaginationResponse } from '@shared/pagination';
import { instanceToPlain, plainToInstance, plainToClassFromExist } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentMethod, PaymentMethodPaginationRequest } from './models';

@Injectable()
export class PaymentMethodService {
  constructor(private apiService: ApiService) { }

  public search(): Observable<PaginationResponse<PaymentMethod>> {
    const requestParams = new PaymentMethodPaginationRequest({
      all: true,
      withoutApplePay: true,
      withoutGooglePay: true
    });

    return this.apiService.get<PaginationResponse<PaymentMethod>>('/payment-methods', instanceToPlain(requestParams))
      .pipe(
        map((response) => plainToClassFromExist(new PaginationResponse<PaymentMethod>(PaymentMethod), response))
      );
  }

  public setActive(id: number): Observable<void> {
    return this.apiService.put(`/payment-methods/${id}/set-active`);
  }

  public create(request: Partial<PaymentMethod>): Observable<PaymentMethod> {
    const requestBody = new PaymentMethod(request);

    return this.apiService.post('/payment-methods', instanceToPlain(requestBody))
      .pipe(
        map((response) => plainToInstance(PaymentMethod, response))
      );
  }


  public editZipCode(id: number,request: Partial<PaymentMethod>): Observable<PaymentMethod> {
    const requestBody = new PaymentMethod(request);

    return this.apiService.post(`/payment-methods/${id}/update`, instanceToPlain(requestBody))
      .pipe(
        map((response) => plainToInstance(PaymentMethod, response))
      );
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete(`/payment-methods/${id}`);
  }
}
