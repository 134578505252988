import { BaseEntity } from '@shared/base-entity';
import { Media } from '@shared/media';
import { Expose, Type } from 'class-transformer';

export class Item extends BaseEntity {
  @Expose()
  public title: string;

  @Expose()
  public description: string;

  @Expose()
  public price: number;

  @Expose({ name: 'category_id' })
  public categoryID: number;

  @Expose({ name: 'media_id' })
  public mediaID: number;

  @Expose()
  @Type(() => Media)
  public media: Media;

  @Expose({ name: 'is_custom' })
  public is_custom: number;

  public get imageLink(): string {
    return this.media?.link;
  }

  constructor(model: Partial<Item>) {
    super(model);
    Object.assign(this, model);
  }
}
