import { BaseOrderCreationStage } from '@shared/base-service-page';
import { OrderCreationStageName } from '../types';

export class OrderCreationStage extends BaseOrderCreationStage<OrderCreationStageName> {
  public get isItemSelection(): boolean {
    return this.name === 'items';
  }

  public get isPreferences(): boolean {
    return this.name === 'preferences';
  }

  public get isCheckout(): boolean {
    return this.name === 'checkout';
  }

  public get isPlaced(): boolean {
    return this.name === 'placed';
  }

  public fromStageName(name: OrderCreationStageName): OrderCreationStage {
    return new OrderCreationStage({ name });
  }

  public nextStage({ isSaveOptions }: {
    isSaveOptions: boolean;
  }): OrderCreationStage {
    switch (this.name) {
      case 'items': return this.fromStageName((isSaveOptions) ? 'checkout' : 'preferences');
      default: return this.fromStageName('checkout');
    }
  }

  public previousStage({ isSaveOptions }: {
    isSaveOptions: boolean;
  }): OrderCreationStage {
    switch (this.name) {
      case 'checkout': return this.fromStageName((isSaveOptions) ? 'items' : 'preferences');
      default: return this.fromStageName('items');
    }
  }
}
declare global {
  interface Window {
    dataLayer: any[];
  }
}
