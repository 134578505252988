import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Address } from '@shared/address';

export class UserAddressActions {
  public static setAddresses = createAction(
    '[UserAddress] Set Addresses',
    props<{ addresses: Array<Address> }>()
  );

  public static tryUpsert = createAction(
    '[UserAddress] Try Upsert',
    props<{
      request: Partial<Address>;
      onSuccess?: (address: Address) => void;
      dialogID?: string
    }>()
  );
  public static tryInit = createAction(
    '[UserAddress] Try Init',
  );

  public static upsert = createAction(
    '[UserAddress] Upsert',
    props<{
      request: Partial<Address>;
      onSuccess?: (address: Address) => void;
      onFailure?: (response: HttpErrorResponse) => void;
      dialogID?: string
    }>()
  );

  public static upsertSuccess = createAction(
    '[UserAddress] Upsert Success',
    props<{ address: Address }>()
  );

  public static upsertFailure = createAction(
    '[UserAddress] Upsert Failure',
    props<{ response: HttpErrorResponse }>()
  );

  public static delete = createAction(
    '[UserAddress] Delete',
    props<{
      address: Address;
      onSuccess?: () => void;
    }>()
  );

  public static deleteSuccess = createAction(
    '[UserAddress] Delete Success'
  );

  public static deleteFailure = createAction(
    '[UserAddress] Delete Failure',
    props<{ response: HttpErrorResponse }>()
  );

  public static select = createAction(
    '[UserAddress] Select',
    props<{
      address: Address;
      onSuccess?: (address: Address) => void;
    }>()
  );

  public static selectSuccess = createAction(
    '[UserAddress] Select Success'
  );

  public static selectFailure = createAction(
    '[UserAddress] Select Failure',
    props<{ response: HttpErrorResponse }>()
  );
}
