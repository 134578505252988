import {Action, createReducer, on} from '@ngrx/store';
import {TopLocationsActions} from './actions';
import {TopLocationsState} from './state';

const initialState = new TopLocationsState();

const reducer = createReducer(
  initialState,
  on(TopLocationsActions.loadLocations, (state) => ({
    ...state,
  })),
  on(TopLocationsActions.loadLocationsSuccess, (state, { response: data  }) => ({
    ...state,
    cites: data
  })),
  on(TopLocationsActions.lessLocations, (state) => ({
    ...state,
  })),
  on(TopLocationsActions.lessLocationsSuccess, (state, {id}) => {

    const cites = state.cites.filter((city) => city.id < id)

    return({
      ...state,
      page: state.page - 1,
      cites: cites
    })
  }),
);

export const topLocationsReducer = (
  state: TopLocationsState | undefined,
  action: Action
): TopLocationsState => reducer(state, action);
