import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { AblyModule } from '@shared/ably';
import { RealtimeService } from './realtime.service';
import { RealtimeEffects } from './store/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AblyModule,
    EffectsModule.forFeature([RealtimeEffects])
  ],
  providers: [
    RealtimeService
  ]
})
export class RealtimeModule { }

