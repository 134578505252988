import { Pipe, PipeTransform } from '@angular/core';
import { ItemForm } from '@shared/item/forms';
import { ServiceItem } from '@shared/item/models';

@Pipe({
  standalone: true,
  name: 'itemsCount'
})
export class ItemsCountPipe implements PipeTransform {
  public transform(items: Array<ServiceItem | ItemForm>): number {
    return items?.reduce<number>((prev, curr) => prev + (curr?.count || 1), 0);
  }
}
