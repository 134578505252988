import { Injectable } from '@angular/core';
import { AppDialogService } from '@shared/dialog';
import { DeliveryPrice } from '@shared/service';
import { OrderSummaryDeliveryFeeInfoComponent, OrderSummaryServiceFeeInfoComponent } from './components';
import {ItemForm, ServiceItem} from "@shared/item";

@Injectable()
export class OrderSummaryFacade {
  constructor(private dialogService: AppDialogService) { }

  public openServiceFeeInfo(): void {
    this.dialogService.openComponent(OrderSummaryServiceFeeInfoComponent, {
      width: OrderSummaryServiceFeeInfoComponent.width
    });
  }

  public openDeliveryFeeInfo(deliveryPrices: Array<DeliveryPrice>): void {
    this.dialogService.openComponent<Array<DeliveryPrice>>(OrderSummaryDeliveryFeeInfoComponent, {
      width: OrderSummaryDeliveryFeeInfoComponent.width,
      data: deliveryPrices
    });
  }

  public addItemInArray(item: ServiceItem, items: Array<ServiceItem>): Array<ItemForm> {

    let convertItems=items.map(item=>{
      return ItemForm.fromEntity(item)
    })
    if (!convertItems?.find((itemForm) => itemForm.id === item.id)) {
      return [...(convertItems || []), ItemForm.fromEntity(item)];
    }

    return convertItems.map((itemForm) => itemForm.id === item.id
      ? ItemForm.fromEntity(item, itemForm.count + 1)
      : itemForm
    );

  }

  public removeItemFromArray(item: ServiceItem, items: Array<ServiceItem>): Array<ItemForm> {
    let convertItems=items.map(item=>{
      return ItemForm.fromEntity(item)
    })

    return convertItems
      ?.map((itemForm) => itemForm.id === item.id
        ? ItemForm.fromEntity(item, itemForm.count - 1)
        : itemForm
      )
      .filter((itemForm) => itemForm.count > 0) || [];
  }
}
