import { OrderDraft } from '@shared/order';
import {LocalStorageClassItem, LocalStorageDateItem, LocalStorageObjectItem, LocalStorageStringItem} from './models';
import { LocalStorageBooleanItem } from './models/boolean-item';


export class LocalStorageService {
  public static likedPosts: LocalStorageObjectItem<Array<number>> = new LocalStorageObjectItem('likedPosts');
  public static orderDraft: LocalStorageClassItem<OrderDraft> = new LocalStorageClassItem('orderDraft', OrderDraft);
  public static orderDraftExpiration: LocalStorageDateItem = new LocalStorageDateItem('orderDraftExpiration');
  public static arePublicPromocodesAvailable: LocalStorageBooleanItem = new LocalStorageBooleanItem('arePublicPromocodesAvailable');
  public static promocodesAvailabilityExpiration: LocalStorageDateItem = new LocalStorageDateItem('promocodesAvailabilityExpiration');
  public static promoCodeForActivateLoginMode: LocalStorageStringItem = new LocalStorageStringItem('promoCodeForActivateLoginMode');
}
