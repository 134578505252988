import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PromocodeService } from './promocode.service';
import {PromocodeGuard} from '@shared/promocode/guards';
import {PromocodeFieldAppliedModalComponent} from '@shared/promocode/components/applied-modal';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PromocodeFieldAppliedModalComponent
  ],
  providers: [PromocodeService,PromocodeGuard]
})
export class PromocodeModule { }

