import { BaseEntity } from '@shared/base-entity';
import { Laundry } from '@shared/laundry';
import { PaymentMethod } from '@shared/payment-method';
import { PriceType } from '@shared/price';
import { Service } from '@shared/service';
import { TransformDate } from '@shared/transform-date';
import { Exclude, Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { DropOffType } from '../enums';
import { Address } from '@shared/address';
import { OrderSummary } from './order-summary';
import { Promocode } from '@shared/promocode';
import { isUndefined } from 'lodash';
import { ServiceItem } from '@shared/item';
import { ServicePreferenceOption } from '@shared/service-preference';
import { PaymentSystem } from '@shared/payment';

export class BaseOrder extends BaseEntity {
  @Expose({ name: 'bags_quantity' })
  public bagsQuantity: number;

  @Expose({ name: 'pickup_address_id' })
  public pickupAddressID: number;

  @Expose({ name: 'pickup_address' })
  @Type(() => Address)
  public pickupAddress: Address;

  @Expose({ name: 'pickup_time' })
  @TransformDate()
  public pickupTime: DateTime;

  @Expose({ name: 'pickup_to' })
  @TransformDate()
  public pickupTo: DateTime;

  @Expose({ name: 'delivery_address_id' })
  public deliveryAddressID: number;

  @Expose({ name: 'delivery_address' })
  @Type(() => Address)
  public deliveryAddress: Address;

  @Expose({ name: 'delivery_time' })
  @TransformDate()
  public deliveryTime: DateTime;

  @Expose({ name: 'delivery_to' })
  @TransformDate()
  public deliveryTo: DateTime;

  @Expose({ name: 'subscription_id' })
  public subscriptionID: number;

  @Expose()
  @Type(() => ServiceItem)
  public items: Array<ServiceItem>;

  @Expose({ name: 'options', toClassOnly: true })
  @Type(() => ServicePreferenceOption)
  public options: Array<ServicePreferenceOption>;

  @Expose({ name: 'laundry_id' })
  public laundryID: number;

  @Expose()
  @Type(() => Laundry)
  public laundry: Laundry;

  @Expose({ name: 'service_id' })
  public serviceID: number;

  @Expose()
  @Type(() => Service)
  public service: Service;

  @Expose({ name: 'laundry_service_id' })
  public laundryServiceID: number;

  @Expose({ name: 'laundry_service' })
  @Type(() => Service)
  public laundryService: Service;

  @Expose()
  public pounds: number;

  @Expose()
  @Type(() => Promocode)
  public promocode: Promocode;

  @Expose()
  public tip: number;

  @Expose({ name: 'tip_type' })
  public tipType: PriceType;

  @Expose()
  @Type(() => OrderSummary)
  public details: OrderSummary;

  @Expose({ name: 'drop_off_type' })
  public dropOffType: DropOffType;

  @Expose({ name: 'payment_method_id' })
  public paymentMethodID: number;

  @Expose({ name: 'payment_method' })
  @Type(() => PaymentMethod)
  public paymentMethod: PaymentMethod;

  @Expose()
  public paymentSystem: PaymentSystem;

  @Expose()
  public note: string;

  @Expose({ name: 'is_quick' })
  public isQuick: boolean;

  @Expose({ name: 'delivery_instructions' })
  public deliveryInstructions: string;

  @Expose({ name: 'is_save_options' })
  public isSaveOptions: boolean;

  @Expose({ name: 'promocode_id' })
  public get promocodeID(): number {
    return isUndefined(this._promocodeID) ? this.promocode?.id : this._promocodeID;
  };

  public set promocodeID(promocodeID: number) {
    this._promocodeID = promocodeID;
  }

  public get pricePerPound(): number {
    return this.details?.poundPrice;
  }

  public get itemsCount(): number {
    return this.items?.reduce<number>((prev, curr) => prev + curr.count, 0) || 0;
  }

  public get hasPreferences(): boolean {
    return !!this.options?.length;
  }

  public get hasItems(): boolean {
    return this.items?.length > 0;
  }

  public get hasPounds(): boolean {
    return this.pounds > 0;
  }

  public get serviceCode(): string {
    return this.service?.serviceCode || this.laundryService?.serviceCode;
  }

  @Exclude()
  private _promocodeID: number;

  constructor(model: Partial<BaseOrder>) {
    super(model);

    Object.assign(this, model);
  }
}
