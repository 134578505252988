import type { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { UserZipState } from './state';
import { Zip } from '@shared/zip';
import { Location, LocationType } from '@shared/location';

const selectFeature = (state: AppState): UserZipState => state.user.zipState;

export class UserZipSelectors {
  public static zip: MemoizedSelector<AppState, Zip> = createSelector(
    selectFeature,
    (state) => state.zip
  );

  public static city: MemoizedSelector<AppState, Location | null> = createSelector(
    UserZipSelectors.zip,
    (zip) => zip?.getLocationOfType(LocationType.CITY) || null
  );

  public static zipAddress: MemoizedSelector<AppState, string> = createSelector(
    selectFeature,
    (state) => state.zipAddress
  );

  public static isGeocoding: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => state.isGeocoding
  );

  public static isChecking: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => state.isChecking
  );
}
