import { TransformDate, TransformDateFormat } from '@shared/transform-date';
import { Expose } from 'class-transformer';
import { DateTime } from 'luxon';

export class AvailabilitiesRequest {
  @Expose()
  @TransformDate(TransformDateFormat.SQL)
  public from: DateTime;

  @Expose()
  @TransformDate(TransformDateFormat.SQL_DATE)
  public to: DateTime;

  @Expose({ name: 'service_id' })
  public serviceID: number;

  @Expose({ name: 'zip_id' })
  public zipID: number;

  constructor(model: Partial<AvailabilitiesRequest>) {
    Object.assign(this, model);
  }
}
