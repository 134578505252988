import { Directive, Input, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { BaseFormControlDirective } from '@shared/base-form-control';
import { Actions, createFormGroupState, FormControlValueTypes, FormGroupState, formStateReducer, markAsSubmitted, SetValueAction } from 'ngrx-forms';
import { FormControlDialogForm } from './forms';

@Directive()
export class BaseFormControlDialogDirective<StateType extends FormControlValueTypes>
  extends BaseFormControlDirective<StateType, StateType>
  implements OnInit {
  @Input() dialogRef: DialogRef;

  public formState: FormGroupState<FormControlDialogForm<StateType>>;

  constructor() {
    super();
    this.formState = createFormGroupState(FormControlDialogForm.name, new FormControlDialogForm());
  }

  public override ngOnInit(): void {
    this.handleNgrxFormsAction(
      new SetValueAction<FormControlDialogForm<StateType>>(
        this.formState.id,
        { field: this.value }
      )
    );
    super.ngOnInit();
  }

  public handleNgrxFormsAction(action: Actions<any>): void {
    this.formState = this.validateForm(formStateReducer(this.formState, action));
  }

  public submit(): void {
    if (this.formState.isValid) {
      const convertedValue = this.ngrxValueConverter.convertStateToViewValue(this.formState.value.field);
      if (this.value === convertedValue) {
        this.close();
      } else {
        this.value = convertedValue;
        this.handleValueChange();
      }
    } else if (this.formState.isUnsubmitted) {
      this.formState = markAsSubmitted(this.formState);
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  protected handleValueChange(): void { }

  protected validateForm(
    state: FormGroupState<FormControlDialogForm<StateType>>
  ): FormGroupState<FormControlDialogForm<StateType>> {
    return state;
  }
}
