<div [ngClass]="'form-counter-' + variant" class="form-counter">
  <button [disabled]="value <= min" (click)="minusButtonClicked()" class="form-counter-button" type="button">
    <icon type="minus-math" color="white"></icon>
  </button>
  <span class="form-counter-count">
    <ng-container *ngIf="valueTemplate; else defaultValueTemplate" [ngTemplateOutlet]="valueTemplate"
      [ngTemplateOutletContext]="{ value: value }">
    </ng-container>
    <ng-template #defaultValueTemplate>
      {{ value || 0 }}
    </ng-template>
  </span>
  <button (click)="plusButtonClicked()" class="form-counter-button" type="button">
    <icon type="plus-math" color="white"></icon>
  </button>
</div>
