import type { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { GeolocationState } from './state';

const selectFeature = (state: AppState): GeolocationState => state.geolocation;

export class GeolocationSelectors {
  public static isGeolocating: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: GeolocationState) => state.isGeolocating
  );
}
