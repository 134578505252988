import { BaseEntity } from '@shared/base-entity';
import { TransformDate, TransformDateFormat } from '@shared/transform-date';
import { Expose } from 'class-transformer';
import { DateTime } from 'luxon';
import { DiscountType, PromocodeStatus } from '../enums';

export class Promocode extends BaseEntity {
  @Expose()
  public code: string;

  @Expose()
  public discount: string;

  @Expose({ name: 'discount_type' })
  public discountType: DiscountType;

  @Expose({ name: 'usage_limit_per_client' })
  public usageLimit: number;

  @Expose({ name: 'times_used' })
  public timesUsed: number;

  @Expose({ name: 'times_used_by_client' })
  public timesUsedByClient: number;

  @Expose({ name: 'expires_at' })
  @TransformDate(TransformDateFormat.SQL)
  public expiresAt: DateTime;

  @Expose({ name: 'is_closed' })
  public isClosed: boolean;

  @Expose()
  public type: string;

  @Expose()
  public status?: PromocodeStatus;

  public get usageRemaining(): number {
    return this.usageLimit ? Math.max((this.usageLimit - this.timesUsedByClient), 0) : null;
  }

  public get isPast(): boolean {
    return this.status !== PromocodeStatus.AVAILABLE;
  }

  constructor(model: Partial<Promocode>) {
    super(model);
    Object.assign(this, model);
  }
}
