import { BaseServicePageState } from '@shared/base-service-page/store/state';
import { Service } from '@shared/service';
import { createFormGroupState } from 'ngrx-forms';
import { CreateOrderForm } from '../forms';
import { OrderCreationStage } from '../models';

export class PrivateServicesServicePageState extends BaseServicePageState<CreateOrderForm, OrderCreationStage> {
  public code: string;
  public laundryService: Service;
  public isLoading: boolean;
  public isConfirming: boolean;
  public isPrefillingOrder: boolean;
  public isInformationShowed: boolean;

  constructor() {
    super(
      createFormGroupState('PrivateServicePageForm', new CreateOrderForm())
    );

    this.stage = new OrderCreationStage({ name: 'items' });
    this.isLoading = false;
    this.isConfirming = false;
    this.isPrefillingOrder = false;
    this.isInformationShowed = false;
  }
}
