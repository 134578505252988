import { NamedEntity } from '@shared/base-entity';
import { Expose, Type } from 'class-transformer';
import { PreferencePriceType, PreferenceType } from '../enums';
import { PreferenceOption } from './option';

export class Preference extends NamedEntity {
  @Expose()
  public title: string;

  @Expose()
  public type: PreferenceType;

  @Expose({ name: 'price_type' })
  public priceType: PreferencePriceType;

  @Expose()
  @Type(() => PreferenceOption)
  public options: Array<PreferenceOption>;

  public override get viewName(): string {
    return this.title;
  }

  constructor(model: Partial<Preference>) {
    super(model);
    Object.assign(this, model);
  }

  public compareTo(target: Preference): number {
    if (this.type === target.type) {
      return 0;
    }

    return (this.type === PreferenceType.SELECT) ? 1 : -1;
  }
}
