export class Place {
  public formattedAddress: string;
  public zipCode: string;
  public state: string;
  public city: string;
  public latitude: number;
  public county: string;
  public longitude: number;
  public googlePlaceID: string;

  constructor(model: Partial<Place>) {
    Object.assign(this, model);
  }
}
