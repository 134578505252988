import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from 'lodash';
import { ServicePreferenceOptionForm } from '../forms';
import { ServicePreferenceOption } from '../models';

@Pipe({
  standalone: true,
  name: 'optionNames'
})
export class PreferencesOptionNamesPipe implements PipeTransform {
  public transform(options: Array<ServicePreferenceOption | ServicePreferenceOptionForm>): string {
    return capitalize(options?.map((option) => option.fullName).join(', ') || '');
  }
}
