import { Expose } from 'class-transformer';
import { ConnectionType } from '../enums';

export class Connection {
  @Expose()
  public type: ConnectionType;

  @Expose()
  public token: string;

  @Expose()
  public email?: string;

  constructor(model: Partial<Connection>) {
    Object.assign(this, model);
  }
}
