import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { AppState } from '@shared/store';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { filter, Observable, tap, withLatestFrom } from 'rxjs';
import { RealtimeActions } from './actions';
import { AblyService } from '@shared/ably';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '@shared/auth';

@Injectable()
export class RealtimeEffects {
  public init$: Observable<[Action, boolean]> = createEffect(() =>
    this.actions$.pipe(
      ofType(RealtimeActions.init),
      withLatestFrom(this.authService.isAuthenticated$),
      filter(([_, isAuthenticated]) => isAuthenticated && isPlatformBrowser(this.platformID)),
      tap(() => this.ablyService.init())
    ),
    { dispatch: false }
  );

  public close$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RealtimeActions.close),
      tap(() => this.ablyService.closeConnection())
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private ablyService: AblyService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformID: object
  ) { }
}
