import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@shared/store';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { UserRootActions } from './actions';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Order, OrderDraft } from '@shared/order';
import { User, UserRootSelectors, UserService } from '@shared/user';
import { LocalStorageService } from '@shared/local-storage';
import { Promocode, PromocodeService } from '@shared/promocode';
import { PaginationResponse } from '@shared/pagination';
import { DateTime } from 'luxon';
import { isPlatformBrowser } from '@angular/common';
import { AuthActions } from '@shared/auth/store';
import {AppDialogService} from "@shared/dialog";
import {UserTypeModalComponent, UserTypeModalData} from "@shared/user-type-modal";

@Injectable()
export class UserRootEffects {
  public resetState$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.unauthorized),
      map(() => UserRootActions.resetState())
    )
  );

  public refreshProfile$: Observable<User> = createEffect(
    () => this.actions$.pipe(
      ofType(UserRootActions.refreshProfile),
      switchMap(() => this.userService.fetchProfile())
    ),
    { dispatch: false }
  );

  public saveActiveOrder$: Observable<[Action, Order]> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRootActions.saveActiveOrder),
      withLatestFrom(
        this.store.select(UserRootSelectors.activeOrder)
      ),
      tap(([_, order]) => {
        if (!!order?.serviceID) {
          LocalStorageService.orderDraftExpiration.set(DateTime.now().plus({ hours: 1 }));
          LocalStorageService.orderDraft.set(new OrderDraft(order));
        }
      })
    ),
    { dispatch: false }
  );

  public clearCurrentOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRootActions.clearCurrentOrders),
      tap(() => {
        LocalStorageService.orderDraftExpiration.remove();
        LocalStorageService.orderDraft.remove();
      })
    ),
    { dispatch: false }
  );

  public checkPromocodesAvailability$: Observable<Action | PaginationResponse<Promocode>> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRootActions.checkPromocodesAvailability),
      filter(() => isPlatformBrowser(this.platformID)),
      filter(() => {
        const promocodesAvailabilityExpiration = LocalStorageService.promocodesAvailabilityExpiration.get();

        return !promocodesAvailabilityExpiration || promocodesAvailabilityExpiration.diffNow('seconds').seconds < 0;
      }),
      filter(() => {
        return localStorage.getItem('isPromoCodeUsed') === 'false'
      }),
      exhaustMap(() => this.promocodeService
        .search({ perPage: 1, page: 1 })
        .pipe(
          tap(({ total }) => {
            const areAvailable = total > 0;

            this.store.dispatch(UserRootActions.updatePromocodesAvailability({ areAvailable }));
            LocalStorageService.arePublicPromocodesAvailable.set(areAvailable);
            LocalStorageService.promocodesAvailabilityExpiration.set(DateTime.now().plus({ hours: 1 }));
          }),
          catchError(() => of())
        )
      )
    ),
    { dispatch: false }
  );

  public orderCancelationFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRootActions.initClientType),
      tap(() => {
        this.dialogService.openComponent(UserTypeModalComponent, {
          data: new UserTypeModalData({
            onConfirm: (data => {
              this.dialogService.closeAll();
              return this.store.dispatch(UserRootActions.changeClientType({  account_type: data }));

            })
          })
        })
      })
    ),
    {dispatch:false}
  );

  public tryClientTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRootActions.changeClientType),
      exhaustMap(({account_type}) => this.userService.update({account_type: account_type})
        .pipe(
          map((user) => UserRootActions.changeClientTypeSuccess( )),
        )
      )
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private promocodeService: PromocodeService,
    @Inject(PLATFORM_ID) private platformID: object,
    private userService: UserService,
    protected dialogService: AppDialogService,
  ) { }
}
