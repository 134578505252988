import { TransformDate, TransformDateFormat } from '@shared/transform-date';
import { Expose } from 'class-transformer';
import { DateTime } from 'luxon';

export class BaseEntity {
  @Expose()
  public id: number;

  @Expose({ name: 'created_at', toClassOnly: true })
  @TransformDate(TransformDateFormat.ISO)
  public createdAt: DateTime;

  @Expose({ name: 'updated_at', toClassOnly: true })
  @TransformDate(TransformDateFormat.ISO)
  public updatedAt: DateTime;

  @Expose({ name: 'deleted_at', toClassOnly: true })
  @TransformDate(TransformDateFormat.ISO)
  public deletedAt: DateTime;

  constructor(model: Partial<BaseEntity>) {
    Object.assign(this, model);
  }
}
