import type { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IntercomState } from './state';
import { User } from '@shared/user';

const selectFeature = (state: AppState): IntercomState => state.intercom;

export class IntercomSelectors {
  public static user: MemoizedSelector<AppState, User | null> = createSelector(
    selectFeature,
    (state: IntercomState) => state.user
  );
}
