import { ClassConstructor, Exclude, Expose, Type } from 'class-transformer';

export class PaginationResponse<T> {
  @Expose({ name: 'current_page' })
  public currentPage: number;

  @Expose({ name: 'per_page' })
  public perPage: number;

  @Expose()
  public total: number;

  @Expose({ name: 'last_page' })
  public lastPage: number;

  @Type((options) => (options.newObject as PaginationResponse<T>).type)
  @Expose()
  public data: Array<T>;

  @Exclude()
  private type: ClassConstructor<T>;

  constructor(type: ClassConstructor<T>) {
    this.type = type;
  }
}
