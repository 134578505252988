<dialog-view *ngVar="'SHARED.ORDER_SUMMARY.DELIVERY_FEE_INFO.' as translations" variant="small" class="delivery-fee">
  <dialog-header variant="underline" class="delivery-fee-header">
    <dialog-content size="smaller">
      {{ translations + 'TEXT_TITLE' | translate }}
      <dialog-close (clicked)="close()"></dialog-close>
    </dialog-content>
  </dialog-header>
  <dialog-body>
    <dialog-content size="smaller">
      <div class="delivery-fee-image">
        <img ngSrc="/assets/images/delivery-fee-info-image287x135.svg" width="287" height="135" alt="icon"/>
      </div>
      <div *ngIf="data" class="delivery-fee-prices">
        <div *ngFor="let interval of data; let index = index">
          <span class="delivery-fee-prices-bold">{{ interval.price?(interval.price| currency): ( translations + 'TEXT_FREE')| translate}}</span>
          <span *ngIf="index === 0">
            {{ translations + 'TEXT_LESS_THAN' | translate: { cost: interval.to | currency } }}
          </span>
          <span *ngIf="index > 0 && index < data.length - 1">
            {{ translations + 'TEXT_IN_RANGE' | translate: { from: interval.from | currency, to: interval.to | currency
            } }}
          </span>
          <span *ngIf="index === data.length - 1">
            {{ translations + 'TEXT_OVER' | translate: { cost: interval.from | currency } }}
          </span>
        </div>
      </div>
      <div *ngIf="!data" class="delivery-fee-prices">
        <div class="delivery-fee-none">{{( translations + 'TEXT_FREE') | translate}}</div>
      </div>
      <app-button variant="primary" textSize="big" [isBlock]="true" (clicked)="close()">
        {{ translations + 'BUTTON_OK' | translate }}
      </app-button>
    </dialog-content>
  </dialog-body>
</dialog-view>
