import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AvailabilityType } from './enums';
import { Availability, AvailabilitiesRequest } from './models';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {
  constructor(private apiService: ApiService) { }

  public search(type: AvailabilityType, request: Partial<AvailabilitiesRequest>): Observable<Array<Availability>> {
    const requestParams = new AvailabilitiesRequest({
      ...request,
      from: (type === AvailabilityType.PICKUP)
        ? request.from?.setZone('utc', { keepLocalTime: true }).startOf('day')
        : request.from
    });

    return this.apiService.get<Array<Availability>>(`/orders/availabilities/${type}`, instanceToPlain(requestParams))
      .pipe(
        map((response) => plainToInstance(Availability, response))
      );
  }
}
