import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AvailabilityInterval } from '@shared/availability';
import { BaseServicePageActions } from '@shared/base-service-page/store/actions';
import { Location } from '@shared/location';
import { PaginationResponse } from '@shared/pagination';
import { SeoPage } from '@shared/seo-page';
import { Service } from '@shared/service';
import { PublicServicesServicePageState } from './state';


class Actions extends BaseServicePageActions<PublicServicesServicePageState> {
  public init = createAction(
    `[${this.stateName}] Init`
  );

  public setPageParams = createAction(
    `[${this.stateName}] Set Page Params`,
    props<{ serviceCode: string; locationType?: string; locationCode?: string }>()
  );

  public loadServices = createAction(
    `[${this.stateName}] Load Services`
  );

  public loadServicesSuccess = createAction(
    `[${this.stateName}] Load Services Success`,
    props<{ services: Array<Service> }>()
  );

  public loadServicesFailure = createAction(
    `[${this.stateName}] Load Services Failure`,
    props<{ response: HttpErrorResponse }>()
  );

  public setSettings = createAction(
    `[${this.stateName}] Set Settings`,
    props<{ settings: SeoPage }>()
  );

  public handleSuccessfulAuth = createAction(
    `[${this.stateName}] Handle Successful Auth`
  );

  public handleSuccessfulAuthSuccess = createAction(
    `[${this.stateName}] Handle Successful Auth Success`
  );

  public handleSuccessfulAuthFailure = createAction(
    `[${this.stateName}] Handle Successful Auth Failure`,
    props<{ response: HttpErrorResponse }>()
  );

  public loadLocations = createAction(
    `[${this.stateName}] Load Locations`,
    props<{ page?: number }>()
  );

  public loadLocationsSuccess = createAction(
    `[${this.stateName}] Load Locations Success`,
    props<{ response: PaginationResponse<Location> }>()
  );

  public loadLocationsFailure = createAction(
    `[${this.stateName}] Load Locations Failure`,
    props<{ response: HttpErrorResponse }>()
  );

  public tryLoadInitialDates = createAction(
    `[${this.stateName}] Try Load Initial Dates`,
    props<{ laundryID: number; serviceID: number }>()
  );

  public loadInitialDates = createAction(
    `[${this.stateName}] Load Initial Dates`
  );

  public loadInitialDatesSuccess = createAction(
    `[${this.stateName}] Load Initial Dates Success`,
    props<{ pickupInterval: AvailabilityInterval; deliveryInterval: AvailabilityInterval }>()
  );

  public loadInitialDatesFailure = createAction(
    `[${this.stateName}] Load Initial Dates Failure`,
    props<{ response: HttpErrorResponse }>()
  );

  constructor() {
    super('publicServicesServicePage');
  }
}

export const PublicServicesServicePageActions = new Actions();
