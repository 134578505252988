import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { configuration } from '@configurations';
import { ApiService as CommonApiService } from '@ronas-it/angular-common';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService extends CommonApiService {
  constructor(
    private http: HttpClient
    ) {
    super(http, { apiUrl: configuration.api.url });
  }

  public getPDF(endpoint: string, params?: any, options?: object | undefined): Observable<Blob> {
    return this.get(endpoint, params, {
      ...options,
      responseType: 'blob',
      headers: new HttpHeaders({ Accept: 'application/pdf' })
    });
  }
}
