import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import type { AppState } from '@shared/store';
import { PhoneVerificationForm } from '@shared/phone-verification';
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { PhoneVerificationActions, PhoneVerificationSelectors } from '@shared/phone-verification/store';

@Injectable()
export class PhoneVerificationModalFacade {
  public get formState$(): Observable<FormGroupState<PhoneVerificationForm>> {
    return this.store.select(PhoneVerificationSelectors.formState);
  }

  public get isLoading$(): Observable<boolean> {
    return this.store.select(PhoneVerificationSelectors.isLoading);
  }

  constructor(
    private store: Store<AppState>
  ) { }

  public sendCode(): void {
    this.store.dispatch(PhoneVerificationActions.tryStartVerification({}));
  }

  public resetState(): void {
    this.store.dispatch(PhoneVerificationActions.resetState());
  }
}
