import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { configuration } from '@configurations';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { isPlatformBrowser } from '@angular/common';

@NgModule({
  imports: [
    GoogleTagManagerModule.forRoot({
      id:  configuration.environment === 'production'? configuration.googleTagManagerIDProd:  configuration.googleTagManagerIDStaging
    })
  ]
})
export class GoogleTagModule {
  constructor(
    private gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) private platformID: object,
  ) {
    if (isPlatformBrowser(this.platformID)) {
      if (configuration.environment === 'production' || configuration.environment === 'staging') {
        this.gtmService.addGtmToDom();
      }
    }
  }
}
