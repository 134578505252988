import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { AppState } from '@shared/store';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeolocationActions } from './actions';
import { exhaustMap, map } from 'rxjs/operators';
import { GeolocationService } from '../geolocation.service';

@Injectable()
export class GeolocationEffects {
  public geolocate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationActions.geolocate),
      exhaustMap(({ onFailure, onSuccess }) => this.geolocationService.geolocate()
        .pipe(
          map((place) => {
            if (place) {
              onSuccess(place);
            } else {
              onFailure?.();
            }

            return GeolocationActions.geolocateComplete();
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private geolocationService: GeolocationService
  ) { }
}
