import { SeoPage } from '@shared/seo-page';

export class SeoState {
  public isLoading: boolean;
  public settings: SeoPage | null;

  constructor() {
    this.isLoading = false;
    this.settings = null;
  }
}
