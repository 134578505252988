import { Expose } from 'class-transformer';

export class CheckCodeRequest {
  @Expose()
  public promocode: string;

  constructor(model: CheckCodeRequest) {
    Object.assign(this, model);
  }
}
