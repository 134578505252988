import { BaseEntity } from '@shared/base-entity';
import { Media } from '@shared/media';
import { Expose, Type } from 'class-transformer';

export class Employee extends BaseEntity {
  @Expose()
  public username: string;

  @Expose({ name: 'first_name' })
  public firstName: string;

  @Expose({ name: 'last_name' })
  public lastName: string;

  @Expose()
  public address: string;

  @Expose({ name: 'photo_id' })
  public photoID: number;

  @Type(() => Media)
  @Expose()
  public photo: Media;

  public get fullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  constructor(model: Partial<Employee>) {
    super(model);
    Object.assign(this, model);
  }
}
