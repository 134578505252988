import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaymentMethodService } from './payment-method.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [PaymentMethodService]
})
export class PaymentMethodModule { }

