import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollService } from '@shared/scroll';
import { asyncScheduler, throttleTime } from 'rxjs';

@UntilDestroy()
@Directive({
  standalone: true,
  selector: '[animateOnScroll]',
  providers: [
    ScrollService
  ]
})
export class AnimateOnScrollDirective implements OnInit, AfterViewInit {
  @Input() animationClass: string;
  @Input() offset: number;
  @Input() isOffsetInPercent: boolean;

  @Input()
  public set animationCheckTrigger(_: any) {
    this.checkVisibility();
  }

  private isApplied: boolean;

  constructor(
    private elementRef: ElementRef<Element>,
    private scrollService: ScrollService
  ) {
    this.animationClass = 'scrolled';
    this.offset = 0;
    this.isOffsetInPercent = false;
  }

  public ngOnInit(): void {
    this.scrollService.scrollEvents$
      .pipe(
        untilDestroyed(this),
        throttleTime(30, asyncScheduler, { leading: true, trailing: true })
      )
      .subscribe({ next: this.checkVisibility.bind(this) });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.checkVisibility());
  }

  private checkVisibility(): void {
    if (this.isApplied || !this.elementRef.nativeElement.clientHeight) {
      return;
    }
    const offset = (this.isOffsetInPercent) ? this.elementRef.nativeElement.clientHeight * this.offset / 100 : this.offset;
    if (this.scrollService.isElementInView(this.elementRef.nativeElement, offset)) {
      this.elementRef.nativeElement.classList.add(this.animationClass);
      this.isApplied = true;
    }
  }
}
