import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollService } from './scroll.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ScrollService]
})
export class ScrollModule { }

