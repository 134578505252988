import parsePhoneNumberFromString from 'libphonenumber-js';
import { PhoneValidationResult } from './models';

export function createPhoneValidator(phoneCode?: string): (phone: string) => PhoneValidationResult {
  return (phone) => {
    let validationResult: object = {};

    if (phone) {
      const fullPhone = parsePhoneNumberFromString(`${(phoneCode) ? `+${phoneCode}` : ''}${phone}`);

      if (!fullPhone || !fullPhone.isValid()) {
        validationResult = { phone: { actual: phone } };
      }
    }

    return validationResult;
  };
}
