import { Action, createReducer, on } from '@ngrx/store';
import { UserActions } from '../actions';
import { UserZipActions } from './actions';
import { UserZipState } from './state';

const initialState = new UserZipState();

const reducer = createReducer(
  initialState,
  on(UserActions.resetState, () => initialState),
  on(UserZipActions.setZip, (state, { zip, zipAddress }) => ({
    ...state,
    zip,
    zipAddress: zipAddress || ((zip?.code === state.zip?.code) ? state.zipAddress : zip?.code) || ''
  })),
  on(UserZipActions.geocodeZip, (state) => ({
    ...state,
    isGeocoding: true
  })),
  on(UserZipActions.geocodeZipSuccess, (state, { address }) => ({
    ...state,
    zipAddress: address,
    isGeocoding: false
  })),
  on(UserZipActions.geocodeZipFailure, (state) => ({
    ...state,
    isGeocoding: false
  })),
  on(UserZipActions.clearZip, (state) => ({
    ...state,
    zip: null,
    zipAddress: ""
  })),
  on(UserZipActions.checkZip, (state) => ({
    ...state,
    isChecking: true
  })),
  on(
    UserZipActions.checkZipSuccess,
    UserZipActions.checkZipFailure,
    (state) => ({
      ...state,
      isChecking: false
    })
  )
);

export const userZipReducer = (
  state: UserZipState | undefined,
  action: Action
): UserZipState => reducer(state, action);
