import { TransformDate, TransformDateFormat } from '@shared/transform-date';
import { Expose } from 'class-transformer';
import { DateTime } from 'luxon';

export class AvailabilityInterval {
  @Expose()
  @TransformDate(TransformDateFormat.EPOCH)
  public from: DateTime;

  @Expose()
  @TransformDate(TransformDateFormat.EPOCH)
  public to: DateTime;

  constructor(model: Partial<AvailabilityInterval>) {
    Object.assign(this, model);
  }
}
