import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/base-form-control';
import { CheckboxComponent, CheckboxVariant } from '@shared/checkbox';

@Component({
  standalone: true,
  imports: [
    CheckboxComponent
  ],
  selector: 'checkbox-form-control',
  templateUrl: 'checkbox-form-control.html',
  styleUrls: ['checkbox-form-control.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxFormControlComponent),
      multi: true
    }
  ]
})
export class CheckboxFormControlComponent extends BaseFormControlDirective<boolean> {
  @Input() variant: CheckboxVariant;
  @Input() isCheckded: null | boolean;

  constructor() {
    super();
    this.variant = 'default';
  }

  public checkboxChanged(isChecked: boolean): void {
    this.value = isChecked;
  }
}
