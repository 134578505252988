import { Pipe, PipeTransform } from '@angular/core';

const creditCardType = require('credit-card-type');

@Pipe({
  standalone: true,
  name: 'cardNumberMask'
})
export class CardNumberMaskPipe implements PipeTransform {
  public transform(cardNumber: string): string {
    const type = creditCardType(cardNumber)?.[0];
    if (!type?.gaps) {
      return this.fakeMask();
    }

    let mask = '';
    for (let i = 0; i < type.gaps.length; i++) {
      mask += `${'0'.repeat(type.gaps[i] - (type.gaps?.[i - 1] || 0))} `;
    }
    mask += '0'.repeat(Math.max(...type.lengths) - type.gaps[type.gaps.length - 1]);

    return mask;
  }

  fakeMask(){
    let mask2 = '';
    let  fakeGap=[4, 8, 12];
    for (let i = 0; i <fakeGap.length; i++) {
      mask2 += `${'0'.repeat(fakeGap[i] - (fakeGap[i - 1] || 0))} `;
    }
    mask2 += '0'.repeat(Math.max(19) - fakeGap[fakeGap.length - 1]);
    return mask2
  }
}
