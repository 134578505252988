import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api';
import { instanceToPlain } from 'class-transformer';
import { Observable } from 'rxjs';
import { CheckVerificationCodeRequest, PhoneVerificationRequest } from './models';

@Injectable()
export class PhoneVerificationService {
  constructor(private apiService: ApiService) { }

  public start(request: Partial<PhoneVerificationRequest>): Observable<void> {
    const requestBody = new PhoneVerificationRequest(request);

    return this.apiService.post('/phone-verify/start', instanceToPlain(requestBody, { excludeExtraneousValues: true }));
  }

  public check(request: Partial<CheckVerificationCodeRequest>): Observable<void> {
    const requestBody = new CheckVerificationCodeRequest(request);

    return this.apiService.post('/phone-verify/check', instanceToPlain(requestBody, { excludeExtraneousValues: true }));
  }
}
