import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseUIDirective } from '@shared/base-ui';
import { CheckboxVariant } from './types';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgClass
  ],
  selector: 'checkbox',
  templateUrl: 'checkbox.html',
  styleUrls: ['checkbox.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent extends BaseUIDirective {
  @Input() isChecked: boolean;
  @Input() isDisabled: boolean;
  @Input() variant: CheckboxVariant;
  @Input() type: 'checkbox' | 'radio' | 'switch';

  @Output() checkboxChanged: EventEmitter<boolean>;

  constructor() {
    super();

    this.checkboxChanged = new EventEmitter<boolean>();
    this.type = 'checkbox';
  }

  public itemCheckboxChanged(event: any): void {
    if (this.isDisabled) {
      return;
    }

    this.isChecked = event.target.checked;
    this.checkboxChanged.emit(this.isChecked);
  }
}
