import {Actions, createEffect, ofType} from '@ngrx/effects';
import type {AppState} from '@shared/store';
import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {SeoService} from '../seo.service';
import {catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom} from 'rxjs';
import {SeoActions} from './actions';
import {SeoPage, SeoPageHeadingTags, SeoPageService, SeoPageTag, SeoPageTagType, SeoPageType} from '@shared/seo-page';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {getSelectors} from '@ngrx/router-store';
import {ErrorHandlingService} from '@shared/error-handling';

@Injectable()
export class SeoEffects {
  public resetState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SeoActions.resetState),
      tap(() => this.resetSEO())
    ),
    { dispatch: false }
  );

  public loadSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SeoActions.tryLoadSettings),
      tap(() => this.resetSEO()),
      filter(({ request, defaultSettings }) => !!request?.url && (!!request?.type || !!defaultSettings)),
      tap(() => this.store.dispatch(SeoActions.loadSettings())),
      withLatestFrom(
        this.store.select(getSelectors().selectUrl)
      ),
      switchMap(([{ request, onSuccess, onFailure, defaultSettings }, initialURL]) =>
        ((!!request?.type) ? this.seoPageService.getByURL(request) : of(defaultSettings))
          .pipe(
            withLatestFrom(
              this.store.select(getSelectors().selectUrl)
            ),
            filter(([_, currentURL]) => initialURL === currentURL),
            tap(([settings]) => {
              if (settings?.deletedAt && !settings?.isDefault) {
                if(settings.type===SeoPageType.COMMON){
                  throw new HttpErrorResponse({ status: HttpStatusCode.BadRequest });

                }else{
                  throw new HttpErrorResponse({ status: HttpStatusCode.NotFound });

                }
              }
            }),
            tap(([settings]) => {
              if(settings?.deletedAt){
                defaultSettings= {...defaultSettings,codeHead:settings.codeHead+''+defaultSettings.codeHead,isSanDiego:false,isMiamiFlorida:false}
                if (!defaultSettings) {
                  this.seoService.initSettings(defaultSettings);
                }
                onSuccess?.(defaultSettings);
                if(settings.type===SeoPageType.COMMON){
                  throw new HttpErrorResponse({ status: HttpStatusCode.BadRequest });

                }else{
                  throw new HttpErrorResponse({ status: HttpStatusCode.NotFound });

                }
              }else {
                if(request.url==='/services/wash-and-fold-laundry-services/city/san-diego-ca' || request.url==='/services/wash-and-fold-laundry-services/city/miami-fl'){
                  settings.url=request.url;
                  settings=this.initSpecialSetting(settings)
                }
                onSuccess?.(settings);
                this.seoService.initSettings(settings);
              }
            }),
            map(([settings]) => SeoActions.loadSettingsSuccess({ settings })),
            catchError((response: HttpErrorResponse) => {
              if(response.status===HttpStatusCode.NotFound){
                this.errorHandlingService.handleHttpError(response);
              }
              onFailure?.(response);
              if (defaultSettings) {
                this.seoService.initSettings(defaultSettings);
              }
              return of(SeoActions.loadSettingsFailure({ response, settings: defaultSettings }));
            })
          )
      )
    )
  );
    public initSpecialSetting(settings:SeoPage): SeoPage {
      settings.codeHead = settings.isMiamiFlorida ? '<link rel="canonical" href="https://www.hamperapp.com/services/wash-and-fold-laundry-services/city/miami-fl">\n\n<script type="application/ld+json" id="head_2">\n' +
        '{\n' +
        '  "@context": "https://schema.org",\n' +
        '  "@type": "DryCleaningOrLaundry",\n' +
        '  "name": "Hamperapp Laundry Service in Miami, FL",\n' +
        '  "image": "https://storage.googleapis.com/hamperappv2-dev/0_11419800_1706181908_c650794652eb15a2ed7114a05c4581dd.png",\n' +
        '  "@id": "",\n' +
        '  "url": "https://www.hamperapp.com/services/wash-and-fold-laundry-services/city/miami-fl",\n' +
        '  "telephone": "(412) 927-4669",\n' +
        '  "priceRange": "$1.00 - $2.50",\n' +
        '  "description": "Hamperapp offers top-notch wash and fold laundry services in Miami, FL. Convenient, reliable, and affordable laundry solutions for both residential and commercial needs.",\n' +
        '  "servesCuisine": "Laundry",\n' +
        '  "areaServed": {\n' +
        '    "@type": "Place",\n' +
        '    "name": "Miami, FL"\n' +
        '  },\n' +
        '  "aggregateRating": {\n' +
        '    "@type": "AggregateRating",\n' +
        '    "ratingValue": "4.9",\n' +
        '    "bestRating": "5",\n' +
        '    "worstRating": "1",\n' +
        '    "ratingCount": "583006"\n' +
        '  },\n' +
        '  "openingHoursSpecification": {\n' +
        '    "@type": "OpeningHoursSpecification",\n' +
        '    "dayOfWeek": [\n' +
        '      "Monday",\n' +
        '      "Tuesday",\n' +
        '      "Wednesday",\n' +
        '      "Thursday",\n' +
        '      "Friday",\n' +
        '      "Saturday",\n' +
        '      "Sunday"\n' +
        '    ],\n' +
        '    "opens": "07:00",\n' +
        '    "closes": "22:00"\n' +
        '  },\n' +
        '  "sameAs": [\n' +
        '    "https://www.facebook.com/hamperapp",\n' +
        '    "https://twitter.com/hamperapp",\n' +
        '    "https://www.linkedin.com/company/hamperapp"\n' +
        '  ],\n' +
        '  "potentialAction": {\n' +
        '    "@type": "ReserveAction",\n' +
        '    "target": {\n' +
        '      "@type": "EntryPoint",\n' +
        '      "urlTemplate": "https://www.hamperapp.com/register",\n' +
        '      "inLanguage": "en-US",\n' +
        '      "actionPlatform": [\n' +
        '        "http://schema.org/DesktopWebPlatform",\n' +
        '        "http://schema.org/SmartphoneApplication",\n' +
        '        "http://schema.org/TabletWebPlatform"\n' +
        '      ]\n' +
        '    },\n' +
        '    "result": {\n' +
        '      "@type": "Reservation",\n' +
        '      "name": "Laundry Service Reservation"\n' +
        '    }\n' +
        '  }\n' +
        '}\n' +
        '</script>'
        : '<link rel="canonical" href="https://www.hamperapp.com/services/wash-and-fold-laundry-services/city/san-diego-ca"> \n\n<script type="application/ld+json" id="head_2">\n' +
        '{\n' +
        '  "@context": "https://schema.org",\n' +
        '  "@type": "DryCleaningOrLaundry",\n' +
        '  "name": "Hamperapp Laundry Service in San Diego, CA",\n' +
        '  "image": "https://storage.googleapis.com/hamperappv2-dev/0_11419800_1706181908_c650794652eb15a2ed7114a05c4581dd.png",\n' +
        '  "@id": "",\n' +
        '  "url": "https://www.hamperapp.com/services/wash-and-fold-laundry-services/city/san-diego-ca",\n' +
        '  "telephone": "(412) 927-4669",\n' +
        '  "priceRange": "$1.00 - $2.50",\n' +
        '  "description": "Hamperapp offers top-notch wash and fold laundry services in San Diego, CA. Convenient, reliable, and affordable laundry solutions for both residential and commercial needs.",\n' +
        '  "servesCuisine": "Laundry",\n' +
        '  "areaServed": {\n' +
        '    "@type": "Place",\n' +
        '    "name": "San Diego, CA"\n' +
        '  },\n' +
        '  "aggregateRating": {\n' +
        '    "@type": "AggregateRating",\n' +
        '    "ratingValue": "4.9",\n' +
        '    "bestRating": "5",\n' +
        '    "worstRating": "1",\n' +
        '    "ratingCount": "583006"\n' +
        '  },\n' +
        '  "openingHoursSpecification": {\n' +
        '    "@type": "OpeningHoursSpecification",\n' +
        '    "dayOfWeek": [\n' +
        '      "Monday",\n' +
        '      "Tuesday",\n' +
        '      "Wednesday",\n' +
        '      "Thursday",\n' +
        '      "Friday",\n' +
        '      "Saturday",\n' +
        '      "Sunday"\n' +
        '    ],\n' +
        '    "opens": "07:00",\n' +
        '    "closes": "22:00"\n' +
        '  },\n' +
        '  "sameAs": [\n' +
        '    "https://www.facebook.com/hamperapp",\n' +
        '    "https://twitter.com/hamperapp",\n' +
        '    "https://www.linkedin.com/company/hamperapp"\n' +
        '  ],\n' +
        '  "potentialAction": {\n' +
        '    "@type": "ReserveAction",\n' +
        '    "target": {\n' +
        '      "@type": "EntryPoint",\n' +
        '      "urlTemplate": "https://www.hamperapp.com/register",\n' +
        '      "inLanguage": "en-US",\n' +
        '      "actionPlatform": [\n' +
        '        "http://schema.org/DesktopWebPlatform",\n' +
        '        "http://schema.org/SmartphoneApplication",\n' +
        '        "http://schema.org/TabletWebPlatform"\n' +
        '      ]\n' +
        '    },\n' +
        '    "result": {\n' +
        '      "@type": "Reservation",\n' +
        '      "name": "Laundry Service Reservation"\n' +
        '    }\n' +
        '  }\n' +
        '}\n' +
        '</script>'
      settings.socialTags = settings.isMiamiFlorida ? [new SeoPageTag({id: 2123, name: 'og:description', type: SeoPageTagType.SEO, content: 'Hamperapp offers convenient laundry pick-up and delivery in Miami. Schedule online, and we\'ll wash, dry, and fold your clothes for you!'}), new SeoPageTag({id: 2123, name: 'og:title', type: SeoPageTagType.SEO, content: 'Laundry service in Miami, FL - Hamperapp'}), new SeoPageTag({id: 2123, name: 'og:image', type: SeoPageTagType.SEO, content: 'https://storage.googleapis.com/hamperappv2-dev/0_40005900_1713191767_11b84fe0954b36801c05dd6316c8b770.png'})] : [new SeoPageTag({id: 2257, name: 'og:description', type: SeoPageTagType.SEO, content: 'Hamperapp best laundry service in San Diego! We offer convenient pickup and delivery, wash and fold, and dry cleaning. Save time try us today!'}), new SeoPageTag({id: 2256, name: 'og:title', type: SeoPageTagType.SEO, content: 'Laundry service in San Diego CA - Hamperapp'}), new SeoPageTag({id: 2255, name: 'og:image', type: SeoPageTagType.SEO, content: 'https://storage.googleapis.com/hamperappv2-dev/0_40005900_1713191767_11b84fe0954b36801c05dd6316c8b770.png'})]
      settings.title = settings.isMiamiFlorida ? 'Laundry service in Miami, FL - Hamperapp' : 'Laundry service in San Diego CA - Hamperapp'
      settings.seoTags = settings.isMiamiFlorida ? [new SeoPageTag({id: 2122, name: 'description', type: SeoPageTagType.SEO, content: 'Hamperapp offers convenient laundry pick-up and delivery in Miami. Schedule online, and we\'ll wash, dry, and fold your clothes for you!'})] : [new SeoPageTag({id:2165, type:SeoPageTagType.SEO, name:'description', content:'Hamperapp best laundry service in San Diego! We offer convenient pickup and delivery, wash and fold, and dry cleaning. Save time try us today!'})]
      settings.tags.heading1 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'Hamperapp Laundry Service in Miami FL',description:'Discover Hamperapp, the best laundry pick-up and delivery service near you! Miami has so much to offer, from walking along Ocean Drive to exploring Wynwood Arts District or studying at the University of Miami. While you enjoy your day, let Hamperapp handle your laundry.\n \n' +
          'Our laundry pick-up service in Miami makes it easy to schedule a wash and fold with just a few taps. Enter your zip code to check our service area, and we’ll pick up your clothes, wash, dry, and fold them, and deliver them back to you fresh and clean within 24 hours. With our affordable laundry service cost, you can relax while we handle everything, including stain removal!\n'}) : new SeoPageHeadingTags({title:'Hamperapp Laundry service in San Diego CA',description:'Try Hamperapp, the best laundry service near you in San Diego, CA! We pick up and deliver your dry cleaning and laundry for free, offering same-day laundry service in San Diego. You can easily schedule a pick-up with just a few clicks. Plus, you can track your clothes from dirty to clean, whether you need us every day, once a week, or once a month.\n \n' +
          'We provide many cleaning options, including wash and fold services in San Diego. Your local eco-friendly laundromat manages all services.\n \n' +
          'Rest easy knowing your clothes are in good hands. Then, sit back and relax while we bring your clean clothes right to your doorstep. With Hamperapp, you gain more free time to enjoy your day.\n \n' +
          'Hamperapp is the most convenient and trustworthy commercial laundry service in San Diego, CA. Let us help keep your clothes clean while you focus on what you love!\n'})
      settings.tags.heading2 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'Miami Laundry Service',description:'In Miami, Hamperapp has the best laundry service with free pickup and delivery near you! No more worrying about crowded laundromats or doing laundry on your own. We make laundry simple by offering convenient laundry and dry cleaning services, including wash and fold in Miami.\n \n' +
          'Hamperapp uses liquid laundry detergent and adjusts the water temperature—whether hot, cold, or cool water—to treat stains and keep your machine washable clothes in top shape. We even handle tough stains with special care using products like hydrogen peroxide. Our laundry pickup service in Miami is fast and reliable, with your clothes washed, dried, and folded within 24 hours.\n \n' +
          'Whether your clothes need a gentle wash in cold water or a deeper clean with hot water, we make sure to use the right method to get them perfectly clean. So, skip the hassle and enjoy the best laundry service with free pickup and delivery—let Hamperapp take care of your laundry while you focus on more important things!\n'}) : new SeoPageHeadingTags({title:'San Diego Laundry service',description:'Looking for the best laundry pick-up service in San Diego, CA? Try Hamperapp! We are the top choice for getting your laundry and dry cleaning done without leaving your house. We offer the cheapest wash and fold San Diego prices, so you can save money in the long run.\n \n' +
          'We work with many laundry experts all over the city. They use eco-friendly products and hot water. They also use energy-saving washers and dryers to keep your clothes very clean.\n \n' +
          'Just tap a few buttons on your phone or computer. We will arrange for someone to pick up and deliver your laundry. While you\'re busy with other stuff, our reliable partners will wash, dry, and fold your clothes. You can even check where your order is in real-time and talk to our customer service any time, day or night.\n \n' +
          'Trust Hamperapp to bring back your clean laundry, fresh and neatly folded, right to your doorstep. We make laundry and dry cleaning easy and worry-free for the long term.\n'})
      settings.tags.heading3 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'Experience Clean and Convenient Laundry',description:'Experience hassle-free laundry with Hamperapp in Miami! Say goodbye to laundry day and enjoy the vibrant city instead. Whether you\'re exploring the Art Deco District, relaxing on South Beach, or savoring the flavors of Little Havana, Hamperapp has your laundry needs covered.\n \n' +
          'Our wash and fold service in Miami is the perfect solution for busy days. Simply schedule a pickup, and we’ll take care of the rest!\n \n' +
          'Hamperapp offers convenient laundry pick-up and delivery near you, so you can focus on what really matters. We wash your clothes with care, using cold water for delicate fabrics and handling "dry clean only" items through our professional dry cleaning service. Your clothes will be returned clean, fresh, and perfectly folded.\n \n' +
          'With Hamperapp’s reliable pickup and delivery service, you’ll get your laundry back in no time. Whether it\'s wash and fold or dry cleaning, we ensure your clothes are spotless and ready to wear. Don’t let laundry slow you down—let Hamperapp keep your clothes clean while you enjoy everything Miami has to offer!\n'}) : new SeoPageHeadingTags({title:'Wash and fold laundry service in San Diego',description:'Looking for the best laundry service San Diego has to offer? Our high-quality dry cleaner is here to help on your laundry day! We provide the best wash and fold San Diego service, using environmentally friendly and friendly cleaning products. If you like doing laundry yourself, try our self-service wash and fold San Diego option.\n \n' +
          'We are offering free pick-up and delivery within one business day, so you can have clean clothes without any hassle. At our laundry San Diego location, we believe in making your life easier with high-quality service. Come see why we\'re the best laundry service San Diego residents trust!\n'})

      settings.tags.heading4 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'How to find a coin laundry close to me now?',description:'Instead of searching for a coin laundry, try Hamperapp wash and fold service for convenient pickup and delivery. We wash, dry, and fold your clothes so you can enjoy life without the hassle.'}) : new SeoPageHeadingTags({title:'How can I find a coin laundry near me right now?',description:'Instead of looking for a coin laundry in San Diego, use Hamperapp\'s wash and fold service. They offer easy pickup and delivery. We wash, dry, and fold your clothes so you can enjoy life without the hassle.'})
      settings.tags.heading5 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'What does a laundry service do?',description:'A laundry service like Hamperapp washes, dries, and folds your garments with professional care. With our delivery laundry service, you get your laundry cleaned without leaving your home.'}) : new SeoPageHeadingTags({title:'What does a laundry service do?',description:'A laundry service like Hamperapp in San Diego washes, dries, and folds your garments with professional care. With our delivery service, you get your laundry cleaned without leaving your home.'})
      settings.tags.heading6 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'How do laundry services tag customer clothes?',description:'Hamperapp laundry service tags each customer\'s clothes with unique identifiers to ensure proper tracking and return. You\'ll receive updates via email or text message depending on your preference.'}) : new SeoPageHeadingTags({title:'How do laundry services tag customer clothes?',description:'Hamperapp tags each customer\'s clothes with unique identifiers to ensure proper tracking and return. You\'ll receive updates via email or text message, depending on your preference.\n'})
      settings.tags.heading7 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'How to do laundry while traveling?',description:'Use Hamperapp laundry service to take care of your laundry while you travel. We provide pickup and delivery in Miami and nearby areas. Simply schedule a pickup, and we\'ll wash, dry, and fold your clothes so you can enjoy life.'}) : new SeoPageHeadingTags({title:'How can I do laundry while traveling?',description:'Use Hamperapp\'s laundry service in San Diego to take care of your laundry while you travel. Simply schedule a pickup, and we\'ll wash, dry, and fold your clothes so you can focus on enjoying your trip.\n'})
      settings.tags.heading8 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'How to find the best laundry services around you?',description:'Try Hamperapp laundry service for reliable and professional laundry cleaning. We offer delivery laundry service, so you can enjoy life without worrying about laundry chores.'}) : new SeoPageHeadingTags({title:'How do I find the best laundry services around me?',description:'Try Hamperapp\'s laundry service in San Diego for reliable and professional laundry cleaning. We offer pickup and delivery, so you can enjoy life without worrying about laundry chores.\n'})
      settings.tags.heading9 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'Where can I find a professional laundry service?',description:'Look no further than Hamperapp laundry service for professional care of your garments. We provide washing, drying, and folding with convenient pickup and delivery.'}) : new SeoPageHeadingTags({title:'Where can I find a professional laundry service?',description:'Look no further than Hamperapp in San Diego for professional care of your garments. We provide washing, drying, and folding with convenient pickup and delivery.'})
      settings.tags.heading10 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'Are laundry services worth it?',description:'Yes, using a service like Hamperapp allows you to enjoy life without spending time on laundry chores. Our delivery laundry service ensures that we professionally clean and handle your clothes.'}) : new SeoPageHeadingTags({title: 'Are laundry services worth it?', description: 'Yes! Using a service like Hamperapp in San Diego allows you to enjoy life without spending time on laundry chores. Our delivery service ensures that we professionally clean and handle your clothes.'})
      settings.tags.heading11 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'What are the advantages of wash and fold laundry services?',description:'Wash and fold services like Hamperapp save you time by professionally washing, drying, and folding your laundry. With our delivery service, you can focus on what matters most.'}) : new SeoPageHeadingTags({title: 'What are the advantages of wash and fold laundry services?', description: 'Wash and fold services like Hamperapp save you time by professionally washing, drying, and folding your laundry. With our delivery service in San Diego, you can focus on what matters most.'})
      settings.tags.heading12 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'How much does it cost to have someone do your laundry?',description:'Hamperapp laundry service offers competitive pricing based on weight or item count. Check our website for rates and enjoy the convenience of our delivery laundry service.'}) : new SeoPageHeadingTags({title: 'How much does it cost to have someone do your laundry?', description: 'Hamperapp offers competitive pricing based on weight or item count. Check our website for rates and enjoy the convenience of our delivery laundry service in San Diego.'})
      settings.tags.heading13 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'Which is better: dry cleaning or laundry?',description:'It depends on the fabric and care instructions; Hamperapp offers both dry cleaning and laundry services to meet your needs. We\'ll ensure that we clean your clothes properly using the best method for each item.'}) : new SeoPageHeadingTags({title: 'Which is better: dry cleaning or laundry?', description: 'It depends on the fabric and care instructions. Hamperapp offers both dry cleaning and laundry services in San Diego to meet your needs. We\'ll ensure we clean your clothes properly using the best method for each item.'})

      return settings;
  }
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private seoService: SeoService,
    private seoPageService: SeoPageService,
    private errorHandlingService: ErrorHandlingService,
  ) { }

  private resetSEO(): void {
    this.seoService.reset();
  }
}
