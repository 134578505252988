export class OrderSchedullingForm {
  public pickupTime: string;
  public pickupTo: string;
  public deliveryTime: string;
  public deliveryTo: string;

  constructor() {
    this.pickupTime = '';
    this.pickupTo = '';
    this.deliveryTime = '';
    this.deliveryTo = '';
  }
}
