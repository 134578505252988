<dialog-view *ngVar="data.isLoading$ | async as isLoading" variant="small" class="confirmation" [class.confirmation-smaller]="data.type === 'orderCancellation'">
  <dialog-header class="confirmation-header">
    <dialog-content size="large">
      {{ data.title | translate }}
      <dialog-close [isLoading]="isLoading" (clicked)="close()">
      </dialog-close>
    </dialog-content>
  </dialog-header>
  <dialog-body [isEmphasized]="data.isEmphasized" class="confirmation-body">
    <dialog-content size="large">
      <ng-container *ngIf="data.textTemplate" [ngTemplateOutlet]="data.textTemplate"></ng-container>
      <span *ngIf="data.type !== 'orderCancellation' && data.text">{{ data.text | translate }}</span>
      <div *ngIf="data.type === 'orderCancellation'">
        <p class="confirmation-smaller-text" *ngIf="data?.text">
          <icon type="information-outline"></icon>
          {{data.text | translate}}
        </p>
        <div class="confirmation-smaller-reason">
          <p class="confirmation-smaller-reason-title" *ngIf="data?.reasonSelectText">{{data.reasonSelectText | translate}}</p>
          <div class="confirmation-smaller-reason-block">
            <div *ngFor="let reason of reasons" >
              <checkbox variant="primary" (checkboxChanged)="changeReasonHandler(reason)" [isChecked]="reason == selectedReason">
                {{reason}}
              </checkbox>
            </div>
            <form-control-textarea
              *ngIf="selectedReason == 'Other'"
              [(ngModel)]="otherReasonText"
              [placeholder]="data.reasonPlaceholder | translate">

            </form-control-textarea>
          </div>
        </div>
      </div>
      <dialog-buttons class="confirmation-buttons">
        <div class="confirmation-button">
          <app-button [isDisabled]="isLoading || (data.type === 'orderCancellation' && (selectedReason === 'Other' && !otherReasonText.length))" [isLoading]="isLoading" variant="error" [isBlock]="true"
            (clicked)="!isLoading && confirmButtonClicked()" testID="confirmation-confirm">
            <div class="confirmation-button-content">
              {{ data.confirmButtonText | translate }}
            </div>
          </app-button>
        </div>
        <div class="confirmation-button">
          <app-button [isDisabled]="isLoading" variant="grays-dark" [isOutline]="true" [isBlock]="true"
            (clicked)="!isLoading && close()">
            <div class="confirmation-button-content">
              {{ data.cancelButtonText | translate }}
            </div>
          </app-button>
        </div>
      </dialog-buttons>
    </dialog-content>
  </dialog-body>
</dialog-view>
