import { Injectable, TemplateRef } from '@angular/core';
import { getSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { AppDialogService } from '@shared/dialog';
import type { AppState } from '@shared/store';
import { User, UserService } from '@shared/user';
import { UserZipSelectors } from '@shared/user/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isLocationAllowedOnURL } from './utils';
import { GeolocationViewActions, GeolocationViewSelectors } from './store';
import { AddressPickerComponent } from '@shared/address-picker';
import { GeolocationViewZipPickerComponent } from './components';

@Injectable()
export class GeolocationViewFacade {
  public get hasAddresses$(): Observable<boolean> {
    return this.userService.profile$.pipe(
      map((user) => !!user?.addresses?.length)
    );
  }

  public get user$(): Observable<User> {
    return this.userService.profile$;
  }

  public get zipAddress$(): Observable<string> {
    return this.store.select(UserZipSelectors.zipAddress);
  }

  public get predictedZip$(): Observable<string> {
    return this.store.select(GeolocationViewSelectors.predictedZip);
  }

  public get shouldShowInfo$(): Observable<boolean> {
    return this.store.select(GeolocationViewSelectors.shouldShowInfo);
  }

  public get isLoading$(): Observable<boolean> {
    return this.store.select(GeolocationViewSelectors.isLoading);
  }

  public get isVisible$(): Observable<boolean> {
    return this.store.select(getSelectors().selectUrl).pipe(
      map((url) => isLocationAllowedOnURL(url))
    );
  }

  constructor(
    private store: Store<AppState>,
    private dialogService: AppDialogService,
    private userService: UserService
  ) { }

  public tryPredictZip(): void {
    this.store.dispatch(GeolocationViewActions.tryPredictZip());
  }

  public destroy(): void {
    this.store.dispatch(GeolocationViewActions.resetState());
  }

  public clearPredictedZip(): void {
    this.store.dispatch(GeolocationViewActions.clearPredictedZip());
  }

  public changeZip(zipCode: string, dialogID?: string,isRedirectToCity?:boolean): void {
    this.store.dispatch(GeolocationViewActions.tryUpdateZip({ dialogID, zipCode ,isRedirectToCity}));
  }

  public openAddressPicker(templateRef: TemplateRef<any>): void {
    this.dialogService.openTemplate(templateRef, {
      width: AddressPickerComponent.width
    });
  }

  public openZipPicker(templateRef: TemplateRef<any>): void {
    this.dialogService.openTemplate(templateRef, {
      width: GeolocationViewZipPickerComponent.width
    });
  }

  public closeInfoPopup(): void {
    this.store.dispatch(GeolocationViewActions.closeInfoPopup());
  }
}
