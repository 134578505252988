import {Component, ChangeDetectionStrategy} from '@angular/core';
import {BaseDialogDirective} from '@shared/base-dialog';
import {DeliveryPrice} from '@shared/service';

@Component({
  selector: 'summary-delivery-fee-info',
  templateUrl: 'delivery-fee-info.html',
  styleUrls: ['delivery-fee-info.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderSummaryDeliveryFeeInfoComponent extends BaseDialogDirective<Array<DeliveryPrice>> {
  public static width = '380px';

  constructor() {
    super();
    this.checkInterval();
  }

  public checkInterval() {
    if (this.data) {
      let anyData = this.data.find((interval) => interval.price > 0)
      if (!anyData) {
        this.data = null
      }
    }
  }
}
