import { Injectable } from '@angular/core';
import { ClassConstructor, instanceToPlain, plainToInstance } from 'class-transformer';
import { CookieKey } from './types';
import { CookieService as CommonCookieService, CookieOptions } from '@ronas-it/angular-common';

@Injectable()
export class CookieService extends CommonCookieService<CookieKey> {
  public getClassItem<T>(key: CookieKey, type: ClassConstructor<T>): T {
    const parsedObject = this.getObject(key);

    return (parsedObject)
      ? plainToInstance(type, parsedObject)
      : null;
  }

  public putClassItem<T>(key: CookieKey, value: T, options?: CookieOptions): void {
    const plainObject = (value)
      ? instanceToPlain(value, {
        excludeExtraneousValues: true,
        exposeUnsetFields: false
      })
      : null;

    this.putObject(key, plainObject, options);
  }

  public removeAllNotStartsWith(notStartsWith: string, options?: CookieOptions): void {
    const cookies = this.getAll();

    for (const cookieKey of Object.keys(cookies)) {
      if (notStartsWith && cookieKey.startsWith(notStartsWith)) {
        continue;
      }
      this.remove(cookieKey as CookieKey, options);
    }
  }
}
