import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private static loadedScripts: Record<string, ReplaySubject<void>> = {};

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public loadScript(url: string): Observable<void> {
    if (ScriptService.loadedScripts[url]) {
      return ScriptService.loadedScripts[url].asObservable();
    }

    ScriptService.loadedScripts[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;
    script.onload = () => {
      ScriptService.loadedScripts[url].next();
      ScriptService.loadedScripts[url].complete();
    };
    script.onerror = (e) => {
      ScriptService.loadedScripts[url].error(e);
      ScriptService.loadedScripts[url].complete();
    };

    this.document.body.appendChild(script);

    return ScriptService.loadedScripts[url].asObservable();
  }



  public load(url: string): Observable<void> {
    if (ScriptService.loadedScripts[url]) {
      return ScriptService.loadedScripts[url].asObservable();
    }

    ScriptService.loadedScripts[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'application/json';
    script.src = url;
    script.async = true;
    script.onload = () => {
      ScriptService.loadedScripts[url].next();
      ScriptService.loadedScripts[url].complete();
    };
    script.onerror = (e) => {
      ScriptService.loadedScripts[url].error(e);
      ScriptService.loadedScripts[url].complete();
    };

    this.document.body.appendChild(script);

    return ScriptService.loadedScripts[url].asObservable();
  }


}
