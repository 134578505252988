import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SeoPageService } from './seo-page.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [SeoPageService]
})
export class SeoPageModule { }

