import { Injectable } from '@angular/core';
import { DeviceInfo } from '@shared/device-info';

@Injectable()
export class WebviewService {
  public get isWebview(): boolean {
    return !!this.deviceInfo;
  }

  public get deviceInfo(): DeviceInfo {
    return this._deviceInfo;
  }

  private _deviceInfo: DeviceInfo;

  public initWebview(deviceInfo: DeviceInfo): void {
    this._deviceInfo = deviceInfo;
  }
}
