import { Expose } from 'class-transformer';

export class PhoneVerificationRequest {
  @Expose()
  public phone: string;

  @Expose()
  public token: string;

  constructor(model: Partial<PhoneVerificationRequest>) {
    Object.assign(this, model);
  }
}
