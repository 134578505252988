<dialog-view variant="small" class="user-type" *ngVar="'PRIVATE.SHARED.USER_TYPE.' as translations">
  <dialog-header class="success-header">
    <dialog-content>
      {{ translations + 'TEXT_SELECT_TITLE' | translate }}
    </dialog-content>
  </dialog-header>
  <dialog-body class="success-body">
    <dialog-content>
      <div class="user-type-default">
        <button *ngFor="let option of userTypeOptions" [disabled]="option.value === value" [class.current]="option.value === value"
                (click)="optionItemClicked(option.value)" class="switch-item">
          <icon [type]="option.iconType"></icon>
          {{ option.title | translate }}
        </button>

      </div>

      <dialog-buttons class="row">
        <app-button [isDisabled]="!value" class="user-type-conform" variant="primary" size="small" [isBlock]="true" (clicked)="confirmButtonClicked()">
          {{ translations + 'TEXT_SELECT_OK' | translate }}
        </app-button>
      </dialog-buttons>
    </dialog-content>
  </dialog-body>
</dialog-view>
