import { Injectable } from '@angular/core';
import { AuthService } from '@shared/auth';
import { Observable } from 'rxjs';

@Injectable()
export class LogoFacade {
  public get isAuthenticated$(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  constructor(private authService: AuthService) { }
}
