import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { getSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { IntercomActions } from '@shared/intercom/store';
import type { AppState } from '@shared/store';
import {UserRootActions, UserService, UserZipActions} from '@shared/user';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { BrazeService } from '@shared/braze';
import {DialogService} from '@ngneat/dialog';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private store: Store<AppState>,
    private dialogService: DialogService,
    private brazeService: BrazeService,
    @Inject(PLATFORM_ID) private platformID: object
  ) { }

  public canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const isPublicSection = route.data?.isPublicSection || false;

    return this.authService.isAuthenticated$
      .pipe(
        withLatestFrom(
          this.store.select(getSelectors().selectUrl)
        ),
        exhaustMap(([isAuthenticated, currentURL]) => {
          if (!isAuthenticated) {
            if (isPublicSection) {
              return of(true);
            }
            this.authService.clearPersonalStore();

            return of(this.getLoginPageURLTree(currentURL));
          }

          return this.userService.profile$
            .pipe(
              take(1),
              exhaustMap((profile) => (profile) ? of(profile) : this.userService.fetchProfile()),
              tap((profile) => this.store.dispatch(UserZipActions.setZip({ zip: profile?.zip }))),
              tap((user) => this.store.dispatch(IntercomActions.tryLogin({ user }))),
              tap((user) =>{ if(!user.account_type) {
                this.dialogService.closeAll();
                if (isPlatformBrowser(this.platformID)) {
                  return this.store.dispatch(UserRootActions.initClientType())
                }
              }
              }),
              tap((user) => {
                this.brazeService.changeUser(user.id.toString())
                return this.store.dispatch(IntercomActions.tryLogin({ user }))
              }),
              map(() => true),
              catchError(() => {
                this.authService.unauthorize({ disableRedirect: true });

                if (isPublicSection) {
                  return of(true);
                } else {
                  return of(this.getLoginPageURLTree(currentURL));
                }
              })
            );
        })
      );
  }

  private getLoginPageURLTree(redirectRoute: string): UrlTree {
    return this.router.parseUrl(`/login?redirectRoute=${redirectRoute}`);
  }
}
