<page *ngVar="'PUBLIC.NOT_FOUND.' as translations" class="promo not-found">
  <section class="not-found-top">
    <content class="not-found-content">
      <div class="not-found-image">
        <not-found-stars></not-found-stars>
        <not-found-ufo></not-found-ufo>
      </div>
      <h2>
        {{ translations + 'TEXT_OOPS' | translate }}
      </h2>
      <div style="display: none">forGetNotFoundPage</div>
      <p>
        {{ translations + 'TEXT_CANT_FIND' | translate }}
      </p>
      <a routerLink="/">
        <app-button variant="primary">
          <icon type="angle-left" color="default"></icon>
          {{ translations + 'BUTTON_BACK' | translate }}
        </app-button>
      </a>
    </content>
  </section>
  <invite-friends></invite-friends>
</page>
