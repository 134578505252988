<div *ngVar="'SHARED.ORDER_MAIN_STAGE.' as translations" class="order-main">
  <div *ngIf="service.hasPricePerPound" class="order-main-information">
    <p class="order-main-information-title">
      {{translations + 'TEXT_ADDITIONAL_TITLE' | translate}}
      <span class="order-main-information-svg">
        <icon type="shirt-outline" color="purple"></icon>
      </span>
    </p>
    <div class="order-main-information-price">{{pricePerPound | currency}} / lb</div>
    <div class="order-main-information-block">
      <p class="order-main-information-text">{{translations + 'TEXT_ADDITIONAL_INFO' | translate: { minOrderWeight: service.minimumOrderWeight} }}</p>
      <ul class="order-main-information-list">
        <li>{{translations + 'TEXT_ADDITIONAL_LIST_HOURS' | translate}}</li>
        <li>{{translations + 'TEXT_ADDITIONAL_LIST_FREE' | translate}}</li>
        <li>{{ translations + 'TEXT_ADDITIONAL_LIST_INFO' | translate : { serviceName: service.name } }}</li>
        <li>{{translations + 'TEXT_ADDITIONAL_LIST_PRICED' | translate}}</li>
      </ul>
    </div>
  </div>
  <ng-container *ngIf="service.hasItems">
    <div *ngIf="service.hasPricePerPound && service.isLaundryService" class="order-main-headline">
      {{ translations + 'TEXT_ADDITIONAL_ITEMS' | translate }}
    </div>
    <order-items
      (ngrxFormsAction)="handleFormAction($event)"
      [ngrxFormControlState]="formState.controls.items"
      [service]="service"
      class="order-main-items"
      (deleteItemFromCard)="deleteItemFromCardHandler($event)"
      (addItemToCard)="addItemToCardHandler($event)"
    >
    </order-items>
  </ng-container>
</div>
