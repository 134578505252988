import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dialog-close',
  templateUrl: 'close.html',
  styleUrls: ['close.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogViewCloseComponent {
  @Input() isLoading: boolean;

  @Output() clicked: EventEmitter<void>;

  constructor() {
    this.clicked = new EventEmitter();
  }

  public buttonClicked(): void {
    if (!this.isLoading) {
      this.clicked.next();
    }
  }
}
