import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/base-form-control';
import { IConfig, initialConfig, NgxMaskModule } from 'ngx-mask';

@Component({
  standalone: true,
  imports: [
    NgxMaskModule,
    FormsModule
  ],
  selector: 'form-control',
  templateUrl: 'form-control.html',
  styleUrls: ['form-control.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlComponent),
      multi: true
    }
  ]
})
export class FormControlComponent extends BaseFormControlDirective<string | number> {
  @Input() type: string;
  @Input() mask: string;
  @Input() prefix: IConfig['prefix'];
  @Input() dropSpecialCharacters: IConfig['dropSpecialCharacters'];
  @Input() showMaskTyped: IConfig['showMaskTyped'];
  @Input() autocomplete: string;
  @Input() specialCharacters: IConfig['specialCharacters'];
  @Input() inputMode: string;
  @Input() errorBorder: boolean;
  @Input() isCVV?: boolean = false;
  @ViewChild('input') input: ElementRef;

  constructor() {
    super();
    this.dropSpecialCharacters = initialConfig.dropSpecialCharacters;
    this.specialCharacters = initialConfig.specialCharacters;
    this.prefix = initialConfig.prefix;
    this.inputMode = 'text';
  }


  public changeHandler(value: any): void {
    const regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    if (regexExp.test(value)) {
      const input = this.input.nativeElement;
      input.value = value.split(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi)[0];
    }
  }
}
