import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Service } from '@shared/service';
import { Actions, formGroupReducer, FormGroupState, SetValueAction } from 'ngrx-forms';
import { BaseServicePageForm } from '@shared/base-service-page';
import { OrderSummary } from '@shared/order/models';
import { Store } from '@ngrx/store';
import { ItemForm } from '@shared/item';

@Component({
  selector: 'order-main-stage',
  templateUrl: 'order-main-stage.html',
  styleUrls: ['order-main-stage.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderMainStageComponent {
  @Input() formState: FormGroupState<BaseServicePageForm>;
  @Input() service: Service;
  @Input() summary: OrderSummary;

  @Output() deleteItemFromCard: EventEmitter<string> = new EventEmitter<string>();
  @Output() addItemToCard: EventEmitter<ItemForm[]> = new EventEmitter<ItemForm[]>();

  constructor(
    private store: Store
  ) {
  }

  public get pricePerPound(): number | undefined {
    return this.summary?.poundPrice;
  }

  public handleFormAction(action: Actions<any>): void {
    this.formState = formGroupReducer(this.formState, action);
    if (action.type === 'ngrx/forms/SET_VALUE') {
      this.store.dispatch(new SetValueAction(action.controlId, action.value));
      // console.log(action.value.value);
      // this.itemChange.emit(action.value.value)
    }
  }

  public deleteItemFromCardHandler(title: string): void {
    this.deleteItemFromCard.emit(title)
  }

  public addItemToCardHandler(items: ItemForm[]): void {
    this.addItemToCard.emit(items)
  }
}
