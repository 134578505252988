import { PaginationRequest } from '@shared/pagination';
import { Expose } from 'class-transformer';
import { ServicePreferenceRelation } from '../types';

export class ServicePreferencePaginationRequest extends PaginationRequest<ServicePreferenceRelation> {
  @Expose({ name: 'laundry_id' })
  public laundryID?: number;

  @Expose({ name: 'service_id' })
  public serviceID?: number;

  @Expose({ name: 'laundry_service_id' })
  public laundryServiceID?: number;

  @Expose({ name: 'preference_id' })
  public preferenceID?: number;

  constructor(model: Partial<ServicePreferencePaginationRequest> = {}) {
    super(model);
    Object.assign(this, model);
  }
}
