export class UserPaymentMethodState {
  public isCreating: boolean;
  public isSelecting: boolean;
  public isDeleting: boolean;
  public isEditing: boolean;

  constructor() {
    this.isCreating = false;
    this.isSelecting = false;
    this.isDeleting = false;
    this.isEditing = false;
  }
}
