import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'stars',
  templateUrl: 'stars.html',
  styleUrls: ['stars.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarsComponent {
  @Input() rating: number;

  public get gapsCount(): number {
    return Math.floor(this.rating);
  }
}
