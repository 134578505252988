import type {AppState} from '@shared/store';
import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UserAddressActions} from './actions';
import {catchError, exhaustMap, filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {Address, AddressService} from '@shared/address';
import {AppDialogService} from '@shared/dialog';
import {HttpErrorResponse} from '@angular/common/http';
import {ConfirmationModalData, ConfirmationModalComponent} from '@shared/confirmation-modal';
import {Order} from '@shared/order';
import {ErrorHandlingService} from '@shared/error-handling';
import {User, UserRootActions, UserRootSelectors, UserService} from '@shared/user';
import {UserZipActions} from '../zip';
import {NotificationService} from '@shared/notification';
import {LocalStorageService} from "@shared/local-storage";

@Injectable()
export class UserAddressEffects {
  public setAddresses$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(UserAddressActions.setAddresses),
      withLatestFrom(this.userService.profile$),
      map(([{addresses}, user]) => this.setUserAddress(user, addresses)),
      map((user) => UserZipActions.trySetZip({zip: user.activeAddress?.zip}))
    )
  );

  public tryUpsert$: Observable<[Action, Record<number, Order>]> = createEffect(
    () => this.actions$.pipe(
      ofType(UserAddressActions.tryUpsert),
      withLatestFrom(
        this.store.select(UserRootSelectors.currentOrders)
      ),
      tap(([{request, onSuccess, dialogID}, currentOrders]) => {
        if (!request.id || !this.shouldConfirmAddressUpdate({request, currentOrders})) {
          return this.store.dispatch(UserAddressActions.upsert({request, onSuccess, dialogID}));
        }

        this.confirmAddressUpdate({request, onSuccess});
      })
    ),
    {dispatch: false}
  );
  public tryInit$: Observable<[Action, Record<number, Order>]> = createEffect(
    () => this.actions$.pipe(
      ofType(UserAddressActions.tryInit),
      withLatestFrom(
        this.store.select(UserRootSelectors.currentOrders)
      ),
      tap(([_, currentOrders]) => {
        const hasDraft = LocalStorageService.orderDraftExpiration.get()?.diffNow().milliseconds > 0 ?? false;

        if (hasDraft) {
          const order = new Order(LocalStorageService.orderDraft.get());
          order.id=undefined;
          this.store.dispatch(UserRootActions.updateCurrentOrders({ order, setActive: true }))
        }
      })
    ),
    {dispatch: false}
  );

  public upsert$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(UserAddressActions.upsert),
      exhaustMap(({request, onSuccess, onFailure, dialogID}) => this.upsertAddress(request)
        .pipe(
          withLatestFrom(this.userService.profile$),
          tap(([address, user]) => {
            if (request.id) {
              this.updateUserAddress(user, address);
            } else {
              this.addUserAddress(user, address);
            }
            onSuccess?.(address);
          }),
          map(([address]) => {
            if (dialogID) {
              this.dialogService.closeByID(dialogID)
            }
            return UserAddressActions.upsertSuccess({address})
          }),
          catchError((response: HttpErrorResponse) => {
            onFailure?.(response);
            if (response?.error?.errors?.zip) {
              this.notificationService.error('SHARED.USER.TEXT_ADDRESS_NOT_SUPPORTED');
            } else {
              this.errorHandlingService.handleHttpError(response, {
                translateKey: 'SHARED.USER.TEXT_SAVE_LOCATION_FAILURE'
              });
            }

            return of(UserAddressActions.upsertFailure({response}));
          })
        )
      )
    )
  );

  public handleCurrentAddressUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAddressActions.upsertSuccess),
      withLatestFrom(this.store.select(UserRootSelectors.activeOrder)),
      filter(([{address}, order]) => address.id === order?.pickupAddress?.id
        && !order?.laundryService?.hasZipCode(address.zipCode)
      ),
      map(() => UserRootActions.clearCurrentOrders())
    )
  );

  public delete$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(UserAddressActions.delete),
      withLatestFrom(this.userService.profile$),
      exhaustMap(([{address, onSuccess}, user]) => this.addressService
        .delete(address.id)
        .pipe(
          tap(() => {
            this.deleteUserAddress(user, address);
            onSuccess?.();
          }),
          map(() => UserAddressActions.deleteSuccess()),
          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'SHARED.USER.TEXT_DELETE_LOCATION_FAILURE'
            });

            return of(UserAddressActions.deleteFailure({response}));
          })
        )
      )
    )
  );

  public select$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(UserAddressActions.select),
      withLatestFrom(this.userService.profile$),
      exhaustMap(([{address, onSuccess}, user]) => this.addressService
        .update({id: address.id, isActive: true, zipID: address.zipID})
        .pipe(
          map(() => new Address({...address, isActive: true})),
          tap((selectedAddress) => {
            this.selectUserAddress(user, selectedAddress);
            onSuccess?.(selectedAddress);
          }),
          map(() => UserAddressActions.selectSuccess()),
          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'SHARED.USER.TEXT_SELECT_LOCATION_FAILURE'
            });

            return of(UserAddressActions.selectFailure({response}));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private addressService: AddressService,
    private dialogService: AppDialogService,
    private errorHandlingService: ErrorHandlingService,
    private notificationService: NotificationService
  ) {
  }

  private setUserAddress(user: User, addresses: Array<Address>): User {
    const activeAddress = addresses.find((address) => address.isActive) || null;
    const updatedUser = new User({
      ...user,
      addresses,
      activeAddress
    });
    this.userService.setProfile(updatedUser);

    return updatedUser;
  }

  private shouldConfirmAddressUpdate({request, currentOrders}: {
    request: Partial<Address>;
    currentOrders: Record<number, Order>;
  }): boolean {
    return !!Object.values(currentOrders)
      .find((order) => order?.pickupAddress?.id === request.id &&
        !order.laundryService?.hasZipCode(request.zipCode)
      );
  }

  private confirmAddressUpdate({request, onSuccess}: {
    request: Partial<Address>;
    onSuccess: (address: Address) => void;
  }): void {
    this.dialogService.openComponent<ConfirmationModalData>(
      ConfirmationModalComponent, {
        data: {
          title: 'SHARED.USER.TEXT_EDIT_ADDRESS',
          text: 'SHARED.USER.TEXT_EDIT_ADDRESS_WARN',
          cancelButtonText: 'SHARED.USER.BUTTON_NO',
          confirmButtonText: 'SHARED.USER.BUTTON_YES',
          action: (dialogID: string) => {
            this.dialogService.closeByID(dialogID);
            this.store.dispatch(UserAddressActions.upsert({request, onSuccess}));
          }
        }
      });
  }

  private upsertAddress(request: Partial<Address>): Observable<Address> {
    return this.addressService
      .upsert(request)
      .pipe(
        exhaustMap((createdAddress) => this.addressService
          .get(((createdAddress instanceof Address) && createdAddress.id) || request.id, {relations: ['zip']})
        )
      );
  }

  private addUserAddress(user: User, newAddress: Address): void {
    const addresses = [

      ...user.addresses.map((address) => (address.isActive && newAddress.isActive) ? new Address({
        ...address,
        isActive: false
      }) : address), newAddress
    ];

    this.store.dispatch(UserAddressActions.setAddresses({addresses}));
  }

  private updateUserAddress(user: User, updatedAddress: Address): void {
    const addresses = user.addresses.map((address) => (address.id === updatedAddress.id)
      ? updatedAddress
      : (address.isActive && updatedAddress.isActive)
        ? new Address({...address, isActive: false})
        : address
    );

    this.store.dispatch(UserAddressActions.setAddresses({addresses}));
  }

  private selectUserAddress(user: User, selectedAddress: Address): void {
    const addresses = user.addresses.map((userAddress) => (userAddress.id === selectedAddress.id)
      ? selectedAddress
      : (userAddress.isActive) ? new Address({...userAddress, isActive: false}) : userAddress
    );

    this.store.dispatch(UserAddressActions.setAddresses({addresses}));
  }

  private deleteUserAddress(user: User, address: Address): void {
    this.store.dispatch(UserAddressActions.setAddresses({
      addresses: user.addresses.filter((_address) => _address.id !== address.id)
    }));
  }
}
