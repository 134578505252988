import { Expose } from 'class-transformer';

export class ValidateRequest {
  @Expose()
  public email: string;

  @Expose()
  public phone: string;

  constructor(model: Partial<ValidateRequest>) {
    Object.assign(this, model);
  }
}
