import { PaginationRequest } from '@shared/pagination';
import { Expose } from 'class-transformer';
import { ServiceRelation } from '../types';

export class ServicePaginationRequest extends PaginationRequest<ServiceRelation> {
  @Expose({ name: 'parent_id' })
  public parentID: number;

  @Expose({ name: 'zip_id' })
  public zipID: number;

  constructor(model: Partial<ServicePaginationRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
