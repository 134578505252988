import { Expose } from 'class-transformer';
import { DeviceInfoType } from '../enums';

export class DeviceInfo {
  @Expose()
  public type: DeviceInfoType;

  @Expose()
  public token: string;

  @Expose({ name: 'device_id' })
  public deviceID: string;

  constructor(model: Partial<DeviceInfo>) {
    Object.assign(this, model);
  }
}
