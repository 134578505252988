import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@shared/notification';

@Injectable()
export class ErrorHandlingService {
  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) { }

  public handleHttpError(response: HttpErrorResponse, additional?: {
    translateKey?: string;
  }): void {
    if (response?.status === HttpStatusCode.NotFound) {
      this.router.navigate(['/not-found']);
    } else if (response?.error?.error || response?.error?.message) {
      this.notificationService.error(response?.error?.error || response?.error?.message);
    } else if (additional?.translateKey) {
      this.notificationService.error(additional?.translateKey);
    }
  }
}
