import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Order, OrderSummary } from '@shared/order';
import { Service } from '@shared/service';
import type { AppState } from '@shared/store';
import { combineLatest, Observable } from 'rxjs';
import { BaseServicePageActions, BaseServicePageSelectors, BaseServicePageState } from './store';
import {ItemForm} from '@shared/item';

@Injectable()
export abstract class BaseServicePageFacade<State extends BaseServicePageState> {
  public get isCalculating$(): Observable<boolean> {
    return this.store.select(this.selectors.isCalculating);
  }

  public get services$(): Observable<Array<Service>> {
    return this.store.select(this.selectors.services);
  }

  public get formState$(): Observable<State['formState']> {
    return this.store.select(this.selectors.formState);
  }

  public get summary$(): Observable<OrderSummary> {
    return this.store.select(this.selectors.summary);
  }

  public get stage$(): Observable<State['stage']> {
    return this.store.select(this.selectors.stage);
  }

  public abstract get order$(): Observable<Order>;
  public abstract get service$(): Observable<Service>;

  public get canGoNext$(): Observable<boolean> {
    return combineLatest([
      this.service$,
      this.order$,
      this.stage$
    ], (service, order, stage) => service?.isLaundryService &&
      (!!order?.items?.length || !!service.hasPricePerPound) &&
      stage.isItemSelection
    );
  }

  public get canSkipItemSelection$(): Observable<boolean> {
    return combineLatest([
      this.service$,
      this.stage$
    ], (service, stage) => service?.isLaundryService && !service.hasPricePerPound && stage.isItemSelection);
  }

  protected store: Store<AppState>;

  constructor(
    protected actions: BaseServicePageActions,
    protected selectors: BaseServicePageSelectors<State>
  ) {
    this.store = inject(Store);
  }

  public resetState(): void {
    this.store.dispatch(this.actions.resetState());
  }

  public removeItems(): void {
    this.store.dispatch(this.actions.removeItems());
  }
  public changeItems(items:Array<ItemForm>): void {
    this.store.dispatch(this.actions.changeItems({items:[...items]}));
  }

  public setStage(stageName: State['stage']['name']): void {
    this.store.dispatch(this.actions.setStage({ stageName }));
  }
}
