import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

@Injectable()
export class NotificationService {
  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrService,
    @Inject(PLATFORM_ID) private platformID: object
  ) { }

  public error(message: string, params?: object): void {
    if (!isPlatformBrowser(this.platformID)) {
      return;
    }
    this.toastrService.error(
      this.translateService.instant(message, params)
    );
  }

  public success(message: string, params?: object): void {
    if (!isPlatformBrowser(this.platformID)) {
      return;
    }
    this.toastrService.success(
      this.translateService.instant(message, params)
    );
  }

  public warning(message: string, params?: object, title?: string, config?: IndividualConfig): void {
    if (!isPlatformBrowser(this.platformID)) {
      return;
    }
    this.toastrService.warning(
      this.translateService.instant(message, params)
    );
  }

  public info(message: string, config?: Partial<IndividualConfig>): void {
    if (!isPlatformBrowser(this.platformID)) {
      return;
    }
    this.toastrService.info(this.translateService.instant(message), '', config);
  }
}
