import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { configuration } from '@configurations';

@Component({
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  selector: 'store-buttons',
  templateUrl: 'store-buttons.html',
  styleUrls: ['store-buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreButtonsComponent {
  public get links(): typeof configuration.customerLinks {
    return configuration.customerLinks;
  }
}
