import {Injectable} from '@angular/core';
import {ApiService} from '@shared/api';
import {instanceToPlain, plainToInstance} from 'class-transformer';
import {map, Observable} from 'rxjs';
import {GetSeoPageByURLRequest, SeoPage} from './models';
import {SeoPageType} from '@shared/seo-page/enums';
import {HttpUrlEncodingCodec} from '@angular/common/http';

@Injectable()
export class SeoPageService {
  constructor(
    private apiService: ApiService
  ) {
  }

  public getByURL(request: GetSeoPageByURLRequest): Observable<SeoPage> {
    const requestParams = new GetSeoPageByURLRequest(request);
    if (requestParams.type === SeoPageType.COMMON && requestParams.url.includes('commercial/laundry-services/city')) {
      requestParams.type = SeoPageType.COMMERCIAL
    }
    if (requestParams.url.includes('/city/')) {
      let codec = new HttpUrlEncodingCodec;
      requestParams.url = codec.decodeValue(requestParams.url);
    }
    return this.apiService.get('/seo-pages/by-url', instanceToPlain(requestParams))
      .pipe(
        map((response) => plainToInstance(SeoPage, response))
      );
  }
}
