import {Actions, createEffect, ofType} from '@ngrx/effects';
import type {AppState} from '@shared/store';
import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Observable, of, switchMap, withLatestFrom} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {TopLocationsActions} from "@shared/footer/store/actions";
import {LocationService} from "@shared/location";
import {UserZipSelectors} from "@shared/user";

@Injectable()
export class TopLocationsEffects {


  public loadTopLocations$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(TopLocationsActions.loadLocations),
      withLatestFrom(this.store.select(UserZipSelectors.zip)),
      filter(([_,zip]) => !!zip?.code),
      switchMap(([_,zip]) => {
        return this.locationService
          .getNearbyCites(zip.code)
          .pipe(
            map((response) => TopLocationsActions.loadLocationsSuccess({response})),
            catchError((response) => {
              return of(TopLocationsActions.loadLocationsFailure({response}));
            })
          )
      })
    )
  );

  public lessTopLocations: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(TopLocationsActions.lessLocations),
      tap(({id}) => {
        return this.store.dispatch(TopLocationsActions.lessLocationsSuccess({id}))
      })
    ),
    { dispatch: false }
  )


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private locationService: LocationService
  ) {
  }

}
