import { Zip } from '@shared/zip';

export class UserZipState {
  public zip: Zip;
  public zipAddress: string;
  public isGeocoding: boolean;
  public isChecking: boolean;

  constructor() {
    this.zip = null;
    this.zipAddress = '';
    this.isGeocoding = false;
    this.isChecking = false;
  }
}
