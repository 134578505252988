import { FormGroupState, updateGroup, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

export class ZipCodeForm {
  public zipCode: string;

  constructor() {
    this.zipCode = '';
  }

  public static validate = (
    state: FormGroupState<ZipCodeForm>
  ): FormGroupState<ZipCodeForm> => updateGroup(state, {
    zipCode: validate<string>(required)
  });
}
