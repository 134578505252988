import { truthyValidator } from '@shared/truthy-validator';
import { FormGroupState, setValue, updateGroup, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { Address } from '@shared/address';

export class AddressForm {
  public address: string;
  public formattedAddress: string;
  public zipCode: string;
  public city: string;
  public latitude: number | null;
  public longitude: number | null;
  public state: string;
  public apt: string;
  public gatecode: string;
  public buildingName: string;
  public isActive: boolean;

  constructor() {
    this.address = '';
    this.formattedAddress = '';
    this.zipCode = '';
    this.city = '';
    this.state = '';
    this.latitude = null;
    this.longitude = null;
    this.apt = '';
    this.gatecode = '';
    this.buildingName = '';
    this.isActive = true;
  }

  public static validate = (
    state: FormGroupState<AddressForm>
  ): FormGroupState<AddressForm> => updateGroup(state, {
    zipCode: validate(required),
    address: validate<string>(
      truthyValidator(state.value.address?.length && state.value.address === state.value.formattedAddress)
    ),
    longitude: validate(required),
    latitude: validate(required)
  });

  public static prefillForm = (address?: Address): (
    state: FormGroupState<AddressForm>
  ) => FormGroupState<AddressForm> => updateGroup({
    address: setValue(address?.address ?? ''),
    formattedAddress: setValue(address?.address ?? ''),
    zipCode: setValue((address?.zipCode || address?.zip?.code) ?? ''),
    city: setValue(address?.city ?? ''),
    state: setValue(address?.state ?? ''),
    longitude: setValue<number | null>(address?.longitude ?? null),
    latitude: setValue<number | null>(address?.latitude ?? null),
    apt: setValue(address?.apt ?? ''),
    gatecode: setValue(address?.gatecode ?? ''),
    buildingName: setValue(address?.buildingName ?? ''),
    isActive: setValue<boolean>(address?.isActive || true)
  });
}
