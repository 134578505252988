import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/button';
import { IconModule } from '@shared/icon';

@Component({
  standalone: true,
  imports: [
    ButtonComponent,
    IconModule,
    TranslateModule,
    RouterLink
  ],
  selector: 'page-back',
  templateUrl: 'page-back.html',
  styleUrls: ['page-back.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageBackComponent {
  @Input() link?: string;
  @Input() text?: string;

  @Output() clicked: EventEmitter<void>;

  constructor() {
    this.clicked = new EventEmitter();

    this.link = '../';
  }

  public linkClicked(): void {
    this.clicked.emit();
  }
}
