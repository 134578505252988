import { Order, OrderSummary } from '@shared/order';
import { Service } from '@shared/service';
import { FormGroupState } from 'ngrx-forms';
import { BaseServicePageForm } from '../forms';
import { BaseOrderCreationStage } from '../models';

export class BaseServicePageState<
  TForm extends BaseServicePageForm = BaseServicePageForm,
  TStage extends BaseOrderCreationStage = BaseOrderCreationStage
> {
  public isCalculating: boolean;
  public services: Array<Service>;
  public summary: OrderSummary;
  public formState: FormGroupState<TForm>;
  public order: Partial<Order>;
  public stage: TStage;

  constructor(formState: FormGroupState<TForm>) {
    this.isCalculating = false;
    this.services = [];
    this.summary = null;
    this.formState = formState;
    this.order = {};
  }
}
