import { NgModule } from '@angular/core';
import { PublicNotFoundPageComponent } from './not-found.component';
import { PublicNotFoundPageRoutingModule } from './not-found.routing';
import { ButtonComponent } from '@shared/button';
import { IconModule } from '@shared/icon';
import { InviteFriendsComponent } from '@shared/invite-friends';
import { PublicNotFoundUfoComponent } from './shared/components/ufo/ufo.component';
import { PublicNotFoundStarsComponent } from './shared/components/stars/stars.component';
import { PageModule } from '@shared/page';

@NgModule({
  declarations: [
    PublicNotFoundPageComponent,
    PublicNotFoundUfoComponent,
    PublicNotFoundStarsComponent
  ],
  imports: [
    PageModule,
    PublicNotFoundPageRoutingModule,
    ButtonComponent,
    IconModule,
    InviteFriendsComponent
  ],
  exports: [
    PublicNotFoundStarsComponent,
    PublicNotFoundUfoComponent
  ],
  providers: []
})
export class PublicNotFoundPageModule { }
