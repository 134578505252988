import {Directive, Inject, inject, OnInit, PLATFORM_ID} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Order, OrderSummary } from '@shared/order';
import { Service } from '@shared/service';
import { Observable, tap } from 'rxjs';
import { BaseServicePageFacade } from './base-service-page.facade';
import { BaseServicePageState } from './store';
import { isPlatformBrowser } from "@angular/common";
import {ItemForm, ServiceItem} from '@shared/item';

@UntilDestroy()
@Directive()
export class BaseServicePageDirective<State extends BaseServicePageState> implements OnInit {
  public service$: Observable<Service>;
  public services$: Observable<Array<Service>>;
  public order$: Observable<Order>;
  public summary$: Observable<OrderSummary>;
  public isCalculating$: Observable<boolean>;
  public formState$: Observable<State['formState']>;
  public stage$: Observable<State['stage']>;
  public canGoNext$: Observable<boolean>;
  public canSkipItemSelection$: Observable<boolean>;

  protected facade: BaseServicePageFacade<State>;

  constructor(  @Inject(PLATFORM_ID) protected platformID?: object) {
    this.facade = inject(BaseServicePageFacade);
    this.service$ = this.facade.service$;
    this.services$ = this.facade.services$;
    this.order$ = this.facade.order$;
    this.summary$ = this.facade.summary$;
    this.isCalculating$ = this.facade.isCalculating$;
    this.formState$ = this.facade.formState$;
    this.stage$ = this.facade.stage$;
    this.canGoNext$ = this.facade.canGoNext$;
    this.canSkipItemSelection$ = this.facade.canSkipItemSelection$;
  }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
    this.stage$.pipe(
      untilDestroyed(this),
      tap(() => window.scrollTo(0, 0))
    ).subscribe(item=>{

        if (item.name === "checkout" || item.name === "placed") {
          window?.dataLayer?.push({
            "event": item.name === "checkout" ? 'Checkout' : 'Order_Confirmation',
            "eventButton": "page-" + (item.name === "placed" ? "order-confirmation" : item.name),
            "services-component": item.name === "placed" ? "order-confirmation" : item.name,
          });
        }
    });
    }
  }

  public removeItemsButtonClicked(items?: ServiceItem[]): void {
    this.facade.removeItems();
  }
  public changeItemsButtonClicked(items?: Array<ItemForm>): void {
    this.facade.changeItems(items);
  }
}
