import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { PaymentMethod } from '@shared/payment-method';

export class UserPaymentMethodActions {
  public static delete = createAction(
    '[UserPaymentMethod] Delete',
    props<{
      method: PaymentMethod;
      onSuccess?: () => void;
    }>()
  );

  public static deleteSuccess = createAction(
    '[UserPaymentMethod] Delete Success'
  );

  public static deleteFailure = createAction(
    '[UserPaymentMethod] Delete Failure',
    props<{ response: HttpErrorResponse }>()
  );

  public static select = createAction(
    '[UserPaymentMethod] Select',
    props<{
      method: PaymentMethod;
      onSuccess?: (method: PaymentMethod) => void;
    }>()
  );

  public static selectSuccess = createAction(
    '[UserPaymentMethod] Select Success'
  );

  public static selectFailure = createAction(
    '[UserPaymentMethod] Select Failure',
    props<{ response: HttpErrorResponse }>()
  );

  public static create = createAction(
    '[UserPaymentMethod] Create',
    props<{
      request: Partial<PaymentMethod>;
      onSuccess?: (method: PaymentMethod) => void;
    }>()
  );

  public static createSuccess = createAction(
    '[UserPaymentMethod] Create Success'
  );

  public static createFailure = createAction(
    '[UserPaymentMethod] Create Failure',
    props<{ response: HttpErrorResponse }>()
  );

  public static edit = createAction(
    '[UserPaymentMethod] Edit',
    props<{
      id:number,
      request: Partial<PaymentMethod>;
      dialogId:string;
      onSuccess?: (method: PaymentMethod) => void;
    }>()
  );

  public static editSuccess = createAction(
    '[UserPaymentMethod] Edit Success'
  );

  public static editFailure = createAction(
    '[UserPaymentMethod] Edit Failure',
    props<{ response: HttpErrorResponse }>()
  );
}
