import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { RouterLinkActive, RouterLink, Router } from '@angular/router';
import { LinkColor, LinkUnderline } from './types';

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgTemplateOutlet,
    RouterLink,
    RouterLinkActive
  ],
  selector: 'app-link',
  templateUrl: 'link.html',
  styleUrls: ['link.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent implements OnChanges {
  @Input() routerLink: string;
  @Input() isExact: boolean;
  @Input() color: LinkColor;
  @Input() isBlock: boolean;
  @Input() underline: LinkUnderline;
  @Input() useInheritedFont: boolean;
  @Input() inheritFont: boolean;
  @Input() href: string;
  @Input() target: '_self' | '_blank';
  @Input() unLink: boolean=false;
  public classes: Array<string>;
  public activeClasses: Array<string>;

  constructor(public router: Router) {
    this.isExact = true;
    this.target = '_self';
  }

  public ngOnChanges(): void {
    this.classes = [
      'link',
      this.color ? 'link-' + this.color : '',
      this.isBlock ? 'link-block' : '',
      this.underline === 'always' ? 'link-underline' : '',
      this.useInheritedFont ? 'link-inherit' : '',
      this.inheritFont ? 'link-inherit' : '',
      this.unLink ? 'link-unlink' : '',
    ];

    this.activeClasses = [
      'current',
      this.underline === 'current' ? 'link-underline' : ''
    ];
  }

  public navigate(link: string) {
    if(link && this.target!='_blank' && this.router.url===link){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
          this.router.navigate([link]));
    }

}
}
