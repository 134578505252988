import { BaseEntity } from '@shared/base-entity';
import { Expose } from 'class-transformer';

export class DeliveryPrice extends BaseEntity {
  @Expose()
  public from: number;

  @Expose()
  public to: number;

  @Expose()
  public price: number;

  @Expose({ name: 'laundry_id' })
  public laundryID: number;

  constructor(model: Partial<DeliveryPrice>) {
    super(model);
    Object.assign(this, model);
  }
}
