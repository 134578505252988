export const MIN_PASSWORD_LENGTH = 6;

export function passwordValidator(value: string): object {
  let validationResult: object = {};

  if (!value || value.length < MIN_PASSWORD_LENGTH) {
    validationResult = { password: { minLength: true } };
  }

  return validationResult;
}
