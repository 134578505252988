import { Action, createReducer, on } from '@ngrx/store';
import { UserActions } from '../actions';
import { UserPaymentMethodActions } from './actions';
import { UserPaymentMethodState } from './state';

const initialState = new UserPaymentMethodState();

const reducer = createReducer(
  initialState,
  on(UserActions.resetState, () => initialState),
  on(UserPaymentMethodActions.delete, (state) => ({
    ...state,
    isDeleting: true
  })),
  on(
    UserPaymentMethodActions.deleteSuccess,
    UserPaymentMethodActions.deleteFailure,
    (state) => ({
      ...state,
      isDeleting: false
    })
  ),
  on(UserPaymentMethodActions.select, (state) => ({
    ...state,
    isSelecting: true
  })),
  on(
    UserPaymentMethodActions.selectSuccess,
    UserPaymentMethodActions.selectFailure,
    (state) => ({
      ...state,
      isSelecting: false
    })
  ),
  on(UserPaymentMethodActions.create, (state) => ({
    ...state,
    isCreating: true
  })),
  on(
    UserPaymentMethodActions.createSuccess,
    UserPaymentMethodActions.createFailure,
    (state) => ({
      ...state,
      isCreating: false
    })
  ),
  on(UserPaymentMethodActions.edit, (state) => ({
    ...state,
    isEditing: true
  })),
  on(
    UserPaymentMethodActions.editSuccess,
    UserPaymentMethodActions.editFailure,
    (state) => ({
      ...state,
      isEditing: false
    })
  )
);

export const userPaymentMethodReducer = (
  state: UserPaymentMethodState | undefined,
  action: Action
): UserPaymentMethodState => reducer(state, action);
