import type { AppState } from '@shared/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from, Observable, of, timer } from 'rxjs';
import { AuthActions } from './actions';
import { catchError, exhaustMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { NotificationService } from '@shared/notification';
import { FirebaseError } from '@angular/fire/app';
import { AuthErrorCodes } from '@angular/fire/auth';
import { isEmpty } from 'lodash';
import { ConnectionType } from '../enums';
import { SocialAuthData } from '@shared/firebase-auth/models';
import { ErrorHandlingService } from '@shared/error-handling';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { PublicRegisterFormFacade } from '@app/public/shared/register-form/register-form.facade';
import { Connection } from '@shared/auth';

@Injectable()
export class AuthEffects {
  public init$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      withLatestFrom(this.authService.isAuthenticated$),
      filter(([_, isAuthenticated]) => isAuthenticated),
      map(() => AuthActions.authorized())
    )
  );

  public signInSocial$: Observable<SocialAuthData> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signInSocial),
      exhaustMap((action) => (action.retryCount)
        ? from(this.authService.signOutSocialMedia())
          .pipe(map(() => action))
        : of(action)
      ),
      exhaustMap(({ connection, onSuccess, onFailure, retryCount, isLogin }) => this.authService
        .signInSocialMedia(connection)
        .pipe(
          tap((data) => {
            if (!!isLogin) {
              timer(1000).subscribe(() => {
                this.publicRegisterFormFacade.prefillRegistrationData({user: data, connection: new Connection({ type: connection, token: data.token, email: data.email})})
              })
            }
            return this.handleSocialSignIn({
              connection,
              data,
              retryCount,
              onFailure,
              onSuccess
            })
          }),
          catchError((error) => {
            if (!(error instanceof FirebaseError)) {
              this.notificationService.error('SHARED.AUTH.TEXT_SIGN_IN_SOCIAL_FAILURE');
              onFailure?.(error);

              return of(error);
            }

            const ignoredCodes: Array<string> = [AuthErrorCodes.POPUP_CLOSED_BY_USER];
            if (!ignoredCodes.includes(error.code)) {
              onFailure?.(error);
            }

            return of(error);
          })
        )
      )
    ),
    { dispatch: false }
  );

  public logout$: Observable<void> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() => this.progressRef.start()),
      exhaustMap(() => this.authService
        .logout()
        .pipe(
          tap(() => {
            this.progressRef.complete();
            this.authService.unauthorize();
            this.publicRegisterFormFacade.resetState();
          }),
          catchError((response) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'SHARED.AUTH.TEXT_LOGOUT_FAILURE'
            });
            this.progressRef.complete();

            return of(response);
          })
        )
      )
    ),
    { dispatch: false }
  );

  private progressRef: NgProgressRef;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private authService: AuthService,
    private notificationService: NotificationService,
    private publicRegisterFormFacade: PublicRegisterFormFacade,
    private errorHandlingService: ErrorHandlingService,
    progress: NgProgress
  ) {
    this.progressRef = progress.ref('router-progressbar');
  }

  private handleSocialSignIn({ connection, data, retryCount, onFailure, onSuccess }: {
    connection: ConnectionType;
    data: SocialAuthData;
    onSuccess?: (data: SocialAuthData) => void;
    onFailure?: (error: any) => void;
    retryCount?: number;
  }): void {
    if (!isEmpty(data.email)) {
      return onSuccess?.(data);
    }

    if (retryCount > 0) {
      return this.notificationService.error('SHARED.AUTH.TEXT_PERMISSIONS_NOT_GRANTED');
    }

    this.store.dispatch(AuthActions.signInSocial({
      connection,
      onFailure,
      onSuccess,
      retryCount: (retryCount || 0) + 1
    }));
  }
}
