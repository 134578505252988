import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnChanges, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BaseFormControlDirective } from '@shared/base-form-control';
import { IconModule } from '@shared/icon';
import { compact } from 'lodash';
import { SwitchOption } from './models';
import { SwitchVariant } from './types';

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    TranslateModule,
    IconModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ],
  selector: 'switch',
  templateUrl: 'switch.html',
  styleUrls: ['switch.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchComponent extends BaseFormControlDirective<string | number> implements OnChanges {
  @Input() options: Array<SwitchOption>;
  @Input() variant: SwitchVariant;
  @Input() isReadonly: boolean;

  @Output() optionSelected: EventEmitter<SwitchOption>;

  public classes: Array<string>;

  constructor() {
    super();
    this.variant = 'default';
    this.optionSelected = new EventEmitter<SwitchOption>();
  }

  public ngOnChanges(): void {
    this.classes = compact([
      'switch',
      `switch-${this.variant}`,
      this.isReadonly && 'switch-readonly'
    ]);
  }

  public optionItemClicked(option: SwitchOption): void {
    this.value = option.value;
    this.optionSelected.emit(option);
  }
}
