import { BaseEntity } from './base-entity';

export abstract class NamedEntity extends BaseEntity {
  public abstract get viewName(): string;

  constructor(model: Partial<NamedEntity> = {}) {
    super(model);
    Object.assign(this, model);
  }
}
