import type {AppState} from '@shared/store';
import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UserPaymentMethodActions} from './actions';
import {catchError, exhaustMap, map, tap, withLatestFrom} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {PaymentMethod, PaymentMethodService} from '@shared/payment-method';
import {ErrorHandlingService} from '@shared/error-handling';
import {User, UserService} from '@shared/user';
import {AppDialogService} from "@shared/dialog";

@Injectable()
export class UserPaymentMethodEffects {
  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPaymentMethodActions.delete),
      withLatestFrom(this.userService.profile$),
      exhaustMap(([{ method, onSuccess }, user]) => this.paymentMethodService
        .delete(method.id)
        .pipe(
          tap(() => {
            this.deleteUserPaymentMethod(user, method);
            onSuccess?.();
          }),
          map(() => UserPaymentMethodActions.deleteSuccess()),
          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'SHARED.USER.TEXT_DELETE_PAYMENT_METHOD_FAILURE'
            });

            return of(UserPaymentMethodActions.deleteFailure({ response }));
          })
        )
      )
    )
  );

  public select$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPaymentMethodActions.select),
      withLatestFrom(this.userService.profile$),
      exhaustMap(([{ method, onSuccess }, user]) => this.paymentMethodService
        .setActive(method.id)
        .pipe(
          map(() => new PaymentMethod({ ...method, isActive: true })),
          tap((selectedMethod) => {
            this.updateSelectedUserPaymentMethod(user, selectedMethod);
            onSuccess?.(selectedMethod);
          }),
          map(() => UserPaymentMethodActions.selectSuccess()),
          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'SHARED.USER.TEXT_SELECT_PAYMENT_METHOD_FAILURE'
            });

            return of(UserPaymentMethodActions.selectFailure({ response }));
          })
        )
      )
    )
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPaymentMethodActions.create),
      withLatestFrom(this.userService.profile$),
      exhaustMap(([{ request, onSuccess }, user]) => this.paymentMethodService
        .create(request)
        .pipe(
          tap((newMethod) => {

            this.addUserPaymentMethod(user, newMethod);
            onSuccess?.(newMethod);

          }),
          map(() => UserPaymentMethodActions.createSuccess()),
          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'SHARED.USER.TEXT_CREATE_PAYMENT_METHOD_FAILURE'
            });

            return of(UserPaymentMethodActions.createFailure({ response }));
          })
        )
      )
    )
  );
  public edit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPaymentMethodActions.edit),
      withLatestFrom(this.userService.profile$),
      exhaustMap(([{id, request, dialogId,onSuccess }, user]) => this.paymentMethodService
        .editZipCode(id,request)
        .pipe(
          tap((editMethod) => {
            this.privateEditUserPaymentMethod(user, editMethod);

            onSuccess?.(editMethod);
          }),
          tap(() => {
            if( this.dialogService.isOpened(dialogId)){
              this.dialogService.closeByID(dialogId)
            }
          }),
          map(() => UserPaymentMethodActions.editSuccess()),

          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'SHARED.USER.TEXT_EDIT_PAYMENT_METHOD_FAILURE'
            });

            return of(UserPaymentMethodActions.editFailure({ response }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private paymentMethodService: PaymentMethodService,
    private errorHandlingService: ErrorHandlingService,
    private dialogService: AppDialogService
  ) { }

  private deleteUserPaymentMethod(user: User, method: PaymentMethod): void {
    this.userService.setProfile(new User({
      ...user,
      paymentMethods: user.paymentMethods.filter((_method) => _method.id !== method.id)
    }));
  }

  private updateSelectedUserPaymentMethod(user: User, selectedMethod: PaymentMethod): void {
    this.userService.setProfile(new User({
      ...user,
      paymentMethods: user.paymentMethods.map((userMethod) => (userMethod.id === selectedMethod.id)
        ? selectedMethod
        : (userMethod.isActive) ? new PaymentMethod({ ...userMethod, isActive: false }) : userMethod
      )
    }));
  }

  private addUserPaymentMethod(user: User, newMethod: PaymentMethod): void {

    const duplicate = user?.paymentMethods.filter(item => item.id === newMethod.id)
    if(duplicate?.length>0){
      const paymentMethods = (newMethod.isActive)
        ? user.paymentMethods.map((method) => (  method.id!==newMethod.id) ?
          new PaymentMethod({...method, isActive: false}) :
          new PaymentMethod({...method, isActive: true})
        )
        : user.paymentMethods;
      this.userService.setProfile(new User({...user, paymentMethods: [...paymentMethods]}));

    }else {
      const paymentMethods = (newMethod.isActive)
        ? user.paymentMethods.map((method) => (method.isActive) ? new PaymentMethod({
          ...method,
          isActive: false
        }) : method)
        : user.paymentMethods;
      this.userService.setProfile(new User({...user, paymentMethods: [...paymentMethods, newMethod]}));
    }
  }

  privateEditUserPaymentMethod(user: User, editMethod: PaymentMethod): void {
    this.userService.setProfile(new User({
      ...user,
      paymentMethods: user.paymentMethods.map((userMethod) => (userMethod.id === editMethod.id)
        ? editMethod
        : (userMethod.isActive) ? new PaymentMethod({ ...userMethod, isActive: false }) : userMethod
      )
    }));
  }
}
