import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: true,
  selector: 'radio',
  templateUrl: 'radio.html',
  styleUrls: ['radio.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent {
  @Input() name: string;
  @Input() isSelected: boolean;
  @Input() isCentered: boolean;
  @Input() canUnselect: boolean;
  @Input() isDisabled: boolean;

  @Output() radioChanged: EventEmitter<boolean>;

  constructor() {
    this.radioChanged = new EventEmitter<boolean>();
  }

  public labelClicked(event: MouseEvent, radio: HTMLInputElement): void {
    if (this.isDisabled) {
      return;
    }

    if (!this.canUnselect && radio.checked) {
      return;
    }

    event.preventDefault();
    radio.checked = !radio.checked;
    this.radioChanged.emit(radio.checked);
  }
}
