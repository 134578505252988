<dialog-view *ngVar="'SHARED.GEOLOCATION_VIEW.ZIP_PICKER.' as translations" variant="small" class="zip-picker">
  <dialog-header variant="underline">
    <dialog-content size="smaller">
      {{ translations + 'TEXT_TITLE' | translate }}
      <dialog-close [isLoading]="isLoading" (clicked)="close()"></dialog-close>
    </dialog-content>
  </dialog-header>
  <dialog-body *ngIf="formState" class="zip-picker-body">
    <dialog-content size="smaller">
      <div class="row">
        <div class="col col-stretch">
          <form-group [formControlState]="formState.controls.zipCode">
            <form-control [ngrxFormControlState]="formState.controls.zipCode" mask="0*"
              [placeholder]="translations + 'TEXT_ZIP_PLACEHOLDER' | translate"
              (ngrxFormsAction)="handleFormAction($event)">
            </form-control>
          </form-group>
        </div>
        <div class="col col-content">
          <geolocation-button (geolocated)="geolocated($event)"></geolocation-button>
        </div>
      </div>
      <div class="row">
        <div class="col col-50">
          <app-button [isDisabled]="isLoading" variant="primary" [isOutline]="true" [isBlock]="true"
            (clicked)="!isLoading && close()">
            {{ translations + 'BUTTON_CANCEL' | translate }}
          </app-button>
        </div>
        <div class="col col-50">
          <app-button variant="primary" [isBlock]="true" [isLoading]="isLoading"
            (clicked)="!isLoading && saveButtonClicked()" testID="zip-save">
            {{ translations + 'BUTTON_SAVE' | translate }}
          </app-button>
        </div>
      </div>
    </dialog-content>
  </dialog-body>
</dialog-view>
