import { Expose } from 'class-transformer';

export class CreateMediaRequest {
  @Expose()
  public file: File;

  @Expose({ name: 'isPublic' })
  public isPublic: boolean;

  constructor(model: Partial<CreateMediaRequest>) {
    Object.assign(this, model);
  }
}
