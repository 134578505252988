import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { DeviceInfoType } from '@shared/device-info';
import { WebviewService } from '../webview.service';

@Injectable()
export class WebviewGuard implements CanActivate {
  constructor(
    private webviewService: WebviewService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    if (route.queryParamMap.has('webview')) {
      this.webviewService.initWebview({
        token: route.queryParamMap.get('webviewToken'),
        deviceID: route.queryParamMap.get('webviewDeviceID'),
        type: DeviceInfoType.ANDROID
      });
    }

    return true;
  }
}
