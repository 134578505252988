import { UserAddressState } from './address';
import { UserPaymentMethodState } from './payment-method';
import { UserRootState } from './root';
import { UserZipState } from './zip';

export class UserState {
  public root: UserRootState;
  public addressState: UserAddressState;
  public paymentMethodState: UserPaymentMethodState;
  public zipState: UserZipState;

  constructor() {
    this.root = new UserRootState();
    this.addressState = new UserAddressState();
    this.paymentMethodState = new UserPaymentMethodState();
    this.zipState = new UserZipState();
  }
}
