import {AsyncPipe, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IconModule} from '@shared/icon';
import {Location} from '@shared/location';
import {MapPipe} from '@shared/map';
import {NgVariableDirective} from '@shared/ng-variable';
import {Zip} from '@shared/zip';
import {TooltipModule} from 'ng2-tooltip-directive';
import {LocationMismatchFacade} from './location-mismatch.facade';
import {Observable, BehaviorSubject} from 'rxjs';

@Component({
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    TranslateModule,
    IconModule,
    TooltipModule,
    NgVariableDirective,
    MapPipe
  ],
  providers: [
    LocationMismatchFacade
  ],
  selector: 'location-mismatch',
  templateUrl: 'location-mismatch.html',
  styleUrls: ['location-mismatch.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationMismatchComponent {
  @Input() location: Location;

  public userZip$: Observable<Zip>;
  public geolocationZip$: BehaviorSubject<any> = new BehaviorSubject<any>('');

  constructor(
    private facade: LocationMismatchFacade
  ) {
    this.userZip$ = this.facade.userZip$;
    this.facade.geolocate((place) => this.geolocationZip$.next(place.zipCode));

  }

}
