import { Component, ChangeDetectionStrategy, Input, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { ItemForm, ServiceItem } from '@shared/item';
import { ItemCategory } from '@shared/item-category';
import { Service } from '@shared/service';
import { Boxed, FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { OrderItemsFiltersForm } from './forms';
import { OrderItemsFacade } from './order-items.facade';
import { BaseFormControlDirective } from '@shared/base-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'order-items',
  templateUrl: 'order-items.html',
  styleUrls: ['order-items.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    OrderItemsFacade,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderItemsComponent),
      multi: true
    }
  ]
})
export class OrderItemsComponent extends BaseFormControlDirective<Array<ItemForm>, Boxed<Array<ItemForm>>> implements OnInit {
  @Input() public set service(service: Service) {
    this._service = service;
    if (this.isInitialized) {
      this.facade.init(service);
    }
  }

  @Output() deleteItemFromCard: EventEmitter<string> = new EventEmitter<string>()
  @Output() addItemToCard: EventEmitter<ItemForm[]> = new EventEmitter<ItemForm[]>()

  public get service(): Service {
    return this._service;
  }

  public filtersForm$: Observable<FormGroupState<OrderItemsFiltersForm>>;
  public categories$: Observable<Array<ItemCategory>>;
  public isLoading$: Observable<boolean>;
  public openedCategoriesIDs$: Observable<Array<number>>;

  private _service: Service;

  constructor(private facade: OrderItemsFacade) {
    super();
    this.filtersForm$ = this.facade.filtersForm$;
    this.categories$ = this.facade.categories$;
    this.isLoading$ = this.facade.isLoading$;
    this.openedCategoriesIDs$ = this.facade.openedCategoriesIDs$;
  }

  public override ngOnInit(): void {
    this.facade.init(this.service);
    super.ngOnInit();
  }

  public increaseItemCountButtonClicked(item: ServiceItem): void {
    this.value = this.facade.addItemInArray(item, this.value);
    this.addItemToCard.emit(this.value)
  }

  public decreaseItemCountButtonClicked(item: ServiceItem): void {
    this.value = this.facade.removeItemFromArray(item, this.value);
    const isItemDeleted = this.value.find((searchedItem) => searchedItem.id === item.id)
    if (!isItemDeleted) {
      this.deleteItemFromCard.emit(item.title)
    }
  }

  public contactLinkClicked(): void {
    this.facade.openIntercom();
  }

  public categoryOpened(categoryID: number): void {
    this.facade.openCategory(categoryID);
  }

  public categoryClosed(categoryID: number): void {
    this.facade.closeCategory(categoryID);
  }
}
