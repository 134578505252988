import {BaseEntity} from '@shared/base-entity';
import {startCase} from 'lodash';
import {PaymentMethodType} from '../enums';
import {Expose} from 'class-transformer';
import {PaymentSystem, paymentSystemsData} from '@shared/payment';

export class PaymentMethod extends BaseEntity {
  @Expose({toPlainOnly: true})
  public cvc: string;

  @Expose({toPlainOnly: true})
  public number: string;

  @Expose()
  public brand: string;

  @Expose({name:'zip_code'})
  public zipCode: string;

  @Expose({name: 'client_id'})
  public clientID: number;

  @Expose()
  public country: string;

  @Expose({name: 'exp_month'})
  public expMonth: string;

  @Expose({name: 'exp_year'})
  public expYear: string;

  @Expose()
  public last4: string;

  @Expose({name: 'stripe_id'})
  public stripeID: string;

  @Expose()
  public type: PaymentMethodType;

  @Expose({name: 'is_active'})
  public isActive: boolean;

  @Expose({name: 'is_google_pay'})
  public isGooglePay: boolean;

  @Expose({name: 'is_apple_pay'})
  public isApplePay: boolean;

  public get viewBrand(): string {
    return paymentSystemsData[this.paymentSystem]?.name || startCase(this.brand);
  }

  public get name(): string {
    return this.viewBrand ? `${this.viewBrand}${this.last4 ? '\xA0••••\xA0' + this.last4 : ''}` : '';
  }

  public get isPlain(): boolean {
    return !this.paymentSystem;
  }

  public get paymentSystem(): PaymentSystem | null {
    if (this.isApplePay) {
      return PaymentSystem.APPLE_PAY;
    }
    if (this.isGooglePay) {
      return PaymentSystem.GOOGLE_PAY;
    }

    return null;
  }

  constructor(model: Partial<PaymentMethod>) {
    super(model);
    Object.assign(this, model);
  }
}
