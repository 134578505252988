import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceInfoType } from '@shared/device-info';
import { WebviewService } from '@shared/webview';
import isMobile from 'is-mobile';
import { Observable } from 'rxjs';

@Injectable()
export class DeviceTypeInterceptor implements HttpInterceptor {
  constructor(
    private webviewService: WebviewService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req.clone({
        setHeaders: {
          'device-type': this.webviewService.deviceInfo?.type ||
            (isMobile({ tablet: true }) ? DeviceInfoType.MOBILE_WEB : DeviceInfoType.WEB)
        }
      }));
  }
}
