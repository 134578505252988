import { PaginationRequest } from '@shared/pagination';
import { Expose } from 'class-transformer';
import { LocationType } from '../enums';
import { LocationRelation } from '../types';

export class LocationPaginationRequest extends PaginationRequest<LocationRelation> {
  @Expose()
  public type: LocationType;

  @Expose({ name: 'parent_id' })
  public parentID: number;

  constructor(model: Partial<LocationPaginationRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
