import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { WebviewService } from '@shared/webview/webview.service';

@Directive({
  standalone: true,
  selector: '[webviewHidden]'
})
export class WebviewHiddenDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private webviewService: WebviewService
  ) {
    if (!this.webviewService.isWebview) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
