
import { NamedEntity } from '@shared/base-entity';
import { PreferenceOption } from '@shared/preference';
import { Expose, Type } from 'class-transformer';

export class ServicePreferenceOption extends NamedEntity {
  @Expose()
  public price: number;

  @Expose({ name: 'preference_option_id' })
  public preferenceOptionID: number;

  @Expose({ name: 'preference_option' })
  @Type(() => PreferenceOption)
  public preferenceOption?: PreferenceOption;

  @Expose({ name: 'service_preference_id' })
  public servicePreferenceID: number;

  @Expose()
  public get fullName(): string {
    if (!this.preferenceOption) {
      return this._fullName || '';
    }

    return this.preferenceOption.fullName;
  }

  public set fullName(fullName: string | null) {
    this._fullName = fullName || '';
  }

  public get viewName(): string {
    return this.preferenceOption?.viewName || '';
  }

  public get title(): string {
    return this.preferenceOption?.title || '';
  }

  private _fullName: string;

  constructor(model: Partial<ServicePreferenceOption>) {
    super(model);

    Object.assign(this, model);
  }
}
