import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { BaseServicePageActions } from './actions';
import { BaseServicePageStateName } from '../types';
import { BaseServicePageState } from './state';
import { box, onNgrxForms, setValue, updateGroup } from 'ngrx-forms';
import { BaseServicePageForm } from '../forms';

export function baseServicePageReducerEvents<State extends BaseServicePageState = BaseServicePageState>(
  stateName: BaseServicePageStateName
): Array<ReducerTypes<State, Array<ActionCreator>>> {
  const actions = new BaseServicePageActions(stateName);

  return [
    on(actions.calculateOrder, (state) => ({
      ...state,
      isCalculating: true
    })),
    on(actions.calculateOrderSuccess, (state, { summary }) => ({
      ...state,
      isCalculating: false,
      summary
    })),
    on(actions.calculateOrderFailure, (state) => ({
      ...state,
      isCalculating: false
    })),
    on(actions.removeItems, (state) => ({
      ...state,
      formState: updateGroup<BaseServicePageForm>(state.formState, {
        items: setValue(box([]))
      })
    })),
    on(actions.changeItems, (state,{items}) => ({
      ...state,
      formState: updateGroup<BaseServicePageForm>(state.formState, {
        items: setValue(box([...items]))
      })
    })),
    on(actions.setStage, (state, { stageName }) => ({
      ...state,
      stage: state.stage.fromStageName(stageName)
    })),
    onNgrxForms()
  ];
}
