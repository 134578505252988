<dialog-view *ngVar="'PRIVATE.SHARED.PROMOCODE_FIELD.COMPONENTS.APPLIED_MODAL.' as translations" variant="small"
  class="applied">
  <dialog-body class="applied-body">
    <dialog-content size="small">
      <img ngSrc="/assets/images/relax-drawing369x280.svg" width="369" height="280" class="applied-image" alt="promo"/>
      <h3 class="applied-title">
        {{ translations + 'TEXT_PROMOCODE_APPLIED' | translate: { code: data.code } }}
      </h3>
      <h3 class="applied-title">
        {{ translations + 'TEXT_DISCOUNT' | translate: { discount: (data | promocodeDiscount) } }}
      </h3>
      <dialog-buttons class="row">
        <div class="col col-stretch">
          <app-button variant="primary" [isBlock]="true" (clicked)="close()">
            {{ translations + 'BUTTON_OK' | translate }}
          </app-button>
        </div>
      </dialog-buttons>
    </dialog-content>
  </dialog-body>
</dialog-view>
