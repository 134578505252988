import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavbarComponent } from './navbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgVariableDirective } from '@shared/ng-variable';
import { LogoComponent } from '@shared/logo';
import { LinkComponent } from '@shared/link';
import { AvatarComponent } from '@shared/avatar';
import { ButtonComponent } from '@shared/button';
import { NavbarFacade } from './navbar.facade';
import { ItemsCountPipe } from '@shared/item/pipes';
import { IconModule } from '@shared/icon';
import { GeolocationViewModule } from '@shared/geolocation-view';
import { RouterModule } from '@angular/router';
import { ContentComponent } from '@shared/content';

@NgModule({
  declarations: [
    NavbarComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgVariableDirective,
    LogoComponent,
    LinkComponent,
    AvatarComponent,
    ButtonComponent,
    ItemsCountPipe,
    IconModule,
    GeolocationViewModule,
    RouterModule,
    ContentComponent
  ],
  providers: [NavbarFacade],
  exports: [
    NavbarComponent
  ]
})
export class NavbarModule { }
