import { isNil } from 'lodash';

export class LocalStorageStringItem {
  constructor(private key: string) { }

  public set(value: string): void {
    if (isNil(value)) {
      this.remove();
    } else {
      localStorage.setItem(this.key, value);
    }
  }

  public get(): string | null {
    return localStorage.getItem(this.key);
  }

  public remove(): void {
    localStorage.removeItem(this.key);
  }
}
