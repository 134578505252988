import { SeoPageHeadingTags, SeoPageTag } from '@shared/seo-page';
import { SeoPageHtmlTags } from '@shared/seo-page/models/html-tags';
import { Transform } from 'class-transformer';
import { isArray } from 'lodash';

export function TransformPageTags(): (target: any, key: string) => void {
  const toClass = Transform(({ value }) => {
    if (!isArray(value) || !(value[0] instanceof SeoPageTag)) {
      return new SeoPageHtmlTags({});
    }

    const pageTags: Partial<SeoPageHtmlTags> = {};
    const tags = value as Array<SeoPageTag>;
    for (const tag of tags) {
      const [_, name, order] = tag.name.match(/^(heading|description)_([1-9]|1[0-4])$/) || [];
      if (['heading', 'description'].includes(name) && order) {
        pageTags[`heading${order}`] = pageTags[`heading${order}`] || new SeoPageHeadingTags({});
        const heading: SeoPageHeadingTags = pageTags[`heading${order}`];
        if (name === 'heading') {
          heading.title = tag.content;
        }
        if (name === 'description') {
          heading.description = tag.content;
        }
      }
    }

    return new SeoPageHtmlTags(pageTags);
  },
    { toClassOnly: true }
  );

  return (target, key): void => {
    toClass(target, key);
  };
}
