import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { configuration } from '@configurations';
import { plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';
import { DirectionsResponse } from './models';

@Injectable()
export class MapApiService {
  private httpClient: HttpClient;

  constructor(
    private handler: HttpBackend
  ) {
    this.httpClient = new HttpClient(this.handler);
  }

  public getDirections(coordinates: Array<{ lng: number; lat: number }>): Observable<DirectionsResponse> {
    const stringCoordinates = coordinates.map((coordinate) => [coordinate.lng, coordinate.lat]).join(';');

    return this.httpClient.get(`https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${stringCoordinates}`, {
      params: {
        access_token: configuration.mapboxToken,
        overview: 'full',
        waypoints: `0;${coordinates.length - 1}`
      }
    }).pipe(
      map((response) => plainToInstance(DirectionsResponse, response))
    );
  }
  public getAddress(zipcode): Observable<any> {

    return this.httpClient.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${zipcode}.json`, {
      params: {
        access_token: configuration.mapboxToken,
        types: 'postcode',
        country:"US"
      }
    })
    //   .pipe(
    //   map((response) => plainToInstance(DirectionsResponse, response))
    // );
  }

}
