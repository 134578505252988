import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationModule } from '@shared/notification';
import { ClipboardService } from './clipboard.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NotificationModule
  ],
  providers: [ClipboardService]
})
export class ClipboardModule { }
