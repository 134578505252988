import { NamedEntity } from '@shared/base-entity';
import { Expose, Type } from 'class-transformer';
import { Preference } from './preference';

export class PreferenceOption extends NamedEntity {
  @Expose()
  public title: string;

  @Expose()
  public price: number;

  @Expose({ name: 'preference_id' })
  public preferenceID: number;

  @Expose()
  @Type(() => Preference)
  public preference: Preference;

  public get viewName(): string {
    return this.title;
  }

  public get fullName(): string {
    if (!this.preference) {
      return this.viewName;
    }

    return `${this.preference.viewName}: ${this.viewName.toLowerCase()}`;
  }

  constructor(model: Partial<PreferenceOption>) {
    super(model);

    Object.assign(this, model);
  }
}
