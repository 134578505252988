import { PaginationRequest } from '@shared/pagination';
import { Expose } from 'class-transformer';
import { ServiceItemRelation } from '../types';

export class ServiceItemsPaginationRequest extends PaginationRequest<ServiceItemRelation, null, string> {
  @Expose({ name: 'service_id' })
  public serviceID: number;

  @Expose({ name: 'laundry_id' })
  public laundryID: number;

  @Expose({ name: 'category_id' })
  public categoryID?: number;

  constructor(model: Partial<ServiceItemsPaginationRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
