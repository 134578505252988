import { configuration } from '@configurations';
import { DateTime } from 'luxon';
import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import { PhoneVerificationForm } from '../forms';
import { PhoneVerificationData } from '../models';

export class PhoneVerificationState {
  public isStarting: boolean;
  public lastCodeRequestTime: DateTime;
  public data: PhoneVerificationData;
  public checkAttempts: number;
  public isChecking: boolean;
  public formState: FormGroupState<PhoneVerificationForm>;

  constructor() {
    this.isStarting = false;
    this.lastCodeRequestTime = null;
    this.data = null;
    this.checkAttempts = configuration.verificationCodeCheckAttempts;
    this.isChecking = false;
    this.formState = createFormGroupState<PhoneVerificationForm>('PhoneVerificationForm', new PhoneVerificationForm());
  }
}
