import { Expose, Type } from 'class-transformer';
import { TransformOrderDiscount } from '../utils';
import { OrderSummaryDetails } from './order-summary-details';
import { OrderSummaryDiscount } from './order-summary-discount';
import { OrderSummaryFees } from './order-summary-fees';

export class OrderSummary {
  @Expose()
  public total: number;

  @Expose({ name: 'sub_total' })
  public subTotal: number;

  @Expose()
  public tip: number;

  @Expose()
  @TransformOrderDiscount
  @Type(() => OrderSummaryDiscount)
  public discount: OrderSummaryDiscount;

  @Expose()
  @Type(() => OrderSummaryDetails)
  public details: OrderSummaryDetails;

  @Expose()
  @Type(() => OrderSummaryFees)
  public fees: OrderSummaryFees;

  public get poundPrice(): number {
    return this.details?.poundsCount > 0 ? this.details?.pounds / this.details.poundsCount : 0;
  }

  constructor(model: Partial<OrderSummary>) {
    Object.assign(this, model);
  }
}
