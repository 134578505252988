import { CurrencyPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconType, IconColor, IconBackgroundVariant, IconModule } from '@shared/icon';
import { isNil } from 'lodash';
import { InfoSize, InfoVariant } from './types';

@Component({
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgTemplateOutlet,
    CurrencyPipe,
    IconModule
  ],
  selector: 'info-field',
  templateUrl: 'info-field.html',
  styleUrls: ['info-field.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoFieldComponent {
  @Input() name: string;
  @Input() title: string;
  @Input() info: string;
  @Input() cost: string | number;
  @Input() iconType: IconType;
  @Input() iconColor: IconColor;
  @Input() iconBackground: IconBackgroundVariant;
  @Input() underline: boolean;
  @Input() infoSize: InfoSize;
  @Input() isLoading: boolean;
  @Input() variant: InfoVariant;
  @Input() isClickable: boolean;
  @Input() isSideCentered: boolean;

  @Output() clicked: EventEmitter<void>;

  public get hasCost(): boolean {
    return !isNil(this.cost);
  }

  constructor() {
    this.underline = true;
    this.infoSize = 'default';
    this.clicked = new EventEmitter<void>();
    this.variant = 'default';
  }

  public fieldClicked(): void {
    this.clicked.emit();
  }
}
