import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhoneVerificationService } from './phone-verification.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CountdownModule } from 'ngx-countdown';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/button';
import { FormControlComponent } from '@shared/form-control';
import { FormGroupComponent } from '@shared/form-group';
import { NgVariableDirective } from '@shared/ng-variable';
import { NgrxFormsModule } from 'ngrx-forms';
import { PhoneVerificationEffects, phoneVerificationReducer } from './store';
import { PhoneVerificationModalComponent } from './components';
import { IconModule } from '@shared/icon';
import { DialogViewModule } from '@shared/dialog-view';

@NgModule({
  declarations: [PhoneVerificationModalComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature('phoneVerification', phoneVerificationReducer),
    EffectsModule.forFeature([PhoneVerificationEffects]),
    CountdownModule,
    TranslateModule,
    NgVariableDirective,
    FormGroupComponent,
    FormControlComponent,
    ButtonComponent,
    NgrxFormsModule,
    IconModule,
    DialogViewModule
  ],
  providers: [PhoneVerificationService]
})
export class PhoneVerificationModule { }

