import type { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { UserPaymentMethodState } from './state';

const selectFeature = (state: AppState): UserPaymentMethodState => state.user.paymentMethodState;

export class UserPaymentMethodSelectors {
  public static isCreating: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => state.isCreating
  );

  public static isDeleting: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => state.isDeleting
  );

  public static isSelecting: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => state.isSelecting
  );
  public static isEditing: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => state.isEditing
  );
}
