import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Address, AddressEntityRequest } from './models';

@Injectable()
export class AddressService {
  constructor(private apiService: ApiService) { }

  public create(address: Partial<Address>): Observable<Address> {
    const requestBody = new Address(address);

    return this.apiService.post<Address>(`/addresses`, instanceToPlain(requestBody))
      .pipe(
        map((response) => plainToInstance(Address, response))
      );
  }

  public update(address: Partial<Address>): Observable<void> {
    const requestBody = new Address(address);

    return this.apiService.put(`/addresses/${address.id}`, instanceToPlain(requestBody));
  }

  public upsert(address: Partial<Address>): Observable<Address | void> {
    return (address.id) ? this.update(address) : this.create(address);
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete(`/addresses/${id}`);
  }

  public get(id: number, request: Partial<AddressEntityRequest>): Observable<Address> {
    const requestParams = new AddressEntityRequest(request);

    return this.apiService.get(`/addresses/${id}`, instanceToPlain(requestParams))
      .pipe(
        map((response) => plainToInstance(Address, response))
      );
  }
}
