export class GeolocationViewState {
  public predictedZip: string;
  public isPredictingZip: boolean;
  public isInfoClosed: boolean;

  constructor() {
    this.predictedZip = '';
    this.isPredictingZip = false;
    this.isInfoClosed = false;
  }
}
