import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthModule as CommonAuthModule } from '@ronas-it/angular-common';
import { configuration } from '@configurations';
import { AuthenticatedGuard, UnauthenticatedGuard, UnauthenticatedMatcher } from './guards';
import { FirebaseAuthModule } from '@shared/firebase-auth';
import { AngularFireModule } from '@angular/fire/compat';

@NgModule({
  declarations: [],
  imports: [
    CommonAuthModule.forRoot({
      unauthorizedRoutes: configuration.api.disallowedRoutes,
      refreshTokenEndpoint: '/auth/refresh',
      refreshTokenEndpointMethod: 'get',
      authService: AuthService,
      unauthenticatedRoute: '/login',
      disableRedirectAfterUnauthorize: true,
      isAuthenticatedField: 'is_authenticated'
    }),
    FirebaseAuthModule,
    AngularFireModule.initializeApp(configuration.firebaseConfig),
  ],
  providers: [
    AuthService,
    AuthenticatedGuard,
    UnauthenticatedGuard,
    UnauthenticatedMatcher
  ]
})
export class AuthModule { }
