export enum OrderStatus {
  DONE = 1,
  NEW,
  RECEIVED,
  PICKING_UP,
  PICKED_UP,
  WASHING,
  DRYING,
  FOLDING,
  READY_TO_GO,
  DELIVERING,
  CANCELED,
  PENDING,
  PROCESSING,
  EN_ROUTE_PICKUP = 15,
  EN_ROUTE_DROPOFF = 16
}
