import { BaseEntity } from '@shared/base-entity';
import { Expose } from 'class-transformer';

export class Media extends BaseEntity {
  @Expose()
  public name: string;

  @Expose()
  public link: string;

  @Expose({ name: 'is_public' })
  public isPublic: string;

  @Expose({ name: 'owner_id' })
  public ownerID: string;

  constructor(model: Partial<Media>) {
    super(model);
    Object.assign(this, model);
  }
}
