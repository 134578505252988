import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions } from '@shared/auth/store';
import { IntercomActions } from '@shared/intercom/store/actions';
import type { AppState } from '@shared/store';
import {User, UserRootSelectors, UserService, UserZipSelectors} from '@shared/user';
import { WebviewService } from '@shared/webview';
import { compact } from 'lodash';
import { BehaviorSubject, map, Observable, of, Subject, switchMap } from 'rxjs';
import { MenuLink } from './models';
import {Location} from '@shared/location';

@Injectable()
export class MenuFacade {
  public isOpened$: Subject<boolean>;

  public get user$(): Observable<User> {
    return this.userService.profile$;
  }

  public get arePublicPromocodesAvailable$(): Observable<boolean> {
    return this.store.select(UserRootSelectors.arePublicPromocodesAvailable);
  }

  public get menuLinks$(): Observable<Array<MenuLink>> {
    return this.user$.pipe(
      switchMap((user) => (user) ? this.privateMenuLinks$ : this.publicMenuLinks$)
    );
  }

  private get publicMenuLinks$(): Observable<Array<MenuLink>> {
    return of(compact([
      new MenuLink({ href: '/login', text: 'SHARED.MENU.LINK_LOGIN', iconType: 'profile', iconColor: 'default' }),
      !this.webviewService.isWebview && new MenuLink({ href: '/services', text: 'SHARED.MENU.LINK_SERVICES', iconType: 'assignment', iconColor: 'default',addUrl:'/city/',isCity:true}),
       !this.webviewService.isWebview && new MenuLink({ href: '/commercial', text: 'SHARED.MENU.LINK_COMMERCIAL_SERVICES', iconType: 'wash-machine', iconColor: 'default',addUrl:'/laundry-services/city/',isCity:true }),
      !this.webviewService.isWebview && new MenuLink({ href: '/blog', text: 'SHARED.MENU.LINK_BLOG', iconType: 'blog', iconColor: 'default' }),
      !this.webviewService.isWebview && new MenuLink({ href: '/become-partner', text: 'SHARED.MENU.LINK_BECOME_PARTNER', iconType: 'handshake', iconColor: 'default' }),
      !this.webviewService.isWebview && new MenuLink({ href: '/signup-to-deliver', text: 'SHARED.MENU.LINK_SIGNUP_TO_DELIVER', iconType: 'van', iconColor: 'default' })
    ]));
  }

  private get privateMenuLinks$(): Observable<Array<MenuLink>> {
    return this.arePublicPromocodesAvailable$.pipe(
      map((arePublicPromocodesAvailable) => compact([
        new MenuLink({ href: '/services', text: 'SHARED.MENU.LINK_SERVICES', iconType: 'assignment', iconColor: 'default' }),
        new MenuLink({ href: '/my-orders', text: 'SHARED.MENU.LINK_MY_ORDERS', iconType: 'shopping-bag-fill', iconColor: 'default' }),
        new MenuLink({ href: '/refer-a-friend', text: 'SHARED.MENU.LINK_REFER_FRIEND', iconType: 'user-circle', iconColor: 'default' }),
        new MenuLink({ href: '/profile/preferences', text: 'SHARED.MENU.LINK_MY_PREFERENCES', iconType: 'design-services', iconColor: 'default' }),
        new MenuLink({ href: '/profile/notifications', text: 'SHARED.MENU.LINK_NOTIFICATIONS', iconType: 'notification', iconColor: 'default' }),
        new MenuLink({ text: 'SHARED.MENU.LINK_SUPPORT', iconType: 'chat', iconColor: 'default', action: () => this.openIntercom() }),
        !this.webviewService.isWebview && new MenuLink({ href: '/blog', text: 'SHARED.MENU.LINK_BLOG', iconType: 'blog', iconColor: 'default' }),

        arePublicPromocodesAvailable && new MenuLink({ href: '/promocodes', text: 'SHARED.MENU.LINK_PROMOS', iconType: 'gift' })
      ]))
    );
  }

  constructor(
    private userService: UserService,
    private store: Store<AppState>,
    private webviewService: WebviewService
  ) {
    this.isOpened$ = new BehaviorSubject(false);
  }

  public open(): void {
    this.isOpened$.next(true);
  }

  public close(): void {
    this.isOpened$.next(false);
  }

  public logout(): void {
    this.store.dispatch(AuthActions.logout());
  }

  public openIntercom(): void {
    this.store.dispatch(IntercomActions.show({}));
    this.close();
  }

  public get city$(): Observable<Location | null> {
    return this.store.select(UserZipSelectors.city);
  }
}
