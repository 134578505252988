import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorHandlingService } from './error-handling.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ErrorHandlingService]
})
export class ErrorHandlingModule { }

