import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { IconModule } from '@shared/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@Component({
  standalone: true,
  imports: [
    IconModule,
    InfiniteScrollModule
  ],
  selector: 'collapsible-field',
  templateUrl: 'collapsible-field.html',
  styleUrls: ['collapsible-field.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsibleFieldComponent {
  @Input() title: string;
  @Input() info: string;
  @Input() maxHeight: string;
  @Input() isCollapsed: boolean;

  @Output() opened: EventEmitter<void>;
  @Output() closed: EventEmitter<void>;
  @Output() scrolled: EventEmitter<void>;

  constructor() {
    this.isCollapsed = true;
    this.opened = new EventEmitter<void>();
    this.closed = new EventEmitter<void>();
    this.scrolled = new EventEmitter<void>();
  }

  public triggerButtonClciked(): void {
    this.isCollapsed = !this.isCollapsed;
    if (!this.isCollapsed) {
      this.opened.emit();
    } else {
      this.closed.emit();
    }
  }

  public contentScrolled(): void {
    this.scrolled.emit();
  }
}
