import { Pipe, PipeTransform } from '@angular/core';

const creditCardType = require('credit-card-type');

@Pipe({
  standalone: true,
  name: 'cvvMask'
})
export class CvvMaskPipe implements PipeTransform {
  public transform(cardNumber: string): string {
    const type = creditCardType(cardNumber)?.[0];
    if (!type?.code) {
      return '';
    }

    return '0'.repeat(type.code.size);
  }
}
