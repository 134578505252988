import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroupComponent } from '@shared/form-group';
import { ButtonComponent } from '@shared/button';
import { NgVariableDirective } from '@shared/ng-variable';
import { IconModule } from '@shared/icon';
import { AddressPickerComponent } from './address-picker.component';
import { RadioComponent } from '@shared/radio';
import { AddressInfoPipe } from '@shared/address-info';
import { NgForTrackByPropertyDirective } from '@shared/ng-for-track-by-property';
import { AddressPickerFacade } from './address-picker.facade';
import { AddressFormModule } from '@shared/address-form';
import { DialogViewModule } from '@shared/dialog-view';

@NgModule({
  declarations: [
    AddressPickerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormGroupComponent,
    ButtonComponent,
    NgVariableDirective,
    NgForTrackByPropertyDirective,
    IconModule,
    RadioComponent,
    AddressInfoPipe,
    AddressFormModule,
    DialogViewModule
  ],
  providers: [AddressPickerFacade],
  exports: [
    AddressPickerComponent
  ]
})
export class AddressPickerModule { }
