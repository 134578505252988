import type { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { GeolocationViewState } from './state';
import { UserAddressSelectors, UserZipSelectors } from '@shared/user';
import { some } from 'lodash';

const selectFeature = (state: AppState): GeolocationViewState => state.geolocationView;

export class GeolocationViewSelectors {
  public static isPredictingZip: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: GeolocationViewState) => state.isPredictingZip
  );

  public static predictedZip: MemoizedSelector<AppState, string> = createSelector(
    selectFeature,
    (state: GeolocationViewState) => state.predictedZip
  );

  public static isPredictionFailed: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: GeolocationViewState) => !state.isPredictingZip && !state.predictedZip
  );

  public static isInfoClosed: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: GeolocationViewState) => state.isInfoClosed
  );

  public static isLoading: MemoizedSelector<AppState, boolean> = createSelector(
    UserAddressSelectors.isUpserting,
    UserZipSelectors.isGeocoding,
    UserZipSelectors.isChecking,
    GeolocationViewSelectors.isPredictingZip,
    (...loadings) => some(loadings)
  );

  public static shouldShowInfo: MemoizedSelector<AppState, boolean> = createSelector(
    GeolocationViewSelectors.isLoading,
    UserZipSelectors.zipAddress,
    GeolocationViewSelectors.isPredictionFailed,
    GeolocationViewSelectors.isInfoClosed,
    (isLoading, zipAddress, isPredictionFailed, isInfoClosed) => !isInfoClosed && !isLoading && !zipAddress && isPredictionFailed
  );
}
