import { LocationRelation } from '@shared/location';
import { ServiceRelation } from '@shared/service';
import { Expose } from 'class-transformer';
import { SeoPageType } from '../enums';

export class GetSeoPageByURLRequest {
  @Expose()
  public url: string;

  @Expose()
  public type: SeoPageType;

  @Expose({ name: 'with' })
  public relation?: Array<LocationRelation | ServiceRelation>;

  @Expose({ name: 'zip' })
  public zipCode?: string;

  constructor(model: Partial<GetSeoPageByURLRequest>) {
    Object.assign(this, model);
  }
}
