import { Injectable } from '@angular/core';
import { ReadableEntityService } from '@shared/base-entity';
import { Service, ServicePaginationRequest } from './models';

@Injectable()
export class ServiceService extends ReadableEntityService<
  Service,
  ServicePaginationRequest
> {
  constructor() {
    super();

    this.endpoint = '/services';
    this.entityConstructor = Service;
    this.entityPaginationRequestConstructor = ServicePaginationRequest;
  }
}
