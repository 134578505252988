import { NamedEntity } from '@shared/base-entity';
import { Expose, Type } from 'class-transformer';
import { Contact } from './contact';

export class Laundry extends NamedEntity {
  @Expose()
  public name: string;

  @Expose()
  public location: string;

  @Expose()
  public description: string;

  @Expose()
  @Type(() => Contact)
  public owner: Contact;

  @Expose()
  @Type(() => Contact)
  public contact: Contact;

  @Expose({ name: 'net_payout' })
  public netPayout: number;

  @Expose({ name: 'partner_subtotal' })
  public partnerSubtotal: number;

  @Expose({ name: 'partner_tip' })
  public partnerTip: number;

  @Expose({ name: 'partner_delivery' })
  public partnerDelivery: number;

  @Expose()
  public timezone: string;

  @Expose({ name: 'google_place_id' })
  public placeID: string;

  @Expose()
  public latitude: string;

  @Expose()
  public longitude: string;

  public get viewName(): string {
    return this.name;
  }

  constructor(model: Partial<Laundry>) {
    super(model);
    Object.assign(this, model);
  }
}
