import { Location, LocationType } from '@shared/location';
import { Pagination } from '@shared/pagination';

export class PublicServicesServiceLocationsState {
  public static countryLocation = new Location({
    id: null,
    type: LocationType.COUNTRY,
    title: 'United States',
    children: []
  });

  public parent: Location | null;
  public isLoading: boolean;
  public pagination: Pagination;

  constructor(model?: Partial<PublicServicesServiceLocationsState>) {
    const defaultValue: Partial<PublicServicesServiceLocationsState> = {
      parent: null,
      isLoading: false,
      pagination: new Pagination({ perPage: 20 })
    };

    Object.assign(this, defaultValue, model);
  }

  public isParentChanged(newParent: Location | null): boolean {
    return !this.parent || this.parent.id !== (newParent?.id || null);
  }
}
