import { NamedEntity } from '@shared/base-entity';
import { Preference, PreferencePriceType, PreferenceType } from '@shared/preference';
import { Expose, Type } from 'class-transformer';
import { ServicePreferenceOption } from './service-option';

export class ServicePreference extends NamedEntity {
  @Expose({ name: 'preference_id' })
  public preferenceID: number;

  @Expose({ name: 'service_id' })
  public serviceID: number;

  @Expose({ name: 'laundry_id' })
  public laundryID: number;

  @Expose()
  @Type(() => Preference)
  public preference?: Preference;

  @Expose()
  @Type(() => ServicePreferenceOption)
  public options: Array<ServicePreferenceOption>;

  public override get viewName(): string {
    return this.preference?.viewName || '';
  }

  public get type(): PreferenceType | undefined {
    return this.preference?.type;
  }

  public get priceType(): PreferencePriceType | undefined {
    return this.preference?.priceType;
  }

  constructor(model: Partial<ServicePreference>) {
    super(model);

    Object.assign(this, model);
  }
}
