import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethodType } from '@shared/payment-method/enums';
import { PaymentMethod } from '@shared/payment-method/models';
import { startCase } from 'lodash';

@Pipe({
  standalone: true,
  name: 'paymentMethodName'
})
export class PaymentMethodNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  public transform(value: PaymentMethod): string {
    if (!value?.brand || value.type === PaymentMethodType.MANUAL) {
      return this.translateService.instant('SHARED.PAYMENT_METHOD.TEXT_CASH');
    }

    return `${startCase(value.brand)} ${value.last4 || ''}`;
  }
}
