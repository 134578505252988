import { PaginationRequest } from '@shared/pagination';
import { Expose } from 'class-transformer';
import { OrderStatus } from '../enums';
import { OrderBy, OrderRelation } from '../types';

export class OrderPaginationRequest extends PaginationRequest<OrderRelation, null, OrderBy> {
  @Expose({ name: 'status_id' })
  public statusID: Array<OrderStatus> | OrderStatus;

  constructor(model: Partial<OrderPaginationRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
