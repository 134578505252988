import { ServicePreferenceOption } from '../models';

export class ServicePreferenceOptionForm {
  public id: number;
  public fullName: string;
  public servicePreferenceID: number;

  constructor(form: Partial<ServicePreferenceOptionForm> = {}) {
    const defaultValue: ServicePreferenceOptionForm = {
      id: null,
      fullName: '',
      servicePreferenceID: null
    };

    Object.assign(this, defaultValue, form);
  }

  public static fromEntity = (
    option: ServicePreferenceOption
  ): ServicePreferenceOptionForm => ({
    id: option.id,
    fullName: option.fullName,
    servicePreferenceID: option.servicePreferenceID
  });

  public static toEntity = (
    formValue: ServicePreferenceOptionForm
  ): ServicePreferenceOption => new ServicePreferenceOption(formValue);
}
