import { Expose } from 'class-transformer';

export class UpdateClientPreferenceOptionsRequest {
  @Expose({ name: 'laundry_id' })
  public laundryID: number;

  @Expose({ name: 'service_id' })
  public serviceID: number;

  @Expose()
  public options: Array<number>;

  constructor(model: Partial<UpdateClientPreferenceOptionsRequest>) {
    Object.assign(this, model);
  }
}
