import { EntityRequest } from '@shared/base-entity';
import { UserRelation } from '../types';

export class UserEntityRequest extends EntityRequest<UserRelation> {
  constructor(request: Partial<UserEntityRequest>) {
    super(request);

    Object.assign(this, request);
  }
}
