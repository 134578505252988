import { isNil } from 'lodash';

export class LocalStorageBooleanItem {
  constructor(private key: string) { }

  public set(value: boolean): void {
    if (isNil(value)) {
      this.remove();
    } else {
      localStorage.setItem(this.key, value ? 'true' : 'false');
    }
  }

  public get(): boolean | null {
    const value = localStorage.getItem(this.key);
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }

    return null;
  }

  public remove(): void {
    localStorage.removeItem(this.key);
  }
}
