import { configuration } from '@configurations';
import { Action, createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import { PhoneVerificationActions } from './actions';
import { PhoneVerificationState } from './state';
import { FormControlState, onNgrxForms, reset, setValue, updateGroup, validate, wrapReducerWithFormStateUpdate } from 'ngrx-forms';
import { PhoneVerificationForm } from '../forms';
import { maxLength, minLength, required } from 'ngrx-forms/validation';

const initialState = new PhoneVerificationState();

const reducer = wrapReducerWithFormStateUpdate(
  createReducer(
    initialState,
    on(PhoneVerificationActions.resetState, (state) => ({
      ...initialState,
      lastCodeRequestTime: state.lastCodeRequestTime,
      checkAttempts: state.checkAttempts,
      data: state.data
    })),
    on(PhoneVerificationActions.tryStartVerification, (state, { data }) => ({
      ...state,
      data: data || state.data,
      formState: updateGroup<PhoneVerificationForm>(state.formState, {
        code: (code: FormControlState<string>) => reset<string>(setValue(code, ''))
      })
    })),
    on(PhoneVerificationActions.startVerification, (state) => ({
      ...state,
    })),
    on(PhoneVerificationActions.startVerificationSuccess, (state) => ({
      ...state,
      isStarting: false,
      lastCodeRequestTime: DateTime.local(),
      checkAttempts: configuration.verificationCodeCheckAttempts,
      stage:2
    })),
    on(PhoneVerificationActions.startVerificationFailure, (state) => ({
      ...state,
      isStarting: false,
      checkAttempts: 0
    })),
    on(PhoneVerificationActions.checkVerificationCode, (state) => ({
      ...state,
      checkAttempts: state.checkAttempts - 1,
      isChecking: true
    })),
    on(
      PhoneVerificationActions.checkVerificationCodeSuccess,
      PhoneVerificationActions.checkVerificationCodeFailure,
      (state) => ({
        ...state,
        isChecking: false
      })
    ),
    onNgrxForms()
  ),
  (state: PhoneVerificationState) => state.formState,
  updateGroup<PhoneVerificationForm>({
    code: validate<string>(required, minLength(4), maxLength(4))
  })
);

export const phoneVerificationReducer = (
  state: PhoneVerificationState | undefined,
  action: Action
): PhoneVerificationState => reducer(state, action);
