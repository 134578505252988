import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GeolocationViewComponent } from './geolocation-view.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/button';
import { NgVariableDirective } from '@shared/ng-variable';
import { IconModule } from '@shared/icon';
import { GeolocationViewFacade } from './geolocation-view.facade';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormGroupComponent } from '@shared/form-group';
import { FormControlComponent } from '@shared/form-control';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AddressPickerModule } from '@shared/address-picker';
import { NgrxFormsModule } from 'ngrx-forms';
import { GeolocationButtonComponent } from '@shared/geolocation-button';
import { GeolocationViewPredictedZipComponent } from './components/predicted-zip/predicted-zip.component';
import { GeolocationViewNoZipComponent } from './components/no-zip/no-zip.component';
import { DialogViewModule } from '@shared/dialog-view';
import { GeolocationViewEffects, geolocationViewReducer } from './store';
import { GeolocationViewZipPickerComponent } from './components';

@NgModule({
  declarations: [
    GeolocationViewComponent,
    GeolocationViewZipPickerComponent,
    GeolocationViewPredictedZipComponent,
    GeolocationViewNoZipComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonComponent,
    NgVariableDirective,
    IconModule,
    StoreModule.forFeature('geolocationView', geolocationViewReducer),
    EffectsModule.forFeature([GeolocationViewEffects]),
    FormGroupComponent,
    FormControlComponent,
    TooltipModule,
    AddressPickerModule,
    NgrxFormsModule,
    GeolocationButtonComponent,
    DialogViewModule
  ],
  providers: [GeolocationViewFacade],
  exports: [
    GeolocationViewComponent,
    GeolocationViewZipPickerComponent
  ]
})
export class GeolocationViewModule { }
